import { Fab, Box, Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextareaAutosize, Checkbox, FormControlLabel, Typography, CircularProgress, makeStyles, Tooltip } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { getUserProblemFilterElements, getFilteredUserProblemImagePaths } from '../../../../../../service/admin.service'
import { useAsync, useErrorMessage } from '../../../../../../util/hooks'

import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddToDataset from '../../../Dataset/AddToDataset';
// import { useMountedState } from 'react-use';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '35rem'
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  textareaAutosize: {
    width: '100%',
    height: 'calc(100% - 6px) !important',
    resize: 'none',
    overflowWrap: 'normal',
    whiteSpace: 'nowrap',
    border: 0,
    backgroundColor: '#efefef',
    outline: 0
  },
  redCheckedIcon: {
    color: 'white',
    backgroundColor: 'red',
    height: 20,
    width: 20,
    margin: 2,
    borderRadius: 4
  },
  tooltipIcon: {
    margin: '-4px 1px -4px 2px',
    fontSize: '18px'
  }
}))

const emptyOrNullish = (arr) => !(arr?.length)

function FilterForm({ userProblemId, setImageList, labels, descriptions }) {
  const classes = useStyles()
  const [includeds, setIncludeds] = useState({ labelIds: [] })
  const [excludeds, setExcludeds] = useState({ descriptions: [] })

  function handleOnIncludeClick(labelId) {
    if (includeds.labelIds.includes(labelId))
      setIncludeds({ ...includeds, labelIds: includeds.labelIds.filter(l => l !== labelId) })
    else
      setIncludeds({ ...includeds, labelIds: [...includeds.labelIds, labelId] })
  }

  function handleOnExcludeClick(descriptionId) {
    if (excludeds.descriptions.includes(descriptionId))
      setExcludeds({ ...excludeds, descriptions: excludeds.descriptions.filter(l => l !== descriptionId) })
    else
      setExcludeds({ ...excludeds, descriptions: [...excludeds.descriptions, descriptionId] })
  }

  return (
    <Box height='100%' display='grid'>
      <Box component='fieldset' display='flex' flexDirection='column' borderRadius='4px' >
        <legend>
          Getirilecek Resimler
          <Tooltip
            title={`Seçilenlere sahip olan resimler dahil edilecek. Hiçbiri seçilmedi ise bütün resimler dahil edilir. 
            (Seçenekler şunların birleşiminden oluşur: GT BBox Labels, UserProblemImage AnswerNewBox Labels, UserProblemImage AnswerLabels)`} >
            <HelpOutlineIcon className={classes.tooltipIcon} />
          </Tooltip>
        </legend>
        {emptyOrNullish(labels) &&
          <Typography
            noWrap align='center'
            style={{ margin: 'auto' }}>
            Resimlere ait hiçbir özellik bulunamadı! <br />
            Bütün resimler dahil edilmiş sayılacak.
          </Typography>
        }
        {labels.map(({ labelId, labelName }) => (
          <FormControlLabel
            key={`include-${labelId}`}
            label={labelName}
            control={
              <Checkbox
                color="primary"
                checked={includeds.labelIds.includes(labelId)}
                onClick={(e) => handleOnIncludeClick(labelId)}
              />}
          />
        ))}
      </Box>
      {!emptyOrNullish(descriptions) &&
        <Box component='fieldset' display='flex' flexDirection='column' borderRadius='4px' >
          <legend>
            Hariç Tutulacaklar
            <Tooltip title="Seçilen açıklamanın yazıldığı (description) resimler hariç tutulacak." >
              <HelpOutlineIcon className={classes.tooltipIcon} />
            </Tooltip>
          </legend>
          {descriptions.map(({ description }) => (
            <FormControlLabel
              key={`exclude-${description}`}
              label={description || '-'}
              control={
                <Checkbox
                  checked={excludeds.descriptions.includes(description)}
                  checkedIcon={<CloseIcon className={classes.redCheckedIcon} />}
                  onClick={(e) => handleOnExcludeClick(description)}
                />}
            />
          ))}
        </Box>
      }
      <ApplyFilterActionButton
        onReceiveData={setImageList}
        userProblemId={userProblemId}
        includeds={includeds}
        excludeds={excludeds}
      />
    </Box>
  )
}


function ApplyFilterActionButton({ onReceiveData, userProblemId, includeds, excludeds }) {
  const [loading, setLoading] = useState(false)
  const setMessage = useErrorMessage();

  async function submit() {
    if (loading) { return }
    setLoading(true)
    const { data, success, exception, message } = await getFilteredUserProblemImagePaths(userProblemId, includeds, excludeds)
    if (!success || exception)
      setMessage({ message, error: exception })
    else
      onReceiveData(data)
    setLoading(false);
  }

  return (
    <Box display='flex'>
      <Button endIcon={loading ? <CircularProgress size={24} /> : <DoubleArrowIcon />}
        disabled={loading} style={{ marginLeft: 'auto' }} onClick={submit}>
        Getir
      </Button>
    </Box>
  )
}

export default ({ open, userProblemId, onClose }) => {
  const classes = useStyles()
  const [imageList, setImageList] = useState([])
  const [error, setError] = useState()
  const [filterOptions, setFilterOptions] = useState()
  const [datasetDialogOpen, setDatasetDialogOpen] = useState(false)

  const { execute, status, value, error: networkErr } = useAsync(() => getUserProblemFilterElements(userProblemId), false)

  useEffect(() => {
    if (typeof (userProblemId) === 'number' && userProblemId !== 0)
      execute()
    else
      setFilterOptions()
  }, [userProblemId])

  useEffect(() => {
    if (status === 'success') {
      const { data, success, exception: apiError, message } = value;
      if (!success || apiError) {
        setError({ error: apiError, message })
      } else {
        setFilterOptions(data)
      }
    }
    if (status === 'error') {
      setError(networkErr)
    }
  }, [status])


  return <>
    <Dialog open={open} onClose={onClose} keepMounted={false} classes={{ paper: classes.dialogPaper }} maxWidth='md' scroll='body' fullWidth>
      <DialogTitle>{"Kullanıcı Problemlerine Ait Resimler"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item sm={6}>
            <Box display='grid' height='100%'>
              {status === 'pending' && <Box className={classes.loadingContainer}><CircularProgress style={{ margin: 'auto' }} /></Box>}
              {error && <Typography primary='Hata! :(' />}
              {filterOptions && <FilterForm userProblemId={userProblemId} setImageList={setImageList} {...filterOptions} />}
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box component='fieldset' position='relative' height='calc(100% - 19px)' borderRadius='4px' minHeight='25rem'>
              <legend>Resim Listesi</legend>
              <TextareaAutosize
                style={{ overflow: 'initial' }}
                className={classes.textareaAutosize}
                value={imageList.join('\n')}
                size='small'
                label='Resim Listesi'
                variant='outlined' />
              <Fab size='small' style={{ position: 'absolute', top: 15, right: 15 }} onClick={() => navigator.clipboard.writeText(imageList.join('\n'))}>
                <FileCopyIcon fontSize='small' />
              </Fab>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color="primary" onClick={() => setDatasetDialogOpen(open)}>
          Dataset'e Ekle
        </Button>
      </DialogActions>
    </Dialog>
    <AddToDataset open={datasetDialogOpen} imagePathList={imageList} onClose={() => setDatasetDialogOpen(false)} />
  </>
}