import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export default function CustomizedAutoComplete({data, showField, id, label, handleChange, width}) {
  return (
    <Autocomplete
      multiple
      options={data}
      disableCloseOnSelect
      getOptionLabel={(option) => option[showField]}
      renderOption={(renderProps, option, { selected }) => {
        renderProps.key = option[id];
        return (
          <li {...renderProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[showField]}
          </li>
        );
      }}
      style={{ width: width }}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={handleChange}
    />
  );
}
