import React from 'react';
import {Image} from 'react-konva';

export default React.memo(({maskImg, alpha}) => {
  return (
    <Image
      x={0}
      y={0}
      image={maskImg}
      opacity={alpha}
      name='mask-image'
    />
  )
}, (prevProps, nextProps) => (
  prevProps.maskImg === nextProps.maskImg && 
  prevProps.alpha === nextProps.alpha
  ))

