import React, { useState } from 'react'
import {
  DialogTitle,
  Paper,
  DialogActions,
  DialogContent,
  Button,
  TextareaAutosize,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: theme.shadows[4],
    minHeight: (776 - 64) / 2
  },
  form: {
    height: '100%'
  },
  dialogContent: {
    height: 'calc(100% - 96px - 36px)',
    overflowY: 'unset !important'
  },
  textArea: {
    height: '99% !important',
    width: '99% !important',
    resize: 'none',
  }
}))

export default ({ title, onSubmit }) => {
  const classes = useStyles()
  const [imagePaths, setImagePaths] = useState([])

  function onChange(e) {
    var items = e.target.value.trim().split('\n')
    items = items.map(path => path.trim())
    items = items.filter(path => !!path)
    setImagePaths(items)
  }

  function submit(e) {
    e.preventDefault()
    onSubmit(imagePaths)
  }

  return (
    <Box component={Paper} className={classes.root}>
      <form onSubmit={submit} className={classes.form}>
        <DialogTitle style={{ textAlign: 'center' }} >{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <TextareaAutosize
            className={classes.textArea}
            size='small'
            label='Resim Listesi'
            variant='outlined'
            required
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Typography style={{ marginRight: 'auto' }}>{'Toplam: '} {imagePaths.length}</Typography>
          <Button id='createVis-JsonProject' type='submit' color="primary" autoFocus>
            Gönder
          </Button>
        </DialogActions>
      </form>
    </Box>
  )
}