import UserManagementRouter from './UserManagementRouter';
import ImageQueryRouter from './ImageQueryRouter';
import ProjectsRouter from './ProjectsRouter';
import SystemSettingsRouter from './SystemSettings/SystemSettingsRouter';
import HelpRouter from './HelpRouter';
import SystemLogsRouter from './SystemLogsRouter';

export default [
  {
    path: 'projects',
    component: ProjectsRouter,
  },
  {
    path: 'image-query',
    component: ImageQueryRouter,
  },
  {
    path: 'settings',
    component: SystemSettingsRouter,
  },
  {
    path: 'users',
    component: UserManagementRouter,
  },
  {
    path: 'help',
    component: HelpRouter,
  },
  {
    path: 'system-logs',
    component: SystemLogsRouter,
  }
];
