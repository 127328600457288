import React, { useState } from 'react';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { actions } from '../../../../../redux/slices/admin';
import { useConfirm } from 'material-ui-confirm';
import { useDispatch } from 'react-redux';
import { addImageSource } from '../../../../../service/admin.service';
import { makeStyles } from '@material-ui/core/styles';
import { usePending } from '../../../../../GlobalComponents/Contexes/pending';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { addCloudWatchLog } from '../../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  addImageSource: {
    marginLeft: '18%',
    marginTop: '5%',
  },
  labelEl: {
    display: 'inline-block',
    position: 'relative',
    top: '2.4rem',
    right: '10rem',
  },
  inputEl: {
    width: '40%',
  },
  cancelButton: {
    position: 'relative',
    top: '2rem',
    left: '7rem',
  },
  saveButton: {
    position: 'relative',
    top: '2rem',
    left: '9rem',
  },
}));

function useAddImageSourceFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const addImageSourceFn = addImageSource;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`AddImageSource.js:54 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`AddImageSource.js:68 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await addImageSourceFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.updateEnum({ enumKey: 'imageSources', data }));
    }
    return { data, success, error, message };
  }
  return submit;
}

function InputandLabel({ inputId, label, setName }) {
  const classes = useStyles();
  let strategies = new Map([['0', setName]]);

  function startAction(inputId, input) {
    let strategy = strategies.get(inputId);
    strategy(input);
  }

  const handleChange = (e) => {
    startAction(e.target.id, e.target.value);
  };

  return (
    <div>
      <InputLabel className={classes.labelEl}>{label}</InputLabel>
      <TextField
        className={classes.inputEl}
        id={inputId}
        label={label}
        onChange={handleChange}
        required
      />
    </div>
  );
}

export default function AddImageSource() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const addImageSourceFn = useAddImageSourceFunction();
  const labels = ['Image Source Name'];

  const history = useHistory();

  let name;

  const setName = (input) => (name = input);

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const request = {
      name,
    };

    const { success, error } = await addImageSourceFn(request);
    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/settings/image-source')
    }
  }

  return (
    <Box
      className={classes.addImageSource}
      component='form'
      autoComplete='off'
      onSubmit={submit}
    >
      {labels.map((label, index) => (
        <InputandLabel
          key={index}
          inputId={index.toString()}
          label={label}
          setName={setName}
        />
      ))}
      <Button
        className={classes.cancelButton}
        color='primary'
        variant='contained'
        to='/admin/settings/image-source'
        component={Link}
      >
        Cancel
      </Button>
      <Button
        className={classes.saveButton}
        type='submit'
        color='primary'
        variant='contained'
        disabled={loading}
      >
        Save
      </Button>
    </Box>
  );
}
