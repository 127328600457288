export function getRelativePointerPosition(node, normalize=true) {
    // source: https://konvajs.org/docs/sandbox/Relative_Pointer_Position.html
    // the function will return pointer position relative to the passed node
    const transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();
  
    // get pointer (say mouse or touch) position
    const pos = node.getStage().getPointerPosition();
    
    // now we find relative point
    const point = transform.point(pos);
    
    if (normalize){
      // normalize
      const imNode = node.children[0]
      if (imNode.name() !== 'fundus-image'){
        throw new Error('Image must be the first child of Layer!')
      }
      const {width, height} = imNode.size();
      point.x = parseFloat((point.x / width).toFixed(5))
      point.y = parseFloat((point.y / height).toFixed(5))
    }
    
    return point;
  }