import { addCloudWatchLog } from "../service/cloud.service";

function getErrorStatusCode(e){
  let eJson = e.toJSON();
  debugger
  if (eJson.message.includes("status code"))
    return e.message.split(" ").pop()
  return undefined
}


export function humanizeError(e){
  if (typeof(e.toJSON) !== "function") {
    addCloudWatchLog(`handleError.js:14 ${e}`);
    throw console.error(e);
  }
  let statusCode = getErrorStatusCode(e)

  debugger
  switch (statusCode) {
    case undefined:
      addCloudWatchLog(`Bilinmeyen hata ${e}`);
      return `Bilinmeyen Hata (${e.message})`;
    case "401":
      addCloudWatchLog(`Erişim yetkisi yok ${e}`);
      return "Erişim yetkisi yok."
    case "500":
      addCloudWatchLog(`Yanlış Kullanıcı Bilgileri ${e}`);
      return "Yanlış Kullanıcı Bilgileri"
    default:
      addCloudWatchLog(`handleError.js:28 ${e} ${statusCode}`);
      console.error("Unhandled statusCode", statusCode , e)
      return `Bilinmeyen Hata (${e.message})`;
  }
} 

export function ErrorContent({message, e}){
  return message
}