const useFlagCoordinates = (points) => {
    let maxX = Number.MIN_SAFE_INTEGER;
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
  
    points.forEach((point) => {
      const x = point?.x;
      const y = point?.y;
  
      if (maxX < x) {
        maxX = x;
      }
  
      if (x < minX) {
        minX = x;
      }
  
      if (y < minY) {
        minY = y;
      }
    });
  
    return { maxX, minX, minY };
  };
  
  export default useFlagCoordinates;
  