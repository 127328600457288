import Api, { ROOT } from './Api';

export async function activateUserProblem(userProblemId) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/userproblems/activate/${userProblemId}`,
  })
  return res.data
}

export async function getPossessedUserProblems() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/userproblems/getpossessed`,
  })
  return res.data
}

export async function createNewProblem(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/userproblems/CreateUserProblem`,
    data
  })
  return res.data
}
