import Api, { ROOT } from './Api';
export { fetchImageData } from './file.service';

// labelIdToFilter: 5
// modelIdentifier: "combined5"

export async function getImages(params) {
  let body = {
    "pageNumber": 1,
    "pageSize": 5,
    "onlyUnanswereds": true,
    "labelIdToFilter": 0,
    "modelIdentifier": "",
    ...params
  }
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/images/getpaginated`,
    data: body
  });
  return res.data;
}

export async function getImage(id) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/images/${id}`
  })
  return res.data
}

export async function getImagesAfterIdx(params) {
  let body = {
    "afterImageId": 1,
    "count": 5,
    "onlyUnanswereds": false,
    "labelIdToFilter": 0,
    "modelIdentifier": "",
    ...params
  }
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/images/getimagesafterindex`,
    data: body
  });
  return res.data;
}

export async function getImageWithIdx(userProblemId, index) {
  let body = {
    userProblemId,
    index
  }
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/images/GetImageWithIndex`,
    data: body
  })
  return res.data
}

export async function getImageWithName(userProblemId, imageName) {
  let body = {
    userProblemId,
    imageName,
  }
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/images/GetImageWithImageName`,
    data: body
  })
  return res.data
}

export async function getUserProblemProgress(userProblemId) {
  const body = { userProblemId }
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/userproblems/GetUserProblemProgress`,
    data: body
  })
  return res.data
}

// let table = []
// let resTable = []
export async function sendAnswer(data) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/answers/v2`,
    data
  })
  // table = [...table, {...data, labelIds: data.labelIds.join(', '), method: isNew?'POST':'PUT'}]
  // resTable = [...resTable, {...res.data.data, labelIds: res.data.data.labelIds.join(', '), method: isNew?'POST':'PUT'}]
  // console.table(table)
  // console.table(resTable)
  return res.data
}