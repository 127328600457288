import Api, { ROOT } from './Api';

export async function getActiveUserProblem() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/userproblems/getactive`,
  })
  return res.data
}


export async function getLabelsWithProjectId(id) { // İşaretlemedeki labelların dinamik gelmesi için
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/userproblems/getProjectLabels/${id}`,
  })
  return res.data
}