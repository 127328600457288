import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';
import ImageSourceRouter from './ImageSourceRouter';
import ImagesRouter from './ImagesRouter';
import Category, {AddCategory, EditCategory} from '../../Components/SystemSettings/Category/Category';
import DatasetRouter from './DatasetRouter';
import Prediction from '../../Components/SystemSettings/Prediction/AddPrediction';

export default function () {
  const match = useRouteMatch();
  return (
    <>
      <ImageSourceRouter />
      <ImagesRouter />
      <DatasetRouter />
      <Route path={`${match.url}/prediction`} component={Prediction}></Route>
      <Route path={`${match.url}/category`} component={Category}></Route>
      <Route path={`${match.url}/add-category`} component={AddCategory}></Route>
      <Route path={`${match.url}/edit-category/:id`} component={EditCategory}></Route>
      <Route path={`${match.url}/information`} component={information}></Route>
    </>
  );
  
  function information() {
    return <></>;
  }
}
