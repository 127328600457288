import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  Paper,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
// import { createNewProblem as _createNewProblem } from '../../../../../../service/options.service';
import { addImagesToUserProblem } from '../../../../../../service/admin.service';
import { useParams } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { addCloudWatchLog } from '../../../../../../service/cloud.service';

const useStyles = makeStyles(theme => ({
  root: {
    // zIndex: '1650 !important',
  }
}))

export default ({ open, onClose }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [imagePaths, setImagePaths] = useState([])
  const confirm = useConfirm();

  function onChange(e) {
    var items = e.target.value.trim().split('\n')
    items = items.map(path => path.trim())
    items = items.filter(path => !!path)
    setImagePaths(items)
  }

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`AddManual.js:45 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`AddManual.js:59 ${e}`);
        console.error(e);
      });
  }

  function submit(e) {
    e.preventDefault()

    const data = {
      imagePaths,
      id: id
    }

    addImagesToUserProblem(data)
      .then(res => {
        if (res.success) {
          onClose(id, 'success')
          return;
        }
        showMessage({ message: res.message})
      })
  }

  return (
    <Dialog className={classes.root} maxWidth='md' open={open} onClose={onClose} fullWidth>
      <form onSubmit={submit}>
        <DialogTitle>{"Projeye Resim Ekle"}</DialogTitle>
        <Box component={Paper} mx={2} mb={2}>
          <TextField size='small' label='Resim Listesi' variant='outlined' id='adminImageList' fullWidth required multiline minRows={20} maxRows={20} onChange={onChange} />
        </Box>
        <DialogActions>
          <Typography style={{ marginRight: 'auto' }}>{'Toplam: '} {imagePaths.length}</Typography>
          <Button id='adminSendImagepath' type='submit' color="primary" autoFocus>
            Gönder
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}