import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { makeStyles, Divider, Box, Button } from '@material-ui/core';
import { actions } from '../../redux/slices/admin';
import { getAllEnumerations, getQuartzJobInfoList  } from '../../service/admin.service';
import { useSelector, useDispatch } from 'react-redux';
import routerService from './routes/routerService';
import { Route, Redirect, Switch } from 'react-router-dom';
import Nav from './Components/Nav';
import PopUpAnnotate from '../../GlobalComponents/PopUpAnnotate';
import { useAsync } from '../../util/hooks';

const useStyles = makeStyles(theme => ({
  appBarSpacer: {
    height: '48px',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  annoutationButton:{
    position:'fixed',
    backgroundcolor:'#D3D3D3',
    size:'70px', 
    bottom:'0', 
    right:'12px',
  }
}))

function useAdminStore() {
  const dispatch = useDispatch()

  const loading = useSelector(state => state.admin.loading)
  const error = useSelector(state => state.admin.error)

  const setLoading = (bool) => dispatch(actions.setLoading(bool))
  const setEnums = (enums) => dispatch(actions.setEnums(enums))
  const setError = (err) => dispatch(actions.setError(err))

  return { loading, error, setLoading, setEnums, setError }
}

export default function () {
  const classes = useStyles();
  const [open,setOpen] =useState(false);
  const [items,setItems] =useState([])
  const [listError,setListError] = useState()
  const { execute:executeList, status:listStat, value:listValue, error:listNetworkErr} = useAsync(() => getQuartzJobInfoList(), false)

  useEffect(() => {executeList ()}, [open]);
  
  const { loading, error, setLoading, setEnums, setError } = useAdminStore()

  const getJobInfoList = async()=> {
    if(listStat ==='success' && listValue!==null){
     const { data, success, exception: apiError2, message } = await listValue;
      if (apiError2 || !success) {
             setListError({error: apiError2,message})
           } else {
              setItems(data.slice(-5)) 
            }
    }
    if (listStat === 'error') {
      setListError(listNetworkErr)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  };

  const handleOpen = ()=>{
    setOpen(true)
    executeList()
    getJobInfoList()
    return open;
  }


  useState(() => {
    let unmounted = false;
    (async () => {
      try {
        const data = await getAllEnumerations()
        setEnums(data)
      } catch (e) {
        if (unmounted) { return }
        setError(e)
      } finally {
        if (unmounted) { return }
        setLoading(false)
      }
    })()
    return () => {
      unmounted = true
    }
  }, [])

  if (error) {
    return <div>
      <h1>Hata</h1>
      <Divider />
      <div>{error.message ?? 'Bir hata ile karşılaşıldı!'}</div>
    </div>
  }

  if (loading) {
    return <div>Tanımlamalar yükleniyor...</div>
  }


  return (
    <div style={{ display: "flex" }}>
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Scrollbars
          autoHide
          autoHideDuration={100}
          style={{ height: 'calc(100vh - 48px)' }}
        >
          <Switch>
            {routerService.map((route, key) =>
              <Route key={key} path={`/admin/${route.path}`} component={route.component} />
            )}
            <Route>
              <Redirect to='/admin/projects' />
            </Route>
          </Switch>
        </Scrollbars>
        <Box sx={{ display:'flex', alignItems: 'flex-end', justifycontent: 'flex-end' } } className={classes.annoutationButton}>
          <Button  variant='contained' color='inherit' onClick={handleOpen} >Job Status</Button>
          {(open ?(<PopUpAnnotate open={open} onClose={handleClose} items={items} status={listStat} userProblemId={null} ></PopUpAnnotate>):'')}
        </Box>
      </main>
    </div>
  )
}