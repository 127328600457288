import React, { useEffect, useState } from 'react';
import { actions } from '../redux/slices/user';
import { Container } from '@material-ui/core';
import Evaluate from './Evaluate';
import Annotate from './Annotate';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveUserProblem as _getActiveUserProblem, getLabelsWithProjectId } from '../service/user.service';
import LoadingMessage from '../GlobalComponents/LoadingMessage';
import { useApiFunction } from '../util/hooks';
import { useMountedState } from 'react-use';
import { useLabelMap } from './Annotate/store';
import { addCloudWatchLog } from '../service/cloud.service';


const useUser = () => {
  const dispatch = useDispatch();
  const panelType = useSelector(state => state.user.panelType);
  const problem = useSelector(state => state.user.problem);

  return {
    problem, panelType,
    setProblem: (newUserProblem) => dispatch(actions.setProblem(newUserProblem)),
  }
}

function Panel() {
  const isMounted = useMountedState();
  const [loading, setLoading] = useState(true)
  const { problem, panelType, setProblem } = useUser()
  const getActiveUserProblem = useApiFunction(_getActiveUserProblem)
  const getLabelsByProjectId = useApiFunction((id) => getLabelsWithProjectId(id));
  
  
  const setLabels = useLabelMap(store => store.setLabels)
  const setDiseaseLabels = useLabelMap(store => store.setDiseaseLabels)

  useEffect(() => {
    // If current local category is dummy problem (Vis-Json)
    // then dont sync local with cloud.
    // (See ../GlobalComponents/Options/Options/Components/UserProblems.js)

    if (problem.problemCategoryId === 5) {
      setLoading(false)
      return () => { }
    }
    (async function () {
      const { data: userProblem, success, error } = await getActiveUserProblem()
      if (!isMounted()) { return; }
      setLoading(false)
      if (!success) { return setProblem({ problemCategoryId: 5})  }
      const { data: categoryLabel, success: categoryLabelStatus, message: categoryLabelMessage } = await getLabelsByProjectId(userProblem.id)
      setProblem({
        userProblemId: userProblem.id,
        problemCategoryId: userProblem.problemCategory.categoryTypeId,  // problemCategoryId changed to CategoryTypeId
        completed: userProblem.completed
      })
      if (!categoryLabelStatus) { 
        addCloudWatchLog(`Panel.js:58 ${error} ${categoryLabelMessage}`);
        
        return console.error({ error: { ...error, message: categoryLabelMessage } }) 
      }
      if (categoryLabelStatus) {
        setDiseaseLabels(categoryLabel)
        let labels = {}
        for (let label of categoryLabel) {
          labels = { ...labels, [label.id]: label.name }
        }
        setLabels(labels)
       }
    })()
  }, [])

  if (loading)
    return <LoadingMessage loading message="Aktif problem bilgileri alınıyor..." />
  if (problem.error)
    return <LoadingMessage message={problem.error.message ?? "Aktif problemi isterken hata oluştu!"} />
  if (panelType === 'NotSet')
    return <LoadingMessage loading message="Aktif problem bilgileri alındı..." />
  if (panelType === 'Annotation')
    return <Annotate key={`annotate-panel-${problem.userProblemId}`} {...problem} />
  if (panelType === 'Evaluation')
    return (
      <Container style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <Evaluate key={`evaluate-panel-${problem.userProblemId}`} />
      </Container>
    )
  return "..."
}


export default Panel