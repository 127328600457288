import React, { useEffect, useState } from 'react'

import { getDatasetsInfo, getDatasetFilterOptions } from '../../../../../service/annotate.service';
import { useAsync, useErrorMessage } from '../../../../../util/hooks';

import {
  Box, Paper, makeStyles,
  List, ListItem, ListItemText,
  DialogTitle, DialogContent, Dialog, DialogActions,
  Collapse, CircularProgress,
  IconButton, ListItemSecondaryAction, Typography, Divider, Button, Tooltip, FormControlLabel, Checkbox
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Scrollbars from 'react-custom-scrollbars';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';


const emptyOrNullish = (arr) => !(arr?.length)

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: theme.shadows[4],
    minHeight: (776 - 64) / 2

  },
  dialogContent: {
    padding: 24,
    paddingTop: 15,
    height: 'calc(100% - 96px)',
    overflowY: 'unset !important'
  },
  textArea: {
    height: '99% !important',
    width: '99% !important',
    resize: 'none',
    minHeight: '14rem'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  dialogPaper: {
    minHeight: "calc(100% - 64px)"
  },
  tooltipIcon: {
    margin: '-4px 1px -4px 2px',
    fontSize: '18px'
  },
  redCheckedIcon: {
    color: 'white',
    backgroundColor: 'red',
    height: 20,
    width: 20,
    margin: 2,
    borderRadius: 4
  }
}))

const NameCount = React.memo(({ name, count }) => <Box display='flex'>{name}<Box ml={'.5rem'} color='#adadad'>{`#${count}`}</Box></Box>)

function DatasetListItem({ onClick, datasetId, name, imageCount, imageSources }) {
  const classes = useStyles();
  const [infoOpen, setInfoOpen] = useState(false);


  return <>
    <ListItem button onClick={onClick}>
      <ListItemText primary={<NameCount name={name} count={imageCount} />} />
      <ListItemSecondaryAction>
        <IconButton onClick={() => setInfoOpen(!infoOpen)} edge="end" aria-label="info">
          {infoOpen ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemSecondaryAction>

    </ListItem>
    <Divider />
    <Collapse in={infoOpen} timeout="auto" unmountOnExit>
      <List dense component="div" disablePadding>
        {imageSources && imageSources.map(({ imageSourceName, count }) => (
          <ListItem key={`dataset-${datasetId}-imsrc-${imageSourceName}`} className={classes.nested}>
            <ListItemText primary={<NameCount name={imageSourceName} count={count} />} />
          </ListItem>
        ))}
        <Divider />
      </List>
    </Collapse>
  </>
}

function CheckboxFieldset({ title, infoString, selecteds, onSelect, items, checkedIcon }) {
  const classes = useStyles()
  return (
    <Box component='fieldset' display='flex' flexDirection='column' borderRadius='4px' >
      <legend>
        {title}
        <Tooltip title={infoString}>
          <HelpOutlineIcon className={classes.tooltipIcon} />
        </Tooltip>
      </legend>
      {emptyOrNullish(items) ?
        <Typography
          noWrap align='center'
          style={{ margin: 'auto' }}>
          Resimlere ait hiçbir özellik bulunamadı! <br />
          Bütün resimler dahil edilmiş sayılacak.
        </Typography>

        : items.map(({ id, name }) => (
          <FormControlLabel
            key={`include-${id}`}
            label={name}
            control={
              <Checkbox
                checkedIcon={checkedIcon}
                color="primary"
                checked={selecteds.includes(id)}
                onClick={(e) => onSelect(id)}
              />}
          />
        ))}
    </Box>
  )
}
const defaultSelecteds = { imageSourceId: [], imageQualityId: [], labelIds: [], descriptions: [] }
function FilterDialog({ selectedDataset, onClose, onVisJsonRequested }) {
  const [selecteds, setSelecteds] = useState(defaultSelecteds)
  const classes = useStyles()
  const [filterOptions, setFilterOptions] = useState(null);
  const showMessage = useErrorMessage();
  useEffect(() => {
    if (selectedDataset) {
      getDatasetFilterOptions(selectedDataset.datasetId)
        .then((resData) => {
          if (!resData.success || resData.exception) {
            return { ...resData.exception, message: resData.message }
          }
          else {
            setFilterOptions({
              imageSourceId: resData.data.imageSources.map(({ imageSourceId, imageSourceName }) => ({ id: imageSourceId, name: imageSourceName })),
              imageQualityId: resData.data.imageQualities.map(({ imageQualityId, imageQualityName }) => ({ id: imageQualityId, name: imageQualityName })),
              labelIds: resData.data.labels.map(({ labelId, labelName }) => ({ id: labelId, name: labelName })),
              descriptions: resData.data.descriptions.map(({ description }) => ({ id: description, name: description }))
            })
          }
        })
        .catch((e) => {
          showMessage(e)
        })
      // Dataset Id provided, fetch filter options
    }
    else if (filterOptions) {
      //CleanUp
      setFilterOptions(null)
      setSelecteds(defaultSelecteds)
    }

  }, [selectedDataset?.datasetId])

  function handleSelected(key) {
    return (id) => {
      setSelecteds({
        ...selecteds,
        [key]: selecteds[key].includes(id) ?
          selecteds[key].filter(_id => _id !== id)
          : [...selecteds[key], id]
      })
    }
  }
  function submit() {
    let data;
    let notEmpty = ['labelIds', 'imageQualityId', 'imageSourceId', 'descriptions'].some((key) => selecteds[key].length !== 0)
    if (notEmpty) {
      data = selecteds
    }
    onVisJsonRequested(selectedDataset.datasetId, data)
  }

  return (
    <Dialog open={!!selectedDataset} onClose={onClose} fullWidth maxWidth="md" classes={{ paper: classes.dialogPaper }}>
      <DialogTitle>{selectedDataset && selectedDataset.name}</DialogTitle>
      <DialogContent style={{ whiteSpace: 'pre', display: "flex" }}>
        {
          filterOptions ?
            <Box width="100%">
              <CheckboxFieldset infoString="deneme" items={filterOptions.labelIds} selecteds={selecteds.labelIds} onSelect={handleSelected("labelIds")} title="labelIds" />
              <CheckboxFieldset infoString="deneme" items={filterOptions.imageQualityId} selecteds={selecteds.imageQualityId} onSelect={handleSelected("imageQualityId")} title="imageQualityId" />
              <CheckboxFieldset infoString="deneme" items={filterOptions.imageSourceId} selecteds={selecteds.imageSourceId} onSelect={handleSelected("imageSourceId")} title="imageSourceId" />
              <CheckboxFieldset infoString="deneme" items={filterOptions.descriptions} selecteds={selecteds.descriptions} onSelect={handleSelected("descriptions")} title="descriptions" checkedIcon={<CloseIcon className={classes.redCheckedIcon} />} />
            </Box>
            : <Box margin="auto" className={classes.loadingContainer}><CircularProgress style={{ margin: 'auto' }} /></Box>
        }
        { }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          İptal
        </Button>
        <Button onClick={submit}>
          Filtrele
        </Button>

      </DialogActions>
    </Dialog>
  )
}



export default ({ title, onVisJsonRequested }) => {
  const classes = useStyles();
  const [error, setError] = useState();
  const [datasets, setDatasets] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null)
  const showMessage = useErrorMessage();
  const { status, value, error: networkErr } = useAsync(getDatasetsInfo)

  useEffect(() => {
    if (status === 'success') {
      const { data, success, exception: apiError, message } = value;
      if (!success || apiError) {
        setError({ error: apiError, message })
      } else {
        setDatasets(data)
      }
    }
    if (status === 'error') {
      setError(networkErr)
    }
  }, [status])


  useEffect(() => {
    if (error) {
      showMessage(error)
    }
  }, [error])

  return (
    <Box component={Paper} className={classes.root}>
      <DialogTitle style={{ textAlign: 'center' }} >{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Scrollbars style={{ height: '100%' }} autoHide>

          {status === 'pending' && <Box className={classes.loadingContainer}><CircularProgress style={{ margin: 'auto' }} /></Box>}
          {error && <Typography primary='Hata! :(' />}
          {datasets && (
            <List>
              {datasets.map((dataset) => (
                <DatasetListItem key={`dataset-${dataset.datasetId}`} onClick={() => setSelectedDataset(dataset)} {...dataset} />
              ))}
            </List>
          )}
        </Scrollbars>
      </DialogContent>
      <FilterDialog selectedDataset={selectedDataset} onClose={() => setSelectedDataset(null)} onVisJsonRequested={onVisJsonRequested} />
    </Box>
  )
}