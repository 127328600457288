import React, { useEffect, useState, useMemo } from 'react'
import { getImageWithIdx, getImageWithName } from '../../../service/evaluate.service'
import { useConfirm } from 'material-ui-confirm'
import { ErrorContent } from '../../../util/handleError'
import prepareImageObject from '../../../util/prepareImageObject';
import { useMountedState } from 'react-use';

function withResponseParser(fetchFn) {
  return async (...args) => {
    const { data, success, error, message } = await fetchFn(...args)
    if (data === null || !success || error)
      return { data, success: false, error, message: 'Bilinmeyen bir hata oluştu' }

    let parsedImageData = prepareImageObject(data)
    return { data: parsedImageData, success, error, message }
  }
}

function withPrecache(fetchFn, maxIdx, prevSize = 3, futureSize = 5) {
  const cache = new Map()

  function updateCache(idx) {
    let start = Math.max(idx - prevSize, 0)
    let end = Math.min(idx + futureSize, maxIdx)

    for (let i = start; i <= end; i++) {
      if (cache.has(i))
        continue

      const dataPromise = fetchFn(i)
      cache.set(i, dataPromise)
    }

    for (const i of cache.keys()) {
      if (i < start || end < i) {
        cache.delete(i)
      }
    }
  }

  return (idx) => {
    updateCache(idx)
    return cache.get(idx)
  }
}

export default function (userProblemId, { initialIdx, maxIdx }) {
  const isMounted = useMountedState();
  const [imageObject, setImageObject] = useState(null)
  const [loading, setLoading] = useState(true)
  const confirm = useConfirm()

  function withLoader(fetchFn) {
    return async (...args) => {
      if (!isMounted()) { return }
      setLoading(true)
      let res = await fetchFn(...args)
      if (!isMounted()) { return }
      setLoading(false)
      return res
    }
  }

  const getImage = useMemo(
    () => withLoader(
      withPrecache(
        withResponseParser(
          (idx) => getImageWithIdx(userProblemId, idx)),
        maxIdx
      )
    ), [userProblemId, maxIdx])

  const currentIndex = imageObject?.currentIndex ?? 0;
  const hasPrev = currentIndex > 0;
  const hasNext = currentIndex < maxIdx;


  function showErrorMessage(message, error = null, title = 'Hata', showButton = true) {
    confirm({
      title,
      confirmationText: 'Sayfayı Yenile',
      confirmationButtonProps: showButton ? { color: 'primary' } : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: <ErrorContent message={message} e={error} />
    }).then(() => { window.location.reload() })
  }

  /*Fetch first unanswered image info*/
  useEffect(() => {
    if (typeof (initialIdx) === "number") {
      (async () => {
        const { data, success, error, message } = await getImage(initialIdx)
        if (!isMounted()) { return }
        if (!success || error)
          return showErrorMessage(message || 'Bilinmeyen hata!', error)

        setImageObject(data)
        setLoading(false)
      })()
    }
  }, [initialIdx])

  //TODO(): Refactor */
  async function next(answerData) {
    if (!isMounted()) { return }
    answerData && Object.assign(imageObject, answerData) //Update image data in cache

    setLoading(true)

    if (!hasNext) { return isMounted() && setLoading(false) }
    const { data, success, error, message } = await getImage(currentIndex + 1);
    if (!isMounted()) { return }
    if (!success || error)
      return showErrorMessage(message || 'Bilinmeyen hata!', error)

    setImageObject(data)
    setLoading(false)
  }

  async function prev() {
    if (!isMounted()) { return }
    setLoading(true)

    const { data, success, error, message } = await getImage(currentIndex - 1);
    if (!isMounted()) { return }
    if (!success || error)
      return showErrorMessage(message || 'Bilinmeyen hata!', error)

    setImageObject(data)
    setLoading(false)
  }

  async function jumpTo(idx) {
    if (!isMounted()) { return }
    idx = parseInt(idx)
    setLoading(true)
    const { data, success, error, message } = await getImage(idx);
    if (!isMounted()) { return }
    if (!success || error)
      return showErrorMessage(message || 'Bilinmeyen hata!', error)

    setImageObject(data)
    setLoading(false)
  }

  async function jumpToWithDrSays(imageName) {
    if (!isMounted()) {
      return;
    }
    setLoading(true);
    const { data, success, error, message } = await getImageWithName(userProblemId, imageName);
    if (!isMounted()) {
      return;
    }
    if (!success || error)
      return showErrorMessage(message || 'Bilinmeyen hata!', error);

    let parsedImageData = prepareImageObject(data);

    setImageObject(parsedImageData);
    setLoading(false);
  }

  return { next, hasNext, prev, hasPrev, jumpTo, jumpToWithDrSays, imageObject, loading }
}