import React from 'react'
import { Grid, Box } from '@material-ui/core'
import CreateProject from '.'

export default function (props) {
  return (
    <Box pl={4} pr={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CreateProject />
        </Grid>
      </Grid>
    </Box>

  )
}