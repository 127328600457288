import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { getUserProblemProgress } from '../../service/evaluate.service';
import DiseaseCard from './Components/DiseaseCard';
import { ErrorContent } from '../../util/handleError';
import LoadingMessage from '../../GlobalComponents/LoadingMessage'
import useImageService from './Components/useImageService'
import { useMountedState } from 'react-use';

const getEvaluateCard = ({ problemCategoryId, onErr }) => {
  switch (problemCategoryId) {
    // case 1:
    //   return DRCard
    case 2:
      return DiseaseCard
    // case 3:
    //   return SymptomCard
    // case 4:
    //   return DRCard
    // case 7:
    //   return QualityCard
    default:
      onErr()
      return () => <div>"Not Implemented!"</div>
  }
}

function Evaluate() {
  const confirm = useConfirm();
  const isMounted = useMountedState();
  const [initLoading, setInitLoading] = useState(true);
  const [initialProgress, setInitialProgress] = useState({})
  const [isFullScreen, setFullScreen] = useState(false);
  const [err, setError] = useState()
  const handle = useFullScreenHandle();

  const { problemCategoryId, userProblemId } = useSelector(state => state.user.problem);

  const EvaluateCard = getEvaluateCard({
    problemCategoryId: problemCategoryId, // this not problemCategoryId, changed to categoryTypeId
    onErr: () => showErrorMessage(`Beklenmeyen problem kategorisi (id:${problemCategoryId}).`)
  })

  const {
    next, prev, hasNext, hasPrev, jumpTo, jumpToWithDrSays,
    imageObject, loading
  } = useImageService(userProblemId, initialProgress)

  /* Fetch progress info */
  useEffect(() => {
    (async () => {
      const { data, success, error, message } = await getUserProblemProgress(userProblemId);
      if (!isMounted()) { return; }

      if (data === null || !success || error) {
        showErrorMessage(message || 'Bilinmeyen hata!', error)
        return setError({ ...error, message: message ?? error.message })
      }

      const { currentIndex, totalCount } = data;

      const initialIdx = currentIndex;
      const maxIdx = totalCount - 1;

      if (maxIdx === initialIdx) {
        confirm({
          title: 'Teşekkürler!',
          confirmationText: 'Başa Git',
          confirmationButtonProps: { color: 'primary' },
          cancellationButtonProps: { style: { display: 'none' } },
          description: 'İşaretlenecek başka bir resim kalmadı.'
        })
          .then(() => {
            if (!isMounted()) { return; }
            setInitialProgress({ initialIdx: 0, maxIdx })
            setInitLoading(false)
          })
      } else {
        setInitialProgress({ initialIdx, maxIdx })
        setInitLoading(false)
      }
    })()
  }, [])


  function showErrorMessage(message, error = null, title = 'Hata', showButton = true) {
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton ? { color: 'primary' } : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: <ErrorContent message={message} e={error} />
    }).then(() => { })
  }

  function handleFullScreen() {
    if (handle.active) { handle.exit() }
    else { handle.enter() }
  }

  const cardProps = { prev, next, hasPrev, hasNext, jumpTo, jumpToWithDrSays }

  function Render() {
    if (err) { return <LoadingMessage message={err.message} /> }
    if (initLoading) { return <LoadingMessage loading message="Problem durumu yükleniyor..." /> }
    if (loading) { return <LoadingMessage loading message="Resim bilgileri yükleniyor..." /> }
    if (!imageObject) { return <LoadingMessage loading message="Resim bilgisi sistemde bulunuamadı!" /> }

    return (
      <EvaluateCard
        key={`evaluate-card-${imageObject.userProblemImageId}`}
        userProblemImageId={imageObject.userProblemImageId}
        imageObject={imageObject}
        handleFullScreen={handleFullScreen}
        isFullScreen={isFullScreen}
        {...cardProps}
      />
    )
  }

  return <FullScreen handle={handle} onChange={(state) => { if (isMounted()) { setFullScreen(state) } }}>
    {Render()}
  </FullScreen>
}


export default Evaluate;