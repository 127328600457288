import React, { useState, useEffect } from 'react';
import { useWindowSize, useApiFunction } from '../../util/hooks';
import Scene from './Scene';
import {
  withJSON, withDrSays,
  generateVisJsonFromImagePaths as _generateVisJsonFromImagePaths,
  getDatasetVisJson as _getDatasetVisJson,
  getDatasetFilteredVisJson as _getDatasetFiltredVisJson
} from '../../service/annotate.service';
import FileInput from '../../GlobalComponents/FileInput';
import { usePending } from '../../GlobalComponents/Contexes/pending';
import { useConfirm } from 'material-ui-confirm';
import { ErrorContent } from '../../util/handleError';
import { ImageDataProvider } from './Contexes/imageData';
import { handleLocalFile, addIdsToBBboxes } from './util/DataHandlers';
import { actions } from '../../redux/slices/annotation'
import { useDispatch, useSelector } from 'react-redux';
import { ImageMaskProvider } from './Contexes/imageMask';
import { useLabelMap } from './store';
import { makeStyles, Grid, Box } from '@material-ui/core';
import ImageListInput from '../../GlobalComponents/ImageListInput';
import DatasetList from './Components/VisJson/Components/DatasetList';
import { addCloudWatchLog } from '../../service/cloud.service';
import {useErrorMessage} from '../../util/hooks';
import { ImageBrushProvider } from './Contexes/imageBrush';

const CATEGORY_TYPE_BOUNDING_BOX = [1, 3] // Annotation categoryTypeId & problemCategoryId


const useStyles = makeStyles(theme => ({
  customInputContainer: {
    minHeight: 'max(776px, calc(100vh - 96px))',
    padding: 48,
    // position: 'absolute',
    // top: 0,
    display: 'flex',
    // flexDirection: 'col'
  }
}))

function useAnnotStore() {
  const dispatch = useDispatch();

  const dataProvider = useSelector(state => state.annotation.dataProvider);

  return {
    dataProvider,
    setDataProvider: (value) => dispatch(actions.setDataProvider(value)),
    setWithJSON: (withJSON) => dispatch(actions.setWithJSON(withJSON)),
  };
}

function Annotate(props) {
  const classes = useStyles();
  const { width, height } = useWindowSize()
  const setLabels = useLabelMap(store => store.setLabels)
  // const resetLabels = useLabelMap(store => store.reset)
  const [init, setInit] = useState(false);
  const confirm = useConfirm()
  const setPending = usePending();
  const dispatch = useDispatch();
  const showMessage = useErrorMessage();

  const apiFnOptions = {
    beforeExecute: () => setPending(true, "VisJson yükleniyor..."),
    afterExecute: () => setPending(false)
  }

  const generateVisJsonFromImagePaths = useApiFunction(_generateVisJsonFromImagePaths, apiFnOptions)
  const getDatasetVisJson = useApiFunction(_getDatasetVisJson, apiFnOptions)
  const getDatasetFilteredVisJson = useApiFunction(_getDatasetFiltredVisJson, apiFnOptions)

  const { dataProvider, setDataProvider, setWithJSON } = useAnnotStore();
  
  useEffect(() => {
    if (CATEGORY_TYPE_BOUNDING_BOX.includes(props.problemCategoryId)) {
      // resetLabels()
      setDataProvider(new withDrSays(props.userProblemId))
    }
    setInit(true)
    return () => {
      dispatch(actions.reset())
    }
  }, [])

  function handleCustomLabelMap(labelMap) {
    confirm({
      title: 'Dikkat!',
      confirmationText: 'Evet',
      cancellationText: 'Hayır',
      description: 'Dosyada, var olandan farklı bir labelMap bulundu! Bu labelMap\'i kullanmak istiyor musunuz?'
    }).then(() => { setLabels(labelMap) })
  }
  //TODO: Implement a wrapper for useConfirm
  function showFileMessage({ message, error = null, title = 'Hata', showButton = true }) {
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton ? { color: 'primary' } : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: <ErrorContent message={message} e={error} />
    })
      .then(() => { })
      .catch((e) => { 
        addCloudWatchLog(`Annotate.index.js:91 ${e}`);
        console.error(e) 
      })
  }

  function handleFile(file) {
    setPending(true, 'Yükleniyor...')
    setWithJSON(true);
    
    handleLocalFile(file)
      .then(({ data, dataId, labelMap }) => {
        const context = new withJSON(data, dataId);
        if (labelMap) { handleCustomLabelMap(labelMap) }
        setDataProvider(context)
      })
      .catch(err => {
        if ("title" in err) {
          showFileMessage(err)
        } else {
          addCloudWatchLog(`Annotate.index.js:108 ${err}`);
          console.error(err)
        }
      })
      .finally(() => {
        setPending(false)
      })
  }

  function handleImageList(imagePaths) {
    const userIds = [];
    generateVisJsonFromImagePaths({imagePaths, userIds})
      .then(({ message, data }) => {
        if (data) {
          const { data: images, labelMap } = data;
          setLabels(labelMap)
          addIdsToBBboxes(images)
          setDataProvider(new withJSON(images, `fromlist-${Date.now().toString()}`))
        } else {
          showMessage({title: 'Error', message});
        }
      })
      .catch((e) => {
        addCloudWatchLog(`Annotate.index.js:130 ${e}`);
        console.error(e)
      })
  }

  function handleSelectDataset(datasetId, filterOptions) {

    (filterOptions ? getDatasetFilteredVisJson(datasetId, filterOptions) : getDatasetVisJson(datasetId))
      .then(({ message, data }) => {
        if (data) {
          const { data: images, labelMap } = data;
          setLabels(labelMap)
          addIdsToBBboxes(images)
          setDataProvider(new withJSON(images, `dataset-${datasetId}-${Date.now().toString()}`))
        } else {
          showMessage({title: 'Error', message});
        }
      })
      .catch((e) => {
        addCloudWatchLog(`Annotate.index.js:149 ${e}`);
        console.error(e)
      })
  }

  if (!init) {
    return "Yükleniyor..."
  }

  if (dataProvider === null) {
    //TODO: Carry this part to ./Components/VisJson/index.js
    return (
      <Box maxWidth='xl' className={classes.customInputContainer}>
        <Grid spacing={4} container justifyContent="space-evenly" alignItems="stretch" >
          <Grid item md={6} xs={12} >
            <FileInput
              title='VisJson Dosyası Yükle'
              handleFile={handleFile}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ display: 'grid' }}>
            <Box mb={2}>
              <DatasetList onVisJsonRequested={handleSelectDataset} title='Dataset Seç' />
            </Box>
            <Box mt={2}>
              <ImageListInput
                title='Resim İsimlerinden VisJson Oluştur'
                onSubmit={handleImageList}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <ImageDataProvider dataProvider={dataProvider} >
      <ImageMaskProvider>
        <ImageBrushProvider>
          <Scene width={width} height={height} />
        </ImageBrushProvider>
      </ImageMaskProvider>
    </ImageDataProvider>
  )
}

export default Annotate