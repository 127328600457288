import Api, { ROOT } from './Api';


export async function getUsers() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/admin/getusers`
  })
  return res.data
}

// async function getUser(userId) {
//   const res = await Api({
//     method: 'GET',
//     url: `${ROOT}/admin/getuser/${userId}`
//   })
//   return res.data
// }

async function getImageSources() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/imagesources`
  })
  return res.data
}

export async function getImagesPageByPage(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/images/search`,
    data
  })
  return res.data
}

export async function addImage(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/images/addImage`,
    data
  })
  return res.data
}

export async function addPrediction(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/boxpredictions/addPredictionsAsync`,
    data
    })
    return res.data
}

export async function addPredictionsAsync(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/boxpredictions/addPredictionsAsync`,
    data
    })
    return res.data
}

export async function deleteImages(data) {
  const res = await Api({
    method: 'DELETE',
    url: `${ROOT}/images/deleteImages`,
    data
  })
  return res.data
}

async function getLabels() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/admin/getlabels`
  })
  return res.data
}

export async function getProjectListForSelection() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/userproblems/getProjectListForSelection`
  })
  return res.data
}

export async function createNewProject(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/userproblems/createProject`,
    data
  })
  return res.data
}

export async function addImagesToUserProblem(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/userproblems/${data.id}/addImagesToUserProblem`,
    data: data.imagePaths
  })
  return res.data
}

export async function getProjectById(id) { // Admin panelinde projenin paneli için gerekli kısım
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/userproblems/getProjectById/${id}`,
  })
  return res.data
}

export async function deleteProjectById(id) {
  const res = await Api({
    method: 'DELETE',
    url: `${ROOT}/userproblems/deleteProjectById/${id}`
  })
  return res.data
}

export async function getProblemCategories() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/problemcategories`
  })
  return res.data
}

export async function getProblemCategoriesById(id) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/problemcategories/${id}`
  })
  return res.data
}

export async function setProblemCategories(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/problemcategories`,
    data
  })
  return res.data
}

export async function deleteProblemCategories(id) {
  const res = await Api({
    method: 'DELETE',
    url: `${ROOT}/problemcategories/${id}`
  })
  return res.data
}

export async function overWriteGT(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/groundtruthoverwrite/overwritegtswithanswers/${data}`
  })
  return res.data
}
export async function editUser(data) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/admin/updateUser`,
    data,
  });
  return res.data;
}
export async function toggleActivate(data) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/admin/toggleActivate`,
    data
  })
  return res.data
}

export async function addUser(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/admin/adduser`,
    data
  })
  return res.data
}

export async function addImageSource(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/imagesources`,
    data
  })
  return res.data
}
export async function deleteImageSource(id)
 {
  const res = await Api({
    method: 'DELETE',
    url: `${ROOT}/imagesources/${id}`,
  });
  return res.data;
}
export async function editImageSource(data) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/imagesources/v2`,
    data
  })
  return res.data
}

export async function submitProblemCategory(data) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/problemcategories/${data.id}`,
    data
  })
  return res.data
}

export async function sendMail(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/mail/sendMail`,
    data,
  });
  return res.data;
}

export async function resetPassword(data) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/public/resetPassword`,
    data,
  });
  return res.data;
}

export async function submitLabel(data) {
  const res = await Api({
    method: 'PUT',
    url: `${ROOT}/admin/putlabel`,
    data
  })
  return res.data
}

export async function getFilterItems(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/imageQuery/getFilterItems`,
    data
  })
  return res.data
}

export async function getImageQueriesPageByPage(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/imageQuery/search`,
    data
  })
  return res.data
}

export async function getAllImageQueries(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/imageQuery/searchAll`,
    data
  })
  return res.data
}

export async function getSystemLogsPageByPage(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/admin/searchLogs`,
    data
  })
  return res.data
}

function parseResponse(resData) {
  const { data, success, error } = resData;

  if (!success || error)
    throw resData

  if (!Array.isArray(data))
    throw { data, success: false, error, message: 'Kayıt boş döndü.' }

  const ids = data.map(e => e.id)
  const byId = Object.fromEntries(data.map(e => [[e.id], e]))

  return { ids, byId }
}

/**
 * Returns ready to use enumerations object of DataBase
 * @throws {{error: Object, message: String}} - exception
 * @returns {Object<String, List<Entity>>} Object from entity names (key) and entities (value)
*/
export async function getAllEnumerations() {

  const datas = await Promise.all(
    [
      getUsers,
      getImageSources,
      getLabels,
      getProblemCategories,
      getDatasets,
      getQuartzJobInfoList,
    ].map(f => f())
  )

  const parsedDatas = datas.map((data) => parseResponse(data))
  const dataEntries = [
    'users',
    'imageSources',
    'labels',
    'problemCategories',
    'datasets',
    'jobInfoList',
  ].map((name, i) => [name, parsedDatas[i]])

  const dataMap = Object.fromEntries(dataEntries)

  return dataMap
}

export async function getProblemCategoryLabelMap(id = null) {
  if(id != null) {
    const res = await Api({
      method: 'GET',
      url: `${ROOT}/problemCategoryLabelMap/${id}`,
    })
    return res.data
  } else {
    const res = await Api({
      method: 'GET',
      url: `${ROOT}/problemCategoryLabelMap`,
    })
    return res.data
  }
}

export async function getAllUserProblems() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/userproblems/GetAll`,
  })
  return res.data
}

export async function getUserProblemFilterElements(userProblemId) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/userproblems/GetFilterItems/${userProblemId}`
  })
  return res.data;
}

export async function getFilteredUserProblemImagePaths(userProblemId, includeds, excludeds) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/userproblems/getFilteredUserProblemImagePaths`,
    data: { userProblemId, includeds, excludeds }
  })
  return res.data;
}


export async function getDatasets() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/dataset`
  })
  return res.data
}

export async function getDatasetImages(id) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/dataset/getDatasetImages?datasetId=${id}`,
  })
  return res.data
}

export async function addDataset(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/dataset/addDataset`,
    headers: {
      'Content-Type': 'plain/text'
    },
    data
  })
  return res.data
}

export async function deleteDataset(id) {
  const res = await Api({
    method: 'DELETE',
    url: `${ROOT}/dataset/deleteDataset`,
    data: id
  })
  return res.data
}

export async function addImageToDataset(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/dataset/addImageToDataset`,
    data
  })
  return res.data
}

export async function deleteDatasetImage(data) {
  const res = await Api({
    method: 'DELETE',
    url: `${ROOT}/dataset/deleteDatasetImage`,
    data
  })
  return res.data
}

export async function getVisJson(id) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/dataset/${id}/visJson`,
  })
  return res.data
}

export async function generateVisJsonFromImagePaths(data) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/dataset/generateVisJsonFromImagePaths`,
    data
  })
  return res.data
}

export async function triggerJob(jobName) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/scheduler/triggerJob?${jobName}`
  })
  return res.data;
}

export async function getQuartzJobInfo(jobUid) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/scheduler/jobInfo?jobUid=${jobUid}`
  })
  return res.data;
}

export async function getAllJobs() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/scheduler/getAll`
  })
  return res.data
}

export async function getQuartzJobInfoList() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/scheduler/jobInfoList`,
  })
  return res.data
}

export async function overWriteGTAsync(userProblemId) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/groundtruthoverwrite/overwriteGTsWithAnswersAsync/${userProblemId}`
  })
  return res.data
}
