import React, { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import { Box, Button } from '@material-ui/core';
import { Typography, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import { addImageToDataset } from '../../../../service/admin.service';
import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { actions } from '../../../../redux/slices/admin';
import { useHistory } from 'react-router';
import { addCloudWatchLog } from '../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '90%',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginRight: '12rem',
    marginTop: '1rem',
  },
}));

function useAddImagetoDatasetFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const addImagetoDatasetFn = addImageToDataset;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`AddImageToDataset.js:42 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`AddImageToDataset.js:56 ${e}`);
        console.error(e);
      });
  }
 
  async function submit(_data) {
    const { data, success, error, message } = await addImagetoDatasetFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      const updatedDataset = {...data, imagePathList: _data.imagePathList};
      dispatch(actions.updateEnum({ enumKey: 'datasets', data: updatedDataset }));
    }
    return { data, success, error, message };
  }
  return submit;
}

export default function AddImagetoDataset() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState('');
  const pending = usePending();
  const addImagetoDatasetFn = useAddImagetoDatasetFunction();
  const history = useHistory();
  let isSuccessfull, isError;

  const { ids } = useParams();
  const idsArray = ids.split(',');

  function handleChange(e) {
    setValues(e.target.value);
  }

  async function submit(e) {
    e.preventDefault();

    const imagePathList = values.split('\n').map(item => item.trim());

    if (loading) {
      return;
    }

    for (const id of idsArray) {
      setLoading(true);
      pending(true, 'Kaydediliyor...');

      const request = { datasetId: +id, imagePathList};
      const { success, error } = await addImagetoDatasetFn(
        request
      );

      isSuccessfull = success;
      isError = error;
      setLoading(false);
      pending(false);
    }

    if (isSuccessfull && !isError) {
      history.push('/admin/settings/dataset');
    }
  }

  return (
    <Box component='form' autoComplete='off' onSubmit={submit}>
      <IconButton color='primary' to='/admin/settings/dataset' component={Link}>
        <ChevronLeftIcon />
        <Typography>Dataset</Typography>
      </IconButton>
      <Box ml={5} mt={2}>
        <Typography>Image List</Typography>
        <TextField
          className={classes.textField}
          multiline
          rows={22}
          onChange={handleChange}
          value={values}
        />
      </Box>
      <Box className={classes.flexEnd}>
        <Button
          type='submit'
          color='primary'
          variant='contained'
          disabled={loading}
        >
          Ok
        </Button>
      </Box>
    </Box>
  );
}
