import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import GetHelp from '../Components/Help/GetHelp';

export default function () {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.url}/help`} component={GetHelp}></Route>
      </Switch>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/help`} />
      </Switch>
    </>
  );
}
