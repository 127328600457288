import React, { createContext, useContext } from 'react';

const DataContext = createContext();

export const ImageDataProvider = ({children, dataProvider}) => (
  <DataContext.Provider value={dataProvider}>
    {children}
  </DataContext.Provider>
)

export const useImageData = () => useContext(DataContext);