import React, { useState } from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from '@mui/material/colors';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { usePending } from "../../../../../GlobalComponents/Contexes/pending";
import { useConfirm } from "material-ui-confirm";
import { addImage } from "../../../../../service/admin.service";
import { addCloudWatchLog } from "../../../../../service/cloud.service";
import { actions } from "../../../../../redux/slices/admin";
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    addImageRoot: {
        position: 'relative',
        top: '10%',
        left: '15%',
    },
    imageLabel: {
        position: 'relative',
        top: '1.8rem',
        right: '7.5rem',
    },
    imageSourceLabel: {
        position: 'relative',
        top: '2.4rem',
        right: '7.5rem',
    },
    fileEl: {
        position: 'relative',
        bottom: '2.3rem',
        left: '11rem',
        backgroundColor: '#cfd8dc',
        borderRadius: '5%',
        width: 'fit-content',
        height: 'fit-content',
        marginBottom: '-2.37rem',
    },
    filePresent: {
        position: 'relative',
        top: '0.4rem',
        marginRight: '0.2rem',
    },
    paragraphEl: {
        display: 'inline-block',
        marginRight: '0.5rem',
    },
    fileCancelling: {
        position: 'relative',
        bottom: '0.1rem',
        '&:hover': {
            backgroundColor: '#b0bec5',
        },
    },
    formControl: {
        width: '30%',
    },
    saveButton: {
        position: 'relative',
        top: '5rem',
        right: '10rem'
    },
    cancelButton: {
        position: 'relative',
        top: '5rem',
        right: '23rem'
    }
}));

function useAddImageFunction() {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const addImageFn = addImage;

    function showMessage({
        message,
        error = null,
        title = 'Hata',
        showButton = true
    }) {
        const fileName = 'AddImagesFromVisJson.js';

        if (error) {
            addCloudWatchLog(`${fileName}:84 ${error}`);
            console.error(error);
        }
        confirm({
            title,
            confirmationText: 'Tamam',
            confirmationButtonProps: showButton
            ? { color: 'primary' }
            : { style: { display: 'none' } },
            cancellationButtonProps: { style: { display: 'none' } },
            description: message ?? 'Bilinmeyen bir hata oluştu.',
        })
        .then(() => {})
        .catch((e) => {
            addCloudWatchLog(`${fileName}:100 ${e}`);
            console.error(e);
        });
    }

    async function submit(
        _data,
        jsonError,
        fileTypeErrorMessage,
        isFileChoosen,
        isDataValid
    ) {
        if (!isFileChoosen) {
            const message = 'File not selected!';
            showMessage({ message });
            return { data: '', success: false, error: null, message };
        } else if (jsonError) {
            const message = 'Please choose json files which are in JSON format!';
            showMessage({ message });
            return { data: '', success: false, error: jsonError, message };
        } else if (fileTypeErrorMessage) {
            const message = fileTypeErrorMessage;
            showMessage({ message });
            return { data: '', success: false, error: null, message };
        } else if (!isDataValid) {
            const message = `Example file content must be as follows:
            [
                {
                    "storagePath": "string",
                    "thumbnailStoragePath": "string",
                    "qualityTypeId": 1
                }
            ]`;
            showMessage({ message });
            return { data: '', success: false, error: null, message };
        }

        const { data, success, error, message } = await addImageFn(_data);

        if (!success || error) {
            showMessage({ message, error });
        } else {
            dispatch(actions.updateEnum({ enumKey: 'imageSources', data }));
        }
        return { data, success, error, message };
    }

    return submit;
}

export default function AddImageFromVisJson() {
    const classes = useStyles();
    const [isFileChoosen, setFileChoosen] = useState(false);
    const [fileName, setFileName] = useState('fileName');
    const [imageSourceId, setImageSourceId] = useState('');
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [jsonError, setJsonError] = useState(null);
    const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState(null);
    const [isDataValid, setValidData] = useState(false);
    const pending = usePending();

    const addImageFn = useAddImageFunction();
    const history = useHistory();

    let fileReader;

    const {byId: imageSources } = useSelector(
        (state) => state.admin.enums.imageSources
    );

    const editedImageSources = {
        ...imageSources,
        undefined: { id: '', name: 'Deselect' },
    };

    async function submit(e) {
        e.preventDefault();

        if (loading) {
            return;
        }

        setLoading(true);
        pending(true, 'Kaydediliyor...');

        const request = { imageSourceId, images };

        const { success, error } = await addImageFn(
            request,
            jsonError,
            fileTypeErrorMessage,
            isFileChoosen,
            isDataValid
        );

        setLoading(false);
        pending(false);

        if (success && !error) {
            history.push('/admin/settings/images');
        }
    }

    const controlData = (jsonContent) => {
        if (Array.isArray(jsonContent)) {
            jsonContent.map((obj) => {
                const keyArray = Object.keys(obj);

                if (
                    keyArray[0] !== 'storagePath' ||
                    keyArray[1] !== 'thumbnailStoragePath' ||
                    keyArray[2] !== 'qualityTypeId'
                ) {
                    return false;
                }
                return true;
            });

            return true;
        }

        return false;
    };

    const handleFileRead = (e) => {
        const content = fileReader.result;
        let jsonContent;
        
        try {
            jsonContent = JSON.parse(content);
            const isValid = controlData(jsonContent);
            setValidData(isValid);

            setImages(jsonContent);
        } catch (e) {
            setJsonError(e);
            return;
        }
    };

    const handleFileChoosen = (file) => {
        setJsonError(null);
        setFileTypeErrorMessage(null);
        setFileChoosen(false);

        if (file) {
            setFileChoosen(true);
            setFileName(file.name);
        }

        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;

        if (file?.type === 'application/json') {
            fileReader.readAsText(file);
        } else {
            setFileTypeErrorMessage('Not a .json file!');
        }
    };

    const handleSelectChange = (e) => {
        setImageSourceId(e.target.value);
    };

    return (
        <Box
        className={classes.addImageRoot}
        component='form'
        autoComplete='off'
        onSubmit={submit}
        >
            <label htmlFor='upload-photo'>
                <InputLabel className={classes.imageLabel}>Image</InputLabel>
                <input
                style={{display: 'none'}}
                id='upload-photo'
                name='upload-photo'
                type='file'
                accept='.json'
                onChange={(e) => handleFileChoosen(e.target.files[0])}
                onClick={(e) => (e.target.value = '')}
                />
                <Button
                startIcon={<FileUploadIcon />}
                color='primary'
                variant='contained'
                component='span'
                type='button'
                >
                    Choose Image
                </Button>
            </label>
            {isFileChoosen && (
                <div className={classes.fileEl}>
                    <FilePresentIcon className={classes.filePresent}/>
                    <Typography className={classes.paragraphEl}>{fileName}</Typography>
                    <Button
                    endIcon={<CancelIcon sx={{ color: red['A700'] }}/>}
                    variant= 'text'
                    className={classes.fileCancelling}
                    type='button'
                    onClick={() => {
                        setFileChoosen(false);
                    }}
                    />
                </div>
            )}
            <InputLabel className={classes.imageSourceLabel}>Image Source</InputLabel>
            <FormControl className={classes.formControl} required>
                <InputLabel id='select-label'>Select</InputLabel>
                <Select
                labelId='select-label'
                value={imageSourceId}
                label='Select'
                onChange={handleSelectChange}
                >
                    {Object.keys(editedImageSources).map(function (key) {
                        const imageSource = editedImageSources[key];
                        return (
                            <MenuItem key={key} value={imageSource.id}>
                                {imageSource.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Button
            variant='contained'
            color='primary'
            className={classes.saveButton}
            type='submit'
            >
                Save
            </Button>
            <Button
            className={classes.cancelButton}
            color='primary'
            variant='contained'
            to='/admin/settings/images'
            component={Link}
            >
                Cancel
            </Button>
        </Box>
    )
}