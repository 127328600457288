import { useState, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import usePagination from './usePagination';
import CustomNoRowsOverlay from '../GlobalComponents/CustomNoRowsOverlay';
import useSelection from './useSelection';
import { addCloudWatchLog } from '../service/cloud.service';

let rowNumber = 1;

export default function useSubmitWithPagination({
  initialPageSize,
  getFn,
  deleteFn,
  rowOrder = 'ASC',
  inputEls = {},
}) {
  const [loading, setLoading] = useState(true);
  const [totalNumberofElements, setTotalNumberofElements] = useState(0);
  const {
    pageNumber,
    setMaxPageNumber,
    setPageNumber,
    pageSize,
    initialPageNumber,
    handleChangePaginationClick,
    noRowsOverlay,
    setNoRowsOverlay,
    anotherPagination,
    handlePageChangeV2,
    handlePageSizeChangeV2,
    request,
    setRequest,
    rows,
    setRows,
  } = usePagination({initialPageSize, rowNumber, inputEls});

  const { ids, selectionModel, handleCheckBoxClicks, isBtnActive } =
    useSelection({rows, rowOrder, pageNumber, pageSize});

  function arrangeData(data) {
    data.map((d) => {
      if (d['imageId']) {
        d['id'] = d['imageId'];
        delete d['imageId'];
      }
      d['rowNumber'] = rowNumber;
      rowNumber++;
    });

    return data;
  }

  useEffect(() => {
    let isMounted = true;

    async function getData() {
      setNoRowsOverlay({});
      setLoading(true);
      
      const {data, totalElements} = await getFn(request);
      setTotalNumberofElements(totalElements);
      if(!isMounted) {return};
      
      setLoading(false);

      setRows((rows) => {
        let arrangedData = arrangeData(data);
        const arrangedRows = [...rows, ...arrangedData];
        return arrangedRows;
      });

      //burası
      if (data.length === 0) {
        setNoRowsOverlay({ NoRowsOverlay: CustomNoRowsOverlay });
      }
    }
    getData();

    return () => {
      isMounted = false;
    }

  }, [request]);

  function submit(e) {
    e.preventDefault();
    setPageNumber(0);
    setMaxPageNumber(0);
    rowNumber = 1;
    setRows([]);
    setRequest({
      ...inputEls,
      pageNumber: initialPageNumber,
      pageSize: pageSize + 1,
    });
  }

  const confirm = useConfirm();

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`useSubmitWithPagination.js:111 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`useSubmitWithPagination.js:125 ${e}`);
        console.error(e);
      });
  }

  async function handleDeleteClick() {
    const { success, error, message } = await deleteFn(ids);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      let tempRows = [...rows];
      ids.map((id) => {
        const filteredRows = tempRows.filter((tempRow) => tempRow.id !== id);
        tempRows = filteredRows;
      });
      setRows(tempRows);
    }
  }

  return {
    submit,
    handleDeleteClick,
    handleChangePaginationClick,
    anotherPagination,
    noRowsOverlay,
    rows,
    pageSize,
    pageNumber,
    isBtnActive,
    ids,
    handleCheckBoxClicks,
    selectionModel,
    handlePageSizeChangeV2,
    handlePageChangeV2,
    loading,
    setLoading,
    arrangeData,
    totalNumberofElements
  };
}
