import React from 'react'
import { Grid, Box } from '@material-ui/core'
import GetProjects from '../Components/Projects/Components/GetProjects'
import {Switch, Route, useRouteMatch} from 'react-router-dom'
import CreateProject from '../Components/Projects/Components/CreateProject/Main'
import UpdateProject from '../Components/Projects/Components/UpdateProject'
import { Redirect } from 'react-router-dom'
import AddImageset from '../Components/Projects/Components/AddImageset/index'

export default function (props) {
  const match = useRouteMatch()
  return (
    <Box pl={4} pr={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Switch>
          <Route path={`${match.url}/all`} component={GetProjects}></Route>
          <Route path={`${match.url}/create`} component={CreateProject}></Route>
          <Route path={`${match.url}/imageset/:id`} component={AddImageset}></Route>
          <Route path={`${match.url}/:id`} component={UpdateProject}></Route>
        </Switch>
        <Switch>
          <Redirect exact from={match.url} to={`${match.url}/all`} />
        </Switch>
        </Grid>
      </Grid>
    </Box>

  )
}