import create from 'zustand'

const defaultLabelMap = {
  1: "Label Bulunamadı",
}

const diseaseCardDefaultLabelmap = {
  1: "diseaseCardDefaultLabelmap Bulunamadı",
}

const withId = (labels) => ({ labels, labelsId: JSON.stringify(labels) })
const withIntKeys = (labels) => Object.fromEntries(Object.keys(labels).map(k => [parseInt(k), labels[k]]))

const diseaseWithId = (diseaseLabels) => ({ diseaseLabels, diseaseLabelsId: JSON.stringify(diseaseLabels) })
const diseaseWithIntKeys = (diseaseLabels) => Object.fromEntries(Object.keys(diseaseLabels).map(k => [parseInt(k), diseaseLabels[k]]))

export const useLabelMap = create(set => ({
  labels: defaultLabelMap,
  labelsId: JSON.stringify(defaultLabelMap),
  diseaseLabels: diseaseCardDefaultLabelmap,
  diseaseLabelsId: JSON.stringify(diseaseCardDefaultLabelmap),
  reset: () => set(withId(defaultLabelMap)),
  setLabel: (labelId, newLabelName) => set(({ labels }) => ({ ...withId(withIntKeys({ ...labels, [labelId]: newLabelName })) })),
  setLabels: (labels) => set(withId(withIntKeys(labels))),
  setDiseaseLabels: (diseaseLabels) => set(diseaseWithId(diseaseWithIntKeys(diseaseLabels))),
  deleteLabel: (labelId) => set(({ labels }) => { delete labels[labelId]; return withId(withIntKeys({ ...labels })) }),
}))
