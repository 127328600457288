import React, { Component } from 'react';
import { auth } from '../../service/auth.service';
import { withStyles } from '@material-ui/core/styles';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  CssBaseline,
  Avatar, Button, TextField, Box, Link, Typography, Container
} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { addCloudWatchLog } from '../../service/cloud.service';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.uraltelekom.com">
        Ural Telekom
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      open: false
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleError(error) {
    addCloudWatchLog(`SignIn.js:65 ${error}`);
    console.error(error);
    if (error === undefined) {
      this.setState({ error: { message: 'Kullanıcı bilgileri geçersiz' }, loading: false })
      return
    }
    const { errorCode, message } = error;
    if (errorCode === "auth/invalid-email") {
      this.setState({ error: { message: "Yanlış kullanıcı adı!" } })
    } else if (errorCode === "auth/user-disabled") {
      this.setState({ error: { message: "Kullanıcı aktif değil!" } })
    } else if (errorCode === "auth/user-not-found") {
      this.setState({ error: { message: "Kullanıcı bulunamadı!" } })
    } else if (errorCode === "auth/wrong-password") {
      this.setState({ error: { message: "Yanlış şifre girildi!" } })
    } else {
      this.setState({ error: { message: `${message}` } })
    }
    this.setState({ loading: false })
  }

  onSubmit(e) {
    e.preventDefault()
    if (this.state.loading) { return; } //Avoid sending multiple requests till response comes
    this.setState({ loading: true })

    auth.login(`${e.target[0].value}`, e.target[2].value, this.props.base || 'users')
      .then((user) => console.log("logged in", user))
      .catch(this.handleError)
  }


  render() {
    const { loading, error, open } = this.state;
    const { classes } = this.props;

    let loginCheck = setInterval(() => {
      if (auth.loginTimeout) {
        this.setState({ open: true })
        clearInterval(loginCheck)
      }
    }, 1 * 60 *1000)

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({ open: false })
    };
    return <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          {loading && <CircularProgress style={{ position: "fixed" }} color="secondary" size={45} />}
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Giriş Yap {this.props.base && `(${this.props.base})`}
        </Typography>
        {error && error.message}
        <form className={classes.form} onSubmit={this.onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="userName"
            label="Kullanıcı Adı"
            name="userName"
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Şifre"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            id="login"
          >
            Giriş
          </Button>
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000}>
        <Alert onClose={handleClose} severity="warning">
          Oturum süreniz dolmuştur! Lütfen tekrar giriş yapınız.
        </Alert>
      </Snackbar>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>;
  }
}

export default withStyles(styles)(SignIn)
