import { createSlice } from '@reduxjs/toolkit'

export const layout = {
  header: {
    enabled: true,
    open: false
  },
};

const userSlice = createSlice({
  name: 'layout',
  initialState: layout,
  reducers: {
    enableHeader(state, { payload }) {
      if (!state.header.enabled) {
        state.header.enabled = true
        state.header.open = false
      }
    },
    disableHeader(state, { payload }) {
      if (state.header.enabled) {
        state.header.open = false
        state.header.enabled = false
      }
    },
    setHeaderOpen(state, { payload }) {
      state.header.open = payload ?? !state.header.open
    }
  }
})


export const reducer = userSlice.reducer
export const actions = userSlice.actions
