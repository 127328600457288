import React from 'react';
import { Image } from 'react-konva';

export default React.memo(({ imObj }) => {
  return (
    <Image
      x={0}
      y={0}
      image={imObj}
      name='fundus-image'
    />
  )
}, (prevProps, nextProps) => prevProps.imObj === nextProps.imObj)
