import { useChange, useLabelMap } from "components/Annotate/store";
import { getRelativePointerPosition } from "components/Annotate/util/konvaUtils";
import React, { useState, useRef, useEffect } from "react";
import { Image as KonvaImage } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/slices/annotation";

function useAnnotStore() {
  const dispatch = useDispatch();
  const brushToolCoefficient = useSelector(
    (state) => state.annotation.brushToolCoefficient
  );
  const showAllPaintedImages = useSelector((state) => state.annotation.showAllPaintedImages);

  return {
    setPaintedImageChanged: (bool) => dispatch(actions.setPaintedImageChanged(bool)),
    setPaintedImagesGT: (bool) => dispatch(actions.setPaintedImagesGT(bool)),
    brushToolCoefficient,
    showAllPaintedImages,
  };
}

export const BrushTool = ({
  arteryImage,
  veinImage,
  setArteryImg,
  setVeinImg, 
  imageWidth, 
  imageHeight, 
  tool, 
  isDrawing, 
  setIsDrawing, 
  tempLabelId, 
  layerRef
}) => {
  const [brushColor, setBrushColor] = useState("yellow");
  const [lastPointerPosition, setLastPointerPosition] = useState();
  const imageRef = useRef(null);
  const arteryImageRef = useRef(null);
  const veinImageRef = useRef(null);
  const informChange = useChange((state) => state.changed);
  const labels = useLabelMap(state => state.labels)
  const { brushToolCoefficient, showAllPaintedImages, setPaintedImageChanged, setPaintedImagesGT } = useAnnotStore();

  const [ arteryCanvas, setArteryCanvas ] = useState();
  const [ veinCanvas, setVeinCanvas ] = useState();
  const [ arteryContext, setArteryContext ] = useState();
  const [ veinContext, setVeinContext ] = useState();

  let _arteryImage = new Image();
  _arteryImage.src = arteryImage;
  let _veinImage = new Image();
  _veinImage.src = veinImage;

  const _arteryCanvas = document.createElement("canvas");
  _arteryCanvas.width = imageWidth
  _arteryCanvas.height = imageHeight
  const _arteryContext = _arteryCanvas.getContext("2d");

  const _veinCanvas = document.createElement("canvas");
  _veinCanvas.width = imageWidth
  _veinCanvas.height = imageHeight
  const _veinContext = _veinCanvas.getContext("2d");

  useEffect(() => {
    if (labels[tempLabelId] === "artery") {
      setBrushColor("green")
      imageRef.current = arteryImageRef.current
    } else if (labels[tempLabelId] === "vein") {
      setBrushColor("blue")
      imageRef.current = veinImageRef.current
    } else {
      setBrushColor("yellow")
    }
  }, [tempLabelId])

  useEffect(() => {
    _arteryContext.drawImage(_arteryImage, 0, 0, imageWidth, imageHeight);
    setArteryCanvas(_arteryCanvas);
    setArteryContext(_arteryContext);

    _veinContext.drawImage(_veinImage, 0, 0, imageWidth, imageHeight);
    setVeinCanvas(_veinCanvas);
    setVeinContext(_veinContext);
  }, []);

  const handleMouseDown = (e) => {
    setIsDrawing(true);
    setLastPointerPosition(getRelativePointerPosition(imageRef.current, false));
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) {
      return;
    }

    if (tool === "brushTool" || tool === "eraser") {
      setPaintedImageChanged(true);
      setPaintedImagesGT(false);
      if(labels[tempLabelId] === "artery") {
        draw(arteryContext);
      } else if (labels[tempLabelId] === "vein") {
        draw(veinContext);
      }
    }
  };

  const draw = (context) => {
    context.strokeStyle = brushColor;
    context.lineJoin = "round";
    context.lineWidth = tool === "brushTool" ? brushToolCoefficient * 40 : brushToolCoefficient * 100;

    if (tool === "brushTool") {
      context.globalCompositeOperation = "source-over";
    }

    if (tool === "eraser") {
      context.globalCompositeOperation = "destination-out";
    }
    context.beginPath();

    var localPos = {
      x: (lastPointerPosition.x),
      y: (lastPointerPosition.y)
    };
    context.moveTo(localPos.x, localPos.y);

    var pos = getRelativePointerPosition(imageRef.current, false);
    localPos = {
      x: (pos.x),
      y: (pos.y)
    };

    context.lineTo(localPos.x, localPos.y);
    context.closePath();
    context.stroke();

    setLastPointerPosition(pos);
    layerRef.current.batchDraw();
  }

  const handleMouseUp = () => {
    setIsDrawing(false);
    if(labels[tempLabelId] === "artery") {
      handleSave(arteryContext);
    } else if (labels[tempLabelId] === "vein") {
      handleSave(veinContext);
    }
  };

  const handleSave = (context) => {
    // generate image from canvas
    const _imageData = context.getImageData(0, 0, (imageWidth), (imageHeight))

    // create image from _imageData
    const _canvas = document.createElement("canvas");
    _canvas.width = (imageWidth)
    _canvas.height = (imageHeight)
    const _context = _canvas.getContext("2d");
    _context.putImageData(_imageData, 0, 0);

    // create new canvas with divide scale
    const _canvas2 = document.createElement("canvas");
    _canvas2.width = (imageWidth)
    _canvas2.height = (imageHeight)
    const _context2 = _canvas2.getContext("2d");
    _context2.drawImage(_canvas, 0, 0, (imageWidth), (imageHeight), 0, 0, (imageWidth), (imageHeight));
    const _dataURL2 = _canvas2.toDataURL({
      pixelRatio: 100,
    });

    if (labels[tempLabelId] === "artery") {
      setArteryImg(_dataURL2)
    } else if (labels[tempLabelId] === "vein") {
      setVeinImg(_dataURL2)
    }

    informChange();
  };

  return (
    <>
      { (labels[tempLabelId] === "artery" || showAllPaintedImages) &&
        <KonvaImage
          image={arteryCanvas}
          ref={arteryImageRef}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          opacity={0.4}
        />
      }
      
      { (labels[tempLabelId] === "vein" || showAllPaintedImages) && 
        <KonvaImage
          image={veinCanvas}
          ref={veinImageRef}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          opacity={0.4}
        />  
      }
    </>

  );
};
