import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Paper, Box, makeStyles, Typography, DialogTitle, DialogContent } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import DropBox from '../assets/images/dropbox200.png';
// const DropBox = "https://static.thenounproject.com/png/3409180-200.png"

const borderPattern = (
  ['right', 'right', 'bottom', 'bottom']
    .map((side) => `repeating-linear-gradient(to ${side}, grey 0%, grey 50%, transparent 50%, transparent 100%)`)
    .join(', ')
)

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    boxShadow: theme.shadows[4]
  },
  dialogContent: {
    padding: 24,
    paddingTop: 15,
    height: 'calc(100% - 96px - 64px)',
    minHeight: '15rem'
  },
  box: {
    height: '100%',
    cursor: 'pointer',
    borderRadius: 5,
    outlineColor: 'white',
    '& :focus': {
      outline: 'none'
    },
    display: 'flex',
    justifyContent: 'center',
    transition: 'box-shadow 0.15s cubic-bezier(0.22, 0.61, 0.36, 1)'
  },
  info: {
    display: 'grid',
    margin: 'auto'
  },
  icon: {
    background: `#ffffff47 url(${DropBox})`,
    backgroundBlendMode: 'soft-light',
    height: 200, width: 200, margin: 'auto'
  },
  boxDragPassive: {
    backgroundImage: borderPattern,
    backgroundPosition: "left top, left bottom, left top, right top",
    backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
    backgroundSize: "20px 3px, 20px 3px, 3px 20px, 3px 20px",
  },
  boxDragActive: {
    border: '4px solid #5f5f5f',
    boxShadow: 'inset 0px -7px 100px 0px'
  }
}))

function FileIput({ handleFile, title }) {
  const classes = useStyles()

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length !== 0) {
      handleFile(acceptedFiles[0])
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


  return (
    <Box component={Paper} className={classes.root}>
      <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box {...getRootProps()}
          className={clsx(classes.box, {
            [classes.boxDragActive]: isDragActive,
            [classes.boxDragPassive]: !isDragActive
          }
          )}
        >
          <input {...getInputProps()} />
          <div className={classes.info}>
            <div className={classes.icon} />
            <Typography variant="h6" color='textSecondary' component="div">
              Dosyayı buraya sürükle...
            </Typography>
          </div>
        </Box>
      </DialogContent>
    </Box>

  );
};

export default FileIput;