import React, { useState, Fragment, useMemo, useEffect } from 'react';
import { Rect, Text, Tag, Label, Circle, Line } from 'react-konva';
import { useLabelMap, useChange } from './../store';
import { useSelector } from 'react-redux';



const bboxColors = {
  gt: {
    [true]: {
      text: '#737373',
      shape: '#5eff60'
    },
    [false]: {
      text: '#737373',
      shape: '#99cc9b',
    }
  },
  pred: {
    [true]: {
      text: '#e6e6e6',
      shape: 'red',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#grey'
    }
  },
  default: {
    [true]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#2135a5',
    }
  },
  new: {
    [undefined]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [true]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#2135a5',
    }
  },
  toolTip: {
    [true]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#2135a5',
    }
  }
}

const flagConfigs = {
  0: {
    title: 'Bilinmeyen Flag',
    description: '??????????',
    color: '#858585',
  },
  1: {
    title: 'Hatalı "Bounding Box"',
    description: 'Bu "bounding box" bozuk olarak işaretlenmiş.',
    color: '#9617ea',
  },
  2: {
    title: 'Şüpheli Anomali',
    description: 'Bu "bounding box" Burak Hoca\'ya teyit ettirilecek.',
    color: '#ea17e2',
  },
  3: {
    title: 'Onaylı',
    description: 'Bu "bounding box" Burak Hoca tarafından onaylandı!',
    color: '#13e300'
  }
}

function getFlagConfigs(flagId) {
  if (flagId in flagConfigs) return flagConfigs[flagId]
  return flagConfigs[0]
}

function BoundingBoxFlag({ flags, commonProps, badgeProps, clickableProps, offsetX }) {
  const [open, setOpen] = useState(false);

  function renderDetails() {
    // const flagDetails = flags.map((flagId) => getFlagConfigs(flagId))

    return flags.map((flagId, i) => {
      const config = getFlagConfigs(flagId)
      return (
        <Label {...commonProps} offsetY={-19 * i} offsetX={offsetX - 18}>
          <Tag fill={config.color} />
          <Text text={config.title} fontSize={14} fill={`#ffff`} padding={2} />
        </Label>
      )
    })
  }

  return (
    <>
      <Label
        scale={10}
        {...commonProps} {...badgeProps}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        offsetX={offsetX}
      >
        <Tag fill={getFlagConfigs(flags[0]).color} />
        <Text text={`${flags.length}`} fontSize={14} fill={'#ffff'} padding={2} />
      </Label>
      {open && renderDetails()}
    </>
  )
}

function BoundingBox({ x1, y1, x2, y2, scale, clickable, bboxNode, colors, patch, ...props }) {
  const [color, setColor] = useState(colors[bboxNode.isValid]);
  const patchColor = useSelector(state => state.annotation.options.patchColor)
  const [isToolTipVisible, setToolTipVisible] = useState(false);
  const toolTipText = 
`x1: ${bboxNode.x1}
y1: ${bboxNode.y1}
x2: ${bboxNode.x2}
y2: ${bboxNode.y2}`;

  const informChange = useChange(state => state.changed);

  function handleMouseEnter() {
    if (clickable) {
      props.handleHover(true)
      setToolTipVisible(true);
      // setColor(primary)
    }
  }

  function handleMouseExit() {
    if (clickable) {
      props.handleHover(false)
      setToolTipVisible(false);
      // setColor(secondary)
    }
  }

  function handleClick() {
    if (clickable) {
      if ("handleClick" in props) {
        informChange()
        props.handleClick(bboxNode)
      } else {
        informChange()
        bboxNode.isValid = !bboxNode.isValid
        setColor(colors[bboxNode.isValid])
      }
    }
  }

  useEffect(() => { setColor(colors[bboxNode.isValid]) }, [bboxNode.isValid])

  const commonProps = {
    x: x1, y: y1
  }
  const clickableProps = {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseExit,
  }

  const toolTipProps = {
   x: x2, y: y2
  };

  const toolTipColors = {
    shape: bboxColors.new.true.shape,
    text: bboxColors.new.true.text
  };

  if (patch) {
    return (
      <Rect
        {...commonProps}
        width={x2 - x1} height={y2 - y1}
        fill={patchColor}
      />
    )
  }

  return (
    <Fragment>
      <Rect
        {...commonProps} {...clickableProps}
        onClick={handleClick}
        fillEnabled={false}
        strokeWidth={2 / scale}
        width={x2 - x1} height={y2 - y1}
        stroke={color.shape}
      />
      {props.text &&
        <Label {...commonProps} {...clickableProps} offsetY={18} offsetX={1} visible={props.showTags} onClick={handleClick}>
          <Tag fill={color.shape} />
          <Text text={props.text} fontSize={14} fill={color.text} padding={2} />
        </Label>}
      {isToolTipVisible && 
        <Label {...toolTipProps}>
          <Tag fill={toolTipColors.shape} />
          <Text text={toolTipText} fontSize={14} fill={toolTipColors.text} padding={2} />
        </Label>}
      {Boolean(bboxNode.flags?.length) &&
        <BoundingBoxFlag
          scale={scale}
          flags={bboxNode.flags}
          offsetX={x1 - x2 - 1}
          commonProps={commonProps}
          clickableProps={clickableProps}
        />}
    </Fragment>
  )
}

// function compareBBoxProps(prev, next) {
//   return (
//     prev.scale === next.scale &&
//     prev.display === next.display &&
//     // prev.x1 === next.x1 &&
//     // prev.x2 === next.x2 && 
//     // prev.y1 === next.y1 && 
//     // prev.y2 === next.y2 && 
//     prev.clickable === next.clickable &&
//     prev.flags?.length === next.flags?.length &&
//     prev.showTags === next.showTags &&
//     prev.bboxNode.isValid === next.bboxNode.isValid
//   )
// }

const BoundingBoxScaled = /*React.memo*/(({ coords: { x1, y1, x2, y2 }, imageSize, ...props }) => {
  return <BoundingBox
    x1={x1 * imageSize.width}
    x2={x2 * imageSize.width}
    y1={y1 * imageSize.height}
    y2={y2 * imageSize.height}
    {...props}
  />
}/*, compareBBoxProps*/)


// function useLabelIdFilter(){
//   const dispatch = useDispatch();

//   const hideLabelIds = useSelector(state => state.annotation.hideLabelIds)

//   const labelIdsToHide = useMemo(() => new Set(hideLabelIds), [hideLabelIds.length])
//   return { labelIdsToHide }
// }


function BBoxList({ items, itemProps, hideBoxes, threshold, selectedOnly, flaggedOnly, color = "default" }) {

  const labels = useLabelMap(state => state.labels);
  const showLabelsSet = useMemo(() => new Set(itemProps.showLabels), [itemProps.showLabels.length])

  const bboxColor = bboxColors[color]

  //TODO: Refactor
  const _items = useMemo(() => hideBoxes ? [] : items.filter(
    ({ confidence, isValid, labelId, flags }) => ((!confidence) || (
      (threshold[0] <= confidence && threshold[1] >= confidence) && ((!selectedOnly) || isValid)
    )) && showLabelsSet.has(labelId) && (!flaggedOnly || flags?.length)
  ), [threshold[0], threshold[1], hideBoxes, selectedOnly, itemProps.showLabels.length, flaggedOnly])

  if (!_items) {
    return <Rect x={1} y={1} width={2} height={2} opacity={0} />
  }
  return (
    _items.map((bbox) => { //rest is coords
      const { id, labelId, confidence, tempId } = bbox
      const { x1, y1, x2, y2 } = bbox
      return (
        <BoundingBoxScaled
          key={`bbox-${id || tempId}`}
          text={`${labels[labelId] || labelId}` +
            (confidence ? `: ${parseInt(confidence * 1000) / 10}%` : '')}
          colors={bboxColor}
          coords={{ x1, y1, x2, y2 }}
          bboxNode={bbox}
          {...itemProps}
        />
      )
    })
  )
}

// const tempboxPropsCompare = (prev, next) => (
//   prev.bbox.x1 === next.bbox.x1 &&
//   prev.bbox.x2 === next.bbox.x2 &&
//   prev.bbox.y1 === next.bbox.y1 &&
//   prev.bbox.y2 === next.bbox.y2 &&
//   prev.bbox.labelId === next.bbox.labelId &&
//   prev.isDrawing === next.isDrawing &&
//   prev.scale === next.scale &&
//   prev.text === next.text &&
//   prev.isDrawing === next.isDrawing
// )

/*
A   B   C
0   0   1
0   1   1
1   0   0
1   1   1

(!A || B)
*/

export function NewBoxList({ items, itemProps, hideBoxes, flaggedOnly, color = 'default' }) {
  const labels = useLabelMap(state => state.labels);
  const showLabelsSet = useMemo(() => new Set(itemProps.showLabels), [itemProps.showLabels.length])

  const bboxColor = bboxColors[color]
  const _items = useMemo(() => hideBoxes ? [] :
    items.filter(({ labelId, flags }) => showLabelsSet.has(labelId) && (!flaggedOnly || flags?.length)),
    [hideBoxes, itemProps.showLabels.length, flaggedOnly]);

  if (!_items) {
    return <Rect x={1} y={1} width={2} height={2} opacity={0} />
  }


  return (
    _items.map((bbox) => { //rest is coords
      const { id, labelId, confidence, tempId } = bbox
      const { x1, y1, x2, y2 } = bbox
      return (
        <BoundingBoxScaled
          key={`bbox-${id || tempId}`}
          text={`${labels[labelId] || labelId}` +
            (confidence ? `: ${parseInt(confidence * 1000) / 10}%` : '')}
          colors={bboxColor}
          coords={{ x1, y1, x2, y2 }}
          bboxNode={bbox}
          {...itemProps}
        />
      )
    })
  )
}

export function TempBox({ bbox, isDrawing, imageSize, scale, hideBoxes, ...props }) {
  const [ color ] = useState(bboxColors["new"][true]);

  const labels = useLabelMap(state => state.labels);


  const onMouseLeave = () => !(isDrawing) && props.setCursor('')

  function handleCursor(cursor) {
    if (!(isDrawing)) {
      return () => props.setCursor(cursor)
    }
  }

  function handleMouseDownCorner(anchor) {
    return () => {
      props.redraw(anchor)
    }
  }

  function onMouseUp() {
    props.setCursor('')
  }

  const [circles, lines, labelProps] = useMemo(() => {
    const { width, height } = imageSize
    let x1 = bbox.x1 * width
    let x2 = bbox.x2 * width
    let y1 = bbox.y1 * height
    let y2 = bbox.y2 * height

    return [
      [
        { key: "topLeft", x: x1, y: y1, cursor: 'nwse-resize', anchor: { x1: bbox.x2, y1: bbox.y2 } },
        { key: "topRight", x: x2, y: y1, cursor: 'nesw-resize', anchor: { x1: bbox.x1, y1: bbox.y2 } },
        { key: "bottomLeft", x: x1, y: y2, cursor: 'nesw-resize', anchor: { x1: bbox.x2, y1: bbox.y1 } },
        { key: "buttomRight", x: x2, y: y2, cursor: 'nwse-resize', anchor: { x1: bbox.x1, y1: bbox.y1 } }
      ],
      [
        { key: "top", points: [x1 + 4 / scale, y1, x2 - 4 / scale, y1] },
        { key: "right", points: [x2, y1 + 4 / scale, x2, y2 - 4 / scale] },
        { key: "bottom", points: [x1 + 4 / scale, y2, x2 - 4 / scale, y2] },
        { key: "left", points: [x1, y1 + 4 / scale, x1, y2 - 4 / scale] },
      ],
      { x: Math.min(x1, x2), y: Math.min(y1, y2) }
    ]
  }, [scale, bbox.x1, bbox.x2, bbox.y1, bbox.y2, imageSize.width, imageSize.height])



  const text = useMemo(() => labels[bbox.labelId], [bbox.labelId])

  if (hideBoxes) {
    return <Fragment />
  }
  return (
    <Fragment>
      {lines.map(({ key, cursor, ...lineProps }) => (
        <Line
          key={`tempbox-${key}`}
          strokeWidth={2 / scale}
          stroke={color.shape}
          onMouseEnter={handleCursor(cursor)}
          {...lineProps}
        />
      ))}
      {text &&
        <Label {...labelProps} offsetY={18} offsetX={-5}>
          <Tag fill={color.shape} />
          <Text text={text} fontSize={14} fill={color.text} padding={2} />
        </Label>
      }
      {circles.map(({ key, cursor, anchor, ...circleProps }) => (
        <Circle
          key={`tempbox-${key}`}
          radius={6 / scale}
          fill="#d0d0d080"
          stroke={color.shape}
          strokeWidth={2 / scale}
          onMouseEnter={handleCursor(cursor)}
          onMouseDown={handleMouseDownCorner(anchor)}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}

          onTouchStart={handleMouseDownCorner(anchor)}
          onTouchEnd={onMouseUp}
          onTouchCancel={onMouseLeave}
          {...circleProps}
        />
      ))}
    </Fragment>
  )
}


// export const TempBox = React.memo(_TempBox, tempboxPropsCompare)

export default BBoxList