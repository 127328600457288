import React, { useState } from 'react';
import { makeStyles, FormControl, Popover, Chip, IconButton, Input, InputAdornment } from '@material-ui/core'

import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 2,
    padding: 5,
    paddingBottom: 4
  }
}))

export default function ({ currentIndex, totalCount, jumpTo, className, disabled = false }) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null)
  const [value, setValue] = useState(0)

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  }
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    jumpTo((parseInt(value) - 1) ?? 0)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'label-popover' : undefined;

  return (<>
    <Chip variant="outlined" color="primary"
      className={className}
      label={`${currentIndex + 1}/${totalCount}`}
      aria-describedby={id} onClick={handleOpen}
    />
    <Popover
      PaperProps={{ className: classes.form }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <form onSubmit={handleSubmit} >
        <FormControl disabled={disabled}>
          <Input
            type='number'
            value={value}
            inputProps={{
              style: { marginRight: -10 },
              max: totalCount,
              min: 1,
              required: true
            }}
            onChange={e => setValue(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={disabled}
                  type='submit'
                  size='small'
                >
                  <ArrowRightIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
    </Popover>
  </>
  )
}