import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import LabelMap from './Components/LabelMap';


export default function({onClose, open, ...props}) {

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle >Label Haritası</DialogTitle>

      <LabelMap />
    </Dialog>
  );
}

 
