import React, { useState, useEffect } from 'react';
import { InputLabel, TextField, Grid, LinearProgress } from '@mui/material';
import { Button, Box, makeStyles } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import getAlternateStyle, { getColor } from '../../CommonStyles/AlternateColor';
import { useSelector } from 'react-redux';
import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import {
  addDataset,
  deleteDataset,
  getDatasetImages,
  deleteDatasetImage,
  getVisJson,
} from '../../../../service/admin.service';
import { Link } from 'react-router-dom';
import useSelection from '../../../../util/useSelection';
import useSubmit from '../../../../util/useSubmit';
import usePagination from '../../../../util/usePagination';
import CustomNoRowsOverlay from '../../../../GlobalComponents/CustomNoRowsOverlay';
import useDownload from '../../../../util/useDownload';

const useStyles = makeStyles((theme) => ({
  datasetRoot: {
    width: '90%',
    height: '45.2%',
    padding: '0 30px 0 30px',
  },
  spaceAround: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    margin: '1rem',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: '1rem',
    margin: '1rem',
  },
  datasetLabel: {
    position: 'relative',
    top: '1rem',
  },
  addBtn: {
    position: 'relative',
    top: '0.5rem',
  },
  topChangePagination: {
    position: 'relative',
    bottom: '7rem',
    left: '1rem',
  },
  bottomChangePagination: {
    position: 'relative',
    bottom: '9.2rem',
    left: '1rem',
  },
}));

function GetTopPart({ getData, getClickedId }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const [datasetValue, setDatasetValue] = useState('');
  const initialPageSize = 11;
  const rows = [];
  let rowNumber = 1;

  const { byId: datasets } = useSelector((state) => state.admin.enums.datasets);

  function createData(rowNumber, id, name, count) {
    return { rowNumber, id, name, count };
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Dataset Name',
      flex: 1,
    },
    {
      field: 'count',
      headerName: 'Image Count',
      flex: 1,
    },
  ];

  const {
    pageNumber,
    pageSize,
    noRowsOverlay,
    setNoRowsOverlay,
    anotherPagination,
    handleChangePaginationClick,
    handlePageChange,
    handlePageSizeChange,
  } = usePagination({initialPageSize});

  const {
    ids,
    selectionModel,
    handleCheckBoxClicks,
    handleCellClick,
    isBtnActive,
    data,
    clickedId,
  } = useSelection({rows, pageNumber, pageSize});

  const { submit: deleteDatasetFn } = useSubmit(
    deleteDataset,
    'delete',
    'datasets'
  );
  const { submit: addDatasetFn } = useSubmit(addDataset, 'add', 'datasets');
  const { submit: clickFn } = useSubmit(getDatasetImages);
  const { submit: getVisJsonFn } = useSubmit(getVisJson);

  const download = useDownload();

  for (const key in datasets) {
    const dataset = datasets[key];
    rows.push(createData(rowNumber, dataset.id, dataset.name, dataset.count));
    rowNumber++;
  }

  useEffect(() => {
    if (rows.length === 0) {
      setNoRowsOverlay({ NoRowsOverlay: CustomNoRowsOverlay });
      return;
    }
    getData(data);
    getClickedId(clickedId);
  }, [data, clickedId]);

  function handleDatasetChange(e) {
    setDatasetValue(e.target.value);
  }

  async function submit(e) {
    e.preventDefault();

    const tagName = e.target.tagName;
    const innerHTML = e.target.innerHTML;

    if (loading) {
      return;
    }

    setLoading(true);
    
    if (tagName === 'SPAN') {
      if (innerHTML === 'Delete') {
        pending(true, 'Siliniyor...');
        for (const id of ids) {
          await deleteDatasetFn(id);
        }
      } else if (innerHTML === 'Download VisJson') {
        pending(true, 'İndiriliyor...');
        for (const id of ids) {
          const { data } = await getVisJsonFn(id);
          const selectedRow = rows.filter((row) => row.id === id);
          const name = selectedRow[0].name;
          download(`${name}.json`, JSON.stringify(data));
        }
      }
    } else if (tagName === 'FORM') {
      pending(true, 'Ekleniyor...');
      await addDatasetFn(datasetValue);
    }

    setLoading(false);
    pending(false);
  }

  return (
    <>
      <Box component='form' autoComplete='off' onSubmit={submit}>
        <Grid container spacing={3} m={4} alignItems='stretch'>
          <Grid item>
            <InputLabel className={classes.datasetLabel}>Dataset</InputLabel>
          </Grid>
          <Grid item>
            <TextField
              label='Dataset'
              onChange={handleDatasetChange}
              value={datasetValue}
              required
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.addBtn}
              variant='contained'
              color='primary'
              type='submit'
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
          ...noRowsOverlay,
          ...anotherPagination,
        }}
        rows={rows}
        columns={columns}
        checkboxSelection
        onCellClick={(cell) => {
          handleCellClick(cell, clickFn);
        }}
        disableSelectionOnClick
        onSelectionModelChange={handleCheckBoxClicks}
        selectionModel={selectionModel}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        page={pageNumber}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        sx={getAlternateStyle}
        getRowClassName={(params) => {
          return getColor(params.row.rowNumber);
        }}
      />
      <Box className={classes.spaceAround}>
        <Button
          to={`/admin/settings/add-image-to-dataset/${ids}`}
          component={Link}
          variant='contained'
          color='primary'
          disabled={!isBtnActive}
        >
          Add Images
        </Button>
        <Button
          id='visJson'
          variant='contained'
          color='primary'
          onClick={submit}
          disabled={!isBtnActive}
        >
          Download VisJson
        </Button>
        <Button
          id='delete'
          variant='contained'
          color='primary'
          onClick={submit}
          disabled={!isBtnActive}
        >
          Delete
        </Button>
      </Box>
      <Button
        className={classes.topChangePagination}
        variant='contained'
        color='primary'
        onClick={handleChangePaginationClick}
      >
        Change Pagination
      </Button>
    </>
  );
}

function GetBottomPart({ data, clickedId }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [arrangedData, setArrangedData] = useState([]);
  const pending = usePending();
  const initialPageSize = 11;
  let rowNumber = 0;
  const { submit: deleteDatasetImageFn } = useSubmit(
    deleteDatasetImage,
    'deleteOther',
    'datasets'
  );

  useEffect(() => {
    if (data.length === 0) {
      setNoRowsOverlay({ NoRowsOverlay: CustomNoRowsOverlay });
      return;
    }
    setArrangedData(
      data.map((d) => {
        rowNumber++;
        return { rowNumber, id: d, storagePath: d };
      })
    );
  }, [data]);

  const columns = [
    {
      field: 'storagePath',
      headerName: 'Storage Path',
      flex: 1,
    },
  ];

  const {
    pageNumber,
    pageSize,
    noRowsOverlay,
    setNoRowsOverlay,
    anotherPagination,
    handleChangePaginationClick,
    handlePageChange,
    handlePageSizeChange,
  } = usePagination({initialPageSize});

  const { ids, selectionModel, handleCheckBoxClicks, isBtnActive } =
    useSelection({rows: data, pageNumber, pageSize});

  async function submit() {
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    await deleteDatasetImageFn({
      datasetId: clickedId,
      imagePathList: ids,
    });

    ids.map((id) =>
      setArrangedData((prevData) => prevData.filter((d) => d.id !== id))
    );

    setLoading(false);
    pending(false);
  }

  return (
    <>
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
          ...noRowsOverlay,
          ...anotherPagination,
        }}
        rows={arrangedData}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={handleCheckBoxClicks}
        selectionModel={selectionModel}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        page={pageNumber}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        sx={getAlternateStyle}
        getRowClassName={(params) => {
          return getColor(params.row.rowNumber);
        }}
      />
      <Box className={classes.flexEnd}>
        <Button
          variant='contained'
          color='primary'
          onClick={submit}
          disabled={!isBtnActive}
        >
          Delete
        </Button>
      </Box>
      <Button
        className={classes.bottomChangePagination}
        variant='contained'
        color='primary'
        onClick={handleChangePaginationClick}
      >
        Change Pagination
      </Button>
    </>
  );
}

export default function GetDatasets() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [clickedId, setClickedId] = useState(-99);

  const getData = (newData) => {
    setData(newData);
  };

  const getClickedId = (clickedId) => {
    setClickedId(clickedId);
  };

  return (
    <Box className={classes.datasetRoot}>
      <GetTopPart getData={getData} getClickedId={getClickedId} />
      <GetBottomPart data={data} clickedId={clickedId} />
    </Box>
  );
}
