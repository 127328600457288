import { createSlice } from '@reduxjs/toolkit'

const AnnotationProblemIds = [1, 3, 5]


export const user = {
  user: null,
  problem: {
    error: null,
    problemCategoryId: null,
    userProblemId: null,
    completed: false
  },
  loading: true,
  coverScreen: false,
  panelType: 'NotSet'
};

const userSlice = createSlice({
  name: 'user',
  initialState: user,
  reducers: {
    setUser(state, { payload }) {
      state.user = payload;
      state.loading = false
    },
    setProblem(state, { payload }) {
      state.problem = { ...payload };

      if (typeof (payload.problemCategoryId) !== 'number') {
        state.coverScreen = false;
        state.panelType = 'NotSet'
      } else if (AnnotationProblemIds.includes(payload.problemCategoryId)) {
        state.coverScreen = true
        state.panelType = 'Annotation'
      } else {
        state.coverScreen = false
        state.panelType = 'Evaluation'
      }
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
    setCoverScreen(state, { payload }) {
      state.coverScreen = payload
    },
    setPanelType(state, { payload }) {
      state.panelType = payload
    },
    resetUser(state) {
      Object.assign(state, user, { loading: false })
    }
  }
})


export const reducer = userSlice.reducer
export const actions = userSlice.actions
