import React, { useState, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { makeStyles } from '@material-ui/core/styles';
import { fetchImageData } from '../service/evaluate.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ErrorContent } from '../util/handleError';
import { addCloudWatchLog } from '../service/cloud.service';

const smallImageHeight = 'calc(100vh - 9.3rem - 4px - 2rem)'

const useStyles = makeStyles({
  media: {
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
    backgroundSize: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  image: {
    height: 'fit-content',
    width: 'auto',
    margin: 'auto',

    maxWidth: '100wh',
    objectFit: 'fill',
  },
  progressWrapper: {
    margin: 'auto',
    marginTop: 270,
  },
  fullscreenProgress: {
    display: 'flex',
    height: 'calc(100vh - 9.3rem)',
    minHeight: 'calc(100vh - 9.3rem)',
    width: '100%',
    margin: 'auto',
  },
  smallProgress: {
    display: 'flex',
    height: smallImageHeight,
    width: '100%'
  }
});

const Loading = ({ containerClass, progressWrapper }) => (
  <div className={containerClass}>
    <div className={progressWrapper}>
      <CircularProgress size={90} />
    </div>
  </div>
)

function Image({ image, setLoading, isFullScreen }) {
  const confirm = useConfirm();
  const classes = useStyles();
  const [imageData, setImageData] = useState(false)
  const [error, setError] = useState(false);
  const [highResImageData, setHighResImageData] = useState(false);

  useEffect(() => {
    let unmounted = false
    if (isFullScreen && !highResImageData) {
      const setHighResImage = async () => {
        //TODO: Dont set state after dismantle 
        try {
          setLoading(true)
          let resData = await fetchImageData(image.highResUrl);
          if (unmounted) { return }
          resData = "data:image/jpg;base64," + resData;
          setLoading(false)
          setHighResImageData(resData)
        } catch (e) {
          confirm({
            title: 'Hata',
            confirmationText: 'Sayfayı Yenile',
            confirmationButtonProps: { color: 'primary' },
            cancellationButtonProps: { style: { display: 'none' } },
            description: <ErrorContent message={"Yüksek çözünürlüklü görüntüyü işlerken bir sorun oluştu."} e={e} />
          }).then(() => { window.location.reload() })
        }
      }
      setHighResImage()
      return () => {
        unmounted = true
      }
    }
  }, [isFullScreen])

  useEffect(() => {
    let unmounted = false
    const watchPromise = async () => {
      try {
        let resData = await image.promise;
        if (unmounted) { return }
        resData = "data:image/jpg;base64, " + resData;
        setImageData(resData);
        setLoading(isFullScreen);
      } catch (e) {
        addCloudWatchLog(`Image.js:100 ${e}`);
        console.error(e)
        confirm({
          title: 'Hata',
          confirmationText: 'Sayfayı Yenile',
          confirmationButtonProps: { color: 'primary' },
          cancellationButtonProps: { style: { display: 'none' } },
          // TODO: change this error
          description: <ErrorContent message={"Görüntüyü işlerken bir sorun oluştu."} e={e} />
        })
          .then(() => { window.location.reload() })
        setError(e)
      }
    }
    watchPromise();
    return () => {
      unmounted = true
    }
  }, [image])

  function openNewWindow(e) {
    e.preventDefault()
    if (!highResImageData) { return }
    var win = window.open();
    win.document.write(`
      <head><meta name="viewport" content="width=device-width, minimum-scale=0.1"><title>Original Image</title></head>
      <body style="margin: 0px; background: #0e0e0e;">
        <img style="-webkit-user-select: none; margin: auto;" src="${highResImageData}"></img>
      </body>`);
    win.document.close();
  }




  if (error) {
    return <div style={{ height: 800 }} />;
  }

  return <div className={classes.media}>
    {imageData ?
      (!isFullScreen) ?
        <img
          className={classes.image}
          src={imageData}
          style={{
            maxHeight: smallImageHeight,
            minHeight: smallImageHeight
          }}
        />
        : highResImageData ?
          <img
            onClick={openNewWindow}
            src={highResImageData}
            className={classes.image}
            style={{
              maxHeight: 'calc(100vh - 9.3rem)',
              minHeight: 'calc(100vh - 9.3rem)',
              margin: 'auto',
              maxWidth: '100wh',
              cursor: 'pointer',
            }}
          />
          : <Loading containerClass={classes.fullscreenProgress} progressWrapper={classes.progressWrapper} />
      : <Loading containerClass={classes.smallProgress} progressWrapper={classes.progressWrapper} />}
  </div>
}

export default Image
