import revalidator from 'revalidator'
import { addCloudWatchLog } from '../../../service/cloud.service'

const isFile = (maybefile) => maybefile.constructor.name === 'File'
const isJson = (maybeJson) => maybeJson.type.includes('json')
const isArr = (maybeArr) => Array.isArray(maybeArr)

//TODO: Implement Locale messages!

const coordProps = {
  type: "number",
  minimum: 0,
  maximum: 1,
  // allowEmpty: false,
  required: true
  // messages: {
  //   type: 'Not a number',

  // }
}

const labelIdProps = {
  allowEmpty: false,
  type: ['integer', 'string', 'number'],
  required: true
}


const defaultBboxProps = {
  x1: coordProps,
  x2: coordProps,
  y1: coordProps,
  y2: coordProps,
  labelId: labelIdProps,
  id: { type: ['string', 'number'], required: true }
}

const gtBBoxArrProps = {
  type: "array",
  required: false,
  items: {
    type: "object",
    properties: defaultBboxProps,
  }
}

const predBBoxArrProps = {
  type: "array",
  required: false,
  items: {
    type: 'object',
    properties: {
      ...defaultBboxProps,
      confidence: coordProps
    }
  }
}

const imagePathProps = {
  type: "string",
  required: true
}

const dataArrayProps = {
  type: "array",
  required: true,
  minItems: 1,
  allowEmpty: false,
  items: {
    type: 'object',
    properties: {
      imagePath: imagePathProps,
      bboxGT: gtBBoxArrProps,
      bboxPred: predBBoxArrProps
    }
  }
}

const fileDataProps = {
  type: 'object',
  required: true,

  properties: {
    data: dataArrayProps,
    labelMap: { required: false, allowEmpty: false, type: 'object' },
    // dataId: { required: true, allowEmpty: false, type: 'string' }
  }
}

const coordNames = ['x1', 'y1', 'x2', 'y2']
const validByDefault = {
  bboxGT: true,
  regionGT: true,
  bboxPred: false,
  regionPred: false
}

export function addIdsToBBboxes(datas, keys = ["bboxGT", "bboxPred", "regionGT", "regionPred"]) {
  var i = 0;
  (datas || []).forEach(data => { //imagePath, bboxGT, bboxPred, ...
    keys.forEach(key => {
      if (key in data) {
        data[key].forEach(bbox => {
          bbox.id = bbox.id || coordNames.map(cn => `${bbox[cn]}`.substr(0, 5)).join('_').concat(`_${i++}`)
          if (bbox.isValid === undefined) {
            bbox.isValid = validByDefault[key]
          }
        })
      }
    })
  })
}


//TODO: Needs refactor
export async function handleLocalFile(file) {
  if (!isFile(file)) {
    throw {
      message: `Semantik bir hata oluştu. 
                File objesi beklenirken ${file.constructor.name} bulundu.`,
      title: 'Beklenmeyen Obje'
    }
  }

  if (!isJson(file)) {
    throw {
      message: 'Yalnızca JSON formatı seçin.',
      title: 'Beklenmeyen Dosya Formatı'
    }
  }

  const fileData = {};
  let maybePreds;
  const strData = await file.text()

  try {
    maybePreds = JSON.parse(strData)
  } catch (error) {
    addCloudWatchLog(`Dosya Hatası! ${error}`);
    console.error(error)
    throw {
      title: "Dosya hatası!", error,
      message: 'Json dosyasını okumaya çalışırken bir hata oluştu. Detaylar için konsola bakın.'
    }
  }


  if (isArr(maybePreds)) {

    fileData["data"] = maybePreds;
    fileData["dataId"] = `${file.name}_${file.lastModified}_wrapped`;

  } else if ("data" in maybePreds) {
    fileData["data"] = maybePreds.data;
    fileData["dataId"] = maybePreds.dataId ?? `${file.name}_${file.lastModified}_wrapped`;
    fileData['labelMap'] = maybePreds.labelMap
  } else {
    throw {
      message: (
        "Data formatı liste olmalı ya da obje ise" +
        " 'data' fieldi içinde liste uygun bir şekilde sağlanmalıdır"
      ),
      title: 'Beklenmeyen veri yapısı!'
    }
  }

  addIdsToBBboxes(fileData.data)

  const { valid, errors } = revalidator.validate(fileData, fileDataProps)

  if (!valid) {
    addCloudWatchLog(`Dosya içinde ${errors.length} hatalı veri var.`);
    console.error(errors)
    throw {
      message: `Dosya içinde ${errors.length} hata bulundu. ` +
        "Daha fazlası bilgi için konsola bakın.",
      title: 'Beklenmeyen veri yapısı!'
    }
  }

  return fileData

}