import AWS from 'aws-sdk'
import 'dotenv/config'

const config = {
    region: process.env.REACT_APP_REGION,
    credentials: { 
        accessKeyId: process.env.REACT_APP_ACCESS_ID_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    },
}

const s3 = new AWS.S3(config)

export async function s3ClientGetObject(bucket, key) {
    const params = {
        Bucket: bucket,
        Key: key
    }
    const data = await s3.getObject(params).promise()
    return "data:image/png;base64," + Buffer.from(data.Body, 'binary').toString('base64')
}

export async function s3ClientWriteObject(bucket, key, base64, imageName, imageType) {
    const buf = Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""),'base64')

    const params = {
        Bucket: bucket,
        Key: key,
        Body: buf,
        ContentType: 'image/' + imageType
    }
    const data = await s3.putObject(params).promise()
    return data
}