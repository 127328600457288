import React, { useMemo, useState, useEffect } from 'react'
import { actions } from '../../../redux/slices/annotation';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton, makeStyles, Box,
  List, ListItem, ListItemText,
  ListItemSecondaryAction, Paper
} from '@material-ui/core'
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import { useLabelMap } from '../store';
import Scrollbars from 'react-custom-scrollbars';


function useBBoxStore() {
  const tempBox = useSelector(state => state.annotation.tempBox)
  const loading = useSelector(state => state.annotation.loading)
  const newBoxes = useSelector(state => state.annotation.newBoxes)
  const showLabels = useSelector(state => state.annotation.filterOptions.showLabels)
  const region = useSelector(state => state.annotation.region)
  const editableRegion = useSelector(state => state.annotation.editableRegion)
  const tool = useSelector(state => state.annotation.tool)
  const regionList = useSelector(state => state.annotation.regionList) 
  const editableRegionList = useSelector(state => state.annotation.editableRegionList) 

  const dispatch = useDispatch();

  return {
    loading,
    newBoxes,
    tempBox,
    showLabels,
    region,
    editableRegion,
    tool,
    regionList,
    editableRegionList,

    setTempBox: (tb) => dispatch(actions.setTempBox(tb)),
    delNewBox: (newBox) => dispatch(actions.delNewBox(newBox)),
    setCursor: (cursor) => dispatch(actions.setCursor(cursor)),
    setLabelId: (id) => dispatch(actions.setTempLabelId(id)),
  }
}

const useBBoxStyles = makeStyles(theme => ({
  listContainer: {
    height: '100%',
    backgroundColor: '#f7f7f7',
    boxShadow: 'inset 0 0 10px 0px #9e9e9e',
    borderRadius: theme.shape.borderRadius,
    margin: "0px 0px 0px -1px"
  },
  list: {
    paddingLeft: 10, paddingRight: 10,
    '& > div+div': {
      marginTop: theme.spacing(1)
    }
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                  color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  },
  secondaryAction: {
    right: 5
  },
  selectedListItem: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
  },
  editBtn: {
    transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  selectedBtn: {
    color: 'rgba(255, 255, 255, .5) !important'
  },
  hidden: {
    display: 'none'
  }
}))

// This component manages both new boxes on the image and the labels on the left.
export default function NewBoxList(props) {
  const labels = useLabelMap(state => state.labels)
  const classes = useBBoxStyles()
  const { loading, newBoxes, tempBox, showLabels, setTempBox, setCursor, setLabelId, region, editableRegion, tool, regionList, editableRegionList } = useBBoxStore();
  const showLabelsSet = useMemo(() => new Set(showLabels), [showLabels.length])

  // This function is called when we edit the labels(when pencil icon is clicked) inside Square Box or Polygonal Box div.
  function onClickEdit(newBox) {
    setTempBox(newBox)
    setLabelId({ id: newBox.labelId })
    setCursor('')
  }

  const showTempBox = React.useMemo(() => (
    tempBox !== null &&
    !newBoxes.some(({ id, tempId }) => (id || tempId) === (tempBox.id || tempBox.tempId || !undefined))
  ), [tempBox?.id, tempBox?.tempId])

  const showTempPolygonalBox = React.useMemo(() => (
    region !== null &&
    !regionList.some(({ id, tempId }) => (id || tempId) === (region.id || region.tempId || !undefined))
  ), [region?.id, region?.tempId])

  const showTempEditablePolygonalBox = React.useMemo(() => (
    editableRegion !== null &&
    !editableRegionList.some(({ id, tempId }) => (id || tempId) === (editableRegion.id || editableRegion.tempId || !undefined))
  ), [editableRegion?.id, editableRegion?.tempId])

  const RenderListItem = (newBox, i) => {
    const { id, tempId, labelId } = newBox;
    const isEditing = (id || tempId) === (tempBox?.id || tempBox?.tempId || region?.id || region?.tempId || editableRegion?.id || editableRegion?.tempId);
    const hidden = !showLabelsSet.has(labelId)

    // console.log('Labels: ', labels)
    // console.log('LabelId: ', labelId)
    return (
      <ListItem
        key={`new-box-listitem-${id || tempId}`}
        role="listitem"
        ContainerComponent={Paper}
        className={clsx(classes.listItem, {
          [classes.selectedListItem]: isEditing,
          // [classes.hidden]: hidden
        })}
        disabled={hidden}
      >
        <ListItemText primary={`${i} - ${labels[labelId]}`} />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          {/* ((tempBox?.id||tempBox?.tempId) === (id||tempId)) //Editing current newBox */}
          <IconButton
            classes={{ disabled: classes.selectedBtn }}
            disabled={tempBox !== null || region != null || editableRegion != null || hidden}
            onClick={() => onClickEdit(newBox)}
          >
            <EditIcon fontSize='small' />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  return (
    <Box className={classes.listContainer}>
      <Scrollbars style={{ height: '100%' }} autoHide>
        <List className={classes.list} component="div" role="list">
        {tool === 'add' ? 
        (!loading) && showTempBox && RenderListItem(tempBox, '+') : 
        tool === 'poly' ?
        (!loading) && showTempPolygonalBox && RenderListItem(region, '+') :
        tool === 'editablePoly' &&
        showTempEditablePolygonalBox && RenderListItem(editableRegion, '+')}
        {tool === 'add' ? (!loading) && newBoxes.map(RenderListItem) : 
        tool === 'poly' ?
        (!loading) && regionList.map(RenderListItem) :
        tool === 'editablePoly' &&
        editableRegionList.map(RenderListItem)}
        </List>
      </Scrollbars>
    </Box>
  )
}