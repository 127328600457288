import React, { useEffect } from 'react';
import { Fab, Tooltip, makeStyles, Box, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import {
  PanTool,
  TouchApp,
  CropSharp,
  Create,
  Adjust,
  Brush
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../redux/slices/annotation';
import { useChange } from '../store';
import { EraserIcon } from 'assets/svg/Icons';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';

const tabletMode = window.innerWidth < 1024;

const tools = [
  {
    key: 'add',
    name: 'Bounding Box',
    nameShort: 'A',
    Icon: () => <CropSharp />,
  },
  {
    key: 'poly',
    name: 'Polygonal Box',
    nameShort: 'P',
    Icon: () => <Create />,
  },
  {
    key: 'editablePoly',
    name: 'Editable Polygonal Box',
    nameShort: 'E',
    Icon: () => <Adjust fontSize='small' />,
  },
  { 
    key: 'brushTool', 
    name: 'Fırça', 
    nameShort: 'F', 
    Icon: () => <Brush />
  },
  {
    key: 'eraser',
    name: 'Silgi',
    nameShort: 'E',
    Icon: () => <EraserIcon className="" style={{color: 'white'}} />,
  },
  { 
    key: 'select', 
    name: 'Seç', 
    nameShort: 'S', 
    Icon: () => <TouchApp /> 
  },
  {
    key: 'drag',
    name: 'Resmi sürükle',
    nameShort: 'D',
    Icon: () => <PanTool fontSize='small' />,
  },
];

const maxLeftPad = '17.5rem';

const useStyles = makeStyles((theme) => ({
  drawerButton: {
    bottom: '1rem',
    position: 'fixed',
    zIndex: 9999,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  buttonClose: {
    backgroundColor: 'rgb(131, 131, 131)',
    '&:hover': {
      backgroundColor: 'rgb(125, 125, 125)',
    },
    borderRadius: '0 10rem 10rem 0',
    marginLeft: '0px',
    position: 'fixed',
    left: 0,
    zIndex: 9999,
  },
  buttonOpen: {
    backgroundColor: 'rgb(131, 131, 131)',
    '&:hover': {
      backgroundColor: 'rgba(125, 125, 125)',
    },
    color: 'rgb(255, 255, 255)',
    borderRadius: '0 10rem 10rem 0',
    marginLeft: 0,
    zIndex: 9999,
  },
  root: {
    padding: 6,
    backgroundColor: theme.palette.background.default,
    borderRadius: '2rem',
    position: 'absolute',
    zIndex: 2,
    flexDirection: 'column',
    top: '.5rem',
    '& > div + div': {
      marginTop: 8,
    },
  },
  leftPadOpen: {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: maxLeftPad,
  },
  leftPadClose: {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    left: '.5rem',
  },
  disabledFabContainer: {
    boxShadow: 'inset 1.51px 1.5px 5px -2.5px #737999',
    backgroundColor: '#f3f3f3f7',
  },
  leftSidebarButton: { 
    width: '45px', 
    position: 'fixed', 
    left: '0', 
    bottom: '2%', 
    zIndex: '9999', 
    backgroundColor: 'white', 
    borderRadius: '0 10rem 10rem 0', 
    boxShadow: '0px 0px 10px -5px'
  }
}));

const compareProps = (prev, next) =>
  prev.currentTool === next.currentTool && prev.display === next.display;

function useAnnotStore() {
  const dispatch = useDispatch();

  const tempLabelId = useSelector((state) => state.annotation.tempLabelId);
  const editablePolyBoxCoefficient = useSelector(
    (state) => state.annotation.editablePolyBoxCoefficient
  );
  const editableRegion = useSelector(
    (state) => state.annotation.editableRegion
  );
  const numberOfPoints = useSelector(
    (state) => state.annotation.numberOfPoints
  );
  const leftMenuOpened = useSelector((state) => state.annotation.leftMenuOpened);

  return {
    setTempBox: (tb) => dispatch(actions.setTempBox(tb)),
    setEditableRegionRectWidth: (width) =>
      dispatch(actions.setEditableRegionRectWidth(width)),
    setEditableRegionRectHeight: (height) =>
      dispatch(actions.setEditableRegionRectHeight(height)),
    setEditableRegionRectOffsetX: (offsetX) =>
      dispatch(actions.setEditableRegionRectOffsetX(offsetX)),
    setEditableRegionRectOffsetY: (offsetY) =>
      dispatch(actions.setEditableRegionRectOffsetY(offsetY)),
    setEditableRegionLineStrokeWidth: (strokeWidth) =>
      dispatch(actions.setEditableRegionLineStrokeWidth(strokeWidth)),
    setEditablePolyBoxCoefficient: (val) =>
      dispatch(actions.setEditablePolyBoxCoefficient(val)),
    setEditablePolygonalBoxFiltersDisabled: (isDisabled) =>
      dispatch(actions.setEditablePolygonalBoxFiltersDisabled(isDisabled)),
    setLeftMenuOpened: (isOpened) =>
      dispatch(actions.setLeftMenuOpened(isOpened)),
    leftMenuOpened,
    tempLabelId,
    editablePolyBoxCoefficient,
    editableRegion,
    numberOfPoints,
  };
}

function Toolbar({ currentTool, display, handleChange, imageSize, imagePath }) {
  const classes = useStyles();
  const informChange = useChange((state) => state.changed);
  const {
    setTempBox,
    setEditableRegionRectWidth,
    setEditableRegionRectHeight,
    setEditableRegionRectOffsetX,
    setEditableRegionRectOffsetY,
    setEditableRegionLineStrokeWidth,
    tempLabelId,
    editablePolyBoxCoefficient,
    editableRegion,
    setEditablePolygonalBoxFiltersDisabled,
    numberOfPoints,
    leftMenuOpened,
    setLeftMenuOpened
  } = useAnnotStore();

  const leftPadOpen = tabletMode ? leftMenuOpened && (
    currentTool === 'add' ||
    currentTool === 'poly' ||
    currentTool === 'editablePoly' ||
    currentTool === 'brushTool'
  ) :
    currentTool === 'add' ||
    currentTool === 'poly' ||
    currentTool === 'editablePoly' ||
    currentTool === 'brushTool';

  const getLineStrokeWidthByImageType = (imageType) => {
    if (imageType === 'cropped') {
      return 0.7;
    } else if (imageType === 'od_cropped') {
      return 1;
    } else if (imageType === 'od_colormap') {
      return 1;
    }
  };

  const getRectWidthAndHeightByCoefficient = (_editablePolyBoxCoefficient) => {
    let { width, height } = {};

    if (_editablePolyBoxCoefficient > 0.5) {
      width = 7;
      height = 7;
    } else if (_editablePolyBoxCoefficient > 0.2) {
      width = 5;
      height = 5;
    } else {
      width = 4;
      height = 4;
    }
    return { width, height };
  };

  const getEditablePolyAttributes = () => {
    const commonAttributes = {
      offsetX: 1,
      offsetY: 1,
      radius: editablePolyBoxCoefficient * 0.5,
    };

    const imageType = imagePath.split('/').slice(3)[0];
    const lineStrokeWidth = getLineStrokeWidthByImageType(imageType);

    const { width, height } = getRectWidthAndHeightByCoefficient(
      editablePolyBoxCoefficient
    );

    return {
      width,
      height,
      lineStrokeWidth,
      ...commonAttributes,
    };
  };

  useEffect(() => {
    if (currentTool === 'editablePoly') {
      setEditablePolygonalBoxFiltersDisabled(false);
      const editablePolyAttributes = getEditablePolyAttributes();
      drawEditablePoly(editablePolyAttributes);
    }
  }, [numberOfPoints, editablePolyBoxCoefficient, currentTool]);

  const drawEditablePoly = ({
    radius,
    width,
    height,
    offsetX,
    offsetY,
    lineStrokeWidth,
  }) => {
    const { points, currentCenterPoints } = getShapeCoordinates(radius);

    setEditableRegionRectWidth(width);
    setEditableRegionRectHeight(height);
    setEditableRegionRectOffsetX(offsetX);
    setEditableRegionRectOffsetY(offsetY);
    setEditableRegionLineStrokeWidth(lineStrokeWidth);

    setTempBox({
      boxType: 3,
      tempId: Math.random(),
      labelId: parseInt(tempLabelId),
      points,
      currentCenterPoints,
    });

    informChange();
  };

  const convertDegreeToRadian = (degree) => {
    return degree * (Math.PI / 180);
  };

  const getShapeCoordinates = (radius) => {
    let points = [];
    // Initial center points are {x: 0.5, y: 0.5}
    const currentCenterPoints = editableRegion
      ? editableRegion.currentCenterPoints
      : { x: 0.5, y: 0.5 };

    for (let i = 0; i <= numberOfPoints; i++) {
      const oneInternalAngle = 360 / numberOfPoints;
      const x =
        radius * Math.cos(convertDegreeToRadian(oneInternalAngle * i)) +
        currentCenterPoints.x;
      const y =
        radius * Math.sin(convertDegreeToRadian(oneInternalAngle * i)) +
        currentCenterPoints.y;
      points.push({ x, y });
    }

    return { points, currentCenterPoints };
  };

  return (
    <>
      {tabletMode && (
        <div>
          <IconButton
            variant='contained'
            id='left-drawer'
            className={clsx(classes.drawerButton, {
              [classes.buttonOpen]: leftMenuOpened,
              [classes.buttonClose]: !leftMenuOpened,
            })}
            onClick={() => setLeftMenuOpened(!leftMenuOpened)}
          >
            {leftMenuOpened ? <MenuOpenIcon style={{zIndex: 9999999}} /> : <ArrowRightIcon style={{zIndex: 9999999}}/>}
          </IconButton>
      </div>
      )}
      <Box
        className={clsx(classes.root, {
          [classes.leftPadOpen]: leftPadOpen,
          [classes.leftPadClose]: !leftPadOpen,
        })}
        >
        {tools.map(({ key, name, Icon }) => (
          <Tooltip
          arrow
          title={name}
          placement='right'
          id={`toolbar-tooltip-${key}`}
          key={`toolbox-btn-${key}`}
          open={undefined}
          enterDelay={600}
          >
            <Box
              borderRadius={25}
              className={clsx({
                [classes.disabledFabContainer]: currentTool === key,
              })}
              >
              <Fab
                color='primary'
                onClick={() => {
                  handleChange(key);
                }}
                size='medium'
                disabled={currentTool === key || !display}
                >
                <Icon />
              </Fab>
            </Box>
          </Tooltip>
        ))}
      </Box>
    </>
  );
}

export default React.memo(Toolbar, compareProps);
