import React, {useState} from 'react';
import {Paper, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, InputAdornment, FilledInput, IconButton} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {useLabelMap} from '../../../store';

function EditCell({onSave, labelId, oldValue}){
  const [label, setLabel] = useState(oldValue)
  function onChange(e){
    setLabel(e.target.value)
  }
  function onSubmit(e){
    e.preventDefault()
    onSave(labelId, label)
  }
  return (<form onSubmit={onSubmit}>
    <FilledInput autoFocus onChange={onChange} value={label} fullWidth margin='dense'
      endAdornment={
        <InputAdornment position="end">
          <IconButton type='submit'>
            <CheckCircleIcon />
          </IconButton>
          {/* <IconButton
            onClick={() => onDelete(labelId)}
            edge="end"
          >
            <DeleteIcon />
          </IconButton> */}
        </InputAdornment>
      }
    />
    </form>
  )
}


export default function(props){
  const [editingId, setEditingId] = useState();

  const labels = useLabelMap(state => state.labels)
  const [setLabel, deleteLabel] = useLabelMap(state => [state.setLabel, state.deleteLabel])

  function openEditMode(labelId){
    setEditingId(labelId)
  }

  function onSave(labelId, label){
    setLabel(labelId, label)
    setEditingId()
  }

  function onDelete(labelId){
    deleteLabel(labelId)
    setEditingId()
  }

  function onCancel(){
    setEditingId()
  }

  const isEditing = editingId !== undefined;

  return (
    <TableContainer component={Paper}>
      <Table size='small' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{width: '2rem'}}>Id</TableCell>
            <TableCell>İsim</TableCell>
            <TableCell align="center" style={{width: '3rem'}}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(labels).map((labelId) => (
            <TableRow key={labelId}>
              <TableCell component="th" scope="row">
                {labelId}
              </TableCell>
              
              <TableCell>
                {labelId === editingId?
                  <EditCell 
                    oldValue={labels[labelId]}
                    labelId={labelId}
                    onSave={onSave}
                    onDelete={onDelete}
                    onCancel={onCancel}  
                  />
                  :labels[labelId]
                }
              </TableCell>
              <TableCell component="th" scope="row">
                {labelId === editingId?
                  <IconButton onClick={() => onCancel()} >
                    <CancelIcon />
                  </IconButton>
                  :<IconButton disabled={isEditing} onClick={() => openEditMode(labelId)}>
                    <EditIcon />
                  </IconButton>
                }
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}