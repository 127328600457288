import React, { useState } from 'react';
import { Typography, InputLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@material-ui/core';
import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import { editUser } from '../../../../service/admin.service';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { actions } from '../../../../redux/slices/admin';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { addCloudWatchLog } from '../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  inputAndLabel: {
    position: 'relative',
    left: '13%',
    top: '8%',
  },
  inputEl: {
    width: '70%',
  },
  labelEl: {
    position: 'relative',
    top: '2.4rem',
    right: '6rem',
  },
  saveButton: {
    position: 'relative',
    left: '78%',
    top: '2rem',
  },
  userSettingsButton: {
    position: 'relative',
    top: '2%',
  },
}));

function useEditUserFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const editUserFn = editUser;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`EditUser.js:60 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`EditUser.js:74 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await editUserFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.updateEnum({ enumKey: 'users', data }));
    }
    return { data, success, error, message };
  }
  return submit;
}

function InputandLabel({ inputId, label, ...otherProps }) {
  const classes = useStyles();
  const { name, email, title, role } = otherProps.getters;
  const { setName, setEmail, setTitle, setRole } = otherProps.setters;
  const emailRegex =
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

  let getStrategies = new Map([
    ['0', name],
    ['1', email],
    ['2', title],
  ]);

  let setStrategies = new Map([
    ['0', setName],
    ['1', setEmail],
    ['2', setTitle],
  ]);
 
  function startGetAction(inputId) {
    let value = getStrategies.get(inputId);
    return value;
  }

  function startSetAction(inputId, input) {
    let fn = setStrategies.get(inputId);
    fn(input);
  }

  const handleTextFieldChange = (e) => {
    startSetAction(e.target.id, e.target.value);
  };

  const handleSelectChange = (e) => {
    setRole(e.target.value);
  };

  const value = startGetAction(inputId);

  return (
    <div className={classes.inputAndLabel}>
      <InputLabel className={classes.labelEl}>{label}</InputLabel>
      {label === 'Role' ? (
        <FormControl className={classes.inputEl} required>
          <InputLabel id='select-label'>Select</InputLabel>
          <Select
            labelId='select-label'
            label='Select'
            value={role}
            onChange={handleSelectChange}
          >
          <MenuItem value={'Superuser'}>Superuser</MenuItem>
          <MenuItem value={'User'}>User</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <TextField
          id={inputId}
          className={classes.inputEl}
          label={label}
          onChange={handleTextFieldChange}
          inputProps={{ pattern: label === 'E-mail' ? emailRegex : undefined }}
          value = {value}
          required
        />
      )}
    </div>
  );
}

export default function EditUser() {
  const classes = useStyles();
  const labels = ['Name', 'E-mail', 'Title', 'Role'];
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const editUserFn = useEditUserFunction();
  const history = useHistory();

  const { id } = useParams();

  let initialName;
  let initialEmail;
  let initialTitle;
  let initialRole;

  const { byId: users } = useSelector((state) => state.admin.enums.users);

  function getRoleById(roleId) {
    if (roleId === 1) {
      return 'Superuser';
    } else if (roleId === 2) {
      return 'User';
    } else {
      return 'Invalid Value';
    }
  }

  for (const key in users) {
    const user = users[key];
    if (user.id === +id) {
      initialName = user.name;
      initialEmail = user.email;
      initialTitle = user.title;
      initialRole = getRoleById(user.roleId);
    }
  }

  const [name, setName] = useState(initialName);
  const [email, setEmail] = useState(initialEmail);
  const [title, setTitle] = useState(initialTitle);
  const [role, setRole] = useState(initialRole);

  const getters = { name, email, title, role };
  const setters = { setName, setEmail, setTitle, setRole };

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    function getRoleId(role) {
      if (role === 'Superuser') {
        return 1;
      } else if (role === 'User') {
        return 2;
      }
    }

    const roleId = getRoleId(role);

    const request = {
      id,
      name,
      email,
      title,
      roleId,
    };

    const { success, error } = await editUserFn(request);
    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/users/all');
    }
  }

  return (
    <Box component='form' autoComplete='off' onSubmit={submit}>
      <IconButton
        color='primary'
        className={classes.userSettingsButton}
        to='/admin/users'
        component={Link}
      >
        <ChevronLeftIcon />
        <Typography>User Settings</Typography>
      </IconButton>
      {labels.map((label, index) => (
        <InputandLabel
          key={index}
          inputId={index.toString()}
          label={label}
          getters={{ ...getters }}
          setters={{ ...setters }}
        />
      ))}
      <Button
        className={classes.saveButton}
        type='submit'
        color='primary'
        variant='contained'
        disabled={loading}
      >
        Save
      </Button>
    </Box>
  );
}
