import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/slices/annotation';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  box: {
    transform: 'translateY(-105%)',
    zIndex: 999,
    justifyContent: 'center',
    padding: 0,
    paddingLeft: 3,
    paddingRight: 3,
  },
  textarea: {
    '& textarea': {
      overflowY: 'auto !important',
      transition: 'height 0.15s cubic-bezier(0.22, 0.61, 0.36, 1) !important',
    },
  },
  textareaFocused: {
    '& textarea': {
      height: '5rem !important',
    },
  },
  textareaBlured: {
    '& textarea': {
      height: '19px !important',
    },
  },
}));

function DescriptionBox({ description, setDescription, disabled }) {
  const [focused, setFocused] = useState(false);

  const classes = useStyles();

  const dispatch = useDispatch();
  const activateKeyEvents = () => dispatch(actions.setKeyEventsActive(true));
  const disableKeyEvents = () => dispatch(actions.setKeyEventsActive(false));

  const handleFocus = (e) => {
    setFocused(true);
    disableKeyEvents();
  };

  const handleBlur = (e) => {
    setFocused(false);
    activateKeyEvents();
  };

  const handleTextChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <TextField
      className={clsx({
        [classes.textarea]: true,
        [classes.textareaFocused]: focused,
        [classes.textareaBlured]: !focused,
      })}
      disabled={disabled}
      multiline
      fullWidth
      margin='dense'
      size='small'
      variant='outlined'
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={disabled ? '' : description || ''}
      onChange={handleTextChange}
      placeholder={'Açıklama (Opsiyonel)'}
    />
  );
}

export default DescriptionBox;
