import React, { useState } from 'react'
import { makeStyles, Container, IconButton } from '@material-ui/core';
import addImage from "../Help/images/addImage.png";
import addImageToProject from "../Help/images/addImageToProject.png";
import addUser from "../Help/images/addUser.png";
import categories from "../Help/images/categories.png";
import createProject from "../Help/images/createProject.png";
import createSubcategory from "../Help/images/createSubcategory.png";
import dataset from "../Help/images/dataset.png";
import editButton from "../Help/images/editButton.png";
import GT from "../Help/images/GT.png";
import imageSource from "../Help/images/imageSource.png";
import imageQuery from "../Help/images/imageQuery.png";
import images from "../Help/images/images.png";
import prediction from "../Help/images/prediction.png";
import projects from "../Help/images/projects.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const useStyles = makeStyles((theme)=>({
    helpRoot: {
        width:'90%',
        height:'90%',
        borderBlockColor:'D3D3D3'
      },
    text: {
        fontSize: '100%',
        color:'D3D3D3',
      },
    container: {
        width:'85%',
        maxHeight:'100%',
        display:'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    scrollDown: {
        overflowX: 'hidden',
    },
    image: {
        width:'90%',
        height:'50%',
    },
    iconButton: {
        fontSize:'20px',
    }
}))
export default function GetHelp() {
    const classes = useStyles();
    const [openProject,setOpenProject] =useState(false);
    const [openCreateProject,setOpenCreateProject] =useState(false);
    const [openAddImageToProject,setOpenAddImageToProject] = useState(false);
    const [openWriteGT,setOpenWriteGT] = useState(false);
    const [openCreateCategory,setOpenCreateCategory] = useState(false);
    const [openAddImageSource,setOpenAddImageSource] = useState(false);
    const [openAddImage,setOpenAddImage] = useState(false);
    const [openDataset,setOpenDataset] = useState(false);
    const [openPrediction,setOpenPrediction] = useState(false);
    const [openImageQuery,setOpenImageQuery] = useState(false);
    const [openAddUser,setOpenAddUser] = useState(false);


  return (
      <div className={classes.scrollDown} >
      <Container className={classes.container}>
            
            <IconButton onClick={()=>setOpenProject(!openProject)} className={classes.iconButton}>Projeler  
            {openProject?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openProject?(
                <>
                {/* <h3>Projeler</h3> */}
                <p>Projeler, Doctorsays’de işaretleme görevlerini düzenlemenin ve dağıtmanın ana yoludur.
                Projelerde, proje sahipleri ve yöneticiler image dosyalarını yükleyebilir ,kullanıcı ekleyebilir, etiket kümeleri (açıklama ) oluşturabilir, sonuçlarını dışa aktarılabilir ve daha fazlasını yapabilir.
                </p> 
                </>
            ):''}

            <IconButton onClick={()=>setOpenCreateProject(!openCreateProject)} className={classes.iconButton}>Proje Oluşturma  
            {openCreateProject?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openCreateProject?(
                <>
                {/* <h4>Proje Oluşturma</h4>   */}
                <p>Projeler sayfasında “Create Project” butonuna tıklayın.</p>
                <img src={projects} className={classes.image}></img>
                <p>Proje oluşturma ekranı açılacaktır.</p>
                <img src={createProject} alt='createProject' className={classes.image}></img>
                <p>Proje adı, Kategori adı, oluşturduğunuz sub kategori adı ve kullanıcı bilgilerini giriniz.Bilgileri girdikten sonra “Create Project” butonuna tıklayınız.</p>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenAddImageToProject(!openAddImageToProject)} className={classes.iconButton}>Projeye Resim Ekleme  
            {openAddImageToProject?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openAddImageToProject?(
                <>
                {/* <h4>Projeye Resim Ekleme</h4>  */}
                <p>Projeler sayfasında resim eklemek istediğiniz proje için edit butonunu tıklayınız. </p>
                <img src={editButton} alt='editButton' className={classes.image}></img>
                <p>Çıkan ekranda “Add Image” butonuna tıklıyoruz. </p>
                <p>"Add Manuel” butonuna tıklıyoruz. </p>
                <p>Çıkan ekrana Image pathlerini yapıştır ve Gönder butonuna tıkla. </p>
                <img src={addImageToProject} className={classes.image}></img>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenWriteGT(!openWriteGT)} className={classes.iconButton}>Projeyi Grount Truth'a Yazma  
            {openWriteGT?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openWriteGT?(
                <>
                {/* <h3>Projeyi Grount Truth'a Yazma</h3> */}
                <p>Solda bulunan Menüden System Settings altında bulunan Category sekmesine tıklayınız. Burada bulunan kategoriler listelenecek.</p>
                <img src={GT} alt='GT' className={classes.image}></img>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenCreateCategory(!openCreateCategory)} className={classes.iconButton}>Kategori Oluşturma 
            {openCreateCategory?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openCreateCategory?(
                <>
                {/* <h3>Kategori Oluşturma</h3> */}
                <p>Projeler sayfasında GT sütunundan checkbox a tıklayarak GT yazabilirsiniz. Not: İşlem biraz zaman alacağından beklemeniz gerekmektedir. </p>
                <img src={categories} alt='categories' className={classes.image}></img>
                <p>Yeni kategori eklemek için add Subcategory butonuna tıklayınız.Annotation ,Classification seçeneklerinden birini seçiniz. Subcategory name bilgisini giriniz ve label isimlerini ekleyin. Add Subcategory butonuna tıkladığınızda category listesine eklenecek. </p>
                <img src={createSubcategory} alt='createSubcategory' className={classes.image}></img>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenAddImageSource(!openAddImageSource)} className={classes.iconButton}>Image Source Ekleme 
            {openAddImageSource?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openAddImageSource?(
                <>
                {/* <h3>Image Source Ekleme</h3> */}
                <p>Solda bulunan Menüden System Settings altında bulunan Image source sekmesine tıklayınız.Burada bulunan image source'lar listelenecek. </p>
                <img src={imageSource} alt='imageSource' className={classes.image}></img>
                <p>Edit butonunu tıklayarak Image source üzerinde değişiklik yapabilirsiniz. Yeni Image source eklemek için new butonuna tıklayarak ekleyebilirsiniz. </p>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenAddImage(!openAddImage)} className={classes.iconButton}>Image Ekleme 
            {openAddImage?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openAddImage?(
                <>
                {/* <h3>Image Ekleme</h3> */}
                <p>Solda bulunan Menüden System Settings altında bulunan Images sekmesine tıklayınız.Burada bulunan image listelenecek ve search butonu ile filtreleme yapabilirsiniz. </p>
                <img src={images} alt='images' className={classes.image}></img>
                <p>ADD butonuna tıklayarak yeni image ekleyebilirsiniz. </p>
                <img src={addImage} alt='addImage' className={classes.image}></img>
                <p>Choose Image butonuna tıklayıp, VsJson dosyasını seçin, eklemek istediğiniz image source seçiniz ve save butonuna tıklayınız. Not: yükleyeceğiniz dosya formatı aşağıdaki gibi olmalıdır.</p>
                <p>&#91;</p>
                <p> &#123;</p>
                <p>"storagePath": “//IMAGEPATH", </p>
                <p>"thumbnailStoragePath": "//IMAGEPATH", </p>
                <p>"qualityTypeId": 3</p>
                <p> &#125;</p>
                <p>&#93;</p>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenDataset(!openDataset)} className={classes.iconButton}>Dataset 
            {openDataset?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openDataset?(
                <>
                {/* <h3>Dataset</h3> */}
                <p>Solda bulunan Menüden System Settings altında bulunan dataset sekmesine tıklayınız.Dataset yerine yazıp yeni dataset ekleyebilirsiniz. Burada bulunan dataset listelenecek ve datasette bulunan resim sayısını görebileceksiniz. </p>
                <img src={dataset} alt='dataset' className={classes.image}></img>
                <p>Datasete image eklemek için tablodan dataseti seçip select image butonuna tıklayınız. Çıkan ekrana image listi yapıştırın  ve Ok butonuna tıklayınız. </p>
                <p>VisJson formatında indirmek için tablodan dataseti seçin ve Download visjson butonuna tıklayınız. </p>
                <p>Dataseti silmek için dataseti seçip sil butonuna tıklayınız. </p>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenPrediction(!openPrediction)} className={classes.iconButton}>Prediction 
            {openPrediction?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openPrediction?(
                <>
                {/* <h3>Prediction</h3> */}
                <p>Prediction dosyasını yüklemek için,solda bulunan Menüden System Settings altında bulunan prediction sekmesine tıklayınız. </p>
                <img src={prediction} alt='prediction' className={classes.image}></img>
                <p>Eklemek için Choose File butonuna tıklayıp dosyayı seçiniz ve save butonuna tıklayınız. </p>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenImageQuery(!openImageQuery)} className={classes.iconButton}>Image Query 
            {openImageQuery?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openImageQuery?(
                <>
                {/* <h3>Image Query</h3> */}
                <p>Proje adı bölümünden pathlerine ulaşmak istediğiniz projelerin isimlerini tıklayınız . Buradan birden fazla proje seçebilirsiniz. Seçtiğiniz proje de bulunan classlar class sekmesinde görünecektir burdan seçim yapabilirsiniz.Seçtiğiniz projelere ait eklediğiniz yorumlara da comment sekmesinden ulaşabilirsiniz. Filtreleme için seçim yaptıktan sonra search butonuna tıklayarak storage pathleri filtreleyebilirsiniz. </p>
                <img src={imageQuery} alt='imageQuery' className={classes.image}></img>
                <p>Add Images to Dataset butonu ile filtrelenen image pathlerini datasete ekleyebilirsiniz. Storage Pathlerin seçimini yaparak resim listesini indirmek için Download Image List butonuna basınız, Seçilen image pathlerini visjson formatından indirmek için Download visJson butonuna tıklayınız. </p>
                </> 
            ):''}

            <IconButton onClick={()=>setOpenAddUser(!openAddUser)} className={classes.iconButton}>Kullanıcı Ekleme 
            {openAddUser?(<ExpandLessIcon/>
            )  :<ExpandMoreIcon /> } </IconButton>
            {openAddUser?(
                <>
                {/* <h3>Kullanıcı Ekleme</h3> */}
                <p>Kullanıcı eklemek için User settings Sekmesine tıklayın. Burada tanımlı kullanıcılar ile ilgili bilgiler listelenmektedir. Yeni kullanıcı eklemek için “ADD USER” butonuna tıklayınız. Kullanıcının durumunu  active/passive ile belirleyebilirsiniz. </p>
                <img src={addUser} alt='addUser' className={classes.image}></img>
                </> 
            ):''}

            <p></p>
            <p></p>

      </Container>
     </div> 

  );
}
