import React from 'react';
import { makeStyles, Fab, Box } from '@material-ui/core';
import { actions } from '../../../redux/slices/annotation';
import { useSelector, useDispatch } from 'react-redux';
import { green, red } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import FlagIcon from '@material-ui/icons/Flag';
import { useChange } from '../store';


const showToBurakHocaFlagId = 2


const useStyles = makeStyles(theme => ({
  rootStatic: {
    marginTop: 'auto',
    paddingLeft: 8,
    borderRadius: 5,
    paddingRight: 8,
    paddingBottom: 8,
  },
  root: {
    position: 'fixed',
    marginTop: 10,
    transform: 'translateX(-50%)',
    transition: 'left 0.1s cubic-bezier(0.66, 0.29, 0.5, 0.99), top 0.1s cubic-bezier(0.66, 0.29, 0.5, 0.99)',
  },
  fabsContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(.5),
    display: 'flex',
    flexDirection: 'row-reverse',
    "& > *": {
      marginLeft: 10,//'2rem',
      marginRight: 3
    }
  },
  fabFlag: {
    color: theme.palette.common.white,
    backgroundColor: '#ea5ce5',
    '&:hover': {
      backgroundColor: '#ea17e2',
    },
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
  fabRed: {
    color: theme.palette.common.white,
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[600],
    },
  }
}))

function useTempStore() {
  const tempBox = useSelector(state => state.annotation.tempBox)
  const isDrawing = useSelector(state => state.annotation.isDrawing)
  const cursor = useSelector(state => state.annotation.cursor)
  const region = useSelector(state => state.annotation.region)
  const editableRegion= useSelector(state => state.annotation.editableRegion)
  const tool = useSelector(state => state.annotation.tool)
  const regionList =  useSelector(state => state.annotation.regionList)

  const dispatch = useDispatch()
  return {
    tool,
    region,
    editableRegion,
    tempBox,
    isDrawing,
    cursor,
    regionList,
    delTempBox: () => dispatch(actions.delTempBox()),
    saveTempBox: (flags) => dispatch(actions.saveTempBox({ flags })),
    setCursor: (cur) => dispatch(actions.setCursor(cur)),
    setTool: (tool) => dispatch(actions.setTool(tool)),
  }
}

function TempBoxButtonsStatic() {
  const informChange = useChange((state) => state.changed);
  const classes = useStyles();
  const { delTempBox, saveTempBox, tempBox, region, editableRegion, setTool, tool } = useTempStore();

  const disabled = (tempBox === null) && (region === null) && (editableRegion === null)

  return (
    <Box className={classes.rootStatic}>
      {/* <Fade in={display}> */}
      <Box className={classes.fabsContainer}>

        <Fab className={classes.fabGreen}
          disabled={disabled}
          onClick={() => {
            saveTempBox([])
            if(tool === 'editablePoly') {
              setTool('select');
            }
          }
        }
        >
          <CheckIcon />
        </Fab>
        <Fab className={classes.fabRed}
          disabled={disabled}
          onClick={() => {
            delTempBox(informChange())
            if(tool === 'editablePoly') {
              setTool('select');
            }
          }
        }
        >
          <DeleteIcon />
        </Fab>
        <Fab className={classes.fabFlag}
          disabled={disabled}
          onClick={() => {
            saveTempBox([showToBurakHocaFlagId])
            if(tool === 'editablePoly') {
              setTool('select');
            }
          }
        }
        >
          <FlagIcon />
        </Fab>
      </Box>
      {/* </Fade> */}
    </Box>
  )
}

// function TempBoxButtons({ imageCenter: { x, y }, scale, imageSize, isDragging }) {
//   const classes = useStyles();

//   const { delTempBox, saveTempBox, tempBox, isDrawing } = useStore();

//   const display = !(tempBox === null || isDrawing || isDragging)

//   const left = x - imageSize.width * scale * (1 / 2 - (tempBox ? (tempBox.x1 + tempBox.x2) / 2 : 2))//(x-imageSize.width/2+imageSize.width*scale*(tempBox?tempBox.x1:0))
//   const top = y - imageSize.height * scale * (1 / 2 - (tempBox ? tempBox.y2 : 2))//(y-imageSize.height*scale/2+imageSize.height*scale*(tempBox?tempBox.y1:0))

//   return (
//     <Box left={left} top={top} className={classes.root}>
//       <Fade in={display}>
//         <Box className={classes.fabsContainer}>
//           <Fab className={classes.fabGreen}
//             disabled={tempBox === null}
//             onClick={saveTempBox}
//           >
//             <CheckIcon />
//           </Fab>
//           <Fab className={classes.fabRed}
//             disabled={tempBox === null}
//             onClick={delTempBox}
//           >
//             <DeleteIcon />
//           </Fab>
//         </Box>
//       </Fade>
//     </Box>
//   )
// }


export default TempBoxButtonsStatic