import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import {LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { usePending } from '../../../../../GlobalComponents/Contexes/pending';
import { useConfirm } from 'material-ui-confirm';
import { deleteImageSource } from '../../../../../service/admin.service';
import { actions } from '../../../../../redux/slices/admin';
import getAlternateStyle, {getColor} from '../../../CommonStyles/AlternateColor';
import CustomNoRowsOverlay from '../../../../../GlobalComponents/CustomNoRowsOverlay';
import usePagination from '../../../../../../src/util/usePagination';
import useSelection from '../../../../../../src/util/useSelection';
import { addCloudWatchLog } from '../../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  imageSourceRoot: {
    width: '98%',
    height: '89%',
    padding: '32px 0 0 15px',
  },
  newButton: {
    position: 'relative',
    bottom: '1rem',
  },
  deleteButton: {
    position: 'relative',
    bottom: '1rem',
    left: '2rem',
  },
  changePagination: {
    position: 'relative',
    bottom: '2.8rem',
    left: '1rem',
  },
}));

function useDeleteImageSourceFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const deleteImageSourceFn = deleteImageSource;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`GetImageSources.js:51 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`GetImageSources.js:65 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await deleteImageSourceFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.deleteEnum({ enumKey: 'imageSources', data: _data }));
    }
    return { data, success, error, message };
  }
  return submit;
}

export default function GetImageSources() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const deleteImageSourceFn = useDeleteImageSourceFunction();
  const initialPageSize = 11;
  let rowNumber = 1;

  const { byId: imageSources } = useSelector(
    (state) => state.admin.enums.imageSources
  );

  const rows = [];

  const {
    pageNumber,
    pageSize,
    noRowsOverlay,
    setNoRowsOverlay,
    anotherPagination,
    handleChangePaginationClick,
    handlePageChange,
    handlePageSizeChange,
  } = usePagination({initialPageSize});

  const {
    ids,
    selectionModel,
    handleCheckBoxClicks,
    isBtnActive,
  } = useSelection({rows, pageNumber, pageSize});
  
  function createData(rowNumber, id, name, creationDate) {
    return {rowNumber, id, name, creationDate };
  }

  for (const key in imageSources) {
    const imageSource = imageSources[key];
    rows.push(
      createData(rowNumber, imageSource.id, imageSource.name, imageSource.creationDate)
    );
    rowNumber++;
  }

  if(rows.length === 0) {
    setNoRowsOverlay({ NoRowsOverlay: CustomNoRowsOverlay });
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Image Source Name',
      flex: 1,
    },
    {
      field: 'creationDate',
      headerName: 'Creation Date',
      flex: 1,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 60,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const url = '/admin/settings/edit-image-source/' + params.row.id;
        return (
          <Button style={{width: '10px'}} to={url} component={RouterLink} variant='contained' size='small'>
            EDIT
          </Button>
        );
      },
    },
  ];

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    for (const id of ids) {
      const { success, error } = await deleteImageSourceFn(id);
  
      setLoading(false);
      pending(false);

      if (success && !error) {
      }
    }
  }


  return (
    <div className={classes.imageSourceRoot}>
      <Button
        className={classes.newButton}
        to='/admin/settings/create-image-source'
        component={RouterLink}
        variant='contained'
        color='primary'
      >
        New
      </Button>
      <Button
        className={classes.deleteButton}
        variant='contained'
        color='primary'
        disabled={!isBtnActive}
        onClick={submit}
      >
        Delete
      </Button>
      <DataGrid
        className={classes.dataGrid}
        components={{
          LoadingOverlay: LinearProgress,
          ...noRowsOverlay,
          ...anotherPagination,
        }}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10,25,50]}
        page={pageNumber}
        onPageSizeChange={handlePageSizeChange}
        onPageChange={handlePageChange}
        disableSelectionOnClick
        checkboxSelection
        onSelectionModelChange={handleCheckBoxClicks}
        selectionModel = {selectionModel}
        sx={getAlternateStyle}
        getRowClassName={(params) => {return getColor(params.row.rowNumber)}}
      />
      <Button
        className={classes.changePagination}
        variant='contained'
        color='primary'
        onClick={handleChangePaginationClick}
      >
        Change Pagination
      </Button>
    </div>
  );
}
