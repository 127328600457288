import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Paper,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import AddImagesErrorModal from "./AddImagesErrorModal";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default ({ open, onClose }) => {
  const classes = useStyles();
  const [imagePaths, setImagePaths] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const qualityTypeId = 1;
  const handleModalClose = () => setOpenModal(false);

  function onChange(e) {
    var items = e.target.value.trim().split("\n");
    items = items.map((path) => path.trim());
    items = items.filter((path) => !!path);
    setImagePaths(items);
  }

  function submit(e) {
    e.preventDefault();
    createJson(imagePaths);
  }

  async function createJson(imagePaths) {
    let list = [];
    let validation = true;
    imagePaths.forEach((imagePath) => {
      let pathArr = imagePath.split("/");
      pathArr[3] = "thumbnails";
      validation = checkPathArr(pathArr);
      if (!validation) {
        return;
      }
      let thumbnails = "";
      pathArr.forEach((pathArrItem, index) => {
        if (index !== pathArr.length - 1) {
          thumbnails += pathArrItem + "/";
        } else {
          thumbnails += pathArrItem;
        }
      });
      const map = {
        "storagePath": imagePath,
        "thumbnailStoragePath": thumbnails,
        "qualityTypeId": qualityTypeId,
      };
      list.push(map);
    });
    if (!validation) {
      setOpenModal(true);
    } else {
      onClose(list);
    } 
  }

  function checkPathArr(pathArr) {
    if (pathArr.length === 0) {
      return true;
    }
    return Object.values(pathArr).length === pathArr.length;
  }

  return (
    <Dialog
      className={classes.root}
      maxWidth="md"
      open={open}
      onClose={onClose}
      fullWidth
    >
      <form onSubmit={submit}>
        <DialogTitle>{"Projeye Resim Ekle"}</DialogTitle>
        <Box component={Paper} mx={2} mb={2}>
          <TextField
            size="small"
            label="Resim Listesi"
            variant="outlined"
            fullWidth
            required
            multiline
            minRows={20}
            maxRows={20}
            onChange={onChange}
          />
        </Box>
        <DialogActions>
          <Typography style={{ marginRight: "auto" }}>
            {"Toplam: "} {imagePaths.length}
          </Typography>
          <Button color="primary" autoFocus onClick={submit}>
            Gönder
          </Button>
        </DialogActions>
      </form>
      <AddImagesErrorModal open={openModal} onClose={handleModalClose} text="Example image path content must be as follows:
      s3://uraltelekom-ai/cropped/EgitimArastirma/
      good/
      1.2.276.0.75.2.1.20.0.3.190315144524390.
      35415.25939.JPG"/>
    </Dialog>
  );
};
