import React from 'react';
import { useSelector } from 'react-redux';
import Nav from './Nav';
import { makeStyles } from '@material-ui/core/styles';
import Scrollbars from 'react-custom-scrollbars';

const useStyle = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    height: '100vh',
  },
  scrollbars: {
    "& > :first-child": {
      display: 'flex'
    }
  }
}))


function Container({ children }) {
  const classes = useStyle()
  const coverScreen = useSelector(state => state.user.coverScreen);

  return (
    <div style={{ display: "flex" }}>
      <Nav />
      <main className={classes.content}>
        {coverScreen ? children
          : <Scrollbars
            autoHide
            autoHideDuration={100}
            style={{ height: '100vh' }}
            className={classes.scrollbars}
          >
            {children}
          </Scrollbars>}
      </main>
    </div>
  )
}

export default Container