import React, { useEffect } from 'react'
import {actions} from '../../../redux/slices/annotation';
import {useDispatch, useSelector} from 'react-redux';
import {
  Drawer, makeStyles, Box,  FormControl, 
  Select, InputLabel, MenuItem, Divider, Switch, styled, alpha, FormGroup, Slider
} from '@material-ui/core'
import clsx from 'clsx';


import {useLabelMap} from '../store';
import NewBoxList from './NewBoxList';
import TempBoxButtonsStatic from './TempBoxButtons';
import { green, orange } from '@material-ui/core/colors';

const drawerWidth = '17rem';
let toolName = ''

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    marginTop: 6,
    color: 'rgb(245 245 245)',
    right: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.54)'
    }
  },
  paper: {
    borderRight: 0//'1px solid rgba(0, 0, 0, 0.1)'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'visible',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0)
  },
  content: {
    paddingTop: '.5rem',
    backgroundColor: theme.palette.background.default,
    display: 'flex', 
    flexDirection: 'column', 
    height: '100%', 
    overflowX: 'hidden', 
    maxWidth: drawerWidth, 
    flexShrink: -2
  },
  labelSelect: {
    margin: '2.9px 3.9px'
  },

}));

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: orange[600],
    '&:hover': {
      backgroundColor: alpha(orange[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: orange[600],
  },
}));

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}));

function useAnnotStore() {
  const dispatch = useDispatch();
  const fixedBoundingBoxMode = useSelector((state) => state.annotation.fixedBoundingBoxMode);
  const showAllPaintedImages = useSelector((state) => state.annotation.showAllPaintedImages);
  const brushToolCoefficient = useSelector(
    (state) => state.annotation.brushToolCoefficient
  );
  const arteryImagePath = useSelector((state) => state.annotation.arteryImagePath);
  const veinImagePath = useSelector((state) => state.annotation.veinImagePath);
  const paintedImagesGT = useSelector((state) => state.annotation.paintedImagesGT);

  return {
    setFixedBoundingBoxMode: (bool) => dispatch(actions.setFixedBoundingBoxMode(bool)),
    setShowAllPaintedImages: (bool) => dispatch(actions.setShowAllPaintedImages(bool)),
    setBrushToolCoefficient: (val) => dispatch(actions.setBrushToolCoefficient(val)),
    brushToolCoefficient,
    showAllPaintedImages,
    fixedBoundingBoxMode,
    arteryImagePath,
    veinImagePath,
    paintedImagesGT
  };
}

export default function LeftSidebar({open, onClose, whichTool}){
  const classes = useStyles();
  toolName = whichTool

  useEffect(() => {
    if (!open){
      onClose()
    }
  }, [open])

  return <div>
    <Drawer anchor="left"
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Content 
        classes={{
          root: classes.content,
          labelSelect: classes.labelSelect,
        }}
      />
    </Drawer>
  </div>
}

function useContentStore(){
  const labelId = useSelector(state => state.annotation.tempLabelId)
  const tempBox = useSelector(state => state.annotation.tempBox)
  const loading = useSelector(state => state.annotation.loading)

  const dispatch = useDispatch()
  return {
    loading,
    labelId,
    tempBox,
    delTempBox: () => dispatch(actions.delTempBox()),
    saveTempBox: () => dispatch(actions.saveTempBox()),
    setLabelId: (payload) => dispatch(actions.setTempLabelId(payload))
  }
}

function Content({bboxListProps, classes, ...props}){
  const [labels, labelsId] = useLabelMap(state => [state.labels, state.labelsId])
  
  const { loading, labelId, setLabelId } = useContentStore()
  let legendText = '';


  useEffect(() => {
    if (!(labelId in labels)){
      //Default label when page launches
      setLabelId({id: Object.keys(labels)[0]})
    }
  }, [labelsId])

  
  if (labelId === null || !(labelId in labels)){
    return "Label Bulunamadı!"
  }
  
  if(toolName === 'add') {
      legendText = 'Square Box';
  } else if(toolName === 'poly') {
      legendText = 'Polygonal Box';
  } else if(toolName === 'editablePoly') {
      legendText = 'Editable Polygonal Box';
  } else if (toolName === 'brushTool') {
      legendText = 'Brush';
  } else if (toolName === 'eraser') {
      legendText = 'Eraser';
  }

  return (
    <div className={classes.root}>
      <Box margin="5px .5rem .5rem">
        <LabelSelect
          loading={loading}
          labelId={labelId}
          setLabelId={setLabelId}
        />
      </Box>
      <Divider />
      {toolName !== 'brushTool' && (
        <Box
          borderColor='white'
          component='fieldset'
          height='100%'
          marginTop='.5rem'
          marginLeft="7px"
          marginRight="7px"
          borderRadius={5}
          padding={0}
        >
          <legend style={{ marginLeft: '.5rem', paddingBottom: 6 }}>
            {legendText}
          </legend>
          {/* This NewBoxList(inside the NewBoxList.js) handles handles labels on the left side.
            NewBoxList(inside the BoundingBox.js) called from the Scene handles new drawed rectangle boxes on the image.
        */}
          <NewBoxList />
        </Box>
      )}
      <div />
      {toolName !== 'brushTool' && (
        <TempBoxButtonsStatic />
      )}
    </div>
  )
}


// This component is responsible for BoxLabel Select input.
function LabelSelect({labelId, loading, setLabelId, ...props}){
  const labels = useLabelMap(state => state.labels)
  const {
    brushToolCoefficient,
    setBrushToolCoefficient,
    fixedBoundingBoxMode, 
    setFixedBoundingBoxMode, 
    showAllPaintedImages,
    setShowAllPaintedImages,
    arteryImagePath,
    veinImagePath,
    paintedImagesGT
  } = useAnnotStore();

  function handleChange(e){
    setLabelId({id: parseInt(e.target.value)})
  }

  function handleFixedModeChange() {
    setFixedBoundingBoxMode(!fixedBoundingBoxMode);
  }

  function handleShowAllPaintedImages() {
    setShowAllPaintedImages(!showAllPaintedImages);
  }

  return (
    <FormControl fullWidth variant="outlined" size='small' disabled={loading}>
      <InputLabel id="label-select-box">Box Label</InputLabel>
      <Select
        labelId="label-select-box"
        value={labelId}
        onChange={handleChange}
        label="Box Label"
      >
        {Object.keys(labels).map(labelKey => (
          <MenuItem 
            key={`label-select-box-item-${labelKey}`} 
            value={labelKey}
          >
            {labels[labelKey]}
          </MenuItem>
        ))}
      </Select>
      {toolName === 'brushTool' &&
        <>
          <Box component='fieldset' borderRadius={5} p={1} margin="5px .5rem .5rem">
            <legend>Brush Thickness</legend>
            <FormGroup>
              <Slider
                value={brushToolCoefficient}
                style={{ zIndex: 99 }}
                valueLabelDisplay='auto'
                valueLabelFormat={(x) => `${parseInt(x * 100)}%`}
                step={0.01}
                onChange={(e, val) => setBrushToolCoefficient(val)}
                min={0.01}
                max={1}
              />
            </FormGroup>
          </Box>
          <Box component='fieldset' borderRadius={5} p={1} margin="5px .5rem .5rem">
            <legend>Artery Path</legend>
            <Box
              style={{ backgroundColor: '#f0f0f0' }}
              id='imagePath' 
              onClick={() => navigator.clipboard.writeText(arteryImagePath)}
            >
              {arteryImagePath.split('/').pop() || 'No Path'} 
            </Box>
          </Box>

          <Box component='fieldset' borderRadius={5} p={1} margin="5px .5rem .5rem">
            <legend>Vein Path</legend>
            <Box
              style={{ backgroundColor: '#f0f0f0' }}
              id='imagePath' 
              onClick={() => navigator.clipboard.writeText(veinImagePath)}
            >
              {veinImagePath.split('/').pop() || 'No Path'} 
            </Box>
          </Box>
          <Box margin="5px .5rem .5rem" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className='width: 300px'>Show All: </p>
            <GreenSwitch
              checked={showAllPaintedImages}
              onChange={handleShowAllPaintedImages}
            />
          </Box>
          {/* Ground Truth Badge */}
          <Box margin="5px .5rem .5rem" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className='width: 300px'>Ground Truth: </p>
            <div style={{ width: '60px', height: '20px', borderRadius: '10px', backgroundColor: paintedImagesGT ? 'green' : 'red', color: 'white', textAlign: 'center', fontSize: 'small' }}>
              { paintedImagesGT ? 'Yes' : 'No' }
            </div>
          </Box>
        </>
      }
      {toolName === 'add' && 
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <p>Fixed Bounding Box Mode:</p>
        <OrangeSwitch
          checked={fixedBoundingBoxMode}
          onChange={handleFixedModeChange}
        />
      </Box>}
    </FormControl>
  )
}