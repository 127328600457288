import React, { useState, useRef, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  Paper,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { createNewProblem as _createNewProblem } from '../../../service/options.service';
import { useApiFunction, useAsync, useErrorMessage } from '../../../util/hooks';
import { getProblemCategories } from '../../../service/admin.service';
import { addCloudWatchLog } from '../../../service/cloud.service';

const useStyles = makeStyles(theme => ({
  root: {
    // zIndex: '1650 !important',
  }
}))

export default ({ open, onClose }) => {
  const classes = useStyles();
  const [imagePaths, setImagePaths] = useState([])
  const [pending, setPending] = useState(false)
  const problemCategory = useRef()
  const description = useRef()
  const [category, setCategory] = useState(null)
  const [subCategory, setSubcategory] = useState(null)
  const [userCategories, setProblemCategory] = useState([]);
  const { status: statCategory, value: valCategory, error: errCategory } = useAsync(getProblemCategories)
  const showMessage = useErrorMessage();

  const createNewProblem = useApiFunction(_createNewProblem, {
    beforeExecute: () => setPending(true),
    afterExecute: () => setPending(false),
    onError: () => setPending(false)
  })

  function onChange(e) {
    var items = e.target.value.trim().split('\n')
    items = items.map(path => path.trim())
    items = items.filter(path => !!path)
    setImagePaths(items)
  }

  function submit(e) {
    e.preventDefault()
    if (pending) { return }

    const data = {
      imagePaths,
      description: description.current?.value,
      problemCategoryId: subCategory.id
    }

    createNewProblem(data)
      .then(({ success, message }) => {
        if (success) {
          onClose(e, 'success')
        } else {
          showMessage({title: 'Error', message});
        }
      })
      .catch(e => {
        addCloudWatchLog(`AddUserProblem.js:72 ${e}`);
        console.error(e)
      })
  }

  useEffect(() => {
    if (statCategory === 'success') {
      const { data, success, exception: apiError, message } = valCategory;
      if (!success || apiError) {
        addCloudWatchLog(`AddUserProblem.js:81 ${apiError} ${message}`);
        console.error({ error: apiError, message })
      } else {
        setProblemCategory(data)
      }
    }
    if (statCategory === 'error') {
      addCloudWatchLog(`AddUserProblem.js:88 ${errCategory}`);
      console.error(errCategory)
    }
  }, [statCategory])

  return (
    <Dialog className={classes.root} maxWidth='md' open={open} onClose={onClose} fullWidth>
      <form onSubmit={submit}>
        <DialogTitle>{"Yeni Proje"}</DialogTitle>
        <Box component={Paper} mx={2} mb={2}>
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id="demo-simple-select-filled-label">Select Category Name</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id='selectCategoryName'
              value={category}
              label="Select Category Name"
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value={1}>Annotation</MenuItem>
              <MenuItem value={2}>Classification</MenuItem>
              <MenuItem value={3}>Segmentation</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box component={Paper} mx={2} mb={2}>
          <FormControl size='small' variant='outlined' fullWidth required>
            <InputLabel id="demo-simple-select-filled-label">Select Subcategory Name</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="selectSubcategoryName"
              value={subCategory}
              label="Select Subcategory Name"
              onChange={(e) => setSubcategory(e.target.value)}
            >
              {userCategories.map((c, i) =>{
                return (
                  c.categoryTypeId === category ? <MenuItem key={i} value={c}>{c.name}</MenuItem> :
                  i === 0 ? <MenuItem disabled key={"empty"} value={"empty"}>Empty</MenuItem> : null
                )})}
            </Select>
          </FormControl>
          <TextField size='small' style={{ marginTop: '1rem' }} label='Proje Adı' id='projectName' variant='outlined' fullWidth required defaultValue='' inputProps={{ ref: description }} />
          <TextField size='small' style={{ marginTop: '1rem' }} label='Resim Listesi' id='imageList' variant='outlined' fullWidth required multiline minRows={20} maxRows={20} onChange={onChange} />
        </Box>
        <DialogActions>
          <Typography style={{ marginRight: 'auto' }}>{'Toplam: '} {imagePaths.length}</Typography>
          <Button id='createProject' type='submit' color="primary" autoFocus>
            Gönder
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}