import { current } from '@reduxjs/toolkit';
import { useState } from 'react';
import { usePending } from '../GlobalComponents/Contexes/pending';

export default function useSelection({rows, rowOrder, pageNumber, pageSize}) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [isBtnActive, setBtnActive] = useState(false);
  const [ids, setIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const [data, setData] = useState([]);
  const [clickedId, setClickedId] = useState(-99);

  function handleCheckBoxClicks(selectedIds) {
    selectedIds.length ? setBtnActive(true) : setBtnActive(false);

    if(rowOrder === 'DESC') {
      selectedIds = selectedIds.reverse();
    }
    // If rows were selected one by one
    let currentPageIds = [...selectedIds];
    setSelectionModel(currentPageIds);

    // If all rows were selected
    const isAllRowsSelected = selectedIds.length === rows.length;
    if (isAllRowsSelected) {
      currentPageIds = getCurrentPageIds(selectedIds);
    }

    setSelectionModel(currentPageIds);
    setIds([...currentPageIds]);
  }

  async function handleCellClick(cell, clickFn) {
    // Avoid checkbox clicks
    if (cell.field === '__check__') {
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Getiriliyor...');

    const id = cell.id;

    const { data, success, message, error } = await clickFn(id);

    setData(data);
    setClickedId(id);

    setLoading(false);
    pending(false);
  }

  function getCurrentPageIds(selectedIds) {
    const currentPageIds = [];

    for (let i = pageNumber * pageSize; i < (pageNumber + 1) * pageSize; i++) {
      if (i < rows.length) {
        currentPageIds.push(selectedIds[i]);
      }
    }
    return currentPageIds;
  }

  return {
    ids,
    selectionModel,
    handleCheckBoxClicks,
    handleCellClick,
    isBtnActive,
    data,
    clickedId,
  };
}
