import sleep from '../util/sleep';
import { ROOT, get, post, setBearerToken, handleError } from './Api';
import { decode } from 'jsonwebtoken';

const SECONDS = 1000
const MINUTES = 60 * SECONDS
const REFRESH_MARGIN = 5 * MINUTES



function getCookie(key) {
  var _cookies = document.cookie.split(';')
  for (var i = 0; i < _cookies.length; i++) {
    if (_cookies[i].includes(`${key}=`)) {
      return _cookies[i].substring(_cookies[i].indexOf("=") + 1).trim()
    }
  }
  return false
}

function setCookie(key, value, expiration) {
  const expires = (new Date(expiration)).toUTCString()
  document.cookie = `${key}=${value}; expires=${expires}; path=/`
}


class Auth {
  onAuthStateChangeCallback = undefined
  toNextRefreshMiliSec = 10
  jwtToken = undefined
  workersCount = 1
  claims = undefined
  role = getCookie('role') || undefined
  loginTimeout = false
  lastFiveMinutes = false

  constructor() {
    this.logout = this.logout.bind(this);
  }

  initialize() {
    const expTk = getCookie('expTk')
    const role = getCookie('role')
    const uid = getCookie('uid')

    if (expTk) {
      setBearerToken(expTk)
      this.refreshToken()
        .then(() => {
          this.startRefreshTokenWorker()
          this.informUserChange({ role, uid })
        })
        .catch(e => {
          this.onAuthStateChangeCallback()
        })
    } else {
      this.onAuthStateChangeCallback()
    }
  }

  handleLoginData(data) {
    const { expireDate, jwtToken, uid, role } = data //
    let exp = new Date((new Date()).setMinutes(new Date().getMinutes() + 50))

    const _accessToken = jwtToken || this.jwtToken;
    setBearerToken(_accessToken)
    setCookie('expTk', _accessToken, expireDate)
    setCookie('role', role || '', expireDate)
    setCookie('uid', uid || '', expireDate)

    this.jwtToken = _accessToken;
    this.toNextRefreshMiliSec = (new Date(exp) - new Date()) - REFRESH_MARGIN//(new Date(expireDate) - new Date()) - REFRESH_MARGIN
  }


  async login(username, password, base) {
    try {
      let res = await post(`${ROOT}/${base}/login`, { username, password })

      const { data, success, error, message } = res.data;
      const { jwtToken } = data;

      if (!success) { throw { error, message } }

      let { exp, nameid, role, iat } = decode(jwtToken);

      let responseData = {
        jwtToken: jwtToken,
        expireDate: exp ? new Date(exp * 1000) : new Date((new Date()).setMinutes(new Date().getMinutes() + 50)),
        loginDate: new Date(iat * 1000),
        uid: nameid,
        role
      }
      window.localStorage.setItem('loginexp', JSON.stringify({"expDt": exp * 1000, "loginDt": iat * 1000}))
      this.logoutTimer()
      this.handleLoginData(responseData)
      this.startRefreshTokenWorker()
      return await this.informUserChange({ role, id: nameid })
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  async informUserChange(user) {
    if (typeof (this.onAuthStateChangeCallback) === "function")
      this.onAuthStateChangeCallback(user)
  }

  async logout() {
    try {
      this.handleLogout()
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  handleLogout() {
    this.jwtToken = undefined
    this.toNextRefreshMiliSec = 10
    this.role = undefined

    setBearerToken()
    if (typeof (this.onAuthStateChangeCallback) === "function")
      this.onAuthStateChangeCallback()
    setCookie('uid', '', 0)
    setCookie('expTk', '', 0)
    setCookie('role', '', 0)
  }

  logoutTimer() {
    let loginexp = JSON.parse(window.localStorage.getItem('loginexp'))
    let exp = loginexp.expDt
    let now = loginexp.loginDt
    
    var controler = setInterval(() => {
      // Time to logout
      if(exp < now) {
        this.logout()
        this.loginTimeout = true
        clearInterval(controler)
      }
    }, 5 * 60 * 1000) // check every 5 minutes

    var controler2 = setInterval(() => {
      // Last 5 minutes
      if ((exp - 1 * 60 * 1000) < now) {
        this.lastFiveMinutes = true
        clearInterval(controler2)
      }
    }, 1 * 60 * 1000) // check every minutes
  }
  async startRefreshTokenWorker() {
    // let i = this.workersCount++
    // console.log("Worker", i, "started.");
    while (true) {
      // console.log("Worker", i, "waiting.");
      await sleep(this.toNextRefreshMiliSec)
      // console.log("Worker", i, "checking accessToken.");
      if (this.jwtToken !== undefined) {
        // console.log("Worker", i, "refreshing token.");
        await this.refreshToken()
      } else {
        // console.log("Worker", i, "stopped.");
        break;
      }
    }
  }

  async refreshToken() {
    try {
      let res = await get(`${ROOT}/users/refreshtokens`)

      const { data, success, error, message } = res.data;
      const { jwtToken } = data; //

      if (!success) { throw { error, message } }

      //TODO: role will be expected from the API
      let { exp, nameid, role, iat } = decode(jwtToken);

      let responseData = {
        jwtToken: jwtToken,
        expireDate: exp ? new Date(exp * 1000) : new Date((new Date()).setMinutes(new Date().getMinutes() + 50)),
        loginDate: new Date(iat * 1000),
        uid: nameid,
        role
      }
      window.localStorage.setItem('loginexp', JSON.stringify({"expDt": exp * 1000, "loginDt": iat * 1000}))
      this.logoutTimer()
      this.handleLoginData(responseData)
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  onAuthStateChange(callback) {
    if (typeof (callback) !== "function")
      throw new Error(`onAuthStateChange should only take function as parameter!`)
    this.onAuthStateChangeCallback = callback
    this.initialize()
  }
}

export const auth = new Auth()
