import React, { useState } from 'react';
import { useParams } from 'react-router';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { actions } from '../../../../../redux/slices/admin';
import { useConfirm } from 'material-ui-confirm';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { editImageSource } from '../../../../../service/admin.service';
import { usePending } from '../../../../../GlobalComponents/Contexes/pending';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { addCloudWatchLog } from '../../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  editImageSource: {
    marginLeft: '18%',
    marginTop: '5%',
  },
  labelEl: {
    display: 'inline-block',
    position: 'relative',
    top: '2.4rem',
    right: '10rem',
  },
  inputEl: {
    width: '40%',
  },
  cancelButton: {
    position: 'relative',
    top: '2rem',
    left: '7rem',
  },
  saveButton: {
    position: 'relative',
    top: '2rem',
    left: '9rem',
  },
}));

function useEditImageSourceFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const editImageSourceFn = editImageSource;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`EditImageSource.js:56 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`EditImageSource.js:70 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await editImageSourceFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.updateEnum({ enumKey: 'imageSources', data }));
    }
    return { data, success, error, message };
  }
  return submit;
}

function InputandLabel({ inputId, label, name, setName }) {
  const classes = useStyles();

  let getStrategies = new Map([['0', name]]);
  let setStrategies = new Map([['0', setName]]);

  function startGetAction(inputId) {
    let value = getStrategies.get(inputId);
    return value;
  }

  function startSetAction(inputId, input) {
    let fn = setStrategies.get(inputId);
    fn(input);
  }

  const handleChange = (e) => {
    startSetAction(e.target.id, e.target.value);
  };

  const value = startGetAction(inputId);

  return (
    <div>
      <InputLabel className={classes.labelEl}>{label}</InputLabel>
      <TextField
        className={classes.inputEl}
        id={inputId}
        label={label}
        onChange={handleChange}
        value = {value}
        required
      />
    </div>
  );
}

export default function EditImageSource() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const editImageSourceFn = useEditImageSourceFunction();
  const { id } = useParams();
  const labels = ['Image Source Name'];

  const history = useHistory();

  const { byId: imageSources } = useSelector(
    (state) => state.admin.enums.imageSources
  );

  let initialName;

  for (const key in imageSources) {
    const imageSource = imageSources[key];
    if (imageSource.id === +id) {
      initialName = imageSource.name;
    }
  }

  const [name, setName] = useState(initialName);

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const request = {
      id,
      name,
    };

    const { success, error } = await editImageSourceFn(request);
    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/settings/image-source');
    }
  }

  return (
    <Box
      className={classes.editImageSource}
      component='form'
      autoComplete='off'
      onSubmit={submit}
    >
      {labels.map((label, index) => (
        <InputandLabel
          key={index}
          inputId={index.toString()}
          label={label}
          name = {name}
          setName={setName}
        />
      ))}
      <Button
        className={classes.cancelButton}
        color='primary'
        variant='contained'
        to='/admin/settings/image-source'
        component={Link}
      >
        Cancel
      </Button>
      <Button
        className={classes.saveButton}
        type='submit'
        color='primary'
        variant='contained'
        disabled={loading}
      >
        Save
      </Button>
    </Box>
  );
}
