import { STATIC_ROOT } from '../service/Api';

export default function pathParser(storagePath) {
  const splittedPath = storagePath.split('/');
  const bucketName = splittedPath[2] + '/';
  const endpoint = String(storagePath).split(bucketName).pop();

  if (storagePath && storagePath.startsWith('s3')) {
    return `${STATIC_ROOT}${endpoint}`;
  }

  if (storagePath && storagePath.startsWith('gs')) {
    return `https://www.googleapis.com/storage/v1/b/${bucketName}o/${encodeURIComponent(
      endpoint
    )}?alt=media`;
  }

  return storagePath;
}
