import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import GetSystemLogs from '../Components/SystemLogs/GetSystemLogs';

export default function () {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.url}/all`} component={GetSystemLogs}></Route>
      </Switch>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/all`} />
      </Switch>
    </>
  );
}
