import React, { useEffect, useState } from 'react'
import { getQuartzJobInfo, overWriteGTAsync } from '../service/admin.service'
import { useAsync } from '../util/hooks';
import PopUpAnnotate from './PopUpAnnotate';

export default ({ open, userProblemId, onClose }) => {
   const [error, setError] = useState()
   const [overError, setOverError] = useState()
   const [token,setToken] = useState('')
   const [item,setItem] = useState([])
   const [intervalId] = useState([])

    const {execute:executable, status: overStat, value: overValue, error:overNetworkErr} = useAsync(() =>overWriteGTAsync(userProblemId),false)
    const {execute, status:jobStat, value:jobValue, error:networkErr} = useAsync(() => getQuartzJobInfo(token),false)

    const useInterval = (open)=>{
      if(open){
        const interval = setInterval(execute,15000)
        intervalId.push(interval)

        //after the job completed it will stop
        if(item.completed){
          intervalId.forEach((e) => clearInterval(e))
        }
      }else{
        clearInterval(intervalId)
        intervalId.forEach((e) => clearInterval(e))
      }
    }

    useEffect(() => {
      if ( typeof userProblemId === 'number' && userProblemId !== 0 && userProblemId!==null){
        executable();
      }else{ 
        setItem([])    
      }  
    }, [userProblemId])

    useEffect(() => {
      if(typeof token ==='string' && token !==''){
        execute();
        intervalId.forEach((e) => clearInterval(e))
      }else{
        setToken();
      }
    }, [token])
    
    useEffect(() => {
      if (overStat ==='success' && userProblemId!==null) {
        const { data, success, exception: apiError2, message } = overValue;
        if (apiError2 || !overStat) {
          setOverError({error: apiError2,message})
        } else {
          setToken(data)      
        }
      }
      if (overStat === 'error') {
        setOverError(overNetworkErr)
      }
       if (jobStat === 'success' ) {
        const { data, success, exception: apiError, message } =  jobValue;
        if (!success || apiError ) {
          setError({ error: apiError, message })
        } else {   
            setItem(data)
            intervalId.forEach((e) => clearInterval(e))
            useInterval(open)
        }
      }
      if (jobStat === 'error') {
        setError(networkErr)
      }
      
    }, [overStat,jobStat,open])

  return (
    ( item.length!== 0? <PopUpAnnotate open={open} onClose={onClose} items={item} status={jobStat} userProblemId={userProblemId}  ></PopUpAnnotate>:'' )
  )
}
