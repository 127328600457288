import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../redux/slices/annotation';
import { TextField, CircularProgress, Box } from '@material-ui/core';
import { useImageData } from '../Contexes/imageData';
import Autocomplete from '@material-ui/lab/Autocomplete';
import sleep from '../../../util/sleep';

const SEARCH_WAIT_TIME_ONCHANGE = 400

function useAnnotStore() {
  const dispatch = useDispatch();
  //TODO: Use comparision function for selector
  const isWithJSON = useSelector(
    (state) => state.annotation.withJSON
  );

  return {
    isWithJSON,
  };
}

function getImageDataProvider(provider){
  if(provider) {
    return [
      provider.canSearchImage,
      provider.search
    ]
  }
  
  async function search(text) {
    return [{
      item: { name: text?.split?.('/').pop() },
      refIndex: text
    }]
  }

  return [true, search];
}

export default function ImageSearchBox({jumpTo = () => {}, jumpToWithDrSays = () => {}, disabled = false}) {
  const {isWithJSON} = useAnnotStore();
  
  const dataProvider = useImageData();
  const [active, search] = useMemo(() => getImageDataProvider(dataProvider), [dataProvider?.id])
 
  async function fetchData(searchText){
    const result = await search(searchText);
    return result
  }

  function handleSelect({ refIndex, item }) {
    if (isWithJSON) {
      jumpTo(refIndex);
      return;
    }

    jumpToWithDrSays(refIndex);
  }
  
  return (
    active&&
      <SearchBar disabled={disabled} fetchData={fetchData} onSelect={handleSelect}/>
    )
}

function SearchBar({fetchData, onSelect, disabled}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('')

  // Controls the activation of global key events for changing tool (draw when press D etc.)
  // TODO: This logic should be moved to Input itself, by decorating inputs.
  const dispatch = useDispatch()
  const activateKeyEvents = () => dispatch(actions.setKeyEventsActive(true))
  const disableKeyEvents = () => dispatch(actions.setKeyEventsActive(false))

  useEffect(() => {
    if (value === ''){
      setLoading(false)
      setOpen(false)
      return
    }

    if (!loading){
      setLoading(true)
      setOpen(true)
      setOptions([])
    }

    let lastChange = true;

    (async () => {
      await sleep(SEARCH_WAIT_TIME_ONCHANGE)
      if (!lastChange){ return }
      let options = await fetchData(value);

      if (options.length !== 0 && options[0].item.name === value?.split?.('/').pop()){
        handleSelect({}, options[0], 'full-match')
      } else {
        setOptions(options);
        setLoading(false)
      }
    })();

    return () => {
      lastChange = false;
    };
  }, [value]);

  function handleSelect(e, selected, reason){
    activateKeyEvents()
    setOpen(false)
    setLoading(false)
    setValue('')
    onSelect(selected)
  }

  function handleInputChange(e, newValue, reason){
    // if (e.nativeEvent.inputType === 'insertFromPaste')
    setValue(newValue);
  }

  return (
    <Box borderRadius={5} p={1} mt={1}>
      <Autocomplete
        id="asynchronous-demo"
        disabled={disabled}
        fullWidth
        open={open}
        onOpen={() => {
          if (value !== ''){
            setOpen(true);
          }
        }}
        onClose={() => {
          setOpen(false);
        }}
        
        onInputChange={handleInputChange}
        inputValue={value}
        value={null}
        onChange={handleSelect}
        filterOptions={(options, state) => options}
        // getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.item.name}
        options={options}
        loading={loading}
        size='small'
        forcePopupIcon={false}
        onFocus={disableKeyEvents}
        onBlur={activateKeyEvents}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Resme Atla"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}