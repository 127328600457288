import { useState, useEffect, useCallback } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { useMountedState } from 'react-use'
import { addCloudWatchLog } from '../service/cloud.service';
//FIXIT(Volkan): May need performance optimization!
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const useAsync = (asyncFunction, immediate = true) => {
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus("pending");
    setValue(null);
    setError(null);
    return asyncFunction()
      .then((response) => {
        setValue(response);
        setStatus("success");
      })
      .catch((error) => {
        setError(error);
        setStatus("error");
      });
  }, [asyncFunction]);
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, status, value, error };
};


export const useErrorMessage = () => {
  const confirm = useConfirm();

  function showMessage({ message, error = null, title = 'Hata', showButton = true }) {
    confirm({
      title,
      dialogProps: { style: { whiteSpace: 'pre-line' } },
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton ? { color: 'primary' } : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message || 'Bilinmeyen bir hata oluştu!'
    })
      .then(() => { })
      .catch((e) => { 
        addCloudWatchLog(`hooks.js:85 ${e}`);
        console.error(e) 
      })
  }

  return showMessage
}

export const useApiFunction = (executable, { beforeExecute, afterExecute, onError, onSuccess } = {}) => {
  const isMounted = useMountedState()

  async function submit(...args) {
    let _;
    _ = beforeExecute?.();
    const resData = await executable(...args);
    const { success, error, message } = resData
    if (!isMounted()) { return }
    if (!success || error) {
      // showMessage({ message, error })
      addCloudWatchLog(`hooks.js:104 ${error} ${message}`);
      console.error(error);
      _ = onError?.(resData)
    } else {
      _ = onSuccess?.(resData)
    }
    _ = afterExecute?.(resData)
    // console.log(_)
    return resData
  }
  return submit
}