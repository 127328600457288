import React from 'react';
import { Dialog, IconButton, AppBar, Tabs, Tab, Box, Container, makeStyles, Toolbar, Slide } from '@material-ui/core';
import UserProblems from './Components/UserProblems';
import Annotation from './Components/Annotation';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
  root: {
    // zIndex: '1600 !important'
  },
  dialogPaper: {
    backgroundColor: 'rgb(64,64,64,0.96)',
    '& *': {
      color: theme.palette.getContrastText('rgb(64,64,64,0.96)')
    }
  }
}))

const SECTIONS = [
  { label: 'Kullanıcı Problemleri', RenderComponent: UserProblems },
  { label: 'Bounding Box', RenderComponent: Annotation }
]

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`options-tabpanel-${index}`}
    aria-labelledby={`options-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3}>
        {children}
      </Box>
    )}
  </div>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `options-tab-${index}`,
    'aria-controls': `options-tabpanel-${index}`,
  };
}

export default ({ open, onClose }) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.root} fullScreen PaperProps={{ className: classes.dialogPaper }} TransitionComponent={Transition} >
      <AppBar position='sticky'>
        <Toolbar style={{ backgroundColor: '#282828', paddingLeft: 0 }}>
          <IconButton id="closeUserProblems" onClick={() => onClose()} color="inherit">
            <CloseIcon />
          </IconButton>
          <Tabs style={{ backgroundColor: '#282828', marginRight: 'auto', marginLeft: 'auto' }} centered indicatorColor='primary' value={value} onChange={handleChange}>
            {SECTIONS.map(({ label }, i) => <Tab label={label} {...a11yProps(i)} key={`options-tab-k-${i}`} />)}
          </Tabs>

        </Toolbar>
      </AppBar>
      <Container>
        {SECTIONS.map(({ RenderComponent }, i) => (
          <TabPanel value={value} index={i} key={`options-tab-panel-k-${i}`}>
            <RenderComponent />
          </TabPanel>
        ))}
      </Container>
    </Dialog>
  )
}