import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux';
import LoadingMessage from '../../LoadingMessage';
import { actions } from '../../../redux/slices/user';
import { getPossessedUserProblems as _getPossessedUserProblems, activateUserProblem as _activateUserProblem } from '../../../service/options.service'
import { usePending } from '../../Contexes/pending'
import { useApiFunction } from '../../../util/hooks';
import { TableContainer, Chip, Table, TableHead, Box, TableCell, TableRow, LinearProgress, makeStyles, TableBody, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import AddUserProblem from './AddUserProblem';
import { getLabelsWithProjectId } from '../../../service/user.service';
import { useLabelMap } from '../../../components/Annotate/store';
import { useConfirm } from 'material-ui-confirm';
import { addCloudWatchLog } from '../../../service/cloud.service';

const DUMMY_CAT_ID = 5

const useStyles = makeStyles(theme => ({
  chip: {
    minWidth: 84
  },
  activeChip: {
    borderColor: 'green !important',
    '& > span': { color: 'green !important' }
  },
  inactiveChip: {
    borderColor: '#f9ff0057',
    '& > span': { color: '#d3d670' }
  },
  activateChip: {
    // '& > span': { color: `${theme.palette.primary.dark} !important` }
  },
  descriptionCell: {
    maxWidth: '10rem'
  },
  addFab: {
    position: 'fixed',
    right: '4rem',
    bottom: '4rem',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#4b9c4f",
    },
  }
}))
/**
 * @param id: UserProblemId
 * @param isActive: active status of user problem
 * @param onChange: Called after activating the given user problem
 * @param submitFun: Overrides default submit function
 */
function ActiveInfoCell({ id, isActive, onChange, submitFun, totalCount }) {
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const pending = usePending();
  const activateUserProblem = useApiFunction(_activateUserProblem, {
    beforeExecute: () => pending(true, 'Problem Aktifleştiriliyor...'),
    afterExecute: () => pending(false),
    onError: () => pending(false)
  })
  const confirm = useConfirm();

  function showMessage() {
    confirm({
      title: `Problem Can't Be Activated`,
      confirmationText: 'Tamam',
      confirmationButtonProps: true
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: 'This problem has no image so, it can not be activated!',
    }).then(() => {})
    .catch((e) => {
      addCloudWatchLog(`UserProblems.js:77 ${e}`);
      console.error(e);
    });
  }

  async function submit() {
    if (loading || isActive) { return; }
    if(totalCount === 0) { showMessage(); return;}
    setLoading(true)
    const { data } = await activateUserProblem(id);
    setLoading(false)
    onChange(data)
  }

  return (
    <Box className={classes.chipContainer}>
      <Chip
        variant={!isActive && hover ? 'default' : 'outlined'}
        color={!isActive && hover ? 'primary' : 'default'}
        label={isActive ? 'Evet' : hover ? 'Aktifleştir' : 'Hayır'}
        className={clsx(classes.chip, { [classes.activeChip]: isActive, [classes.activateChip]: !isActive && hover, [classes.inactiveChip]: !isActive && !hover })}
        clickable={!isActive}
        // Override submit function with given submit function. 
        onClick={submitFun ?? submit}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </Box>

  )
}


export default () => {
  const classes = useStyles();
  const [userProblems, setUserProblems] = useState()
  const [addNewOpen, setAddNewOpen] = useState(false)
  const activeUserProblem = useSelector(state => state.user.problem)
  const [status, setStatus] = useState('loading');
  const dispatch = useDispatch()
  const setLabels = useLabelMap(store => store.setLabels)
  const setDiseaseLabels = useLabelMap(store => store.setDiseaseLabels)
  const getLabelsByProjectId = useApiFunction((id) => getLabelsWithProjectId(id));

  const getPossessedUserProblems = useApiFunction(_getPossessedUserProblems, {
    onSuccess: () => { setStatus('success') },
    onError: () => { setStatus('error') }
  })

  const refreshProblems = () => {
    (async () => {
      setStatus('loading')
      try {
        const { data, success } = await getPossessedUserProblems()
        setUserProblems(data)
        setStatus(success ? 'success' : 'error')
      } catch (e) {
        if (e.name !== 'TypeError')
          addCloudWatchLog(`UserProblems.js:135 ${e}`);
          console.error(e)
      }
    })()
  }

  useEffect(refreshProblems, [])

  async function onUserProblemChange(data) {
    const activeProblemArr = data.filter(({ isActive }) => isActive)
    setUserProblems(data)
    const activeProblem = activeProblemArr[0]
    if (activeProblemArr.length !== 1){
      addCloudWatchLog(`Assertion Failed: activeProblem.length = ${activeProblem.length}`);

      return console.error(`Assertion Failed: activeProblem.length === ${activeProblem.length}!`)
    }
    const { data: categoryLabel } = await getLabelsByProjectId(activeProblem.id)
    setDiseaseLabels(categoryLabel)
        let labels = {}
        for (let label of categoryLabel) {
          labels = { ...labels, [label.id]: label.name }
        }
        setLabels(labels)
    // TODO: set categoryTypeId
    const userProblemData = { userProblemId: activeProblem.id, problemCategoryId: activeProblem.categoryTypeId }
    dispatch(actions.setProblem(userProblemData))
  }

  function onAddProblemClose(e, reason) {
    setAddNewOpen(false)
    if (reason === 'success')
      refreshProblems()
  }

  if (status === 'loading')
    return <LoadingMessage loading message='Kullanıcı problemleri yükleniyor...' />

  if (status === 'error')
    return <LoadingMessage loading message='Bir hata oluştu!' />

  if (!userProblems)
    return <LoadingMessage loading message='...' />

  // if (userProblems?.length === 0)
  //   return <LoadingMessage loading message='Hiçbir problem bulunamadı!' />

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Create Date</TableCell>
              <TableCell>Project Name</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell align='center'>is Active?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userProblems.map(({ id, problemCategoryName, createDate, totalCount, description, totalAnswered, isActive }) =>
              <TableRow key={`problemCategory-row-${id}`}>
                <TableCell>{id}</TableCell>
                <TableCell>{problemCategoryName}</TableCell>
                <TableCell>
                  {createDate?.split?.('T')?.shift?.()?.replaceAll?.('-', '.')}
                </TableCell>
                <TableCell className={classes.descriptionCell}>
                  {description}
                </TableCell>
                <TableCell>
                  {totalAnswered}/{totalCount}
                  <LinearProgress value={(totalAnswered / totalCount * 100) ?? 0} variant='determinate' />
                </TableCell>
                <TableCell align='center'>
                  <ActiveInfoCell id={id} isActive={activeUserProblem.problemCategoryId !== DUMMY_CAT_ID && isActive} onChange={onUserProblemChange} totalCount={totalCount}/>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab id='activeCreateProjectForm' className={classes.addFab} onClick={() => setAddNewOpen(true)}>
        <AddIcon />
      </Fab>
      <AddUserProblem open={addNewOpen} onClose={onAddProblemClose} />
    </Box>
  )
}