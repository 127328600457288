import { createSlice } from '@reduxjs/toolkit';

export const admin = {
  enums: null,
  loading: true,
  error: null,
};

const userSlice = createSlice({
  name: 'admin',
  initialState: admin,
  reducers: {
    setEnums(state, { payload }) {
      state.enums = payload;
    },
    updateEnum(state, { payload }) {
      const { enumKey, data } = payload;
      if (!state.enums[enumKey].ids.includes(data.id))
        state.enums[enumKey].ids.push(data.id);
      state.enums[enumKey].byId[data.id] = data;
    },
    deleteEnum(state, { payload }) {
      const { enumKey, data } = payload;
      if (Array.isArray(data)) {
        data.forEach((id) => {
          delete state.enums[enumKey].byId[id];
        });
      } else {
        delete state.enums[enumKey].byId[data];
      }
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setCoverScreen(state, { payload }) {
      state.coverScreen = payload;
    },
    resetAdmin(state) {
      Object.assign(state, admin);
    },
  },
});

export const reducer = userSlice.reducer;
export const actions = userSlice.actions;
