import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import GetDatasets from '../../Components/Dataset/GetDatasets';
import AddImagetoDataset from '../../Components/Dataset/AddImagetoDataset';

export default function (props) {
  const match = useRouteMatch();
 
  return (
    <>
      <Switch>
        <Route path={`${match.url}/dataset`} component={GetDatasets}></Route>
        <Route path={`${match.url}/add-image-to-dataset/:ids`} component={AddImagetoDataset}></Route>
      </Switch>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/dataset`} />
      </Switch>
    </>
  );
}
