import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import GetImages from '../../Components/SystemSettings/Images/GetImages';
import AddImage from '../../Components/SystemSettings/Images/AddImage';
import AddImageFromVisJson from '../../Components/SystemSettings/Images/AddImagesFromVisJson';

export default function (props) {
  const match = useRouteMatch();
 
  return (
    <>
      <Switch>
        <Route path={`${match.url}/images`} component={GetImages}></Route>
        <Route path={`${match.url}/create-image`} component={AddImage}></Route>
        <Route path={`${match.url}/create-image-visjson`} component={AddImageFromVisJson}></Route>
      </Switch>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/images`} />
      </Switch>
    </>
  );
}
