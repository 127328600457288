import React, { useEffect, useState } from 'react'
import PopUpAnnotate from '../../../../../GlobalComponents/PopUpAnnotate';
import { getQuartzJobInfo } from '../../../../../service/admin.service';
import { useAsync } from '../../../../../util/hooks';

export default ({ open, token, onClose }) => {
   const [item,setItem] = useState([])
   const [intervalId] = useState([])

   
   const {execute, status:jobStat, value:jobValue, error:networkErr} = useAsync(() => getQuartzJobInfo(token),false)

    const useInterval = (open)=>{
      if(open){
        const interval = setInterval(execute,15000)
        intervalId.push(interval)
        if(item.completed){
          intervalId.forEach((e) => clearInterval(e))
        }
      }else{
        clearInterval(intervalId)
        intervalId.forEach((e) => clearInterval(e))
      }
    }

    useEffect(() => {
      if(typeof token ==='string' && token !==''){
        execute();
        intervalId.forEach((e) => clearInterval(e))
      }
    }, [token])
    
    useEffect(() => {
       if (jobStat === 'success' ) {
        const { data, success, exception: apiError, message } =  jobValue;
        if (success && !apiError ) {
            setItem(data)
            intervalId.forEach((e) => clearInterval(e))
            useInterval(open)
          }
        }
    }, [jobStat,open])

    return (
        ( item.length!== 0? <PopUpAnnotate open={open} onClose={onClose} items={item} status={jobStat} userProblemId={null}  ></PopUpAnnotate>:'' )
      )
}
