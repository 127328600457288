import Api, { ROOT } from './Api';
import Fuse from 'fuse.js';
import path from 'path';
import pathParser from '../util/pathParser';
import { fetchImageData } from './file.service';
import { addCloudWatchLog } from './cloud.service';

// import octFewshotPreds from '../assets/temp/oct_laser_few_shot.json';
// import ffaFewshotPreds from '../assets/temp/laser_spot_few_shot_ffa_results_v1.json';
// import ffaDebugPreds from '../assets/temp/ffa_debug.json';

const isCloudPath = (id) => Boolean(id?.startsWith?.('gs://') || id?.startsWith?.('s3://'))

const fuseOptions = {
  includeScore: true,
  keys: ['name'],
  threshold: 0.1,
  distance: 200,
}

export async function generateVisJsonFromImagePaths(imagePaths) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/dataset/generateVisJsonFromImagePaths`,
    data: imagePaths
  })
  return res.data
}

export async function getDatasetsInfo() {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/dataset/info`
  })
  return res.data
}

export async function getDatasetVisJson(datasetId) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/dataset/${datasetId}/visJson`
  })
  return res.data
}
/* 
  returns {
    ImageSources : [{ImageSoruceId:int,ImageSourceName:string}, ...]
    ImageQualities : [{ImageQualityId:int,ImageQualityName:string}, ...]
    Labels : [{LabelId :int,LabelName :string}, ...]
    Descriptions : [{description: string}, ...]
  }
*/
export async function getDatasetFilterOptions(datasetId) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/dataset/GetVisJsonFilterOptions/${datasetId}`
  })
  return res.data
}
/* 
  filterOptions : {
    ImageSourceId : List<int>
    ImageQualityId : List<int>
    LabelIds : List<int>
    Descriptions : List<int>
  }
*/
export async function getDatasetFilteredVisJson(datasetId,filterOptions) {
  const res = await Api({
    method: 'GET',
    url: `${ROOT}/dataset/${datasetId}/visJson`,
    data: filterOptions
  })
  return res.data
}

export async function setCompleted(userProblemId) {
  const res = await Api({
    method: 'POST',
    url: `${ROOT}/userproblems/setCompleted`,
    data: userProblemId
  })
  return res.data
}

class B64ImageProvider {
  constructor(keep = 15) {
    this.cache = new Map()
  }

  get(imagePath) {
    if (this.cache.has(imagePath))
      return this.cache.get(imagePath)

    console.warn('Unexpected behaviour', imagePath, 'was not cached!')
    let b64Promise = fetchImageData(pathParser(imagePath))
    this.cache.set(imagePath, b64Promise)
    return b64Promise
  }

  add(imagePaths) {
    // Clear cache first
    let keys = [...this.cache.keys()]
    keys.forEach(imagePath => {
      if (!imagePaths.includes(imagePath))
        this.cache.delete(imagePath)
    })

    // Add images to cache
    imagePaths.forEach(imagePath => {
      if (!this.cache.has(imagePath))
        this.cache.set(imagePath, fetchImageData(pathParser(imagePath)))
    })

  }
}

export class withJSON {
  preds = []

  startId
  id
  fuse
  b64ImageProvider
  canSearchImage = true
  canEvaluate = false

  constructor(data, dataId) {
    this.id = dataId
    this.startId = Math.min(parseInt(localStorage.getItem(this.id) || 0), data.length)
    this.preds = data
    let fuseData = data.map(({ imagePath }) => ({ name: path.basename(imagePath) }))
    this.fuse = new Fuse(fuseData, fuseOptions)
    this.search = this.search.bind(this)
    this.b64ImageProvider = new B64ImageProvider()
  }

  getFutureImagePaths = async (userProblemImageId, left, right) => {
    if (this.preds[userProblemImageId] === undefined) { return null }
    let startIdx = Math.max(userProblemImageId - left, 0)
    let endIdx = Math.min(userProblemImageId + right, this.preds.length)
    const imagePaths = this.preds.map(p => p.imagePath).slice(startIdx, endIdx)
    return imagePaths
  }

  getImage = async (id) => {
    if (this.preds[id] === undefined) { return null }

    let image = { ...this.preds[id], newBoxes: (this.preds[id].newBoxes || []), regionList:(this.preds[id].regionList || []) };
    localStorage.setItem(this.id, id)

    return {
      image,
      hasPrev: id !== 0,
      hasNext: this.preds.length - 1 !== id,
      nextId: id + 1,
      currentIndex: id,
      totalCount: this.preds.length,
      prevId: id - 1
    }
  }

  async search(text) {
    let _text = text
    if (text.includes('/')) {
      _text = path.basename(text)
    }
    let result = this.fuse.search(_text)
    return result
  }

  downloadable = true
  downloadData = async (name, overrides) => {
    let dateNow = (new Date()).toISOString().split('T')[0].slice(2).replaceAll('-', '.') // YY.MM.DD format
    let exportName = `${name ?? this.id}-${dateNow}`
    const data = this.preds.map((imageData, id) => ({
      ...imageData,
      newBoxes: imageData.newBoxes || [],
      regionList: imageData.regionList || [],
    }))
    const visJson = {
      data, ...overrides
    }
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(visJson));

    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  submit = (payload) => {
    const to_change = this.preds.find(imageData => imageData.imagePath === payload.imageStoragePath)
    if (to_change === undefined) {
      throw Error("Resim storage'da bulunamadı!")
    }
    const gtDeleteList = new Set(payload.groundTruthDeleteList);
    const predAddList = new Set(payload.predictionAddList);

    // Optional chaninig doesnt work?
    to_change && to_change.bboxGT && to_change.bboxGT.forEach((gt) => {
      gt.isValid = !gtDeleteList.has(gt.id)
    })

    to_change && to_change.bboxPred && to_change.bboxPred.forEach((pred) => {
      pred.isValid = predAddList.has(pred.id)
    })

    to_change.newBoxes = payload.newBoxes.map(tempBox => ({ ...tempBox, id: tempBox.id || tempBox.tempId }))
    to_change.regionList = payload.regionList.map(region => ({ ...region, id: region.id || region.tempId }))
  }

  fetchPreds = async (id = 0, withIndex = null) => {
    let payload = await this.getImage(id)
    payload.imageB64Promise = this.b64ImageProvider.get(payload.image.imagePath)
    // Wait for current image, then start fetching others to be cached.
    await payload.imageB64Promise
    this.getFutureImagePaths(id, 5, 5)
      .then((imagePaths) => imagePaths && this.b64ImageProvider.add(imagePaths))
      .catch(e => {
        addCloudWatchLog(`annotate.service.js:223 ${e}`);
        console.error(e)
      })
    return payload
  }

  evaluateDrDme = async (...args) => {
    // Not Implemented
    return {
      drResult: null,
      dmeResult: null
    }
  }
}

export class withDrSays {
  modelIdentifiers = []
  odfModelIdentifiers = ['odf1']
  labelMapName = "firstmap";
  submitMethod = "POST"
  canSearchImage = true
  downloadable = false
  userProblemId = null
  canEvaluate = false
  b64ImageProvider

  constructor(userProblemId) {
    if (userProblemId !== null) {
      this.userProblemId = userProblemId
      this.id = userProblemId
      this.canEvaluate = false
      this.b64ImageProvider = new B64ImageProvider()
    }
  }

  async search(text) {
    return [{
      item: { name: text?.split?.('/').pop() },
      refIndex: text
    }]
  }

  parseRes(data) {
    if (data === null) {
      return data
    }
    const {
      previousUserProblemImageId,
      userProblemImageId,
      nextUserProblemImageId,
      imageStoragePath,
      // thumbnailStoragePath,
      // imageId,
      currentIndex,
      totalCount,
      groundTruths,
      regionGT,
      predictions,
      regionPred,
      answer,
      paintedImageGT
    } = data;

    let newBoxes = [], groundTruthDeleteList = [], predictionAddList = [], regionList = []
    let description = '', paintedImagePath = '', imageAnswer = null

    if (answer === null) {
      this.submitMethod = "POST"
      // paintedImagePath = null
    } else {
      this.submitMethod = "PUT"
      description = answer.description
      newBoxes = answer.boxes || newBoxes
      // paintedImagePath = answer?.paintedImageList[0]?.paintedImagePath || null
      regionList = answer.regionList || regionList
      groundTruthDeleteList = answer.groundTruthDeleteList || []
      predictionAddList = answer.predictionAddList || []
      imageAnswer = answer
    }

    const gtDelList = new Set(groundTruthDeleteList)
    const predAddList = new Set(predictionAddList)

    return {
      image: {
        imagePath: imageStoragePath,
        bboxGT: (groundTruths || []).map(gt => ({ ...gt, isValid: !gtDelList.has(gt.id) })),
        regionGT: (regionGT || []).map(gt => ({ ...gt, isValid: !gtDelList.has(gt.id) })),
        bboxPred: (predictions || []).map(pd => ({ ...pd, isValid: predAddList.has(pd.id) })),
        regionPred: (regionPred || []).map(pd => ({ ...pd, isValid: predAddList.has(pd.id) })),
        newBoxes,
        paintedImagePath,
        imageAnswer,
        regionList,
        description,
        paintedImageGT
      },
      userProblemImageId,
      currentIndex,
      totalCount,
      prevId: previousUserProblemImageId,
      nextId: nextUserProblemImageId,
      hasPrev: previousUserProblemImageId !== null,
      hasNext: nextUserProblemImageId !== null,
    }
  }

  downloadData = async () => {
    throw Error('Not Implemented!')
  }

  submit = async (payload) => {
    payload.labelMapName = this.labelMapName
    const res = await Api({
      method: this.submitMethod,
      url: `${ROOT}/boxanswers`,
      data: payload
    })
    const { data, success, message, error } = res.data
    if (!success) {
      throw { message, error }
    }

    // Next submit method should be put, 
    // in case of user wants to resubmit the same image with post
    this.submitMethod = "PUT"

    return data
  }

  fetchImage = async (id = "") => {
    const data = {}
    let url = ""
    //TODO: refactor
    if (!(id === undefined || id === null || id === "")) {
      data.userProblemImageId = id
      url = `${ROOT}/boxanswers/GetImageWithId`
    } else {
      url = `${ROOT}/boxanswers/GetUnanswered`
    }

    data.modelIdentifiers = this.modelIdentifiers
    data.labelMapName = this.labelMapName

    const res = await Api({
      method: 'POST', url, data
    })
    return res.data
  }

  getFutureImagePaths = async (userProblemImageId, left, right) => {
    const res = await Api({
      method: 'POST',
      url: `${ROOT}/UserProblemImages/GetImagesSlidingWindow`,
      data: { userProblemId: this.userProblemId, userProblemImageId, left, right }
    })
    return res.data && res.data.success && res.data.data
  }

  fetchImageWithIndex = async (index = "") => {
    const data = {
      index,
      labelMapName: this.labelMapName,
      modelIdentifiers: this.modelIdentifiers
    }

    const res = await Api({
      method: 'POST',
      url: `${ROOT}/boxanswers/GetImageWithIndex`,
      data
    })
    return res.data
  }

  fetchImageWithPath = async (imagePath = "") => {
    const data = {
      imagePath,
      labelMapName: this.labelMapName,
      modelIdentifiers: this.modelIdentifiers
    }

    const res = await Api({
      method: 'POST',
      url: `${ROOT}/boxanswers/getImageWithImagePath`,
      data
    })
    return res.data
  }

  fetchImageWithGCSPath = async (storagePath = "") => {
    const data = {
      storagePath,
      labelMapName: this.labelMapName,
      modelIdentifiers: this.modelIdentifiers
    }

    const res = await Api({
      method: 'POST',
      url: `${ROOT}/boxanswers/GetImageWithStoragePath`,
      data
    })
    return res.data
  }

  fetchPreds = async (param, withIndex = false, withName = false) => {

    const fetchFn = withIndex
      ? isCloudPath(param)
        ? this.fetchImageWithGCSPath
        : this.fetchImageWithIndex
      : withName
      ? this.fetchImageWithPath
      : this.fetchImage;

    const { data, success, error, message } = await fetchFn(param)
    if (!success || !data) { 
      return null;
    }
    
    const parsed = this.parseRes(data)

    parsed.imageB64Promise = this.b64ImageProvider.get(parsed.image.imagePath)
    // Wait for current image, then start fetching others to be cached.
    await parsed.imageB64Promise
    this.getFutureImagePaths(parsed.userProblemImageId, 5, 5)
      .then((imagePaths) => imagePaths && this.b64ImageProvider.add(imagePaths))
      .catch(e => {
        addCloudWatchLog(`GetImageSources.js:65 ${e}`);
        console.error(e)
      })
    return parsed

  }

  /**
   * @param {string} storagePath
   * @returns {Object} data -
   * @returns {string} data.drResult - Human-readable DR result
   * @returns {string} data.dmeResult - Human-readable DME result
   */
  evaluateDrDme = async (storagePath) => {
    const payload = {
      storagePath,
      odfScoreThreshold: 0.2,
    }
    payload.labelMapName = this.labelMapName
    payload.userProblemId = this.userProblemId
    payload.odfModelIdentifiers = this.odfModelIdentifiers
    payload.anomalyModelIdentifiers = this.modelIdentifiers

    const res = await Api({
      method: 'POST',
      url: `${ROOT}/diagnostics/diagnosedrdme`,
      data: payload
    })
    const { data, success, message, error } = res.data
    if (!success) {
      throw { message, error }
    }

    // Next submit method should be put, 
    // in case of user wants to resubmit the same image with post
    this.submitMethod = "PUT"

    return data
  }
}

