import React, { createContext, useContext, useState, useRef } from 'react';

const DataContext = createContext({});

export const ImageBrushProvider = ({children}) => {
  const [showBrush, setShowBrush] = useState(false);
  const imgRef = useRef(null);
  const [alpha, setAlpha] = useState(0);

  function setBrushImg(im){
    imgRef.current = im
    setAlpha(1)
  }
  const brushImg = imgRef.current 

  const values = {
    showBrush, setShowBrush, 
    brushImg, setBrushImg,
    alpha, setAlpha
  }
  
  return (
    <DataContext.Provider value={values}>
      {children}
    </DataContext.Provider>
  )
}

export const useImageBrush = () => useContext(DataContext);