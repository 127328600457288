import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import { makeStyles} from '@material-ui/core';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { addImage } from '../../../../../service/admin.service';
import { useConfirm } from 'material-ui-confirm';
import { actions } from '../../../../../redux/slices/admin';
import { usePending } from '../../../../../GlobalComponents/Contexes/pending';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from '@mui/material/colors';
import { addCloudWatchLog } from '../../../../../service/cloud.service';
import AddManualAddImage from './AddManualAddImage';
import AddImagesErrorModal from './AddImagesErrorModal';
import './AddImage.css';

const useStyles = makeStyles((theme) => ({
  addImageRoot: {
    position: 'relative',
    top: '10%',
    left: '15%',
  },
  imageLabel: {
    position: 'relative',
    top: '1.8rem',
    right: '7.5rem',
  },
  imageSourceLabel: {
    position: 'relative',
    top: '2.4rem',
    right: '7.5rem',
  },
  formControl: {
    width: '30%',
  },
  saveButton: {
    position: 'relative',
    top: '5rem',
    right: '10rem',
  },
  cancelButton: {
    position: 'relative',
    top: '5rem',
    right: '23rem',
  },
  fileEl: {
    position: 'relative',
    bottom: '2.3rem',
    left: '11rem',
    backgroundColor: '#cfd8dc',
    borderRadius: '5%',
    width: 'fit-content',
    height: 'fit-content',
    marginBottom: '-2.37rem',
  },
  filePresent: {
    position: 'relative',
    top: '0.4rem',
    marginRight: '0.2rem',
  },
  paragraphEl: {
    display: 'inline-block',
    marginRight: '0.5rem',
  },
  fileCancelling: {
    position: 'relative',
    bottom: '0.1rem',
    '&:hover': {
      backgroundColor: '#b0bec5',
    },
  },
}));

function useAddImageFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const addImageFn = addImage;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`AddImage.js:91 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`AddImage.js:105 ${e}`);
        console.error(e);
      });
  }

  async function submit(
    _data,
    jsonError,
    fileTypeErrorMessage,
    isFileChoosen,
    isDataValid
  ) {
    if (!isFileChoosen) {
      const message = 'File not selected!';
      showMessage({ message });
      return { data: '', success: false, error: null, message };
    } else if (jsonError) {
      const message = 'Please choose json files which are in JSON format!';
      showMessage({ message });
      return { data: '', success: false, error: jsonError, message };
    } else if (fileTypeErrorMessage) {
      const message = fileTypeErrorMessage;
      showMessage({ message });
      return { data: '', success: false, error: null, message };
    } else if (!isDataValid) {
      const message = `Example file content must be as follows:
[
  {
    "storagePath": "string", 
    "thumbnailStoragePath": "string",
    "qualityTypeId": 1
  }
]`;
      showMessage({ message });
      return { data: '', success: false, error: null, message };
    }

    const { data, success, error, message } = await addImageFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.updateEnum({ enumKey: 'imageSources', data }));
    }
    return { data, success, error, message };
  }

  return submit;
}

export default function AddImage() {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [imageSourceId, setImageSourceId] = useState('');
  const [loading, setLoading] = useState(false);
  const [jsonError, setJsonError] = useState(null);
  const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState(null);
  const [isFileChoosen, setFileChoosen] = useState(false);
  const [isDataValid, setValidData] = useState(false);
  const [fileName, setFileName] = useState('');
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [qualityTypeId, setQualityTypeId] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [jsonList, setJsonList] = useState([]);
  const handleModalClose = () => setOpenModal(false);
  const pending = usePending();

  const addImageFn = useAddImageFunction();
  const history = useHistory();

  let fileReader;

  const qualityTypes = [
    { id: 1, name: "Reject" },
    { id: 2, name: "Usable" },
    { id: 3, name: "Good" },
    { id: 4, name: "Unknown" },
  ];

  const { byId: imageSources } = useSelector(
    (state) => state.admin.enums.imageSources
  );

  const editedImageSources = {
    ...imageSources,
    undefined: { id: '', name: 'Deselect' },
  };

  const handleSelectChange = (e) => {
    setImageSourceId(e.target.value);
  };

  const controlData = (jsonContent) => {
    if (Array.isArray(jsonContent)) {
      jsonContent.map((obj) => {
        const keyArray = Object.keys(obj);

        if (
          keyArray[0] !== 'storagePath' ||
          keyArray[1] !== 'thumbnailStoragePath' ||
          keyArray[2] !== 'qualityTypeId'
        ) {
          return false;
        }
        return true
      });

      return true;
    }

    return false;
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    let jsonContent;

    try {
      jsonContent = JSON.parse(content);
      const isValid = controlData(jsonContent);
      setValidData(isValid);

      setImages(jsonContent);
    } catch (e) {
      setJsonError(e);
      return;
    }
  };

  const handleFileChoosen = (file) => {
    setJsonError(null);
    setFileTypeErrorMessage(null);
    setFileChoosen(false);

    if (file) {
      setFileChoosen(true);
      setFileName(file.name);
    }

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;

    if (file?.type === 'application/json') {
      fileReader.readAsText(file);
    } else {
      setFileTypeErrorMessage('Not a .json file!');
    }
  };

  async function submit(e) {
    e.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const request = { imageSourceId, images };

    const { success, error } = await addImageFn(
      request,
      jsonError,
      fileTypeErrorMessage,
      isFileChoosen,
      isDataValid
    );

    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/settings/images');
    }
  }

  function onAddProblemClose(jsonListLocal) {
    if (jsonListLocal.length != undefined) {
      setJsonList(jsonListLocal);
    }
    setAddNewOpen(false);
  }

  function onChangeQuality(e) {
    const localQualityTypeId = e.target.value;
    setQualityTypeId(localQualityTypeId);
    let newJsonList = [];
    jsonList.forEach((jsonListItem) => {
      const map = {"storagePath": jsonListItem["storagePath"],
        "thumbnailStoragePath": jsonListItem["thumbnailStoragePath"],
        "qualityTypeId": localQualityTypeId};
      newJsonList.push(map);
    });
    setJsonList(newJsonList);
  }

  async function createJson(e) {
    e.preventDefault();
    if (jsonList.length != 0) {
      await submitJsonList(jsonList);
    } else {
      setOpenModal(true);
    }
  }

  async function submitJsonList(jsonListLocal) {
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, "Kaydediliyor");

    const request = { imageSourceId, images: jsonListLocal };

    const { success, error } = await addImageFn(
      request,
      jsonError,
      fileTypeErrorMessage,
      true,
      true
    );

    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/settings/images');
    }
  }

  return (
    <Box
      className={classes.addImageRoot}
      component='form'
      autoComplete='off'
      onSubmit={createJson}
    >
      <label htmlFor='upload-photo'>
        <InputLabel className={classes.imageLabel}>Image</InputLabel>
        {/* <input
          style={{ display: 'none' }}
          id='upload-photo'
          name='upload-photo'
          type='file'
          accept='.json'
          onChange={(e) => handleFileChoosen(e.target.files[0])}
          onClick={(e) => (e.target.value = '')}
        /> */}
        <Button
          className='rowC'
          color='primary'
          variant='contained'
          component='span'
          type='button'
          onClick={() => setAddNewOpen(true)}
        >
          Add Manual
        </Button>
        <h4 className="rowC">
          {"Total: "} {jsonList.length} {" image paths are ready to upload"}
        </h4>
        <AddManualAddImage open={addNewOpen} onClose={onAddProblemClose} />
      </label>
      {/* {isFileChoosen && (
        <div className={classes.fileEl}>
          <FilePresentIcon className={classes.filePresent} />
          <Typography className={classes.paragraphEl}>{fileName}</Typography>
          <Button
            endIcon={<CancelIcon sx={{ color: red['A700'] }} />}
            variant= 'text'
            className={classes.fileCancelling}
            type='button'
            onClick={() => {
              setFileChoosen(false);
            }}
          />
        </div>
      )} */}
      <InputLabel className={classes.imageSourceLabel}>Image Source</InputLabel>
      <FormControl className={classes.formControl} required>
        <InputLabel id='select-label'>Select</InputLabel>
        <Select
          labelId='select-label'
          value={imageSourceId}
          label='Select'
          onChange={handleSelectChange}
        >
          {Object.keys(editedImageSources).map(function (key) {
            const imageSource = editedImageSources[key];
            return (
              <MenuItem key={key} value={imageSource.id}>
                {imageSource.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <InputLabel className={classes.imageSourceLabel}>Quality</InputLabel>
      <FormControl className={classes.formControl} required>
        <InputLabel id="quality-label">Select</InputLabel>
        <Select
          labelId="quality-label"
          value={qualityTypeId}
          label="Select"
          onChange={(e) => onChangeQuality(e)}
        >
          {qualityTypes.map((qualityType, index) => (
            <MenuItem key={index} value={qualityType.id}>
              {qualityType.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant='contained'
        color='primary'
        className={classes.saveButton}
        type='submit'
      >
        Save
      </Button>
      <Button
        className={classes.cancelButton}
        color='primary'
        variant='contained'
        to='/admin/settings/images'
        component={Link}
      >
        Cancel
      </Button>
      <AddImagesErrorModal open={openModal} onClose={handleModalClose} text="You must upload least one image path"/>
    </Box>
  );
}
