import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableContainer, Tooltip, Table, Box, TableCell, TableRow, makeStyles, TableBody } from '@material-ui/core'
import { actions } from '../../../redux/slices/annotation';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(theme => ({
  colorPickerInput: {
    padding: '0 1px',
    backgroundColor: '#404040',
    borderColor: '#a9a9a978',
    borderRadius: 5
  },
  popper: {
    // zIndex: 1700
  }
}))


export default () => {
  const classes = useStyles();
  const dispatch = useDispatch()

  const patchColor = useSelector(state => state.annotation.options.patchColor)

  function handleChange(key) {
    return (e) => dispatch(actions.updateOption({ key, value: e.target.value }))
  }

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontSize: 'large' }}>
                {'Yama Rengi '}
                <Tooltip classes={{ popper: classes.popper }} title={<div><kbd>F</kbd>{" tuşuna basıldığında Bounding Box'ların bulunduğu bölgelere çizilen düz renk"}</div>}>
                  <HelpOutlineIcon style={{ marginBlockEnd: -4 }} fontSize='small' />
                </Tooltip>
              </TableCell>
              <TableCell>
                <input
                  type="color"
                  className={classes.colorPickerInput}
                  defaultValue={patchColor}
                  onChange={handleChange('patchColor')}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}