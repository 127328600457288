import { CloudWatchLogs } from "aws-sdk";
import 'dotenv/config'

// TODO: Refactor
const config = {
    region: process.env.REACT_APP_REGION,
    credentials: { 
        accessKeyId: process.env.REACT_APP_ACCESS_ID_KEY,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    },
}
const cloudWatchLogs = new CloudWatchLogs(config);

export async function addCloudWatchLog(message, type = 'error' ) {
    let domain = "test"
    window.location.hostname === "doctorsays.net" || "www.doctorsays.net" ? domain = "prod" : domain = "test";
    const params = {
        /** input parameters */
        logEvents: [
            { 
               message: message,
               timestamp: new Date().getTime()
            }
        ],
        logGroupName: `doctorsays-frontend-${domain}`,
        logStreamName: `${type}-stream`
    };
    sendCloudWatchLogger(params);
}

async function sendCloudWatchLogger(params) {

  try {
    let nextToken = await cloudWatchLogs.describeLogStreams({logGroupName: params.logGroupName})
    params['sequenceToken'] = nextToken.logStreams[0].uploadSequenceToken
    const data = await cloudWatchLogs.putLogEvents(params);
    // process data.
  } catch (error) {
    // error handling.
    console.error(error)
  }
}