import React, { useEffect, useState } from 'react';
import { Button, Typography, InputLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import { usePending } from '../../../../../../GlobalComponents/Contexes/pending';
import { createNewProject, getProblemCategories, getUsers } from '../../../../../../service/admin.service';
import { useConfirm } from 'material-ui-confirm';
import { Link } from 'react-router-dom'
import { useAsync } from '../../../../../../util/hooks';
import { useHistory } from 'react-router-dom';
import { addCloudWatchLog } from '../../../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  createSubCategory: {
    padding:'50px', 
    paddingTop:'20px',
    display:'flex', 
    textAlign:'center', 
    justifyContent:'space-around',
  },
  inputAndLabel: {
    position: 'relative',
    left: '13%',
    top: '8%',
  },
  inputSection: {
    padding:'25px',
    paddingLeft:'50px',
  },
  formEl: {
    width: '60%',
    margin: '10px'
  },
  textFieldEl: {
    width: '60%',
    position: 'relative',
    left: '0.6rem'
  },
  labelEl: {
    display: 'inline-block',
    position: 'relative',
    top: '2.4rem',
    right: '6rem',
  },
  createProjectButton: {
    position: 'relative',
    left: '75%',
    top: '2rem',
  },
  userSettingsButton: {
    position: 'relative',
    top: '5%',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function CreateProject() {
  const classes = useStyles();
  const [projectName, setProjectName] = useState('')
  const [category, setCategory] = useState()
  const [subCategoryId, setSubcategory] = useState()
  const [userId, setUserId] = useState()
  const [loading, setLoading] = useState(false);
  const pending = usePending();

  const [userCategories, setProblemCategory] = useState([]);
  const [users, setUsers] = useState([]);

  const { status: statCategory, value: valCategory, error: errCategory } = useAsync(getProblemCategories)
  const { status: statUsers, value: valUsers } = useAsync(getUsers)
  const addFn = useAddFunction();

  useEffect(() => {
    if (statCategory === 'success') {
      const { data, success, exception: apiError, message } = valCategory;
      if (!success || apiError) {
        addCloudWatchLog(`CreateProject.js:87 ${apiError} ${message}`);
        console.error({ error: apiError, message })
      } else {
        setProblemCategory(data)
      }
    }
    if (statUsers === 'success') {
      const { data, success, exception: apiError, message } = valUsers;
      if (!success || apiError) {
        addCloudWatchLog(`CreateProject.js:96 ${apiError} ${message}`);
        console.error({ error: apiError, message })
      } else {
        setUsers(data)
      }
    }
    if (statCategory === 'error') {
      addCloudWatchLog(`CreateProject.js:103 ${errCategory}`);
      console.error(errCategory)
    }
  }, [statCategory, statUsers])

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    await addFn({
      projectName,
      subCategoryId,
      userId,
    });
    setLoading(false);
    pending(false);
  }

  return (
    <Box component='form' autoComplete='off' onSubmit={submit}>
      <IconButton
        color='primary'
        className={classes.userSettingsButton}
        to='/admin/projects'
        component={Link}
      >
        <ChevronLeftIcon />
        <Typography>Projects</Typography>
      </IconButton>

      <Box className={classes.createSubCategory}>
      <Typography
          variant='h5'
          id='tableTitle'
          component='div'
        >
          Create Project
        </Typography>
      </Box>

      <div className={classes.inputSection}>
        <TextField required className ={classes.textFieldEl} id="adminProjectName" label="Project Name" variant="filled" value={projectName} onChange={(e) => setProjectName(e.target.value)}/>
      </div>

      <div className={classes.inputSection}>
        <FormControl variant="filled" className={classes.formEl} required>
          <InputLabel id="demo-simple-select-filled-label">Select Category Name</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="adminSelectCategoryName"
            value={category}
            label="Select Category Name"
            onChange={(e) => setCategory(e.target.value)}
          >
            <MenuItem value={1}>Annotation</MenuItem>
            <MenuItem value={2}>Classification</MenuItem>
            <MenuItem value={3}>Segmentation</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className={classes.inputSection}>
        <FormControl variant="filled" className={classes.formEl} required>
          <InputLabel id="demo-simple-select-filled-label">Select Subcategory Name</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="adminSelectSubcategoryName"
            value={subCategoryId}
            label="Select Subcategory Name"
            onChange={(e) => setSubcategory(e.target.value)}
          >
            {userCategories.map((c, i) =>{
              return (
                c.categoryTypeId === category ? <MenuItem key={i} value={c.id}>{c.name}</MenuItem> : 
                i === 0 ? <MenuItem key={i} disabled value={null}>Empty</MenuItem> : null
              )})}
          </Select>
        </FormControl>
      </div>

      <div className={classes.inputSection}>
        <FormControl variant="filled" className={classes.formEl} required>
          <InputLabel id="demo-simple-select-filled-label">Select User Name</InputLabel>
          <Select
            labelId="demo-simple-select-filled-label"
            id="adminSelectUserName"
            value={userId}
            label="Select User Name"
            onChange={(e) => setUserId(e.target.value)}
          >
            {users.map((u, i) =>{
              return (
                  <MenuItem key = {i} value={u.id}>{u.name}</MenuItem> 
              )})}
          </Select>
        </FormControl>
      </div>
      
      <Button
        className={classes.createProjectButton}
        id='adminCreateProject'
        type='submit'
        color='primary'
        variant='contained'
        disabled={loading}
      >
        Create Project
      </Button>
    </Box>
  );
}

function useAddFunction() {
  const router = useHistory()
  const confirm = useConfirm();
  const addFn = createNewProject;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`CreateProject.js:231 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`CreateProject.js:245 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await addFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      router.push("/admin/projects/all");
    }
    return { data, success, error, message };
  }
  return submit;
}