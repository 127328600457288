import { createSlice } from '@reduxjs/toolkit';
import { addCloudWatchLog } from '../../service/cloud.service';

//TODO: Carry business logic to middleware?

function setTool(state, { payload: newTool }) {
  if (newTool === 'drag') {
    state.cursor = 'grab';
  } else if (newTool === 'select') {
    state.prevTool = 'select';
    state.cursor = '';
  } else if (newTool === 'poly') {
    state.prevTool = 'poly';
    state.cursor = 'crosshair';
  } else if(newTool === 'editablePoly') {
    state.prevTool = 'editablePoly';
    state.cursor = '';
  } else if(newTool === 'brushTool') {
    state.prevTool = 'brushTool';
    state.cursor = 'crosshair';
  } else if (newTool === 'class') {
    state.prevTool = 'class';
    state.cursor = 'grab';
  } else if (newTool === 'add') {
    state.prevTool = 'add';
    state.cursor = 'crosshair';
  } else if (newTool === 'eraser') {
    state.prevTool = 'eraser';
    state.cursor = 'cell';
  }
  // state.prevTool = state.tool
  state.tool = newTool;
}

function putNewBox(state, { payload: newBox }) {
  if(state.tool === 'add') {
    let maybeOldBox = state.newBoxes.find(
      ({ id, tempId }) =>
        (id !== undefined && id === newBox.id) ||
        (tempId !== undefined && tempId === newBox.tempId)
    );
    if (maybeOldBox !== undefined) {
      Object.assign(maybeOldBox, newBox);
    } else {
      state.newBoxes.push(newBox);
    }
  }
  else if(state.tool === 'poly') {
    let maybeOldBox = state.regionList.find(
      ({ id, tempId }) =>
        (id !== undefined && id === newBox.id) ||
        (tempId !== undefined && tempId === newBox.tempId)
    );
    if (maybeOldBox !== undefined) {
      Object.assign(maybeOldBox, newBox);
    } else {
      state.regionList.push(newBox);
    }
  } 
  else if(state.tool === 'editablePoly') {
    let maybeOldBox = state.editableRegionList.find(
      ({ id, tempId }) =>
        (id !== undefined && id === newBox.id) ||
        (tempId !== undefined && tempId === newBox.tempId)
    );
    if (maybeOldBox !== undefined) {
      Object.assign(maybeOldBox, newBox);
    } else {
      state.editableRegionList.push(newBox);
    }
  }
  else if(state.tool === 'brushTool') {
    let maybeOldBox = state.editableRegionList.find(
      ({ id, tempId }) =>
        (id !== undefined && id === newBox.id) ||
        (tempId !== undefined && tempId === newBox.tempId)
    );
    if (maybeOldBox !== undefined) {
      Object.assign(maybeOldBox, newBox);
    } else {
      state.editableRegionList.push(newBox);
    }
  }
}

function delNewBox(state, { payload: newBox }) {
  if(state.tool === 'add') {
    state.newBoxes = state.newBoxes.filter(
      ({ id, tempId }) => (id || tempId) !== (newBox.id || newBox.tempId)
    );
  }

  else if(state.tool === 'poly') {
    state.regionList = state.regionList.filter(
      ({ id, tempId }) => (id || tempId) !== (newBox.id || newBox.tempId)
    );
  }

  else if(state.tool === 'editablePoly') {
    state.editableRegionList = state.editableRegionList.filter(
      ({ id, tempId }) => (id || tempId) !== (newBox.id || newBox.tempId)
    );
  }

  else if(state.tool === 'brushTool') {
    state.editableRegionList = state.editableRegionList.filter(
      ({ id, tempId }) => (id || tempId) !== (newBox.id || newBox.tempId)
    );
  }
}

function LocalStorageOrDefault(key) {
  if(!localStorage[key]) {
    localStorage[key] = JSON.stringify({"patchColor":"#000000"});
  } 
  try {
    return JSON.parse(localStorage[key]);
  } catch (e) {
    addCloudWatchLog(`annotation.js:71 ${e}`);
    console.error(e);
    return null;
  }
}

export const annotation = {
  selectRegionId: null,
  width: window.innerWidth,
  height: window.innerHeight,
  region: null,
  regionList: [],
  editableRegion: null,
  editableRegionList: [],
  editablePolyBoxCoefficient: 0.3,
  brushToolCoefficient: 0.5,
  showAllPaintedImages: true,
  paintedImagesGT: false,
  arteryImagePath: null,
  veinImagePath: null,
  paintedImageChanged: false,
  isSaveButtonClicked: false,
  numberOfPoints: 16,
  pointFrequency: 24,
  lineThickness: 10,
  editableRegionRectWidth: 0,
  editableRegionRectHeight: 0,
  editableRegionRectOffsetX: 0,
  editableRegionRectOffsetY: 0,
  editableRegionLineStrokeWidth: 0,
  isEditablePolygonalBoxFiltersDisabled: false,
  withJSON: false,
  dataProvider: null,
  tool: 'select',
  prevTool: 'select',
  cursor: '',
  tempBox: null,
  tempLabelId: null,
  isDrawing: false,
  fixedBoundingBoxMode: false,
  fixedBoundingBoxCoefficient: 0.5,
  // changed: false,
  leftMenuOpened: false,
  hideLabelIds: [],
  loading: true,
  newBoxes: [],
  keyEventsActive: true,
  filterOptions: {
    showGT: true,
    showPred: true,
    showPoly: true,
    showSelectedOnly: false,
    showFlaggedOnly: false,
    threshold: [0, 1],
    showLabels: [],
  },
  options: {
    patchColor: 'black',
    ...LocalStorageOrDefault('annotationOptions'),
  },
};

const annotationSlice = createSlice({
  name: 'annotation',
  initialState: annotation,
  reducers: {
    selectRegion(state, { payload }) {
      state.selectRegionId = payload;
    },

    setSize(state, { payload: { width, height } }) {
      state.width = width;
      state.height = height;
    },

    setTempLabelId(state, { payload: { id, checkTool } }) {
      if (null !== state.tempBox) {
        state.tempBox.labelId = id;
      }

      if (null !== state.region) {
        state.region.labelId = id;
      }

      if (null !== state.editableRegion) {
        state.editableRegion.labelId = id;
      }

      if (checkTool) {
        if ('add' === state.tool || 'poly' === state.tool || 'editablePoly' === state.tool || 'brushTool' === state.tool) {
          state.tempLabelId = id;
        }
      } else {
        state.tempLabelId = id;
      }
    },

    updateTempBox(state, { payload }) {
      if(state.tool === 'add') {
        Object.assign(state.tempBox, payload);
      } else if(state.tool === 'editablePoly') {
        Object.assign(state.editableRegion, payload);
      } else if(state.tool === 'brushTool') {
        Object.assign(state.editableRegion, payload);
      } else if (state.tool === 'poly') {
        Object.assign(state.region, payload);
      }
    },
    setTempBox(state, { payload }) {
      if(state.tool === 'add') {
        state.tempBox = payload;
      } else if(state.tool === 'poly') {
        if(!payload)
          state.region = null;
        else if(!payload.has)
          state.region = payload
        else
          state.region.points.push(payload.point);
      } else if(state.tool === 'editablePoly') {
        if(!payload)
          state.editableRegion = null;
        else
          state.editableRegion = payload;
      } else if(state.tool === 'brushTool') {
        if(!payload)
          state.editableRegion = null;
        else
          state.editableRegion = payload;
      }
    },
    clearTempBox(state) {
      state.isDrawing = false;
      state.tempBox = null;
    },

    setIsDrawing(state, { payload }) {
      state.isDrawing = payload;
    },

    //TODO: Look for better practice
    setTool,
    setPrevTool(state) {
      setTool(state, { payload: state.prevTool });
    },
    setFixedBoundingBoxMode(state, { payload }) {
      state.fixedBoundingBoxMode = payload;
    },
    setFixedBboxCoefficient(state, { payload }) {
      state.fixedBoundingBoxCoefficient = payload;
    },
    setLeftMenuOpened(state, { payload }) {
      state.leftMenuOpened = payload;
    },
    setCursor(state, { payload }) {
      state.cursor = payload;
    },

    setLoading(state, { payload }) {
      state.loading = payload;
    },
    setNewBoxes(state, { payload }) {
      state.newBoxes = payload;
    },
    setNewRegionList(state, { payload } ) {
      state.regionList = payload;
    },
    setNewEditableRegionList(state, { payload } ) {
      state.editableRegionList = payload;
    },
    setEditableRegionRectWidth(state, { payload }) {
      state.editableRegionRectWidth = payload;
    },
    setEditableRegionRectHeight(state, { payload }) {
      state.editableRegionRectHeight = payload;
    },
    setEditableRegionRectOffsetX(state, { payload }) {
      state.editableRegionRectOffsetX = payload;
    },
    setEditableRegionRectOffsetY(state, { payload }) {
      state.editableRegionRectOffsetY = payload;
    },
    setEditableRegionLineStrokeWidth(state, { payload }) {
      state.editableRegionLineStrokeWidth = payload;
    },
    setEditablePolyBoxCoefficient(state, { payload }) {
      state.editablePolyBoxCoefficient = payload;
    },
    setBrushToolCoefficient(state, { payload }) {
      state.brushToolCoefficient = payload;
    },
    setShowAllPaintedImages(state, { payload }) {
      state.showAllPaintedImages = payload;
    },
    setPaintedImagesGT(state, { payload }) {
      state.paintedImagesGT = payload;
    },
    setArteryImagePath(state, { payload }) {
      state.arteryImagePath = payload;
    },
    setVeinImagePath(state, { payload }) {
      state.veinImagePath = payload;
    },
    setPaintedImageChanged(state, { payload }) {
      state.paintedImageChanged = payload;
    },
    setIsSaveButtonClicked(state, { payload }) {
      state.isSaveButtonClicked = payload;
    },
    setNumberOfPoints(state, {payload}) {
      state.numberOfPoints = payload;
    },
    setPointFrequency(state, {payload}) {
      state.pointFrequency = payload;
    },
    setLineThickness(state, {payload}) {
      state.lineThickness = payload;
    },
    setEditablePolygonalBoxFiltersDisabled(state, { payload }) {
      state.isEditablePolygonalBoxFiltersDisabled = payload;
    },
    setWithJSON(state, { payload }) {
      state.withJSON = payload;
    },
    setDataProvider(state, { payload }) {
      state.dataProvider = payload;
    },
    putNewBox,
    delNewBox,
    saveTempBox(state, { payload }) {
      if (state.tool === 'add' && null !== state.tempBox && !state.isDrawing) {
        putNewBox(state, {
          payload: { ...state.tempBox, flags: payload?.flags || [] },
        });
        state.tempBox = null;
        state.cursor = 'crosshair';
      } else if(state.tool === 'poly' && null !== state.region && !state.isDrawing) {
        putNewBox(state, {
          payload: { ...state.region, flags: payload?.flags || [] },
        }); 
        state.region = null;
        state.cursor = 'crosshair';
      } else if(state.tool === 'editablePoly' && null !== state.editableRegion) {
        putNewBox(state, {
          payload: { ...state.editableRegion, flags: payload?.flags || [] },
        }); 
        state.editableRegion = null;
        state.cursor = 'crosshair';
      } else if(state.tool === 'brushTool' && null !== state.editableRegion) {
        putNewBox(state, {
          payload: { ...state.editableRegion, flags: payload?.flags || [] },
        }); 
        state.editableRegion = null;
        state.cursor = 'default';
      }
    },
    delTempBox(state) {
      if (state.tool === 'add' && null !== state.tempBox && !state.isDrawing) {
        delNewBox(state, { payload: state.tempBox });
        state.tempBox = null;
        state.cursor = 'crosshair';
      } else if(state.tool === 'poly' && null !== state.region && !state.isDrawing) {
        delNewBox(state, { payload: state.region });
        state.region = null;
        state.cursor = 'crosshair';
      } else if(state.tool === 'editablePoly' && null !== state.editableRegion) {
        delNewBox(state, { payload: state.editableRegion });
        state.editableRegion = null;
        state.cursor = 'crosshair';
      } else if(state.tool === 'brushTool' && null !== state.editableRegion) {
        delNewBox(state, { payload: state.editableRegion });
        state.editableRegion = null;
        state.cursor = 'default';
      }
    },

    // Global key events for toolbox (used in scene)
    setKeyEventsActive(state, { payload }) {
      state.keyEventsActive = payload;
    },
    addHideLabelIds(state, { payload }) {
      if (!state.hideLabelIds.includes(payload)) {
        state.hideLabelIds.append(payload);
      }
    },
    removeHideLabelIds(state, { payload }) {
      state.hideLabelIds = state.hideLabelIds.filter((id) => id === payload);
    },
    updateFilterOptions(state, { payload }) {
      Object.assign(state.filterOptions, payload);
    },
    toggleLabel(state, { payload }) {
      if (state.filterOptions.showLabels.includes(payload.labelId)) {
        state.filterOptions.showLabels = state.filterOptions.showLabels.filter(
          (id) => id !== payload.labelId
        );
      } else {
        state.filterOptions.showLabels.push(payload.labelId);
      }
    },
    setShowLabels(state, { payload }) {
      state.filterOptions.showLabels = payload.labels;
    },
    updateOption(state, { payload: { key, value } }) {
      state.options[key] = value;
      localStorage['annotationOptions'] = JSON.stringify(state.options);
    },
    reset(state) {
      Object.assign(state, {
        ...annotation,
        filterOptions: {
          ...annotation.filterOptions,
          showLabels: [...state.filterOptions.showLabels],
        },
      });
    },
  },
});

export const reducer = annotationSlice.reducer;
export const actions = annotationSlice.actions;
