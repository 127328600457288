import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import GetImageSources from '../../Components/SystemSettings/ImageSource/GetImageSources';
import AddImageSource from '../../Components/SystemSettings/ImageSource/AddImageSource';
import EditImageSource from '../../Components/SystemSettings/ImageSource/EditImageSource';

export default function (props) {
  const match = useRouteMatch();
 
  return (
    <>
      <Switch>
        <Route path={`${match.url}/image-source`} component={GetImageSources}></Route>
        <Route path={`${match.url}/create-image-source`} component={AddImageSource}></Route>
        <Route path={`${match.url}/edit-image-source/:id`} component={EditImageSource}></Route>
      </Switch>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/image-source`} />
      </Switch>
    </>
  );
}
