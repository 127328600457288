import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@material-ui/core';
import { getProjectById, getFilteredUserProblemImagePaths } from '../../../../../../service/admin.service';
import { useParams, Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useAsync } from '../../../../../../util/hooks';
import getAlternateStyle, {getColor} from '../../../../CommonStyles/AlternateColor';
import CustomNoRowsOverlay from '../../../../../../GlobalComponents/CustomNoRowsOverlay';
import CustomPagination from '../../../../../../GlobalComponents/CustomPagination';
import { addCloudWatchLog } from '../../../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  updateSubCategory: {
    padding:'50px', 
    paddingTop:'20px',
    display:'flex', 
    textAlign:'center', 
    justifyContent:'space-around',
  },
  topButton: {
    paddingBottom: '10px',
  },
  userSettingsButton: {
    position: 'relative',
    top: '5%',
  },
  changePagination: {
    position: 'relative',
    bottom: '2.9rem',
    left: '1rem',
  },
}));

const column = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'imagePath',
    headerName: 'Image Path',
    width: 600,
    editable: false,
  },
];

export default function UpdateProject() {
  const { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([])
  const [userProject, setUserProject] = useState([]);
  const [isPaginationChanged, setPaginationChanged] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const { execute, status, value, error: networkErr } = useAsync(() => getProjectById(id), false)

  useEffect(() => {
    execute()
  }, [id])

  useEffect(() => {
    (async () => {
      let _imageList = []
      if (loading) { return }
      setLoading(true)
      const { data, success, exception, message } = await getFilteredUserProblemImagePaths(parseInt(id),  { labelIds: [] },  { descriptions: [] })
      if (!success || exception) {
        addCloudWatchLog(`UpdateProject.js:70 ${exception} ${message}`)
        console.error({ message, error: exception })
      }
      else
        _imageList = data.map((e, i) => {
          return { id: i, imagePath: e }
        });
        setImageList(_imageList)
      setLoading(false);
    })()
  }, [status])

  useEffect(() => {
    if (status === 'success') {
      const { data, success, exception: apiError, message } = value;
      if (!success || apiError) {
        addCloudWatchLog(`UpdateProject.js:86 ${apiError} ${message}`)
        console.error({ error: apiError, message })
      } else {
        setUserProject(data)
      }
    }
    if (status === 'error') {
      addCloudWatchLog(`UpdateProject.js:93 ${networkErr}`)
      console.error(networkErr)
    }
  }, [])

  const anotherPagination = isPaginationChanged && {
    Pagination: CustomPagination,
  };

  return (
    <>
      <Box component='form' autoComplete='off'>
        <IconButton
          id='backToProjects'
          color='primary'
          className={classes.userSettingsButton}
          to='/admin/projects'
          component={Link}
        >
          <ChevronLeftIcon />
          <Typography>Projects</Typography>
        </IconButton>

        <Box className={classes.updateSubCategory}>
        <Typography
            variant='h5'
            id='tableTitle'
            component='div'
          >
            {userProject.description}
          </Typography>
        </Box>

        <Box className={classes.topButton}>
            <Grid style={{paddingRight: '10%'}}>
                <Button to={'/admin/projects/imageset/' + id} component={Link} id='adminAddImageset' variant='contained' color='primary' size='small'>Add Imageset</Button>
            </Grid>
        </Box>
          <div style={{ height: 630, width: '100%', textAlign: 'center' }}>
            <DataGrid 
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                ...anotherPagination,
              }}
              columns={column || []}
              rows={imageList || []}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              sx={getAlternateStyle}
              getRowClassName={(params) => {return getColor(params.id)}}
            />
          </div>
          <Button
              className={classes.changePagination}
              variant='contained'
              color='primary'
              onClick={() => setPaginationChanged((prevState) => !prevState)}
            >
                Change Pagination
            </Button>
      </Box>
    </>
  );
}
