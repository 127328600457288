import React, { createContext, useContext, useState, useRef } from 'react';

const DataContext = createContext({});

export const ImageMaskProvider = ({children}) => {
  const [showMask, setShowMask] = useState(false);
  const imgRef = useRef(null);
  const [alpha, setAlpha] = useState(0);

  function setMaskImg(im){
    imgRef.current = im
    setAlpha(1)
  }
  const maskImg = imgRef.current 

  const values = {
    showMask, setShowMask, 
    maskImg, setMaskImg,
    alpha, setAlpha
  }
  
  return (
    <DataContext.Provider value={values}>
      {children}
    </DataContext.Provider>
  )
}

export const useImageMask = () => useContext(DataContext);