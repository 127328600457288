import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import { ConfirmProvider } from "material-ui-confirm";
import { PendingProvider } from './GlobalComponents/Contexes/pending';
import { OptionsProvider } from './GlobalComponents/Contexes/options';

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: red,
  },
  props: {
    MuiToolbar: {
      variant: 'dense',
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 40
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: indigo[500],
          '& span': {
            color: indigo[50]
          },
          '& p': {
            color: indigo[200]
          },
          '&:hover': {
            backgroundColor: indigo[600],
          }
        }
      }
    }
  }
});

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <PendingProvider>
            <OptionsProvider>
              <App />
            </OptionsProvider>
          </PendingProvider>
        </ConfirmProvider>
      </ThemeProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
