
import React, { useState, useEffect, useRef } from 'react'

import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import { addImageToDataset, getDatasets } from '../../../../service/admin.service';
import { useApiFunction, useErrorMessage, useAsync } from '../../../../util/hooks';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextareaAutosize,
  InputLabel, Select, FormControl, MenuItem
} from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  dialogPaper: {
    // minHeight: '35rem'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  textArea: {
    marginTop: '1rem',
    width: '99%',
    resize: 'none',
    overflowWrap: 'normal',
    whiteSpace: 'nowrap',
    border: 0,
    backgroundColor: '#efefef',
    outline: 0
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
}))


export default ({ imagePathList, open, onClose }) => {
  const classes = useStyles();
  const [error, setError] = useState();
  const [datasets, setDatasets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [posted, setPosted] = useState(false)
  const showMessage = useErrorMessage();
  const pending = usePending();

  const datasetInputRef = useRef()

  const addToDataset = useApiFunction(addImageToDataset, {
    beforeExecute: () => pending(true, 'Gönderiliyor...'),
    afterExecute: () => pending(false)
  })

  const { status, value, error: networkErr } = useAsync(getDatasets)

  useEffect(() => {
    //TODO: this logic started to be re-occuring check if you can put it inside useAsync
    if (status === 'success') {
      const { data, success, exception: apiError, message } = value;
      if (!success || apiError) {
        setError({ error: apiError, message })
      } else {
        setDatasets(data)
      }
    }
    if (status === 'error') {
      setError(networkErr)
    }
  }, [status])

  useEffect(() => {
    if (error) {
      showMessage(error)
    }
  }, [error])

  useEffect(() => { setPosted(false) }, [imagePathList?.length, datasetInputRef.current?.value])

  function submit(e) {
    e.preventDefault()
    let datasetId = parseInt(datasetInputRef.current.value)
    if (typeof (datasetId) !== 'number') { return; }
    if (loading || posted) { showMessage({ message: 'Bir hata oluştu. Lütfen tekrar deneyin.' }); return; }
    setLoading(true)
    addToDataset(imagePathList, datasetId)
      .then(() => setPosted(true))
      .catch((e) => showMessage(e))
      .finally(() => { setLoading(false); pending(false) })
  }

  return <Dialog classes={{ paper: classes.dialogPaper }} open={open} maxWidth='sm' fullWidth onClose={onClose}>
    <DialogTitle>Dataset'e Resim Ekle</DialogTitle>
    <form onSubmit={submit}>
      <DialogContent className={classes.dialogContent}>
        <FormControl size='small' variant='outlined' fullWidth required>
          <InputLabel id="dataset-select-label">Resimlerin Ekleneceği Dataset</InputLabel>
          <Select
            onChange={() => setPosted(false)}
            label='Resimlerin Ekleneceği Dataset'
            inputProps={{ ref: datasetInputRef }}
            defaultValue=''
            labelId="dataset-select-label"
          >
            {datasets && datasets.map(({ id, name }) => (
              <MenuItem key={`dataset-select-input-${id}`} value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextareaAutosize        
          minRows={imagePathList?.length ?? 20}
          value={imagePathList?.join?.('\n') ?? ''}
          className={classes.textArea}
          size='small'
          label='Resim Listesi'
          variant='outlined'
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          İptal
        </Button>
        <Button
          disabled={posted || loading}
          color='primary' type='submit'>
          {posted ? 'Gönderildi ✔' : 'Gönder'}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}