import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './redux/slices/user';
import { auth } from './service/auth.service';
import LoadingMessage from './GlobalComponents/LoadingMessage'
import { Route, Switch } from "react-router-dom";
import SignIn from './components/Auth/SignIn'
import Panel from './components/Panel'
import Admin from './components/Admin'
import Container from './GlobalComponents/Container'

function useUser() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user)
  return {
    setUser: (user) => dispatch(actions.setUser(user)),
    resetUser: () => dispatch(actions.resetUser()),
    ...user
  }
}

function App() {
  const {
    user, loading,
    setUser, resetUser
  } = useUser();


  useEffect(() => {
    auth.onAuthStateChange(async (user) => {
      if (user) {
        // User is signed in.
        setUser(user)
      } else {
        // User is signed out.
        resetUser()
      }
    })
  }, [])

  if (loading) {
    return <LoadingMessage
      message="Kullanıcı bilgileri yükleniyor"
      loading
    />
  }

  if (user) {
    // User is signed in.
    return (
      user.role === 'admin' ?
        <Admin /> :
        <Container>
          <Panel />
        </Container>
    )
  } else {
    //if you want to use with local files, use only return <SignIn /> 
    // User is not signed in.
    return (
      <Switch>
        <Route exact path='/'>
          <SignIn />
        </Route>
        <Route path='/admin'>
          <SignIn base='admin' />
        </Route>
      </Switch>
    )
  }
}

export default App;
