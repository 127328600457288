import React from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { getSystemLogsPageByPage } from '../../../../service/admin.service';
import useSubmitWithPagination from '../../../../util/useSubmitWithPagination';
import getAlternateStyle, { getColor } from '../../CommonStyles/AlternateColor';
import { LinearProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  systemLogsRoot: {
    width: '98%',
    height: '89%',
    padding: '32px 0 0 15px',
  },
  changePagination: {
    position: 'relative',
    bottom: '2.8rem',
    left: '1rem',
  },
}));

export default function GetSystemLogs() {
  const classes = useStyles();
  const initialPageSize = 11;
  const rowOrder = 'DESC';
  const getFn = getSystemLogsPageByPage;

  const {
    rows,
    handlePageChangeV2,
    handlePageSizeChangeV2,
    handleChangePaginationClick,
    pageNumber,
    pageSize,
    noRowsOverlay,
    anotherPagination,
    handleCheckBoxClicks,
    selectionModel,
    loading,
  } = useSubmitWithPagination({
    initialPageSize,
    getFn,
    rowOrder,
  });

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
    },
    {
      field: 'logType',
      headerName: 'Log Type',
      flex: 1,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
    },
    {
      field: 'createDate',
      headerName: 'Created Date',
      flex: 2,
    },
  ];

  return (
    <div className={classes.systemLogsRoot}>
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
          ...noRowsOverlay,
          ...anotherPagination,
        }}
        loading={loading}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={handlePageSizeChangeV2}
        disableSelectionOnClick
        checkboxSelection
        onSelectionModelChange={handleCheckBoxClicks}
        selectionModel={selectionModel}
        page={pageNumber}
        onPageChange={handlePageChangeV2}
        sx={getAlternateStyle}
        getRowClassName={(params) => {
          return getColor(params.row.rowNumber);
        }}
      />
      <Button
        className={classes.changePagination}
        variant='contained'
        color='primary'
        onClick={handleChangePaginationClick}
      >
        Change Pagination
      </Button>
    </div>
  );
}
