import React, { useState } from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import { LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { InputLabel, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  deleteImages,
  getImagesPageByPage,
} from '../../../../../service/admin.service';
import getAlternateStyle, {
  getColor,
} from '../../../CommonStyles/AlternateColor';
import useSubmitWithPagination from '../../../../../util/useSubmitWithPagination';

const useStyles = makeStyles((theme) => ({
  imagesRoot: {
    width: '98%',
    height: '45.2%',
    padding: '90px 0 0 15px',
  },
  searchContainer: {
    position: 'relative',
    bottom: '4rem',
    left: '13rem',
  },
  inputEl: {
    width: '20%',
  },
  labelEl: {
    position: 'relative',
    top: '2.42rem',
    right: '7.5rem',
  },
  searchButton: {
    position: 'relative',
    bottom: '4.4rem',
    left: '2rem',
  },
  addButton: {
    position: 'relative',
    bottom: '1rem',
  },
  addVisJsonButton: {
    position: 'relative',
    bottom: '1rem',
    left: '2rem',
  },
  deleteButton: {
    position: 'relative',
    bottom: '1rem',
    left: '4rem',
  },
  changePagination: {
    position: 'relative',
    bottom: '2.8rem',
    left: '1rem',
  },
}));

// let rowNumber = 1;

export default function GetImages() {
  const classes = useStyles();
  const [imageName, setImageName] = useState('');
  const [qualityTypeId, setQualityTypeId] = useState('');
  const [imageSourceId, setImageSourceId] = useState('');

  const initialPageSize = 11;
  const getFn = getImagesPageByPage;
  const inputEls = { imageName, qualityTypeId, imageSourceId };
  const deleteFn = deleteImages;

  const {
    rows,
    handlePageChangeV2,
    submit,
    handleDeleteClick,
    handlePageSizeChangeV2,
    handleChangePaginationClick,
    pageNumber,
    pageSize,
    noRowsOverlay,
    anotherPagination,
    isBtnActive,
    handleCheckBoxClicks,
    selectionModel,
    loading,
  } = useSubmitWithPagination({
    initialPageSize,
    getFn,
    inputEls,
    deleteFn,
  });

  const qualityTypes = [
    { id: 1, name: 'Reject' },
    { id: 2, name: 'Usable' },
    { id: 3, name: 'Good' },
    { id: 4, name: 'Unknown' },
    { id: '', name: 'Deselect' },
  ];

  const { byId: imageSources } = useSelector(
    (state) => state.admin.enums.imageSources
  );

  const editedImageSources = {
    ...imageSources,
    undefined: { id: '', name: 'Deselect' },
  };

  const columns = [
    {
      field: 'storagePath',
      headerName: 'Image Name',
      flex: 2,
    },
    {
      field: 'createDate',
      headerName: 'Upload Date',
      flex: 1,
    },
    {
      field: 'qualityTypeName',
      headerName: 'Quality',
      flex: 1,
    },
    {
      field: 'imageSourceName',
      headerName: 'Image Source',
      flex: 1,
    },
  ];

  function handleImageNameChange(e) {
    setImageName(e.target.value);
  }

  function handleQualityTypeIdChange(e) {
    setQualityTypeId(e.target.value);
  }
  
  function handleImageSourceIdChange(e) {
    setImageSourceId(e.target.value);
  }

  return (
    <div className={classes.imagesRoot}>
      <Box
        className={classes.searchContainer}
        component='form'
        autoComplete='off'
        onSubmit={submit}
      >
        <InputLabel className={classes.labelEl}>Image Name</InputLabel>
        <TextField
          className={classes.inputEl}
          label='Image Name'
          onChange={handleImageNameChange}
          value={imageName}
        />
        <InputLabel className={classes.labelEl}>Quality</InputLabel>
        <FormControl className={classes.inputEl}>
          <InputLabel id='quality-label'>Select</InputLabel>
          <Select
            labelId='quality-label'
            value={qualityTypeId}
            label='Select'
            onChange={handleQualityTypeIdChange}
          >
            {qualityTypes.map((qualityType, index) => (
              <MenuItem key={index} value={qualityType.id}>
                {qualityType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <InputLabel className={classes.labelEl}>Image Source</InputLabel>
        <FormControl className={classes.inputEl}>
          <InputLabel id='image-source-label'>Select</InputLabel>
          <Select
            labelId='image-source-label'
            value={imageSourceId}
            label='Select'
            onChange={handleImageSourceIdChange}
          >
            {Object.keys(editedImageSources).map(function (key) {
              const imageSource = editedImageSources[key];
              return (
                <MenuItem key={key} value={imageSource.id}>
                  {imageSource.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          className={classes.searchButton}
          variant='contained'
          color='primary'
          type='submit'
        >
          Search
        </Button>
      </Box>
      <Button
        className={classes.addButton}
        to='/admin/settings/create-image'
        component={RouterLink}
        variant='contained'
        color='primary'
      >
        Add
      </Button>
      <Button
        className={classes.addVisJsonButton}
        to="/admin/settings/create-image-visjson"
        component={RouterLink}
        variant="contained"
        color="primary"
      >
        Add From VisJson
      </Button>
      <Button
        className={classes.deleteButton}
        variant='contained'
        color='primary'
        disabled={!isBtnActive}
        onClick={handleDeleteClick}
      >
        Delete
      </Button>
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
          ...noRowsOverlay,
          ...anotherPagination,
        }}
        loading={loading}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={handlePageSizeChangeV2}
        disableSelectionOnClick
        checkboxSelection
        onSelectionModelChange={handleCheckBoxClicks}
        selectionModel={selectionModel}
        page={pageNumber}
        onPageChange={handlePageChangeV2}
        sx={getAlternateStyle}
        getRowClassName={(params) => {
          return getColor(params.row.rowNumber);
        }}
      />
      <Button
        className={classes.changePagination}
        variant='contained'
        color='primary'
        onClick={handleChangePaginationClick}
      >
        Change Pagination
      </Button>
    </div>
  );
}
