import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useAsync } from '../../../../../util/hooks';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  submitProblemCategory,
  setProblemCategories,
  getProblemCategories,
  deleteProblemCategories,
  getProblemCategoryLabelMap,
  getProblemCategoriesById,
} from '../../../../../service/admin.service';
import { usePending } from '../../../../../GlobalComponents/Contexes/pending';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../../../redux/slices/admin';
import getAlternateStyle, {
  getColor,
} from '../../../CommonStyles/AlternateColor';
import CustomPagination from '../../../../../GlobalComponents/CustomPagination';
import CustomNoRowsOverlay from '../../../../../GlobalComponents/CustomNoRowsOverlay';

const useStyles = makeStyles(() => ({
  topButton: {
    paddingLeft: '50px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  inputAndLabel: {
    paddingTop: '10px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around',
  },
  editAndAddSect: {
    paddingTop: '20px',
    padding: '50px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around',
  },
  inputSection: {
    padding: '25px',
    paddingLeft: '50px',
  },
  rightTable: {
    padding: '10px',
  },
  addLabel: {
    width: '25%',
  },
  selectBox: {
    width: '70%',
  },
  sendButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  changePagination: {
    position: 'relative',
    left: '6rem',
    bottom: '2.8rem',
  },
  otherPagesPagination: {
    position: 'relative',
    top: '1rem',
  },
}));
const categoryColumns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'categoryTypeName',
    headerName: 'Category',
    width: 200,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Subcategory',
    width: 400,
    editable: false,
  },
  {
    field: 'selectionTypeName',
    headerName: 'Selection Type',
    width: 250,
    editable: false,
  },
  {
    field: 'edit',
    headerName: 'Edit',
    width: 100,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return (
        <Button
          style={{ width: '10px' }}
          to={'/admin/settings/edit-category/' + params.row.id}
          component={Link}
          variant='contained'
          size='small'
        >
          EDIT
        </Button>
      );
    },
  },
  {
    field: 'delete',
    headerName: 'Delete',
    width: 100,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      return <AlertDialog id={params}></AlertDialog>;
    },
  },
];

const classColumns = [
  { field: 'labelId', headerName: 'ID', width: 90 },
  {
    field: 'labelName',
    headerName: 'Label Name',
    width: 200,
    editable: true,
  },
];

export default function Category() {
  const classes = useStyles();
  const userCategories = useSelector((state) =>
    Object.values(state.admin.enums.problemCategories.byId)
  );
  const [categoryLabel, setProblemCategoryLabelMap] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState([]);
  const [isPaginationsChanged, setPaginationsChanged] = useState({
    top: true,
    bottom: true,
  });
  const [pageSizes, setPageSizes] = useState({ top: 10, bottom: 10 });
  // const [willDeleteLabel, setWillDeleteLabel] = useState([]); // Toplu Silmek için
  const {
    status: statCategory,
    value: valCategory,
    error: errCategory,
  } = useAsync(getProblemCategories);
  const {
    status: statLabelMap,
    value: valLabelMap,
    error: errLabelMap,
  } = useAsync(getProblemCategoryLabelMap);
  let selected = [];

  function selectedRows(rows) {
    if (rows.length === 1) {
      for (let cat of categoryLabel) {
        if (cat.problemCategoryId === rows[0]) {
          selected.push(cat);
        }
      }
      setSelectedLabel(selected);
    } else {
      setSelectedLabel([]);
    }
    // setWillDeleteLabel(rows) // Toplu silmek için
  }

  useEffect(() => {
    if (statLabelMap === 'success') {
      const { data: categoryLabelData } = valLabelMap;
      setProblemCategoryLabelMap(categoryLabelData);
    }
  }, [
    statCategory,
    statLabelMap,
    valCategory,
    valLabelMap,
    errCategory,
    errLabelMap,
  ]);

  const anotherPaginationTop = isPaginationsChanged.top && {
    Pagination: CustomPagination,
  };
  const anotherPaginationBottom = isPaginationsChanged.bottom && {
    Pagination: CustomPagination,
  };

  //TODO:  useSelector ile reduxta tutulan verileri kullan.
  return (
    <>
      <Typography
        className={classes.inputAndLabel}
        variant='h4'
        id='tableTitle'
        component='div'
      >
        Categories
      </Typography>
      <Box className={classes.topButton}>
        <Grid style={{ paddingRight: '10%' }}>
          <Button
            to='/admin/settings/add-category'
            component={Link}
            variant='contained'
            color='primary'
            size='small'
          >
            ADD SUBCATEGORY
          </Button>
        </Grid>
      </Box>
      <Box className={classes.inputAndLabel}>
        <div style={{ height: 500, width: '90%', textAlign: 'center' }}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              ...anotherPaginationTop,
            }}
            rows={userCategories || []}
            columns={categoryColumns}
            pageSize={pageSizes.top}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(newPageSize) =>
              setPageSizes((prevPageSizes) => ({
                ...prevPageSizes,
                top: newPageSize,
              }))
            }
            onSelectionModelChange={selectedRows}
            disableColumnSelector
            disableDensitySelector
            disableExtendRowFullWidth
            disableVirtualization
            sx={getAlternateStyle}
            getRowClassName={(params) => {
              return getColor(params.id);
            }}
          />
        </div>
      </Box>
      <Button
        className={classes.changePagination}
        variant='contained'
        color='primary'
        onClick={() =>
          setPaginationsChanged((prevIsPaginationChanged) => ({
            ...prevIsPaginationChanged,
            top: !prevIsPaginationChanged.top,
          }))
        }
      >
        Change Pagination
      </Button>
      <Box className={classes.inputAndLabel}>
        <div style={{ height: 300, width: '90%', textAlign: 'center' }}>
          <DataGrid
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
              ...anotherPaginationBottom,
            }}
            rows={selectedLabel}
            columns={classColumns}
            pageSize={pageSizes.bottom}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(newPageSize) =>
              setPageSizes((prevPageSizes) => ({
                ...prevPageSizes,
                bottom: newPageSize,
              }))
            }
            disableSelectionOnClick
            sx={getAlternateStyle}
            getRowClassName={(params) => {
              return getColor(params.id);
            }}
          />
        </div>
      </Box>
      <Button
        className={classes.changePagination}
        variant='contained'
        color='primary'
        onClick={() =>
          setPaginationsChanged((prevIsPaginationChanged) => ({
            ...prevIsPaginationChanged,
            bottom: !prevIsPaginationChanged.bottom,
          }))
        }
      >
        Change Pagination
      </Button>
    </>
  );
}

export function AddCategory() {
  const labelColumns = [
    {
      field: 'name',
      headerName: 'Label Name',
      width: 150,
      editable: true,
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 50,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => deleteLabel(params.id)}
            variant='contained'
            color='secondary'
            size='small'
          >
            Delete
          </Button>
        );
      },
    },
  ];
  const [category, setCategory] = useState(null);
  const dispatch = useDispatch();
  const [subCategory, setSubcategory] = useState('');
  const [selectionType, setSelectionType] = useState(true);
  const [label, setLabel] = useState('');
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const pending = usePending();
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [isPaginationChanged, setPaginationChanged] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const classes = useStyles();

  const addSubCategoryData = {
    categoryTypeId: category,
    name: subCategory,
    selectionTypeId: selectionType ? 2 : 1,
    labels: labels,
  };

  // const addSubCategory = () => {
  //   setProblemCategories(addSubCategoryData)
  //   AlertDialog(12)
  // }

  const checkBoxChanged = () => {
    setSelectionType(!selectionType);
  };

  // const pressEnter = (e) => {
  //   if (e.keyCode === 13) {
  //     setLabelHandle()
  //   }
  // }

  const setLabelHandle = () => {
    setLabels([
      ...labels,
      {
        id: labels.length === 0 ? -1 : labels[labels.length - 1].id - 1,
        name: label,
      },
    ]);
    setLabel('');
  };
  const deleteLabel = (id) => {
    setLabels(
      labels
        .filter((e) => e.id !== id)
        .map((e, i) => {
          return { ...e, id: i + 1 };
        })
    );
    setSelectedLabel(null);
  };

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const { data, success, error } = await setProblemCategories(
      addSubCategoryData
    );
    dispatch(actions.updateEnum({ enumKey: 'problemCategories', data: data }));
    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/settings/category');
    }
  }

  const anotherPagination = isPaginationChanged && {
    Pagination: CustomPagination,
  };

  return (
    <>
      <IconButton
        color='primary'
        className={classes.userSettingsButton}
        to='/admin/settings/category'
        component={Link}
      >
        <ChevronLeftIcon />
        <Typography>Categories</Typography>
      </IconButton>
      <Box className={classes.editAndAddSect}>
        <Typography variant='h6' id='tableTitle' component='div'>
          Create New Subcategory
        </Typography>
      </Box>
      <Box component='form' autoComplete='off' onSubmit={submit}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <div className={classes.inputSection}>
              <FormControl
                required
                variant='filled'
                className={classes.selectBox}
              >
                <InputLabel id='demo-simple-select-filled-label'>
                  Select Category Name
                </InputLabel>
                <Select
                  labelId='demo-simple-select-filled-label'
                  id='demo-simple-select-filled'
                  value={category}
                  label='Select Category Name'
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {/* <MenuItem disabled value={0}>Select Category</MenuItem> */}
                  <MenuItem value={1}>Annotation</MenuItem>
                  <MenuItem value={2}>Classification</MenuItem>
                  <MenuItem value={3}>Segmentation</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.inputSection}>
              <TextField
                required
                className={classes.selectBox}
                id='filled-basic'
                label='Subcategory Name'
                variant='filled'
                value={subCategory}
                onChange={(e) => setSubcategory(e.target.value)}
              />
            </div>
            <div className={classes.inputSection}>
              <FormControl className={classes.selectBox} component='fieldset'>
                <FormLabel component='legend'>Selection Type</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectionType}
                        onChange={checkBoxChanged}
                        name='multiple'
                      />
                    }
                    label='Multiple'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!selectionType}
                        onChange={checkBoxChanged}
                        name='single'
                      />
                    }
                    label='Single'
                  />
                </FormGroup>
                <FormHelperText>TODO: Information this section</FormHelperText>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.inputSection}>
              <FormControl variant='filled' className={classes.selectBox}>
                <FormGroup>
                  <TextField
                    id='filled-basic'
                    label='Label Name'
                    variant='filled'
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                  />
                  <Button
                    disabled={!label}
                    onClick={setLabelHandle}
                    variant='contained'
                    color='primary'
                  >
                    ADD LABEL
                  </Button>
                  <Box className={classes.rightTable}>
                    <div
                      style={{
                        height: 300,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      <DataGrid
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay,
                          ...anotherPagination,
                        }}
                        rows={labels || []}
                        columns={labelColumns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 25, 50]}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        onSelectionModelChange={(id) => {
                          setSelectedLabel(id);
                        }}
                        sx={getAlternateStyle}
                        getRowClassName={(params) => {
                          return getColor(params.id);
                        }}
                      />
                      <Button
                        className={classes.otherPagesPagination}
                        variant='contained'
                        color='primary'
                        onClick={() =>
                          setPaginationChanged((prevState) => !prevState)
                        }
                      >
                        Change Pagination
                      </Button>
                    </div>
                  </Box>
                </FormGroup>
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div className={classes.sendButton}>
          <Button
            disabled={loading}
            type='submit'
            variant='contained'
            color='primary'
          >
            ADD SUBCATEGORY
          </Button>
        </div>
      </Box>
    </>
  );
}

export function EditCategory() {
  const labelColumns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'name',
      headerName: 'Label Name',
      width: 150,
      editable: true,
    },
    // {
    //   field: 'delete',
    //   headerName: 'Delete',
    //   width: 50,
    //   flex: 1,
    //   sortable: false,
    //   filterable: false,
    //   renderCell: (params) => {
    //     return (
    //         <Button onClick={() => deleteLabel(params.row.id)} variant='contained' color='secondary' size='small'>
    //           Delete
    //         </Button>
    //     );
    //   },
    // },
  ];
  let { id } = useParams();

  // const [userCategory, setProblemCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const pending = usePending();
  const dispatch = useDispatch();
  /* Data */
  const [category, setCategory] = useState(0);
  const [subCategory, setSubcategory] = useState('');
  const [selectionType, setSelectionType] = useState(true);
  const [label, setLabel] = useState('');
  const [labels, setLabels] = useState([]);
  const [labelsLength, setLabelsLength] = useState(0);
  const [isPaginationChanged, setPaginationChanged] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  /* Data */
  // const [selectedLabel, setSelectedLabel] = useState(null);
  const classes = useStyles();

  const updateSubCategoryData = {
    id: id,
    categoryTypeId: category,
    name: subCategory,
    selectionTypeId: selectionType ? 2 : 1,
    labels: labels,
  };

  useEffect(() => {
    (async () => {
      let response = await getProblemCategoriesById(id);
      // setProblemCategory(response.data);
      setCategory(response.data.categoryTypeId);
      setSubcategory(response.data.name);
      setSelectionType(response.data.selectionTypeId);
      setLabels(response.data.labels);
      setLabelsLength(response.data.labels.length);
    })();
  }, []);

  // const updateSubCategory = () => {
  //   setProblemCategories(updateSubCategoryData)
  // }

  const checkBoxChanged = () => {
    setSelectionType(!selectionType);
  };

  // const pressEnter = (e) => {
  //   if (e.keyCode === 13) {
  //     setLabelHandle()
  //   }
  // }

  const setLabelHandle = () => {
    setLabels([
      ...labels,
      {
        id:
          labels.length - labelsLength === 0
            ? -1
            : labels[labels.length - 1].id - 1,
        name: label,
      },
    ]);
    setLabel('');
  };
  // const deleteLabel = (selectedId) => {
  //   setLabels(labels.filter(e => e.id != selectedId).map((e, i) => {
  //     return {...e, id: i + 1}
  //   }))
  //   // setSelectedLabel(null)
  // }

  const updateLabel = (e) => {
    setLabels(
      labels.map((label) =>
        label.id === e.id ? { id: e.id, name: e.props.value } : label
      )
    );
  };

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const { data, success, error } = await submitProblemCategory(
      updateSubCategoryData
    );
    dispatch(actions.updateEnum({ enumKey: 'problemCategories', data: data }));
    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/settings/category');
    }
  }

  const anotherPagination = isPaginationChanged && {
    Pagination: CustomPagination,
  };

  return (
    <>
      <IconButton
        color='primary'
        className={classes.userSettingsButton}
        to='/admin/settings/category'
        component={Link}
      >
        <ChevronLeftIcon />
        <Typography>Categories</Typography>
      </IconButton>
      <Box className={classes.editAndAddSect}>
        <Typography variant='h6' id='tableTitle' component='div'>
          Update Subcategory
        </Typography>
      </Box>
      <Box component='form' autoComplete='off' onSubmit={submit}>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <div className={classes.inputSection}>
              <FormControl variant='filled' className={classes.selectBox}>
                <InputLabel id='demo-simple-select-filled-label'>
                  Select Category Name
                </InputLabel>
                <Select
                  labelId='demo-simple-select-filled-label'
                  id='demo-simple-select-filled'
                  value={category}
                  label='Select Category Name'
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <MenuItem disabled value={0}>
                    Select Category
                  </MenuItem>
                  <MenuItem disabled value={1}>
                    Annotation
                  </MenuItem>
                  <MenuItem disabled value={2}>
                    Classification
                  </MenuItem>
                  <MenuItem disabled value={3}>
                    Segmentation
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.inputSection}>
              <TextField
                required
                className={classes.selectBox}
                id='filled-basic'
                label='Subcategory Name'
                variant='filled'
                value={subCategory}
                onChange={(e) => setSubcategory(e.target.value)}
              />
            </div>
            <div className={classes.inputSection}>
              <FormControl className={classes.selectBox} component='fieldset'>
                <FormLabel component='legend'>Selection Type</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectionType}
                        onChange={checkBoxChanged}
                        name='multiple'
                      />
                    }
                    label='Multiple'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!selectionType}
                        onChange={checkBoxChanged}
                        name='single'
                      />
                    }
                    label='Single'
                  />
                </FormGroup>
                <FormHelperText>TODO: Information this section</FormHelperText>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={5}>
            <FormHelperText>
              DİKKAT! İşaretleme yapıldıysa dokunma!
            </FormHelperText>

            <div className={classes.inputSection}>
              <FormControl variant='filled' className={classes.selectBox}>
                <FormGroup>
                  <TextField
                    id='filled-basic'
                    label='Label Name'
                    variant='filled'
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                  />
                  <Button
                    disabled={!label}
                    onClick={setLabelHandle}
                    variant='contained'
                    color='primary'
                  >
                    ADD LABEL
                  </Button>
                  <Box className={classes.rightTable}>
                    <div
                      style={{
                        height: 300,
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      <DataGrid
                        components={{
                          NoRowsOverlay: CustomNoRowsOverlay,
                          ...anotherPagination,
                        }}
                        rows={labels || []}
                        columns={labelColumns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 25, 50]}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        disableSelectionOnClick
                        onEditCellPropsChange={(e) => {
                          updateLabel(e);
                        }}
                        sx={getAlternateStyle}
                        getRowClassName={(params) => {
                          return getColor(params.id);
                        }}
                      />
                      <Button
                        className={classes.otherPagesPagination}
                        variant='contained'
                        color='primary'
                        onClick={() =>
                          setPaginationChanged((prevState) => !prevState)
                        }
                      >
                        Change Pagination
                      </Button>
                    </div>
                  </Box>
                </FormGroup>
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div className={classes.sendButton}>
          <Button
            disabled={loading}
            type='submit'
            variant='contained'
            color='primary'
          >
            UPDATE SUBCATEGORY
          </Button>
        </div>
      </Box>
    </>
  );
}

function AlertDialog({ id }) {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [successOpened, setSuccessOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const agree = () => {
    deleteProblemCategories(id.row.id).then((e) => {
      if (e.success) {
        setSuccessOpen(true);
        setMessage(e.message);
      }
      setMessage(e);
    });
    setOpen(false);
    setSuccessOpen(true);
  };

  const successClose = () => {
    setSuccessOpen(false);
    dispatch(
      actions.deleteEnum({ enumKey: 'problemCategories', data: id.row.id })
    );
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant='contained'
        color='secondary'
        size='small'
      >
        Delete
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <b>Do you want to delete this item: </b> {id.row.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={agree} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={successOpened}
        onClose={successClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {message?.success ? 'Success' : 'Error'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <b>
              {' '}
              {message?.message != null
                ? message?.message
                : `${message?.data.name} başarıyla silindi`}{' '}
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={successClose}>CLOSE</Button>
          {/* <Button onClick={agree} autoFocus>
              Agree
            </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
