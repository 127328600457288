import Api from './Api';
import { auth } from './auth.service';

export async function fetchImageData(imageUrl) {
  const res = await Api({
    method: 'GET',
    url: imageUrl,
    withCredentials: true,
    headers: {
      //INFO: While gcs requires Bearer token, 
      //      S3 requires https cookie which is allready being provided by the API.
      Authorization: `Bearer ${auth.jwtToken}`,//
    },
    responseType: 'arraybuffer'
  });
  return Buffer.from(res.data, 'binary').toString('base64');
}