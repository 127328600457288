import React, { createContext, useContext, useState } from 'react';
import {
  makeStyles,
  Dialog,
  DialogContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';

//TODO: Add options

const PendingContext = createContext();

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '99999 !important'
  },
  paper: {
    width: 'auto',
    padding: '5px 15px',
  },
  content: {
    height: 125,
    display: 'flex',
    justifyContent: 'center',
  },
  typography: {
    maxWidth: 170,
  },
}));

export const PendingProvider = ({ children, defaultOptions = { text: 'Kaydediliyor...' } }) => {
  const classes = useStyles();
  const [pending, setPending] = useState(false)
  const [text, setText] = useState(defaultOptions.text);

  function handlePending(status, message = defaultOptions.text) {
    setPending(status);
    setText(message)
  }

  return <PendingContext.Provider value={handlePending}>
    <Dialog
      className={classes.root}
      open={pending}
      classes={{ paper: classes.paper }}
      backdropclick="true"
      disableEscapeKeyDown
      maxWidth="xs"
    >
      <DialogContent className={classes.content}>
        <CircularProgress size={80} thickness={3.3} />
      </DialogContent>
      <Typography
        variant="body2"
        align="center"
        display="inline"
        className={classes.typography}
      >
        {text}
      </Typography>
    </Dialog>
    {children}
  </PendingContext.Provider>
}
export const usePending = () => useContext(PendingContext);