import React, { useState, useEffect } from 'react';
import { useAsync, useErrorMessage } from '../../../../../../util/hooks';

import { getAllUserProblems } from '../../../../../../service/admin.service';

import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import UsersProjects from './UsersProjects';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  topButton: {
    paddingLeft: '10px', 
  }
}))

export default () => {
  const classes = useStyles();
  const [error, setError] = useState();
  const [userProblems, setUserProblems] = useState(null);
  const showMessage = useErrorMessage();
  const { status, value, error: networkErr } = useAsync(getAllUserProblems)

  useEffect(() => {
    if (status === 'success') {
      const { data, success, exception: apiError, message } = value;
      if (!success || apiError) {
        setError({ error: apiError, message })
      } else {
        setUserProblems(data)
      }
    }
    if (status === 'error') {
      setError(networkErr)
    }
  }, [status])


  useEffect(() => {
    if (error) {
      showMessage(error)
    }
  }, [error])

  return (
    <>
      <Box p={2}>
        {status === 'pending' && <Box className={classes.loadingContainer}><CircularProgress style={{ margin: 'auto' }} /></Box>}
        {error && <Typography primary='Hata! :(' />}
        {userProblems && <UsersProjects statUserProblems={status} valUserProblems={value} errUserProblems={networkErr}/>}
      </Box>
    </>
  )
}