import { useState } from 'react';
import CustomPagination from '../GlobalComponents/CustomPagination';

export default function usePagination({
  initialPageSize,
  rowNumber,
  inputEls
 }
) {
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageNumber, setMaxPageNumber] = useState(0);
  const initialPageNumber = 0;
  const [pageSize, setPageSize] = useState(initialPageSize - 1);
  const [isPaginationChanged, setPaginationChanged] = useState(true);
  const [noRowsOverlay, setNoRowsOverlay] = useState({});
  const [request, setRequest] = useState({
    ...inputEls,
    pageNumber: initialPageNumber,
    pageSize: initialPageSize,
  });
  const [rows, setRows] = useState([]);

  function handleChangePaginationClick() {
    setPaginationChanged((prevState) => !prevState);
  }

  const anotherPagination = isPaginationChanged && {
    Pagination: CustomPagination,
  };

  function handlePageChange(pageNumber) {
    setPageNumber(pageNumber);
  }

  function handlePageChangeV2(pageNumber) {
    setPageNumber(pageNumber);

    if (maxPageNumber < pageNumber) {
      setMaxPageNumber(pageNumber);
      if (pageNumber % (pageSize + 1) !== 0) {
        setRequest({ ...request, pageNumber, pageSize: pageSize + 1 });
      }
    }
  }

  function handlePageSizeChange(newPageSize) {
    setPageSize(newPageSize);
  }

  function handlePageSizeChangeV2(newPageSize) {
    setPageNumber(0);
    setMaxPageNumber(0);
    rowNumber = 1;
    setRows([]);
    setPageSize(newPageSize);
    setRequest({ ...request, pageNumber, pageSize: newPageSize + 1 });
  }

  return {
    pageNumber,
    setMaxPageNumber,
    setPageNumber,
    pageSize,
    initialPageNumber,
    noRowsOverlay,
    setNoRowsOverlay,
    anotherPagination,
    handleChangePaginationClick,
    handlePageChange,
    handlePageSizeChange,
    handlePageChangeV2,
    handlePageSizeChangeV2,
    request,
    setRequest,
    rows,
    setRows
  };
}
