import { useEffect, useState, useRef, useMemo } from 'react';
import { actions } from '../../../redux/slices/annotation';
import { useImageData } from '../Contexes/imageData';
import { useChange } from '../store';
import { useConfirm } from 'material-ui-confirm';
import { usePending } from '../../../GlobalComponents/Contexes/pending';
import { useSelector, useDispatch } from 'react-redux';
import { useMountedState } from 'react-use';
import { addCloudWatchLog } from '../../../service/cloud.service';

async function getImageElement(imageB64Promise) {
  const b64 = await imageB64Promise
  const im = new window.Image();
  im.src = "data:image/jpg;base64, " + b64
  return im
}

const getFetchData = (provider) => {
  async function fetchData(param, withIndex = false, withName = false) {
    try {
      const resData = await provider.fetchPreds(param, withIndex, withName);
  
      if (!resData) { console.log('resData', resData); return [null, null] }
      const imObj = await getImageElement(resData.imageB64Promise)

      return [resData, imObj]
    } catch (error) {
      throw error
    }
  }
  return [
    fetchData,
    provider.submit,
    provider.startId
  ]
}

function useAnnotStore() {
  const dispatch = useDispatch();

  const isSaveButtonClicked = useSelector(
    (state) => state.annotation.isSaveButtonClicked
  );
  const isPaintedImageChanged = useSelector(
    (state) => state.annotation.isPaintedImageChanged
  );

  return {
    isSaveButtonClicked,
    setSaveButtonClicked: (bool) =>
      dispatch(actions.setIsSaveButtonClicked(bool)),
    isPaintedImageChanged
  };
}

function useProviderStore() {
  const loading = useSelector(state => state.annotation.loading)
  const newBoxes = useSelector(state => state.annotation.newBoxes)
  const regionList = useSelector(state => state.annotation.regionList);
  const editableRegionList = useSelector(state => state.annotation.editableRegionList);
  const tool = useSelector(state => state.annotation.tool);

  const dispatch = useDispatch();

  return {
    loading,
    newBoxes,
    regionList,
    editableRegionList,
    tool,
    setLoading: (bool) => dispatch(actions.setLoading(bool)),
    putNewBox: (newBox) => dispatch(actions.putNewBox(newBox)),
    delNewBox: (newBox) => dispatch(actions.delNewBox(newBox)),
    setNewBoxes: (newBoxes) => dispatch(actions.setNewBoxes(newBoxes)),
    setNewRegionList: (regionList) => dispatch(actions.setNewRegionList(regionList)),
    setNewEditableRegionList: (editableRegionList) => dispatch(actions.setNewEditableRegionList(editableRegionList)),
  }
}

// TODO: Move this hook to imageData Context! (to ../Contexes/imageData)
export default function ImageProvider() {
  const isMounted = useMountedState();
  const [data, setData] = useState({});
  const [description, setDesc] = useState('')
  const imageRef = useRef(null);

  const setPending = usePending();

  const signChange = useChange(state => state.changed)
  const resetChanged = useChange(state => state.reset)
  const hasChanged = useChange(state => state.hasChanged)

  const setDescription = (desc) => { signChange(); setDesc(desc) }

  const { loading, newBoxes, setLoading, setNewBoxes, setNewRegionList, setNewEditableRegionList, regionList, editableRegionList } = useProviderStore();
  const { setSaveButtonClicked, isPaintedImageChanged } = useAnnotStore();
  
  const confirm = useConfirm();

  const dataProvider = useImageData();
  const [fetchData, submit] = useMemo(() => getFetchData(dataProvider), [dataProvider.id])

  function handleData(data) {
    setData(data)
    setNewBoxes(data.image.newBoxes)
    setNewRegionList(data.image.regionList)
    setNewEditableRegionList(data.image.editableRegionList || [])
    setDesc(data.image.description)
    resetChanged()
  }


  function askSubmit(callback) {
    setSaveButtonClicked(true);
    confirm({
      title: "Değişiklikler Mevcut",
      confirmationText: 'Evet',
      cancellationText: 'Hayır',
      confirmationButtonProps: { color: 'primary' },
      cancellationButtonProps: { color: 'secondary' },
      description: "Resmi değiştirmeden önce değişiklikleri kaydetmek istiyor musunuz?"
    })
      .then(() => submitChanges().then(callback))
      .catch(() => {
        resetChanged()
        callback()
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
      .then(([data, imObj]) => {
        if (!isMounted()) { return; }
        imageRef.current = imObj
        handleData(data);
        setLoading(false)
      })
      .catch((e) => { throw e })
  }, [dataProvider.id])

  const next = (checkChnge = true) => {
    if (checkChnge && hasChanged) {
      return askSubmit(() => next(false))
    }
    if (data.hasNext) {
      if (!isMounted()) { return; }
      setLoading(true)
      fetchData(data.nextId)
        .then(([data, imObj]) => {
          if (!isMounted()) { return; }
          imageRef.current = imObj
          handleData(data);
          setLoading(false)
        })
        .catch((e) => { throw e })
    } else {
      throw Error('Semantic Error!')
    }
  }

  const prev = (checkChnge = true) => {
    if (checkChnge && hasChanged) {
      return askSubmit(() => prev(false))
    }
    if (data.hasPrev) {
      if (!isMounted()) { return; }
      setLoading(true)
      fetchData(data.prevId)
        .then(([data, imObj]) => {
          if (!isMounted()) { return; }
          imageRef.current = imObj
          handleData(data);
          setLoading(false)
        })
        .catch((e) => { throw e })
    } else {
      throw Error('Semantic Error!')
    }
  }

  const jumpTo = (id, checkChnge = true) => {
    if (checkChnge && hasChanged) {
      return askSubmit(() => jumpTo(id, false))
    }
    setLoading(true)
    fetchData(id, true)
      .then(([data, imObj]) => {
        if (!isMounted()) { return; }
        if (data === null) {
          confirm({
            title: "Kayıt bulunamadı!",
            confirmationText: 'Tamam',
            confirmationButtonProps: { color: 'primary' },
            cancellationButtonProps: { style: { display: 'none' } },
            description: "Atlamak istediğiniz index'e ait bir kayıt yok!"
          })
        } else {
          imageRef.current = imObj
          handleData(data);
        }
        setLoading(false)
      })
      .catch((e) => { throw e })
  }

  const jumpToWithDrSays = (name, checkChnge = true) => {
    if (checkChnge && hasChanged) {
      return askSubmit(() => jumpToWithDrSays(name, false))
    }
    setLoading(true)
    fetchData(name, false, true)
      .then(([data, imObj]) => {
        if (!isMounted()) { return; }
        if (data === null) {
          confirm({
            title: "Kayıt bulunamadı!",
            confirmationText: 'Tamam',
            confirmationButtonProps: { color: 'primary' },
            cancellationButtonProps: { style: { display: 'none' } },
            description: "Atlamak istediğiniz path'e ait bir kayıt yok!"
          })
        } else {
          imageRef.current = imObj
          handleData(data);
        }
        setLoading(false)
      })
      .catch((e) => { throw e })
  }

  const mergePredictions = (region, bbox) => {
    let _predictionAddList = []
    let _regionPred = region ? region.filter(pr => pr.isValid).map(({ id }) => id) : []
    let _bboxPred = bbox ? bbox.filter(pr => pr.isValid).map(({ id }) => id) : []
    _predictionAddList = [..._regionPred, ..._bboxPred]
    return _predictionAddList
  }

  const submitChanges = async () => {
    const transformedEditableRegionList = editableRegionList.map(editableRegion => {
      return {...editableRegion, boxType: 2}
    });

    setPending(true)
    const newData = {
      imageStoragePath: data.image.imagePath,
      groundTruthDeleteList: data.image.bboxGT || data.image.regionGT || data.image.paintedImageGT ? await groundTruthDeleteLists() : [],
      predictionAddList: mergePredictions(data.image.regionPred, data.image.bboxPred),
      newBoxes,
      regionList: [...regionList, ...transformedEditableRegionList],
      description: data.image.description ? null : '',
      paintedImageList: [
        {
          paintedImagePath: data.image.paintedImagePath,
          labelId: 31,
          flags: [],
        }
      ]
    }
    if (description) { newData['description'] = description }

    try {
      await submit(newData)
      resetChanged()
    } catch (e) {
      addCloudWatchLog(`GetImageSources.js:65 ${e}`);
      console.error(e)
      if (!isMounted()) { return; }
      confirm({
        title: "Değişiklikler kaydedilemedi!",
        confirmationText: 'Tamam',
        confirmationButtonProps: { color: 'primary' },
        cancellationButtonProps: { style: { display: 'none' } },
        description: "Ayrıntılar için konsola bakın."
      })
    } finally {
      setPending(false)
    }
  }

  const groundTruthDeleteLists = async () => {
    let _gtList = []
    
    let _bboxGT = data.image.bboxGT.filter(gt => !gt.isValid).map(({ id }) => id)
    let _polyGT = data.image.regionGT.filter(gt => !gt.isValid).map(({ id }) => id)
    let _paintedImageGT = data.image.paintedImageGT.filter(() => !isPaintedImageChanged).map(({ id }) => id)

    return _gtList.concat(_bboxGT, _polyGT, _paintedImageGT)

  }

  const handleComplete = (currentIndex, totalCount, setCompleteBtnActive, isComplete) => {
    setCompleteBtnActive(false);
    if (currentIndex === totalCount) {
      setCompleteBtnActive(!isComplete);
    }
  };

  const evaluateDRDME = { evaluate: dataProvider.evaluateDrDme, canEvaluate: dataProvider.canEvaluate, storagePath: data.image?.imagePath }
  return { ...data, submitChanges, next, prev, jumpTo, jumpToWithDrSays, description, setDescription, evaluateDRDME, loading, imObj: imageRef.current, handleComplete }
}