import { combineReducers } from 'redux';
import configureStore from './configureStore';
import { user, reducer as userReducer } from './slices/user';
import { annotation, reducer as annotReducer } from './slices/annotation';
import { admin, reducer as adminReducer } from './slices/admin';
import { layout, reducer as layoutReducer } from './slices/layout';

const initialState = {
  user,
  annotation,
  admin,
  layout
}

const reducers = combineReducers({
  user: userReducer,
  annotation: annotReducer,
  admin: adminReducer,
  layout: layoutReducer
})

export default configureStore(reducers, initialState)
