import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { actions } from '../redux/slices/admin';
import { addCloudWatchLog } from '../service/cloud.service';

export default function useSubmit(fn, requestType = null, enumKey = null) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`useSubmit.js:17 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`useSubmit.js:31 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await fn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      if (requestType === 'add' || requestType === 'deleteOther') {
        dispatch(actions.updateEnum({ enumKey, data }));
      } else if (requestType === 'delete') {
        dispatch(actions.deleteEnum({ enumKey, data: _data }));
      }
    }
    return { data, success, error, message };
  }
  return {submit, showMessage};
}
