import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@material-ui/core";

export default ({open, onClose, text}) => {
    const boxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4
    };

    return (
        <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title">
            <Box sx={boxStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {text}
                </Typography>
            </Box>
        </Modal>
    );
}