import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import getAlternateStyle, { getColor } from '../../CommonStyles/AlternateColor';
import CustomizedAutoComplete from '../../../../GlobalComponents/CustomizedAutoComplete';
import {
  getFilterItems,
  getImageQueriesPageByPage,
  getAllImageQueries,
  addImageToDataset,
  generateVisJsonFromImagePaths,
  getProjectListForSelection,
} from '../../../../service/admin.service';
import useSubmitWithPagination from '../../../../util/useSubmitWithPagination';
import { useSelector } from 'react-redux';
import useDownload from '../../../../util/useDownload';
import useSubmit from '../../../../util/useSubmit';
import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import { useDispatch } from 'react-redux';
import { actions } from '../../../../redux/slices/admin';
import { useConfirm } from 'material-ui-confirm';
import { addCloudWatchLog } from '../../../../service/cloud.service';
import { useErrorMessage } from '../../../../util/hooks';

const useStyles = makeStyles((theme) => ({
  imageQueryRoot: {
    width: '75%',
    height: '37.8%',
    padding: '60px 0 0 100px',
  },
  searchContainer: {
    position: 'relative',
    bottom: '2.5rem',
    left: '7.5rem',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '3rem',
    '@media (max-width: 1300px)': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  inputEl: {
    width: '30%',
  },
  labelEl: {
    position: 'relative',
    top: '2.42rem',
    right: '7.5rem',
  },
  searchButton: {
    position: 'relative',
    bottom: '8rem',
    left: '33rem',
  },
  datasetLabel: {
    position: 'relative',
    top: '3rem',
    right: '17rem',
  },
  userEl: {
    position: 'relative',
    top: '50rem',
    right: '11rem',
  },
  changePagination: {
    position: 'relative',
    bottom: '2.8rem',
    left: '1rem',
    '@media (max-width: 1300px)': {
      position: 'relative',
      bottom: '19.2rem',
    },
  },
  visJsonBtn: {
    position: 'relative',
    left: '20rem',
    bottom: '3rem',
  },
  datasetBtn: {
    position: 'relative',
    top: '0.7rem',
    left: '2rem',
  },
  leftBottomPart: {
    paddingBottom: '3rem',
  },
  imageListBtn: {
    position: 'relative',
    bottom: '11.6rem',
    left: '48.3rem',
  },
}));

function useAddImagetoDatasetFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const addImagetoDatasetFn = addImageToDataset;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      console.log(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`GetImageQueries.js:113 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await addImagetoDatasetFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      const updatedDataset = { ...data, imagePathList: _data.imagePathList };
      dispatch(
        actions.updateEnum({ enumKey: 'datasets', data: updatedDataset })
      );
    }
    return { data, success, error, message };
  }
  return submit;
}

export default function GetImageQueries() {
  const classes = useStyles();
  const [userProblemIds, setUserProblemIds] = useState([]);
  const [userProblems, setUserProblems] = useState([]);
  const [labelIds, setLabelIds] = useState([]);
  const [labels, setLabels] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [excludeDescriptions, setExcludeDescriptions] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [datasetId, setDatasetId] = useState('');
  const [isBtnActive, setBtnActive] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [areAllRowsSelected, setAllRowsSelected] = useState([]);
  const pending = usePending();
  const displayMessage = useErrorMessage();
  const { byId: users } = useSelector((state) => state.admin.enums.users);
  const usersArray = Object.values(users);

  useEffect(() => {
    let isMounted = true;

    async function getUserProblemData() {
      const { data } = await getProjectListForSelection();
      const nonNullData = data.filter((item) => item.description);
      setUserProblems(nonNullData);
    }
    getUserProblemData();

    return () => {
      isMounted = false;
    };
  }, []);

  const { byId: datasets } = useSelector((state) => state.admin.enums.datasets);
  const addImagetoDatasetFn = useAddImagetoDatasetFunction();

  const initialPageSize = 11;
  const getFn = getImageQueriesPageByPage;
  const inputEls = { userProblemIds, labelIds, excludeDescriptions };
  const DATA_SENDING_LIMIT = 30000;
  const DATA_GETTING_LIMIT = 10000;
  const {
    rows,
    handlePageChangeV2,
    submit,
    handlePageSizeChangeV2,
    handleChangePaginationClick,
    pageNumber,
    pageSize,
    noRowsOverlay,
    anotherPagination,
    loading,
    setLoading,
    arrangeData,
    totalNumberofElements,
  } = useSubmitWithPagination({ initialPageSize, getFn, inputEls });

  const { submit: generateVisJsonFromImagePathsFn, showMessage } = useSubmit(
    generateVisJsonFromImagePaths
  );

  const download = useDownload();

  const columns = [
    {
      field: 'storagePath',
      headerName: 'Storage Path',
      flex: 1,
    },
  ];

  async function handleUserProblemIdsChange(event, values) {
    const _userProblemIds = values.map((val) => val.userProblemId);
    setUserProblemIds(_userProblemIds);

    setLoading(true);
    pending(true, 'Getiriliyor...');

    const { data } = await getFilterItems(_userProblemIds);
    setLabels(data.labels);
    const descriptionIds = getDescriptionIds(data.descriptions.length);
    const arrangedDescriptions = arrangeDescriptions(
      data.descriptions,
      descriptionIds
    );
    setDescriptions(arrangedDescriptions);
    setLoading(false);
    pending(false);
  }

  function getDescriptionIds(length) {
    const arr = [];
    for (let i = 0; i < length; i++) {
      arr.push(i);
    }
    return arr;
  }

  function arrangeDescriptions(descriptions, ids) {
    const arrangedDescriptions = [];

    for (let i = 0; i < descriptions.length; i++) {
      arrangedDescriptions.push({
        ...descriptions[i],
        id: ids[i],
      });
    }
    return arrangedDescriptions;
  }

  function handleLabelIdsChange(event, values) {
    const _labelIds = values.map((val) => val.labelId);
    setLabelIds(_labelIds);
  }

  function handleExcludeDescriptionsChange(event, values) {
    const _excludeDescriptions = values.map((val) => val.description);
    setExcludeDescriptions(_excludeDescriptions);
  }

  function handleUserIdsChange(event, values) {
    const _userIds = values.map((val) => val.id);
    setUserIds(_userIds);
  }

  function handleDatasetIdChange(e) {
    setDatasetId(e.target.value);
  }

  function dispartData(paths, partitionSize, index) {
    const pathLength = paths.length;
    const startingIndex = index * DATA_SENDING_LIMIT;
    const endIndex = (index + 1) * DATA_SENDING_LIMIT;
    let slicedArray = [];

    if (index === partitionSize - 1 && endIndex !== pathLength) {
      slicedArray = paths.slice(startingIndex, pathLength);
    } else {
      slicedArray = paths.slice(startingIndex, endIndex);
    }

    return slicedArray;
  }

  async function submitImagesToDataset(e) {
    e.preventDefault();

    setLoading(true);
    pending(true, 'Saving...');

    const selectedRows = areAllRowsSelected
      ? allRows
      : rows.filter((row) => selectionModel.includes(row.id));
    const imagePathList = [];
    selectedRows.map((sr) => imagePathList.push(sr.storagePath));

    const partitionSize = imagePathList.length / DATA_SENDING_LIMIT + 1;

    for (let i = 0; i < partitionSize; i++) {
      const slicedArray = dispartData(imagePathList, partitionSize, i);

      const request = { datasetId, imagePathList: slicedArray };

      if (slicedArray.length) {
        await addImagetoDatasetFn(request);
      }
    }

    setLoading(false);
    pending(false);
  }

  async function handleAllRowsDownloadImageList() {
    const storagePaths = [];
    allRows.map((r) => storagePaths.push(r.storagePath));
    let combinedData = { data: [], flagMap: {}, labelMap: {} };

    // 0, 10000       i*DATA_SENDING_LIMIT    (i+1) * DATA_SENDING_LIMIT
    // 10000, 20000
    // 20000, 30000
    // 30000, 32000 - 40000
    const partitionSize = storagePaths.length / DATA_SENDING_LIMIT + 1;

    for (let i = 0; i < partitionSize; i++) {
      const slicedArray = dispartData(storagePaths, partitionSize, i);

      if (slicedArray.length) {
        const { data: slicedData } = await generateVisJsonFromImagePathsFn({
          imagePaths: slicedArray,
          userIds,
        });

        combinedData = {
          data: [...combinedData.data, ...slicedData.data],
          flagMap: { ...combinedData.flagMap, ...slicedData.flagMap },
          labelMap: { ...combinedData.labelMap, ...slicedData.labelMap },
        };
      }
    }

    download(`ImageVisJson.json`, JSON.stringify(combinedData));
  }

  async function handleSpecificRowsDownloadImageList() {
    const storagePaths = [];
    const selectedRows = rows.filter((row) => selectionModel.includes(row.id));
    selectedRows.map((r) => storagePaths.push(r.storagePath));
    const { data } = await generateVisJsonFromImagePathsFn({
      imagePaths: storagePaths,
      userIds,
    });
    download(`ImageVisJson.json`, JSON.stringify(data));
  }

  async function handleDownloadClicks(e) {
    setLoading(true);
    pending(true, 'Downloading...');

    const innerHTML = e.target.innerHTML;

    if (innerHTML === 'Download Image VisJson') {
      if (areAllRowsSelected) {
        await handleAllRowsDownloadImageList();
      } else {
        await handleSpecificRowsDownloadImageList();
      }
    } else if (innerHTML === 'Download Image List') {
      const storagePaths = [];
      const selectedRows = areAllRowsSelected
        ? allRows
        : rows.filter((row) => selectionModel.includes(row.id));
      selectedRows.map((sr) => storagePaths.push(sr.storagePath));
      download(`ImageList.json`, JSON.stringify(storagePaths));
    }

    setLoading(false);
    pending(false);
  }

  async function handleCheckBoxClicks(selectedIds) {
    const isAllSelected = selectedIds.length === rows.length;
    setAllRowsSelected(isAllSelected);

    if (isAllSelected) {
      if (totalNumberofElements <= DATA_GETTING_LIMIT) {
        setLoading(true);
        pending(true, 'Getiriliyor...');

        const request = { userProblemIds, labelIds, excludeDescriptions };
        const response = await getAllImageQueries(request);
        let arrangedData = arrangeData(response.data);
        setAllRows(arrangedData);
      } else {
        displayMessage({
          title: 'Data Getting Limit Exceeded',
          message:
            'You can only get data with a limit of ' +
            DATA_GETTING_LIMIT +
            ' images.',
        });
        return;
      }
    }

    setLoading(false);
    pending(false);

    setBtnActive(false);

    if (selectedIds.length) {
      setBtnActive(true);
    }

    setSelectionModel(selectedIds);
  }

  return (
    <Box className={classes.imageQueryRoot}>
      <Box
        className={classes.searchContainer}
        component='form'
        autoComplete='off'
        onSubmit={submit}
      >
        <InputLabel className={classes.labelEl}>Project Name</InputLabel>
        <CustomizedAutoComplete
          id='id'
          label='Select'
          showField='description'
          width={500}
          data={userProblems}
          handleChange={handleUserProblemIdsChange}
        />
        <InputLabel className={classes.labelEl}>Class</InputLabel>
        <CustomizedAutoComplete
          id='labelId'
          label='Select'
          showField='labelName'
          width={500}
          data={labels}
          handleChange={handleLabelIdsChange}
        />
        <InputLabel className={classes.labelEl}>Comment</InputLabel>
        <CustomizedAutoComplete
          id='id'
          label='Select'
          showField='description'
          width={500}
          data={descriptions}
          handleChange={handleExcludeDescriptionsChange}
        />
        <Button
          className={classes.searchButton}
          variant='contained'
          color='primary'
          type='submit'
        >
          Search
        </Button>
      </Box>
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
          ...noRowsOverlay,
          ...anotherPagination,
        }}
        loading={loading}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={handlePageSizeChangeV2}
        disableSelectionOnClick
        checkboxSelection
        onSelectionModelChange={handleCheckBoxClicks}
        selectionModel={selectionModel}
        page={pageNumber}
        onPageChange={handlePageChangeV2}
        sx={getAlternateStyle}
        getRowClassName={(params) => {
          return getColor(params.row.rowNumber);
        }}
      />
      <Button
        className={classes.changePagination}
        variant='contained'
        color='primary'
        onClick={handleChangePaginationClick}
      >
        Change Pagination
      </Button>
      <Box className={classes.leftBottomPart}>
        <CustomizedAutoComplete
          id='id'
          label='User'
          showField='userName'
          width={292}
          data={usersArray}
          handleChange={handleUserIdsChange}
        />
        <Button
          className={classes.visJsonBtn}
          variant='contained'
          color='primary'
          disabled={!isBtnActive}
          type='button'
          onClick={handleDownloadClicks}
        >
          Download Image VisJson
        </Button>
        <Box
          component='form'
          autoComplete='off'
          onSubmit={submitImagesToDataset}
        >
          <FormControl className={classes.inputEl} required>
            <InputLabel id='select-dataset-label'>Select Dataset</InputLabel>
            <Select
              labelId='select-dataset-label'
              value={datasetId}
              label='Select Dataset'
              onChange={handleDatasetIdChange}
            >
              {Object.keys(datasets).map(function (key) {
                const dataset = datasets[key];
                return (
                  <MenuItem key={key} value={dataset.id}>
                    {dataset.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            className={classes.datasetBtn}
            variant='contained'
            color='primary'
            disabled={!isBtnActive}
            type='submit'
          >
            Add Images to Dataset
          </Button>
        </Box>
      </Box>
      <Button
        className={classes.imageListBtn}
        variant='contained'
        color='primary'
        disabled={!isBtnActive}
        type='button'
        onClick={handleDownloadClicks}
      >
        Download Image List
      </Button>
    </Box>
  );
}
