import React, { useEffect, useState, createContext } from 'react';
import { DataGrid, GridFilterListIcon } from '@mui/x-data-grid';
import { useAsync } from '../../../../../../util/hooks'
import { deleteProjectById } from '../../../../../../service/admin.service';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress } from '@mui/material';
import UserProblemImagesList from './UserProblemImagesList';
import { Box, Button, Grid, makeStyles} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { usePending } from '../../../../../../GlobalComponents/Contexes/pending';
import getAlternateStyle, {getColor} from '../../../../CommonStyles/AlternateColor';
import CustomPagination from '../../../../../../GlobalComponents/CustomPagination';
import CustomNoRowsOverlay from '../../../../../../GlobalComponents/CustomNoRowsOverlay';
import AnnotateBox from '../../../../../../GlobalComponents/AnnotateBox';
import { addCloudWatchLog } from '../../../../../../service/cloud.service';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  topButton: {
    paddingLeft: '10px', 
  },
  changePagination: {
    position: 'relative',
    bottom: '2.8rem',
    left: '1rem',
  },
}))

export const UserProblemsContext = createContext([]);

export default function UsersProjects({statUserProblems, valUserProblems, errUserProblems}) {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [noRowsOverlay, setNoRowsOverlay] = useState({});
    const pending = usePending()
    const [activeCrudButton, setActiveCrudButton] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState([]);
    const [isPaginationChanged, setPaginationChanged] = useState(true);
    const [pageSize, setPageSize] = useState(10);

    const [usersProjects, setUsersProjects] = useState([]);
    const [filterImages, setFilterImages] = React.useState({ userProblemId: null, open: false });
    //const {status: statUserProblems, value: valUserProblems, error: errUserProblems} = useAsync(getAllUserProblems)

    const [open, setOpen] = useState(false);
    const [userPId,setUserPId] =useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [willDeleteId, setWillDeleteId] = React.useState(false);

    const handleClickOpenDialog = (id) => {
      setWillDeleteId(id);
      setOpenDialog(true);

    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    function getUserProblemId(upi){
      setUserPId(upi)
      setOpen(true)
      setOpenDialog(false)
      // overWriteGT(upi)
    }

    const deleteProject = async (e) => {
      e.preventDefault();
      if (loading) {
        return;
      }
      setLoading(true);
      pending(true, 'Kaydediliyor...');
      const { success } = await deleteProjectById(selectedLabel[0])
      setLoading(false);
      pending(false);
      if(success) {
        setUsersProjects(prevUps => prevUps.filter(e => e.id !== selectedLabel[0][0]))
        setSelectedLabel([])
      }

    }

    const openProject = async (e) => {
      alert('proje ekranına geçiş yapılacak, şu andaki yapı izin vermiyor. admin ile user kontrolleri düzenlenecek.')
    }

    function selectedRows(rows) {
      if (rows.length === 1) {
        setSelectedLabel([rows]);
        setActiveCrudButton(true);
      } else {
        setSelectedLabel([]);
        setActiveCrudButton(false);
      }
      // setWillDeleteLabel(rows) // Toplu silmek için
    }
    useEffect(() => {
      setNoRowsOverlay({});
      setLoading(true);
        if (statUserProblems === 'success') {
          const { data, success, exception: apiError, message } = valUserProblems;
          setLoading(false)
          if (!success || apiError) {
            addCloudWatchLog(`UsersProjects.js:116 ${apiError} ${message}`);
            console.error({ error: apiError, message })
          } else {
            data.map((d, index) => (setUsersProjects((prevVal) => [...prevVal, {...d, rowNumber: index + 1}])))
          }
        }
        if (statUserProblems === 'error') {
          addCloudWatchLog(`UsersProjects.js:123 ${errUserProblems}`);
          console.error(errUserProblems)
        }
        if(valUserProblems.length===0){
          setNoRowsOverlay({NoRowsOverlay: CustomNoRowsOverlay})
        }
      }, [statUserProblems])

    function onListImageFilterClick(userProblemId) {
      setFilterImages({ userProblemId, open: true })
    }

    const columns = [
      { 
        field: 'id', 
        headerName: 'ID', 
        width: 90,
      },
      {
        field: 'userName',
        headerName: 'Username',
        width: 150,
        editable: true,
      },
      {
        field: 'problemCategory',
        headerName: 'Project Subcategory',
        width: 250,
        editable: true,
      },
      {
        field: 'createDate',
        headerName: 'Create Date',
        type: 'date',
        width: 125,
        editable: true,
      },
      {
        field: 'description',
        headerName: 'Project Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 200,
      },
      {
        field: 'totalAnswered',
        headerName: 'Total Answered',
        sortable: false,
        width: 200,
        renderCell: (params) => {
            return (
                `${params.row.totalAnswered} / ${params.row.totalCount}`
            )
        },
      },
      {
        field: 'edit',
        headerName: 'Edit',
        width: 100,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <Button style={{width: '10px'}} id={'get-projects-edit-' + params.row.id} to={'/admin/projects/' + params.row.id} component={Link} variant='contained' size='small'>
                EDIT
            </Button>
          );
        },
      },
      {
        field: 'isActive',
        headerName: 'Active?',
        sortable: false,
        align: 'center',
        width: 100,
        renderCell: (params) => {
            return (
                `${params.row.active ? '✅' : '⬜'}`
            )
        }
      },
      {
        field: 'completed',
        headerName: 'Completed',
        sortable: false,
        align: 'center',
        width: 100,
        renderCell: (params) => {
            return (
              `${params.row.completed ? '✅' : '⬜'}`
            )
        }
      },
      {
        field: 'groundTruthsHaveOverwritten',
        headerName: 'GT?',
        sortable: false,
        width: 100,
        renderCell: (params) => {
            return (
              <Button id='admin-gt-checkbox' onClick={() => handleClickOpenDialog(params.row.id)}>
                {params.row.groundTruthsHaveOverwritten ? '✅' : '⬜'}
              </Button>
            )
        }
      },
      {
        field: 'imageList',
        headerName: 'Image List',
        sortable: false,
        width: 100,
        renderCell: (params) => {
            return (
              <IconButton size='small' onClick={() => onListImageFilterClick(params.row.id)}>
                <GridFilterListIcon />
              </IconButton>
            )
        }
      },
    ];

    const anotherPagination = isPaginationChanged && { Pagination: CustomPagination };

  function handleConfirmClick() {
    getUserProblemId(willDeleteId);
  
    setUsersProjects(prevUps => {
      const newState = prevUps.map(uP => {
        if(!uP.groundTruthsHaveOverwritten && uP.id === willDeleteId) {
          return {...uP, groundTruthsHaveOverwritten: !uP.groundTruthsHaveOverwritten};
        }
        return uP;
      })
      return newState;
    });
  }

  return (
    <UserProblemsContext.Provider value={usersProjects}>
      <Box p={2} pt={5} display='flex' justifyContent='flex-end'>
        <Grid className={classes.topButton}>
          <Button to='/admin/projects/create' component={Link} id='admin-create-project' variant='contained' size='small'>Create Project</Button>
        </Grid>
        {/* <Grid  className={classes.topButton}>
          <Button disabled={!activeCrudButton} to='/admin/projects/project/:id' variant='contained' color='primary' size='small'>Edit Project</Button>
        </Grid> */}
        <Grid  className={classes.topButton}>
          <Button disabled={!activeCrudButton} onClick={deleteProject} id='admin-delete-project' variant='contained' color='secondary' size='small'>Delete Project</Button>
        </Grid>
        {/* <Grid  className={classes.topButton}>
          <Button variant='contained' color='primary'>Ground Truth</Button>
        </Grid> */}
        <Grid  className={classes.topButton}>
          <Button disabled={!activeCrudButton} onClick={openProject} id='admin-open-project' variant='contained' color='primary' size='small'>Open Project</Button>
        </Grid>
      </Box>
      <div style={{ height: '80vh', width: '100%' }}>
        <DataGrid
          components={{
          LoadingOverlay:LinearProgress,
          ...noRowsOverlay,
          ...anotherPagination,
          }}
          loading = {loading}
          rows={usersProjects}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10,25,50]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          checkboxSelection
          onSelectionModelChange={selectedRows}
          sx={getAlternateStyle}
          getRowClassName={(params) => {return getColor(params.row.rowNumber)}}
        />
          <UserProblemImagesList
            key={`user-problem-image-filter-list-${filterImages.userProblemId}`}
            {...filterImages}
            onClose={() => setFilterImages({ userProblemId: null, open: false })}
          />
          <Button
          className={classes.changePagination}
          variant='contained'
          color='primary'
          onClick={() => setPaginationChanged((prevState) => !prevState)}
        >
          Change Pagination
        </Button>
        {/* <AnnotateBox open={open} userProblemId={userPId} onClose={handleClose}></AnnotateBox> */}
      </div>
      <div>
      <AnnotateBox open={open} userProblemId={userPId} onClose={handleClose}></AnnotateBox>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Warning!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            This will initiate the "Ground Truth" process for the project. Do you confirm?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id='close-gt-write' onClick={handleCloseDialog}>Cancel</Button>
            <Button id='confirm-gt-write' onClick={handleConfirmClick}
              autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </UserProblemsContext.Provider>
  );
}