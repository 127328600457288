import React, { useState, useEffect, useCallback } from 'react';
import {
Drawer,
  IconButton,
  makeStyles,
  Box,
  Slider,
  Grid,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Popper,
  ClickAwayListener,
  FormControl,
  Input,
  InputAdornment,
  Grow,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Divider,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import { auth } from '../../../service/auth.service';
import DownloadIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';

import { useImageData } from '../Contexes/imageData';

import { actions } from '../../../redux/slices/annotation';
import { actions as act } from '../../../redux/slices/user';
import { useSelector, useDispatch } from 'react-redux';
import ImageSearchBox from './ImageSearchBox';
import { useChange } from '../store';
import { useImageMask } from '../Contexes/imageMask';
import { usePending } from '../../../GlobalComponents/Contexes/pending';

import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SettingsIcon from '@material-ui/icons/Settings';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import { s3ClientGetObject } from '../../../service/s3.service';
import PathInput from './PathInput';
import DescriptionBox from './DescriptionBox';

import { useConfirm } from 'material-ui-confirm';
import { useLabelMap } from '../store/labelMapStore';
import { useAsync } from '../../../util/hooks';
import { useOptions } from '../../../GlobalComponents/Contexes/options';
import { setCompleted } from '../../../../src/service/annotate.service';
import { addCloudWatchLog } from '../../../service/cloud.service';

const drawerWidth = 240;
const DUMMY_CAT_ID = 5;
const tabletMode = window.innerWidth < 1024;

const useStyles = makeStyles((theme) => ({
  drawerButton: {
    top: '.4rem',
    position: 'fixed',
    zIndex: 99,
    transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
    }),
  },
  buttonClose: {
    backgroundColor: 'rgb(245 245 245)',
    '&:hover': {
      backgroundColor: 'rgb(216 216 216)',
    },
    borderRadius: '10rem 0 0 10rem',
    marginLeft: '-2.5rem',
      },
  buttonOpen: {
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.54)',
    },
    color: 'rgb(245 245 245)',
    borderRadius: '0 10rem 10rem 0',
    marginLeft: 0,
  },
  paper: {
    borderLeft: 0, //'1px solid rgba(0, 0, 0, 0.1)'
      },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'visible',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0), //theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1,
    // },
  },
  content: {
    backgroundColor: theme.palette.background.default,
    paddingTop: '3.5rem',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
    maxWidth: drawerWidth,
    flexShrink: -2,
  },
}));

function useAnnotStore() {
  const dispatch = useDispatch();
  //TODO: Use comparision function for selector
  const tool = useSelector((state) => state.annotation.tool);
  const editablePolyBoxCoefficient = useSelector(
    (state) => state.annotation.editablePolyBoxCoefficient
  );
  const numberOfPoints = useSelector(
    (state) => state.annotation.numberOfPoints
  );
  const pointFrequency = useSelector(
    (state) => state.annotation.pointFrequency
  );
  const lineThickness = useSelector(
    (state) => state.annotation.lineThickness
  );
  const isEditablePolygonalBoxFiltersDisabled = useSelector(
    (state) => state.annotation.isEditablePolygonalBoxFiltersDisabled
  );

  const fixedBBoxMode = useSelector(
    (state) => state.annotation.fixedBoundingBoxMode
  );

  const fixedBboxCoefficient = useSelector(
    (state) => state.annotation.fixedBoundingBoxCoefficient
  );


  return {
    setEditablePolyBoxCoefficient: (val) =>
      dispatch(actions.setEditablePolyBoxCoefficient(val)),
    setNumberOfPoints: (number) => dispatch(actions.setNumberOfPoints(number)),
    setPointFrequency: (number) => dispatch(actions.setPointFrequency(number)),
    setLineThickness: (number) => dispatch(actions.setLineThickness(number)),
    setFixedBboxCoefficient: (val) =>
      dispatch(actions.setFixedBboxCoefficient(val)),
    tool,
    editablePolyBoxCoefficient,
    numberOfPoints,
    pointFrequency,
    lineThickness,
    isEditablePolygonalBoxFiltersDisabled,
    fixedBBoxMode,
    fixedBboxCoefficient
  };
}

export default function RightSidebar({ contentProps, ...props }) {
  const [open, setOpen] = useState(tabletMode ? false : true);
  const classes = useStyles();
  const {
    next,
    prev,
    currentIndex,
    totalCount,
    handleComplete,
    nextDisabled,
    prevDisabled,
  } = contentProps.pagination;
  const [isCompleteBtnActive, setCompleteBtnActive] = useState(false);
  const initialIsActive = totalCount === 1 ? true : false;
  const [isComplete, setComplete] = useState(false);

  contentProps = {
    ...contentProps,
    isCompleteBtnActive,
    setCompleteBtnActive,
    initialIsActive,
    isComplete,
    setComplete,
  };

  function listenKeydownEvents(e) {
    if (e.key === 'ArrowRight' && !nextDisabled) {
      next();
      handleComplete(
        currentIndex + 1,
        totalCount,
        setCompleteBtnActive,
        isComplete
      );
    } else if (e.key === 'ArrowLeft' && !prevDisabled) {
      prev();
      setCompleteBtnActive(false);
    }
  }

  useEffect(() => {
    window.document.addEventListener('keydown', listenKeydownEvents);
    return () => {
      window.document.removeEventListener('keydown', listenKeydownEvents);
    };
  }, [contentProps]);

  return (
    <div>
      {tabletMode && (
        <IconButton
          variant='contained'
          id='drawer-tabletMode'
          className={clsx(classes.drawerButton, {
            [classes.buttonOpen]: open,
            [classes.buttonClose]: !open,
          })}
          style={open ? {display: 'none'} : {display: 'block', right: '0', top: '.4rem'}}
          onClick={() => setOpen(!open)}
        >
          {!open ? <MenuOpenIcon /> : ""}
        </IconButton>
      )}
      
      <Drawer
        anchor='right'
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <IconButton
          variant='contained'
          id='drawer'
          className={clsx(classes.drawerButton, {
            [classes.buttonOpen]: open,
            [classes.buttonClose]: !open,
          })}
          onClick={() => setOpen(!open)}
        >
          {open ? <ArrowRightIcon /> : <MenuOpenIcon />}
        </IconButton>
        <Content {...contentProps} classes={{ root: classes.content }} />
      </Drawer>
    </div>
  );
}

const useUser = () => {
  const dispatch = useDispatch();
  const panelType = useSelector((state) => state.user.panelType);
  const problem = useSelector((state) => state.user.problem);

  return {
    problem,
    panelType,
    setProblem: (newUserProblem) => dispatch(act.setProblem(newUserProblem)),
  };
};

function Content({
  pagination,
  details,
  filter,
  save,
  evaluateDRDME,
  searchBox,
  selecteds,
  config,
  snapshot,
  classes,
  setKeyEventsActive,
  isCompleteBtnActive,
  setCompleteBtnActive,
  initialIsActive,
  isComplete,
  setComplete,
  setSaveButtonClicked,
  ...props
}) {
  const hasChanged = useChange((state) => state.hasChanged);
  const { setProblem } = useUser();
  const { problemCategoryId, userProblemId, completed } = useSelector(
    (state) => state.user.problem
  );
  const [path, setPath] = useState('');
  const { maskImg, setShowMask, setMaskImg } = useImageMask();

  function clearMask() {
    setShowMask(false);
    setMaskImg(null);
  }
  const {
    tool,
    editablePolyBoxCoefficient,
    setEditablePolyBoxCoefficient,
    numberOfPoints,
    pointFrequency,
    lineThickness,
    setNumberOfPoints,
    setPointFrequency,
    setLineThickness,
    isEditablePolygonalBoxFiltersDisabled,
    fixedBBoxMode,
    setFixedBboxCoefficient,
    fixedBboxCoefficient
  } = useAnnotStore();

  useEffect(() => {
    setComplete(completed);
    setCompleteBtnActive(!completed && initialIsActive);
  }, [completed, initialIsActive]);
  const setPending = usePending();
  const options = useOptions();

  const confirm = useConfirm();

  async function handleImageUrl() {
    setPending(true, 'Maske Yükleniyor...');
    let bucket;
    let key;

    if (path === '' || path === null || path === undefined) {
      bucket = details.imagePath.split('/').slice(2, 3)[0];
      key = details.imagePath.split('/').slice(3);
      key[0] = 'od_colormap';
      key = key.join('/');
    } else if (path.includes('s3://')) {
      bucket = path.split('/').slice(2, 3)[0];
      key = path.split('/').slice(3).join('/');
    } else {
      bucket = details.imagePath.split('/').slice(2, 3)[0];
      key = details.imagePath.split('/').slice(3);
      key[0] = path;
      key = key.join('/');
    }

    const im = new window.Image();

    s3ClientGetObject(bucket, key)
      .then((data) => {
        im.src = data;
        setMaskImg(im);
        setShowMask(false);
        setPending(false);
      })
      .catch(() => {
        setPending(false);
      });
  }

  useEffect(() => {
    handleImageUrl();
  }, [details.imagePath]);
  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`RightSidebar.js:243 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`RightSidebar.js:257 ${e}`);
        console.error(e);
      });
  }

  async function handleCompleteClick() {
    const { data, success, error, message } = await setCompleted(userProblemId);
    if (!success || error) {
      showMessage({ message, error });
    } else {
      setProblem({ problemCategoryId, userProblemId, completed: data });
      showMessage({
        title: 'Succesfull',
        message: 'Problem was successfully completed!',
      });
    }
  }

  /*
    FIXME: This is a temporary solution for activating visJson. 
    userProblemId 0 or -1 means that we are in visJson page.
    Find a better way.
  */
  function handleActivateVisJsonClick() {
    if(userProblemId === 0) {
      setProblem({ userProblemId: -1, problemCategoryId: DUMMY_CAT_ID })
      return;
    }
    setProblem({ userProblemId: 0, problemCategoryId: DUMMY_CAT_ID })
  }

  return (
    <div className={classes.root}>
      <Tooltip title='Exit'>
        <IconButton
          id='exit'
          onClick={() => auth.logout()}
          style={{ position: 'absolute', right: 5, top: 5 }}
        >
          <ExitToAppIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Settings'>
        <IconButton
          id='settings'
          onClick={() => {
            setKeyEventsActive(false);
            options(true, () => setKeyEventsActive(true));
          }}
          style={{ position: 'absolute', right: 50, top: 5 }}
        >
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Activate Vis-Json'>
        <IconButton
          id='activateVis-JsonForm'
          onClick={handleActivateVisJsonClick}
          style={{ position: 'absolute', right: 95, top: 5 }}
        >
          <CallMissedOutgoingIcon />
        </IconButton>
      </Tooltip>
      <Box component='fieldset' borderRadius={5}>
        <legend>Detaylar</legend>
        <Box
          style={{ backgroundColor: '#f0f0f0' }}
          id='imagePath' 
          onClick={() => navigator.clipboard.writeText(details.imagePath)}
        >
          {details.imagePath.split('/').pop()}
        </Box>
      </Box>
      {(
        <Box borderRadius={5} p={1}>
          <ImageSearchBox {...searchBox} />
        </Box>
      )}
      {tool === 'editablePoly' && (
        <>
          <Box component='fieldset' borderRadius={5} p={1}>
            <legend>Editable Polygonal Box Size</legend>
            <FormGroup>
              <Slider
                value={editablePolyBoxCoefficient}
                style={{ zIndex: 99 }}
                valueLabelDisplay='auto'
                valueLabelFormat={(x) => `${parseInt(x * 100)}%`}
                step={0.01}
                onChange={(e, val) => setEditablePolyBoxCoefficient(val)}
                min={0.05}
                max={1}
                disabled={isEditablePolygonalBoxFiltersDisabled}
              />
            </FormGroup>
          </Box>
          <Box component='fieldset' borderRadius={5} p={1}>
            <legend>Number of Points</legend>
            <FormGroup>
              <Slider
                value={numberOfPoints}
                style={{ zIndex: 99 }}
                valueLabelDisplay='auto'
                step={1}
                onChange={(e, val) => setNumberOfPoints(val)}
                min={7}
                max={100}
                disabled={isEditablePolygonalBoxFiltersDisabled}
              />
            </FormGroup>
          </Box>
        </>
      )}
      {fixedBBoxMode && (
        <>
          <Box component='fieldset' borderRadius={5} p={1}>
            <legend>Fixed Box Size</legend>
            <FormGroup>
              <Slider
                value={fixedBboxCoefficient}
                style={{ zIndex: 99 }}
                valueLabelDisplay='auto'
                valueLabelFormat={(x) => `${parseInt(x * 100)}%`}
                step={0.01}
                onChange={(e, val) => setFixedBboxCoefficient(val)}
                min={0.01}
                max={1}
                disabled={isEditablePolygonalBoxFiltersDisabled}
              />
            </FormGroup>
          </Box>
        </>
      )}
      {tool === 'poly' && (
        <>
        <Box component='fieldset' borderRadius={5} p={1}>
            <legend>Line Length</legend>
            <FormGroup>
              <Slider
                value={pointFrequency}
                style={{ zIndex: 99 }}
                valueLabelDisplay='auto'
                step={1}
                onChange={(e, val) => setPointFrequency(val)}
                min={10}
                max={50}
              />
            </FormGroup>
          </Box>
          <Box component='fieldset' borderRadius={5} p={1}>
            <legend>Line Thickness</legend>
            <FormGroup>
              <Slider
                value={lineThickness}
                style={{ zIndex: 99 }}
                valueLabelDisplay='auto'
                step={1}
                onChange={(e, val) => setLineThickness(val)}
                min={1}
                max={100}
              />
            </FormGroup>
          </Box>
        </>
      )}
      {(tool !== 'brushTool' && tool !== 'eraser') && (
      <Filter {...filter} />
      )}
      {!maskImg ? (
        <Box p={1}>
          <PathInput path={path} setPath={setPath} />
        </Box>
      ) : null}
      <MaskControl clearMask={clearMask} handleImageUrl={handleImageUrl} />
      <Box p={1}>
        <Button
          onClick={handleCompleteClick}
          disabled={!isCompleteBtnActive}
          variant='contained'
          color='primary'
          fullWidth
        >
          Complete
        </Button>
      </Box>
      <Box p={1}>
        {/* <legend>Kaydet</legend> */}
        {snapshot && (
          <Button
            onClick={snapshot.download}
            disabled={snapshot.disabled}
            variant='contained'
            color='primary'
            fullWidth
          >
            Görüntüyü Kaydet
          </Button>
        )}
      </Box>
      <DownlaodData />
      <EvaluateDRDME {...evaluateDRDME} />
      <div />
      <Box mt='auto' borderRadius={5} px={1} pb={1}>
        <DescriptionBox
          description={save.description}
          setDescription={save.setDescription}
          disabled={save.disabled}
        />
      </Box>
      <Box mt='auto' borderRadius={5} px={1} pb={1}>
        <Box pb={1}>
          <Button
            onClick={() => {
              setSaveButtonClicked(true);
              save.submitChanges();
            }}
            disabled={!hasChanged || save.disabled}
            variant='contained'
            color='primary'
            fullWidth
          >
            Değişiklikleri Kaydet
          </Button>
        </Box>
        <Pagination
          {...pagination}
          clearMask={clearMask}
          handleImageUrl={handleImageUrl}
          userProblemId={userProblemId}
          setCompleteBtnActive={setCompleteBtnActive}
          setSaveButtonClicked={setSaveButtonClicked}
          isComplete={isComplete}
        />
      </Box>
    </div>
  );
}

function Pagination({
  prevDisabled,
  prev,
  nextDisabled,
  next,
  jumpTo,
  jumpDisabled,
  currentIndex,
  totalCount,
  setCompleteBtnActive,
  isComplete,
  handleComplete,
  setSaveButtonClicked,
  userProblemId,
}) {
  let lastCurrentIndex =
    JSON.parse(window.localStorage.getItem('lastCurrentIndex')) || 0;
  const [jumpToOpen, setJumpToOpen] = useState(false);
  const anchorRef = React.useRef(null);

  const { tool } = useAnnotStore();

  const handleClose = (e) => {
    if (e && anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    setJumpToOpen(false);
  };

  return (
    <Box>
      <JumpTo
        handleClose={handleClose}
        anchorEl={anchorRef.current}
        submit={jumpTo}
        open={jumpToOpen}
        disabled={jumpDisabled || !totalCount}
        max={totalCount || 1}
        defaultValue={currentIndex}
        handleComplete={handleComplete}
        setCompleteBtnActive={setCompleteBtnActive}
        isComplete={isComplete}
        userProblemId={userProblemId}
      />
      <ButtonGroup fullWidth>
        <Button
          id='rearIndex'
          color='primary'
          disabled={prevDisabled}
          onClick={() => {
            if(userProblemId !== 0) {
              let _addItem = {
                ...lastCurrentIndex,
                [userProblemId]: currentIndex - 2,
              };
              window.localStorage.setItem(
                'lastCurrentIndex',
                JSON.stringify(_addItem)
              );
            }
            prev();
            setCompleteBtnActive(false);
          }}
        >
          Geri
        </Button>
        <Button
          id='currentIndex'
          color='primary'
          variant='contained'
          disabled={jumpDisabled}
          ref={anchorRef}
          onClick={() => setJumpToOpen(true)}
        >
          {jumpDisabled
            ? `?/${totalCount || '?'}`
            : `${currentIndex}/${totalCount}`}
        </Button>
        <Button
          id='nextIndex'
          color='primary'
          disabled={nextDisabled}
          onClick={() => {
            if(userProblemId !== 0) {
              let _addItem = {
                ...lastCurrentIndex,
                [userProblemId]: currentIndex,
              };
              window.localStorage.setItem(
                'lastCurrentIndex',
                JSON.stringify(_addItem)
              );
            }
            next();
            handleComplete(
              currentIndex + 1,
              totalCount,
              setCompleteBtnActive,
              isComplete
            );
          }}
        >
          İleri
        </Button>
      </ButtonGroup>
    </Box>
  );
}

const usePopperStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: '.5rem',
    width: '6rem',
  },
}));

function JumpTo({
  handleClose,
  anchorEl,
  submit,
  defaultValue,
  disabled,
  max,
  open,
  handleComplete,
  setCompleteBtnActive,
  isComplete,
  userProblemId,
}) {
  let lastCurrentIndex = JSON.parse(
    window.localStorage.getItem('lastCurrentIndex')
  );
  const [value, setValue] = useState(1);
  const classes = usePopperStyles();

  const dispatch = useDispatch();
  const activateKeyEvents = () => dispatch(actions.setKeyEventsActive(true));
  const disableKeyEvents = () => dispatch(actions.setKeyEventsActive(false));

  useEffect(() => {
    if (!isNaN(parseInt(defaultValue))) {
      setValue(parseInt(defaultValue));
    }
  }, [defaultValue]);

  function handleSubmit(e) {
    let _addItem = {
      ...lastCurrentIndex,
      [userProblemId]: value - 1,
    };
    e.preventDefault();
    activateKeyEvents();
    handleComplete(parseInt(value), max, setCompleteBtnActive, isComplete);
    submit(parseInt(value) - 1);
    if(userProblemId !== 0) {
      window.localStorage.setItem('lastCurrentIndex', JSON.stringify(_addItem));
    }
    handleClose();
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal={true}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
          <Box component={Paper} className={classes.paper} p={1}>
            <ClickAwayListener onClickAway={handleClose}>
              <form onSubmit={handleSubmit}>
                <FormControl disabled={disabled}>
                  <Input
                    type='number'
                    value={value}
                    inputProps={{
                      style: { marginRight: -10 },
                      max: max,
                      min: 1,
                      onFocus: disableKeyEvents,
                      onBlur: activateKeyEvents,
                      required: true,
                    }}
                    onChange={(e) => setValue(e.target.value)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          disabled={disabled}
                          type='submit'
                          size='small'
                        >
                          <ArrowRightIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </form>
            </ClickAwayListener>
          </Box>
        </Grow>
      )}
    </Popper>
  );
}

const useEvalDRDMEStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function EvaluateDRDME({ canEvaluate, evaluate, storagePath }) {
  const _evaluate = useCallback(() => evaluate(storagePath), [storagePath]);
  const { execute, status, value, error } = useAsync(_evaluate, false);
  const classes = useEvalDRDMEStyles();

  const hasChanged = useChange((state) => state.hasChanged);

  const confirm = useConfirm();
  const pending = usePending();

  useEffect(() => {
    // idle, success, error
    if (status === 'idle') {
      pending(false);
    } else if (status === 'success') {
      confirm({
        title: 'Test Sonuçları',
        description: `DR : ${value.drResult}\n${
          value.dmeResult ? 'DME:' + value.dmeResult : ''
        }`,
        confirmationText: 'Tamam',
      });
    } else if (status === 'error') {
      addCloudWatchLog(`RightSidebar.js:65 ${error}`);
      console.error(error);
      confirm({
        title: 'Test Sonuçları',
        description: `Bir hata oluştu. Lütfen konsola bakın.`,
        confirmationText: 'Tamam',
      });
    }
  }, [status]);
  if (!canEvaluate) {
    return <React.Fragment />;
  }

  return (
    <Box p={1}>
      <div className={classes.wrapper}>
        <Button
          disabled={hasChanged || !storagePath || status === 'pending'}
          color='primary'
          variant='outlined'
          // endIcon={<DownloadIcon />}
          onClick={execute}
          fullWidth
          // size="large"
        >
          DR & DME Test Et
        </Button>
        {status === 'pending' && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </Box>
  );
}

function DownlaodData() {
  const { downloadable, downloadData } = useImageData();
  const labelMap = useLabelMap((s) => s.labels);
  const confirm = useConfirm();

  function download() {
    confirm({
      title: 'Değişiklikleri indir',
      confirmationText: 'İndir',
      cancellationText: 'Vazgeç',
      confirmationButtonProps: { color: 'primary' },
      cancellationButtonProps: { color: 'secondary' },
      description: 'Json dosyasının değişiklik yapılmış halini indireceksiniz!',
    }).then(() => downloadData({ labelMap }));
  }

  return (
    <Box borderRadius={5}>
      <Button
        disabled={!downloadable}
        endIcon={<DownloadIcon />}
        onClick={download}
        style={{ marginTop: 5 }}
        fullWidth
        size='large'
      >
        Seçilenleri İndir
      </Button>
    </Box>
  );
}

function MaskControl({ clearMask, handleImageUrl }) {
  const { showMask, setShowMask, maskImg, setMaskImg, alpha, setAlpha } =
    useImageMask();
  const setPending = usePending();

  async function handleImageFile(e) {
    if (!(e.target.files && e.target.files[0])) {
      addCloudWatchLog(`Unvalid File ${e}`);
      console.error('Unvalid file...');
      return;
    }
    try {
      setPending(true, 'Maske Yükleniyor...');
      var reader = new FileReader();
      reader.onload = function (evt) {
        const im = new window.Image();
        im.src = evt.target.result;
        setMaskImg(im);
        setShowMask(true);
        setPending(false);
      };
      reader.onerror = function (e) {
        addCloudWatchLog(`RightSidebar.js:656 ${e}`);
        console.error(e);
        setPending(false);
      };
      reader.readAsDataURL(e.target.files[0]); // convert to base64 string
    } catch (e) {
      addCloudWatchLog(`RightSidebar.js:662 ${e}`);
      console.error(e);
    }
  }
  if (maskImg === null) {
    return (
      <>
        <Box p={1}>
          <Button
            fullWidth
            variant='contained'
            component='label'
            onClick={handleImageUrl}
          >
            S3 URL'den Maske Ekle
          </Button>
        </Box>
        <Box p={1}>
          <Button fullWidth variant='contained' component='label'>
            Maske Ekle
            <input
              multiple={false}
              type='file'
              hidden
              accept='image/*'
              onChange={handleImageFile}
            />
          </Button>
        </Box>
      </>
    );
  }
  function handleChange(e) {
    setShowMask(e.target.checked);
  }
  return (
    <>
      <Box p={1}>
        <Button
          fullWidth
          variant='contained'
          component='label'
          color='secondary'
          onClick={() => clearMask()}
        >
          MASKEYİ TEMİZLE
        </Button>
      </Box>
      <Box component='fieldset' borderRadius={5} p={1}>
        <legend>Artifact Maskesi</legend>
        <FormGroup>
          <Slider
            value={alpha}
            style={{ zIndex: 99 }}
            valueLabelDisplay='auto'
            valueLabelFormat={(x) => `${parseInt(x * 100)}%`}
            step={0.01}
            onChange={(e, val) => setAlpha(val)}
            min={0}
            max={1}
          />
          <FormControlLabel
            style={{ marginBottom: -5 }}
            control={
              <Checkbox
                checked={showMask}
                onChange={handleChange}
                name='show-mask-checkbox'
              />
            }
            label='Göster'
          />
        </FormGroup>
      </Box>
    </>
  );
}

function useFilterStore() {
  const dispatch = useDispatch();

  //TODO: Use comparision function for selector
  const showGT = useSelector((state) => state.annotation.filterOptions.showGT);
  const showPred = useSelector(
    (state) => state.annotation.filterOptions.showPred
  );
  const showPoly = useSelector(
    (state) => state.annotation.filterOptions.showPoly
  );
  const showSelectedOnly = useSelector(
    (state) => state.annotation.filterOptions.showSelectedOnly
  );
  const showFlaggedOnly = useSelector(
    (state) => state.annotation.filterOptions.showFlaggedOnly
  );
  const threshold = useSelector(
    (state) => state.annotation.filterOptions.threshold
  );
  const showLabels = useSelector(
    (state) => state.annotation.filterOptions.showLabels
  );

  return {
    setShowGT: (val) => dispatch(actions.updateFilterOptions({ showGT: val })),
    setShowPred: (val) =>
      dispatch(actions.updateFilterOptions({ showPred: val })),
    setShowPoly: (val) =>
      dispatch(actions.updateFilterOptions({ showPoly: val })),
    setShowSelectedOnly: (val) =>
      dispatch(actions.updateFilterOptions({ showSelectedOnly: val })),
    setShowFlaggedOnly: (val) =>
      dispatch(actions.updateFilterOptions({ showFlaggedOnly: val })),

    setThreshold: (val) =>
      dispatch(actions.updateFilterOptions({ threshold: val })),
    toggleLabel: (labelId) => dispatch(actions.toggleLabel({ labelId })),
    setShowLabels: (labels) => dispatch(actions.setShowLabels({ labels })),

    showGT,
    showPred,
    showPoly,
    showSelectedOnly,
    showFlaggedOnly,
    threshold,
    showLabels,
  };
}

const useFilterStyles = makeStyles((theme) => ({
  checkboxGT: {
    color: '#5eff60',
    '&$checked': {
      color: '#5eff60 !important',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  checkboxPred: {
    color: 'red',
    '&$checked': {
      color: 'red !important',
    },
  },
  checkboxPoly: {
    color: 'black',
    '&$checked': {
      color: 'black !important',
    },
  },
  checkboxOnlySelected: {
    color: '#3f51b5',
    '&$checked': {
      color: '#3f51b5 !important',
    },
  },
  checkboxOnlyFlagged: {
    color: '#ea17e2',
    '&$checked': {
      color: '#ea17e2 !important',
    },
  },
  checked: {},
}));

function Filter({ setBBoxesValid }) {
  const classes = useFilterStyles();
  const [labels, labelsId] = useLabelMap((state) => [
    state.labels,
    state.labelsId,
  ]);

  const allLabelIds = React.useMemo(
    () => Object.keys(labels).map((_) => parseInt(_)),
    [labelsId]
  );

  useEffect(() => {
    setShowLabels(allLabelIds);
  }, [labelsId]);

  const {
    showGT,
    showPred,
    showPoly,
    showSelectedOnly,
    threshold,
    showLabels,
    showFlaggedOnly,
    setShowGT,
    setShowPred,
    setShowPoly,
    setShowSelectedOnly,
    setThreshold,
    toggleLabel,
    setShowLabels,
    setShowFlaggedOnly,
  } = useFilterStore();

  function handleCheckboxChange(callback) {
    return (e) => callback(e.target.checked);
  }
  function handleConfidenceChange(e, newValue) {
    setThreshold(newValue);
  }

  function toggleAllLabels() {
    if (showLabels.length < allLabelIds.length) {
      setShowLabels(allLabelIds);
    } else {
      setShowLabels([]);
    }
  }

  function RenderListItem(labelId, i) {
    return (
      <ListItem
        key={`show-lbl-chckbx-${labelId}`}
        role='listitem'
        ContainerComponent={Paper}
        button
        dense
        onClick={() => toggleLabel(labelId)}
      >
        <ListItemIcon>
          <Checkbox
            edge='start'
            checked={showLabels.includes(labelId)}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={`${labels[labelId]}`} />
      </ListItem>
    );
  }

  //TODO: Logic needs refactor.
  return (
    <Box component='fieldset' borderRadius={5} mt={1} px={1}>
      <legend>Filtrele</legend>
      <FormGroup>
        <Typography id='continuous-slider' gutterBottom>
          Kararlılık
        </Typography>
        <Grid container spacing={1}>
          <Grid item></Grid>
          <Grid item xs>
            <Slider
              value={[...threshold]}
              valueLabelDisplay='auto'
              valueLabelFormat={(x) => `${parseInt(x * 100)}%`}
              step={0.01}
              onChange={handleConfidenceChange}
              aria-labelledby='continuous-slider'
              min={0}
              max={1}
            />
          </Grid>
          <Grid item>
            <Tooltip title='Aktifleştir' placement='left'>
              <IconButton
                size='small'
                onClick={() => setBBoxesValid(threshold)}
              >
                <PlaylistAddCheckOutlinedIcon color='primary' />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <FormControlLabel
          style={{ marginBottom: -5 }}
          className={classes.italic}
          control={
            <Checkbox
              classes={{ root: classes.checkboxGT, checked: classes.checked }}
              checked={showGT}
              onChange={handleCheckboxChange(setShowGT)}
              name={'show-gt-checkbox-'+showGT}
              id='show-gt-checkbox'
              indeterminate={(showFlaggedOnly || showSelectedOnly) && showGT}
            />
          }
          label='Ground Truth'
        />
        <FormControlLabel
          style={{ marginBottom: -5 }}
          className={classes.italic}
          control={
            <Checkbox
              classes={{ root: classes.checkboxPred, checked: classes.checked }}
              checked={showPred}
              onChange={handleCheckboxChange(setShowPred)}
              name={'show-pred-checkbox-'+showPred}
              id='show-pred-checkbox'
              indeterminate={(showFlaggedOnly || showSelectedOnly) && showPred}
            />
          }
          label='Prediction'
        />

        <FormControlLabel
          style={{ marginBottom: -5 }}
          className={classes.italic}
          control={
            <Checkbox
              classes={{ root: classes.checkboxPoly, checked: classes.checked }}
              checked={showPoly}
              onChange={handleCheckboxChange(setShowPoly)}
              name={'show-poly-checkbox-'+showPoly}
              id='show-poly-checkbox'
              indeterminate={(showFlaggedOnly || showSelectedOnly) && showPoly}
            />
          }
          label='Polygonal Box'
        />

        <FormControlLabel
          style={{ marginBottom: -5 }}
          control={
            <Checkbox
              classes={{
                root: classes.checkboxOnlySelected,
                checked: classes.checked,
              }}
              checked={showSelectedOnly}
              onChange={handleCheckboxChange(setShowSelectedOnly)}
              name={'show-selected-checkbox-'+showSelectedOnly}
              id='show-selected-checkbox'
              indeterminate={showFlaggedOnly}
            />
          }
          label='Sadece Seçilenler'
        />
        <FormControlLabel
          style={{ marginBottom: -5 }}
          control={
            <Checkbox
              classes={{
                root: classes.checkboxOnlyFlagged,
                checked: classes.checked,
              }}
              checked={showFlaggedOnly}
              onChange={handleCheckboxChange(setShowFlaggedOnly)}
              id='show-flagged-checkbox'
              name={'show-flagged-checkbox-'+showFlaggedOnly}
            />
          }
          label='Sadece Etiketliler'
        />

        <ShowLabels
          toggleAllLabels={toggleAllLabels}
          indeterminate={
            showLabels.length !== 0 && showLabels.length !== allLabelIds.length
          }
          checked={showLabels.length !== 0}
        >
          <List className={classes.list} component='div' role='list'>
            {allLabelIds.map(RenderListItem)}
          </List>
        </ShowLabels>
      </FormGroup>
    </Box>
  );
}

function ShowLabels({ children, toggleAllLabels, checked, indeterminate }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'label-popover' : undefined;

  return (
    <>
      <FormGroup row style={{ display: 'flex' }}>
        <IconButton
          size='small'
          style={{ paddingTop: 'auto', paddingBottom: 'auto' }}
          aria-describedby={id}
          onClick={handleOpen}
        >
          <ArrowDropDownIcon />
        </IconButton>
        <Divider
          orientation='vertical'
          style={{ marginLeft: 2, marginRight: 2 }}
        />
        <FormControlLabel
          style={{ marginBottom: -5 }}
          control={
            <Checkbox
              // classes={{root: classes.checkboxPred, checked: classes.checked}}
              checked={checked}
              onChange={() => toggleAllLabels()}
              indeterminate={indeterminate}
              id='show-labels-checkbox'
              name={'show-labels-checkbox-'+checked}
            />
          }
          label='Labellar'
        />
      </FormGroup>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {children}
      </Popover>
    </>
  );
}
