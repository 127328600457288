import React from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

import RouterIcon from '@material-ui/icons/Router';
import CloudIcon from '@material-ui/icons/Cloud';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HomeIcon from '@material-ui/icons/Home';
import StorageIcon from '@material-ui/icons/Storage';
import ImageIcon from '@material-ui/icons/Image';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Collapse, createStyles, makeStyles } from '@material-ui/core';

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'

const drawerWidth = 240

const useStyles = makeStyles(() =>
  createStyles({
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
  }),
)

const sideNavLinks = [
  {
    name: 'Projects',
    to: '/admin/projects',
    icon: 'home',
    subLinks: [],
  },
  {
    name: 'Image Query',
    to: '/admin/image-query',
    icon: 'image',
    subLinks: [],
  },
  {
    name: 'User Management',
    to: '/admin/users',
    icon: 'users',
    subLinks: [],
  },
  {
    name: 'System Settings',
    to: '#',
    icon: 'enums',
    subLinks: [
      {
        name: 'Image Source',
        to: '/admin/settings/image-source',
      },
      {
        name: 'Images',
        to: '/admin/settings/images',
      },
      {
        name: 'Dataset',
        to: '/admin/settings/dataset',
      },
      {
        name: 'Prediction',
        to: '/admin/settings/prediction',
      },
      {
        name: 'Category',
        to: '/admin/settings/category',
      },
      {
        name: 'Information',
        to: '/admin/settings/information',
      },
    ]
  },
  {
    name: 'Reports',
    to: '/admin/reports',
    icon: 'reports',
    subLinks: [],
  },
  {
    name: 'Help',
    to: '/admin/help',
    icon: 'help',
    subLinks: [],
  },
  {
    name: 'System Logs',
    to: '/admin/system-logs',
    icon: 'logs',
    subLinks: [],
  },
];

function SideNavList({ user }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
  }
  return (
      <List>
        {sideNavLinks.map(({ name, to, icon, subLinks }, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem onClick={subLinks.length > 0 ? handleClick : null} key={`nav-list-item-${to}`} component={Link} to={to} button>
                <ListItemIcon>
                  <Icon name={icon} />
                </ListItemIcon>
                <ListItemText primary={name} />
                {subLinks.length > 0 ? open ? <IconExpandLess /> : <IconExpandMore /> : null}
              </ListItem>
              {subLinks.length > 0 ? subLinks.map(({ name, to }, index) => (
                // TODO: add unique key
                  <Collapse key={index} in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button to={to} component={Link} className={classes.menuItem}>
                        <ListItemText inset primary={name} />
                      </ListItem>
                    </List>
                  </Collapse>
              )) : null}
            </React.Fragment>
          )})}
      </List>
  );
}

function Icon({ name }) {
  switch (name) {
    case 'router':
      return <RouterIcon />;
    case 'cloud':
      return <CloudIcon />;
    case 'home':
      return <HomeIcon />;
    case 'image':
      return <ImageIcon />;
    case 'users':
      return <SupervisorAccountIcon />;
    case 'enums':
      return <StorageIcon />;
    case 'reports':
      return <AssessmentIcon />;
    case 'help':
      return <HelpCenterIcon />
    case 'logs':
      return <ReportProblemIcon />;
    default:
      return <HelpOutlineIcon />;
  }
}

export default connect(({ user: { user } }) => ({ user }))(SideNavList);
