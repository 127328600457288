import React, { useEffect, useState } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { getImagesPageByPage } from '../../../../../../service/admin.service';
import AddManual from './AddManual';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import getAlternateStyle, {getColor} from '../../../../CommonStyles/AlternateColor';
import CustomNoRowsOverlay from '../../../../../../GlobalComponents/CustomNoRowsOverlay';
import CustomPagination from '../../../../../../GlobalComponents/CustomPagination';

const useStyles = makeStyles((theme) => ({
  imagesRoot: {
    width: '98%',
    height: '45.2%',
    padding: '90px 0 0 15px',
  },
  searchContainer: {
    position: 'relative',
    bottom: '4rem',
    left: '13rem',
  },
  inputEl: {
    width: '20%',
  },
  labelEl: {
    position: 'relative',
    top: '2.42rem',
    right: '7.5rem',
  },
  searchButton: {
    position: 'relative',
    bottom: '4.4rem',
    left: '2rem',
  },
  addButton: {
    position: 'relative',
    bottom: '1rem',
    marginRight: '10px',
  },
  deleteButton: {
    position: 'relative',
    bottom: '1rem',
    left: '2rem',
  },
  changePagination: {
    position: 'relative',
    bottom: '2.8rem',
    left: '1rem',
  },
}));

let rowNumber = 1;

export default function AddImageset() {
  const classes = useStyles();
  const { id } = useParams()
  const history = useHistory()
  const initialPageNumber = 0;
  const initialPageSize = 100;
  const [imageName, setImageName] = useState('');
  const [qualityTypeId, setQualityTypeId] = useState(1);
  const [imageSourceId, setImageSourceId] = useState(1);
  const [addNewOpen, setAddNewOpen] = useState(false)
  const [rows, setRows] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [maxPageNumber, setMaxPageNumber] = useState(0);
  const [isPaginationChanged, setPaginationChanged] = useState(true);
  const [pageSize, setPageSize] = useState(10);

  const [request, setRequest] = useState({
    pageNumber: initialPageNumber,
    pageSize: initialPageSize,
  });

  const qualityTypes = [
    { id: 1, name: 'Reject' },
    { id: 2, name: 'Usable' },
    { id: 3, name: 'Good' },
    { id: 4, name: 'Unknown' },
  ];

  const { byId: imageSources } = useSelector(
    (state) => state.admin.enums.imageSources
  );

  function onAddProblemClose(id, reason) {
    setAddNewOpen(false)
    if (reason === 'success')
      history.push('/admin/projects/' + id)
  }

  const columns = [
    {
      field: 'storagePath',
      headerName: 'Image Name',
      flex: 2,
    },
    {
      field: 'createDate',
      headerName: 'Upload Date',
      flex: 1,
    },
    {
      field: 'qualityTypeName',
      headerName: 'Quality',
      flex: 1,
    },
    {
      field: 'imageSourceName',
      headerName: 'Image Source',
      flex: 1,
    },
  ];

  function handleAddButtonClick() {
    alert("add function")
  }

  function arrangeData(images) {
    images.forEach((image) => {
      image['id'] = image['imageId'];
      delete image['imageId'];
      image['rowNumber'] = rowNumber;
      rowNumber++;
    });

    return images;
  }

  useEffect(() => {
    async function getImages() {
      const response = await getImagesPageByPage(request);
      const arrangedData = arrangeData(response.data);
      setRows([...rows, ...arrangedData]);
    }
    getImages();
  }, [request]);

  function handlePageChange(pageNumber) {
    setPageNumber(pageNumber);

    if (maxPageNumber < pageNumber) {
      setMaxPageNumber(pageNumber);
      if (pageNumber % initialPageSize !== 0) {
        setRequest({ ...request, pageNumber, pageSize: initialPageSize });
      }
    }
  }

  function submit(e) {
    e.preventDefault();
    setPageNumber(0);
    setMaxPageNumber(0);
    setRows([]);
    setRequest({
      imageName,
      qualityTypeId,
      imageSourceId,
      pageNumber: initialPageNumber,
      pageSize: initialPageSize,
    });
  }

  const anotherPagination = isPaginationChanged && {
    Pagination: CustomPagination,
  };

  return (
    <>
      <Box>
        <IconButton
          color='primary'
          className={classes.userSettingsButton}
          to={'/admin/projects/' + id}
          component={RouterLink}
          >
          <ChevronLeftIcon />
          <Typography>Projects</Typography>
        </IconButton>
      </Box>
   
      <div className={classes.imagesRoot}>
        <Box
          className={classes.searchContainer}
          component='form'
          autoComplete='off'
          onSubmit={submit}
        >
          <InputLabel className={classes.labelEl}>Image Name</InputLabel>
          <TextField
            className={classes.inputEl}
            label='Image Name'
            onChange={(e) => setImageName(e.target.value)}
            value={imageName}
          />
          <InputLabel className={classes.labelEl}>Quality</InputLabel>
          <FormControl className={classes.inputEl} required>
            <InputLabel id='quality-label'>Select</InputLabel>
            <Select
              labelId='quality-label'
              value={qualityTypeId}
              label='Select'
              onChange={(e) => setQualityTypeId(e.target.value)}
            >
              {qualityTypes.map((qualityType, index) => (
                <MenuItem key={index} value={qualityType.id}>
                  {qualityType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel className={classes.labelEl}>Image Source</InputLabel>
          <FormControl className={classes.inputEl} required>
            <InputLabel id='image-source-label'>Select</InputLabel>
            <Select
              labelId='image-source-label'
              value={imageSourceId}
              label='Select'
              onChange={(e) => setImageSourceId(e.target.value)}
            >
              {Object.keys(imageSources).map(function (key) {
                const imageSource = imageSources[key];
                return (
                  <MenuItem key={key} value={imageSource.id}>
                    {imageSource.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            className={classes.searchButton}
            variant='contained'
            color='primary'
            type='submit'
          >
            Search
          </Button>
        </Box>
        <Button
          onClick={handleAddButtonClick}
          className={classes.addButton}
          variant='contained'
          color='primary'
          disabled={true} // ids.length === 0
        >
          Add Selected (Soon)
        </Button>
        <Button
          id='adminAddManual'
          onClick={() => setAddNewOpen(true)}
          className={classes.addButton}
          variant='contained'
          color='primary'
        >
          Add Manual
        </Button>

        <AddManual open={addNewOpen} onClose={onAddProblemClose} />
        <div style={{ height: '80vh', width: '100%' }}>
          <DataGrid
            components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                ...anotherPagination,
            }}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableSelectionOnClick
            checkboxSelection
            page={pageNumber}
            onPageChange={(pageNumber) => {
              handlePageChange(pageNumber);
            }}
            sx={getAlternateStyle}
            getRowClassName={(params) => {return getColor(params.row.rowNumber)}}
          />
          <Button
            className={classes.changePagination}
            variant='contained'
            color='primary'
            onClick={() => setPaginationChanged((prevState) => !prevState)}
          >
                Change Pagination
          </Button>
        </div>
      </div>
    </>
  );
}
