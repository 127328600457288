export default function getAlternateStyle() {
    return {
        '& .theme--even': {
          '&:hover': {
            bgcolor:'#fafafa'
          },
        },
        '& .theme--odd': {
          bgcolor: '#eeeeee',
          '&:hover': {
            
          },
        },
      };
} 

export function getColor(id) {
    const className = id % 2 === 0 ? 'even' : 'odd';
    return `theme--${className}`;
}