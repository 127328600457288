import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useConfirm } from 'material-ui-confirm';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {
  Card, CardActions, Chip,
  Button, IconButton, Box
} from '@material-ui/core';
import Image from '../../../GlobalComponents/Image';
import { sendAnswer } from '../../../service/evaluate.service';
import { ErrorContent } from '../../../util/handleError';
import Pagination from './Pagination'
import { useMountedState } from 'react-use';
import { useLabelMap } from '../../Annotate/store';
import ImageSearchBox from 'components/Annotate/Components/ImageSearchBox';
import { useWindowSize } from 'util/hooks';

// import CheckedIcon from '@material-ui/icons/RadioButtonChecked';

const useStyles = makeStyles((theme) => { return {
  root: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  title: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '.5rem',
    marginBottom: '.3rem',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 2,
    margin: 'auto',
    marginBottom: 6,
    marginTop: 6,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& > :not(:first-child)': {
      marginLeft: 3,
    },
  },
  textField: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  obscureButton: {
    border: '1px solid #363636',
    backgroundColor: '#fff',
    color: '#363636',
    transition: 'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  obscureBtnDisabled: {
    border: '0px solid',
  },
  rejectButton: {
    backgroundColor: '#363636',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#3c3c3c',
    },
  },
  progressWrapper: {
    margin: 'auto',
  },
  backButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '.5rem',
  },
  normalButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#4b9c4f',
    },
  },
  buttonContainer: {
    padding: 1,
    borderRadius: theme.shape.borderRadius,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardAction: {
    padding: '0px 6px',
    height: '6.6rem',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 100,
    marginLeft: 4,
  },
  imageNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 135,
    marginTop: 20,
    marginRight: 10,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  label: {
    marginTop: 1,
  },
  mw: {
    width: '6.5rem',
  },
  chip: {
    marginTop: 2,
    border: '0 solid #f9f9f900',
  },
  chipOutlined: {
    boxShadow: 'inset 1.51px 1.5px 5px -2.5px #737999',
  },
  chipColorPrimary: {
    boxShadow: '1px 1px 5px -1px #737999',
  },
  imCountChip: {
    position: 'fixed',
    left: '50%',
    marginTop: 7.2,
    transform: 'translateX(-50%)',
  },
  fullScreenButton: {
    position: 'relative',
    top: 0,
  },
};
})

// 1	Normal
// 2	Reject
// 3	Mild
// 4	Moderate
// 5	Abnormal
// 6	Severe NPDR
// 7	PDR
// 8	DME

// Diyabetik Retinopati
// Kuru tip yaşa bağli makula dejenerasyonu
// Yaş tip yaşa bağli makula dejenerasyonu
// Hipertansif retinopati

// Retinal Ven oklüzyonu
// Retinal Arter oklüzyonu
// Tam kat - lameller maküler delik
// Epimaküler membran
// Dejeneratif miyopi
// Retinal arter makroanevrizmasi
// Santral seröz koryoretinopati
// Retinitis pigmentosa
// Retina distrofisi
// Sınıflandırılamayan

const buttonIds = {
  normal: 1,
  obscure: 22,
  reject: 2,
  abnormal: 5,
}


function DiseaseCard({
  userProblemImageId, imageObject,
  next, prev, hasPrev, hasNext, jumpTo,
  jumpToWithDrSays, isFullScreen, handleFullScreen }) {

  const confirm = useConfirm();
  const labelMap = useLabelMap(store => store.diseaseLabels)
  let chips = Object.values(labelMap)
  if(chips.length === 0) {
    chips = [
      { id: 0, name: 'Label Bulunamadı. Kategoriler sayfasından label ekleyiniz.' }
    ]
    // showErrorMessage('Label Bulunamadı! Lütfen projeye kategoriler sayfasından label ekleyiniz..', 'HATA!')
  }
  const selectedModel = Object.assign(...chips.map(({ id }) => ({ [id]: false })))
  const chipIds = chips.map(({ id }) => id)
  const isMounted = useMountedState();
  const [imageLoading, setImageLoading] = useState(true);
  const [pending, setPending] = useState(false)
  const [selecteds, setSelecteds] = useState(selectedModel)
  const [okBtnDisabled, setOkBtnDisabled] = useState(true);
  const loading = false
  const { width, height } = useWindowSize();
  const classes = useStyles({isFullScreen, width})
  
  useEffect(() => {
    if (!isMounted()) { return; }
    if (imageObject) {
      let newSelecteds = { ...selecteds }
      imageObject.answerLabelIds.forEach((id) => newSelecteds[id] = true)
      setSelecteds(newSelecteds)
      setOkBtnDisabled(imageObject?.answerLabelIds.length < 2)
    }
  }, [imageObject?.userProblemImageId])

  const handleChange = (id) => {
    if (okBtnDisabled && !selecteds[id]) {
      setOkBtnDisabled(false)
    } else if (!okBtnDisabled && selecteds[id]) {
      if (chipIds.every((chipId) => !(selecteds[chipId] && (chipId !== id)))) {
        setOkBtnDisabled(true)
      }
    }
    setSelecteds({ ...selecteds, [id]: !selecteds[id] })
  }

  function _decide(decision) {
    if (pending) { return }
    setPending(true);
    let decisions = chipIds.filter(id => selecteds[id]);
    if (decision) { decisions.push(decision) }

    let answer = {
      userProblemImageId: imageObject.userProblemImageId,
      labelIds: decisions,
    }

    sendAnswer(answer)
      .then(resData => {
        if (!isMounted()) { return; }
        const { data, success, error, message } = resData;
        if (success) {
          setPending(false);
          if (!hasNext) {
            confirm({
              title: 'Teşekkürler!',
              description: 'Bütün resimler işaretlediniz.',
              confirmationText: 'Tamam',
              cancellationButtonProps: { style: { display: 'none' } }
            })
          }
          next(data);
        } else {
          showErrorMessage(message ? message : 'Cevap gönderilemedi!', error)
        }
      })
      .catch(e => { if (!isMounted()) { return; } showErrorMessage('Cevap gönderilemedi!', e) })
  }

  function showErrorMessage(message, error, title = 'Hata', showButton = true) {
    confirm({
      title,
      confirmationText: 'Sayfayı Yenile',
      confirmationButtonProps: showButton ? { color: 'primary' } : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: <ErrorContent message={message} e={error} />
    }).then(() => { window.location.reload() })
  }

  const backBtnDisabled = pending || !hasPrev;
  const nextBtnDisabled = pending || !hasNext;
  const fullScrBtnDisabled = pending && !isFullScreen;
  const inputDisabled = pending || loading || imageLoading
  const noChipActive = okBtnDisabled;

  const preSelectedDivStyle = { backgroundColor: '#9b9bde', boxShadow: 'rgb(104, 110, 202) 0px 0px 8px 4px' }
  return (
    <div className={classes.root}>
      <Card>
        <div style={{ display: 'flex', height: '2.8rem' }}>
          <Button
            className={classes.backButton}
            color='primary'
            onClick={(e) => {
              prev();
            }}
            size='small'
            startIcon={<ArrowBackIosIcon fontSize='small' />}
            disabled={backBtnDisabled}
          >
            Önceki Resim
          </Button>
          <Button
            className={classes.backButton}
            color='primary'
            onClick={(e) => {
              next();
            }}
            size='small'
            endIcon={<ArrowForwardIosIcon fontSize='small' />}
            disabled={nextBtnDisabled}
          >
            Sonraki Resim
          </Button>
          <Pagination
            {...imageObject}
            jumpTo={jumpTo}
            className={classes.imCountChip}
          />
          <IconButton
            className={classes.fullScreenButton}
            color='primary'
            onClick={handleFullScreen}
            style={{ marginLeft: 'auto' }}
            disabled={fullScrBtnDisabled}
          >
            <CropFreeIcon fontSize='large' />
          </IconButton>
        </div>
        <div className={classes.flexRow}>
          <Image
            key={`image-${userProblemImageId}`}
            image={imageObject.imageData}
            setLoading={setImageLoading}
            isFullScreen={isFullScreen}
          />
          <div className={clsx(classes.imageNameContainer)}>
            <Box component='fieldset' borderRadius={5}>
              <legend>Resim Adı</legend>
              <Box
                style={{ backgroundColor: '#f0f0f0', marginTop: '-0.3rem' }}
                onClick={() =>
                  navigator.clipboard.writeText(imageObject.storagePath)
                }
              >
                {imageObject.storagePath.split('/').pop()}
              </Box>
            </Box>
            <Box borderRadius={5} p={1} sx={{width: '153.6px', marginLeft: '-12px', marginBottom: '20px'}}>
              <ImageSearchBox jumpToWithDrSays={jumpToWithDrSays} />
            </Box>
          </div>
        </div>
        <div className={clsx(classes.flexRow, classes.cardActions)}>
          <CardActions className={classes.cardAction}>
            {chips.map(({ id, name }) => (
              <Chip
                // icon={}
                key={`chip-${id}`}
                disabled={inputDisabled}
                classes={{
                  root: classes.chip,
                  outlined: classes.chipOutlined,
                  colorPrimary: classes.chipColorPrimary,
                }}
                color={selecteds[id] ? 'primary' : 'default'}
                size='small'
                label={name}
                onClick={() => handleChange(id)}
                variant={selecteds[id] ? 'default' : 'outlined'}
              />
            ))}
          </CardActions>
          <div className={classes.buttonsContainer}>
            <div
              className={classes.buttonContainer}
              style={
                imageObject?.answerLabelIds.includes(buttonIds.reject)
                  ? preSelectedDivStyle
                  : {}
              }
            >
              <Button
                disabled={inputDisabled || !okBtnDisabled}
                className={clsx(classes.rejectButton, classes.mw)}
                size='small'
                variant='contained'
                onClick={() => {
                  setImageLoading(true);
                  _decide(buttonIds.reject);
                }}
              >
                Geçersiz
              </Button>
            </div>
            <div
              className={classes.buttonContainer}
              style={
                imageObject?.answerLabelIds.includes(buttonIds.obscure)
                  ? preSelectedDivStyle
                  : {}
              }
            >
              <Button
                disabled={inputDisabled}
                className={clsx(classes.mw, classes.obscureButton)}
                classes={{ disabled: classes.obscureBtnDisabled }}
                size='small'
                variant='contained'
                onClick={() => {
                  setImageLoading(true);
                  _decide(buttonIds.obscure);
                }}
              >
                Kararsız
              </Button>
            </div>
            {noChipActive ? (
              <div
                className={classes.buttonContainer}
                style={
                  imageObject?.answerLabelIds.includes(buttonIds.normal)
                    ? preSelectedDivStyle
                    : {}
                }
              >
                <Button
                  disabled={inputDisabled}
                  color='primary'
                  size='small'
                  className={clsx(classes.mw, classes.normalButton)}
                  variant='contained'
                  onClick={() => {
                    setImageLoading(true);
                    _decide(buttonIds.normal);
                  }}
                >
                  Normal
                </Button>
              </div>
            ) : (
              <div
                className={classes.buttonContainer}
                style={
                  imageObject?.answerLabelIds.includes(buttonIds.abnormal)
                    ? preSelectedDivStyle
                    : {}
                }
              >
                <Button
                  disabled={inputDisabled}
                  color='primary'
                  size='small'
                  className={classes.mw}
                  variant='contained'
                  onClick={() => {
                    setImageLoading(true);
                    _decide(buttonIds.abnormal);
                  }}
                >
                  Tamam
                </Button>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default DiseaseCard
