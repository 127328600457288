import React, { useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SwitchComponent from '@material-ui/core/Switch';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { DataGrid } from '@mui/x-data-grid';
import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import { toggleActivate } from '../../../../service/admin.service';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { actions } from '../../../../redux/slices/admin';
import { Link as RouterLink } from 'react-router-dom';
import { sendMail } from '../../../../service/admin.service';
import getAlternateStyle, { getColor } from '../../CommonStyles/AlternateColor';
import CustomPagination from '../../../../GlobalComponents/CustomPagination';
import CustomNoRowsOverlay from '../../../../GlobalComponents/CustomNoRowsOverlay';
import { addCloudWatchLog } from '../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  toolBarRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      // backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  userManagementRoot: {
    width: '98%',
    height: '74%',
    padding: '15px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  addUserButton: {
    float: 'right',
    margin: '2% 4% 0 0',
  },
  changePagination: {
    position: 'relative',
    bottom: '2.8rem',
    left: '1rem',
  },
}));

// function SearchBar() {
//   const classes = useStyles();

//   return (
//     <div>
//       <AppBar position='static'>
//         <Toolbar>
//           <div className={classes.search}>
//             <div className={classes.searchIcon}>
//               <SearchIcon />
//             </div>
//             <InputBase
//               placeholder='Search…'
//               classes={{
//                 root: classes.inputRoot,
//                 input: classes.inputInput,
//               }}
//               inputProps={{ 'aria-label': 'search' }}
//             />
//           </div>
//         </Toolbar>
//       </AppBar>
//     </div>
//   );
// }

function useToggleActivateFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const toggleActivateFn = toggleActivate;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`GetUsers.js:151 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`Getusers.js:165 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await toggleActivateFn(_data);
    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.updateEnum({ enumKey: 'users', data }));
    }
    return { data, success, error, message };
  }
  return submit;
}

const EnhancedTableToolbar = () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolBarRoot}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        List of Users
      </Typography>
      {/* <SearchBar />
      <Button variant='contained' color='primary'>
        Search
      </Button> */}
    </Toolbar>
  );
};

const CustomSwitch = ({ id, initialEnabled }) => {
  const [checked, setChecked] = useState(initialEnabled);
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const ToggleActivateFn = useToggleActivateFunction();

  async function submit(changedActive) {
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const request = {
      id,
      enabled: changedActive,
    };

    const { success, error } = await ToggleActivateFn(request);

    setLoading(false);
    pending(false);

    if (success && !error) {
      setChecked(changedActive);
    }
  }

  function handleChange(e) {
    submit(e.target.checked);
  }

  return <SwitchComponent checked={checked} onChange={handleChange} />;
};

export default function GetUsers() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [isPaginationChanged, setPaginationChanged] = useState(true);
  const pending = usePending();
  const sendMailFn = sendMail;
  const rows = [];
  let rowNumber = 1;

  const { byId: users } = useSelector(
    (state) => state.admin.enums.users
  );

  function createData(rowNumber, id, name, email, title, role, enabled) {
    return { rowNumber, id, name, email, title, role, enabled };
  }

  function getRole(roleId) {
    if (roleId === 1) {
      return 'Superuser';
    } else if (roleId === 2) {
      return 'User';
    } else {
      return 'Invalid Value';
    }
  }

  for (const key in users) {
    const user = users[key];
    const role = getRole(user.roleId);
    rows.push(
      createData(
        rowNumber,
        user.id,
        user.name,
        user.email,
        user.title,
        role,
        user.enabled
      )
    );
    rowNumber++;
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
    },
    {
      field: 'enabled',
      headerName: 'Active/Passive',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <CustomSwitch id={params.row.id} initialEnabled={params.value} />
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const url = '/admin/users/edit/' + params.row.id;
        return (
          <Link to={url} component={RouterLink}>
            Edit
          </Link>
        );
      },
    },
    {
      field: 'reset',
      headerName: 'Reset',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            href='#'
            onClick={(e) => {
              handleResetLinkClick(e, params.row.email);
            }}
          >
            Reset(Send mail)
          </Link>
        );
      },
    },
  ];

  const handleResetLinkClick = (e, email) => {
    e.preventDefault();
    submit(email);
  };

  const confirm = useConfirm();

  function showMessage({
    message,
    error = null,
    title = 'Başarılı',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`GetUsers.js:363 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`GetUsers.js:377 ${e}`);
        console.error(e);
      });
  }

  async function submit(email) {
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const request = {
      text: email,
    };

    const { success, error } = await sendMailFn(request);

    setLoading(false);
    pending(false);

    if (success && !error) {
      showMessage({message: 'Mail başarıyla gönderildi.'})
    }
    else {
      showMessage({title: 'Hata', message: 'Geçersiz mail!'})
    }
  }

  const anotherPagination = isPaginationChanged && {
    Pagination: CustomPagination,
  };

  return (
    <div className={classes.userManagementRoot}>
      <EnhancedTableToolbar />
      <DataGrid
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          ...anotherPagination,
        }}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        disableSelectionOnClick
        sx={getAlternateStyle}
        getRowClassName={(params) => {
          return getColor(params.row.rowNumber);
        }}
      />
      <Button
        to='/admin/users/create'
        component={RouterLink}
        className={classes.addUserButton}
        variant='contained'
        color='primary'
      >
        Add User
      </Button>
      <Button
        className={classes.changePagination}
        variant='contained'
        color='primary'
        onClick={() => setPaginationChanged((prevState) => !prevState)}
      >
        Change Pagination
      </Button>
    </div>
  );
}
