import axios from 'axios';
import { addCloudWatchLog } from './cloud.service';


let _ROOT, _STATIC_ROOT, _PATH
// = window.localStorage.getItem('apiUrl'); //window.location.origin+"/api";


if (window.location.hostname === "doctorsays.net") {
  _ROOT = "https://api.doctorsays.net/api";
  _STATIC_ROOT = "https://static.doctorsays.net/";
  _PATH = "";
} else if (window.location.hostname === "test.doctorsays.net") {
  _ROOT = "https://testapi.doctorsays.net/api";
  _STATIC_ROOT = "https://static.doctorsays.net/";
  _PATH = "/test";
} else if (window.location.hostname === "www.doctorsays.net") {
  _ROOT = "https://api.doctorsays.net/api";
  _STATIC_ROOT = "https://static.doctorsays.net/";
  _PATH = "";
} else {
  _ROOT = "http://localhost:8080/api";
  _STATIC_ROOT = "http://127.0.0.1:5500/";
  _PATH = "/dev";
}
export const ROOT = _ROOT
export const STATIC_ROOT = _STATIC_ROOT
export const S3_PATH = _PATH

const api = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

export async function get(url) {
  return await api({ method: 'GET', url })
}
export async function post(url, data) {
  return await api({ method: 'POST', url, data })
}

export function setBearerToken(accessToken) {
  if (accessToken === undefined) {
    delete api.defaults.headers["Authorization"]
  }
  api.defaults.headers = {
    ...api.defaults.headers,
    "Authorization": `Bearer ${accessToken}`
  }
}

export function getErrorStatusCode(e) {
  let eJson = e.toJSON();
  if (eJson.message.includes("status code"))
    return e.message.split(" ").pop()
  return undefined
}


export function handleError(e) {
  if (typeof (e.toJSON) !== "function") {
    addCloudWatchLog(`Api.js:67 ${e}`);
    throw console.error(e);
  }
  let statusCode = getErrorStatusCode(e)

  switch (statusCode) {
    case undefined:
      addCloudWatchLog(`Api.js:74 Unknown error ${e}`);
      console.error("Unknown error", e);
      break;
    case "401":
      addCloudWatchLog(`No Authorization! ${e}`);
      console.error("No Authorization!")
      break;
    case "500":
      addCloudWatchLog(`Wrong UserId or Password! ${e}`);
      console.error("Wrong UserId")
      break;
    default:
      addCloudWatchLog(`Unhandled statusCode ${e} ${statusCode}`);
      console.error("Unhandled statusCode", statusCode, e)
  }
}

export default api
