import React from 'react';
import clsx from 'clsx';
import { auth } from '../../../service/auth.service';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Drawer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/ExpandLess';
import { useOptions } from '../../Contexes/options';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../redux/slices/layout';
import { addCloudWatchLog } from '../../../service/cloud.service';


const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 64,
    paddingLeft: 17,
    paddingTop: 1,
    minHeight: 56
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 299,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoutButton: {
    marginLeft: 'auto'
  },
  paper: {
    zIndex: theme.zIndex.drawer + 299,
    border: 0,//'1px solid rgba(0, 0, 0, 0.1)'
    overflowY: 'hidden'
  },
  drawerButton: {
    top: -6,//'.4rem',
    right: '.4rem',
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 300,
    transition: theme.transitions.create('top', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  buttonClose: {
    color: 'rgb(245 245 245)',
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.54)'
    },
    borderRadius: '0 0 10rem 10rem',
  },
  buttonOpen: {
    backgroundColor: 'rgb(245 245 245)',
    '&:hover': {
      backgroundColor: 'rgb(216 216 216)'
    },
    borderRadius: '10rem 10rem 0 0',
    top: 8,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowY: 'visible',
  },
  drawerOpen: {
    height: 56,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: theme.spacing(0),//theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1,
    // },
  }
}));

function logout(e) {
  auth.logout().then(function () {
    console.log("Signed out succesfully!");
  }).catch(function (error) {
    addCloudWatchLog(`AppBar.js:93 ${error}`);
    console.error(error);
  });
}

export default function () {
  const classes = useStyles();
  const options = useOptions();
  const dispatch = useDispatch();

  const appBarEnabled = useSelector(state => state.layout.header.enabled)
  const appBarOpen = useSelector(state => state.layout.header.open)

  const handleAppBarOpen = (newOpen) => dispatch(actions.setHeaderOpen(newOpen))

  return (
    <Drawer anchor="top"
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: appBarOpen,
        [classes.drawerClose]: !appBarOpen,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: appBarOpen,
          [classes.drawerClose]: !appBarOpen,
        }),
      }}
    >
      {appBarEnabled &&
        <IconButton
          variant='contained'
          id='drawerAppbar'
          className={clsx(classes.drawerButton, {
            [classes.buttonOpen]: appBarOpen,
            [classes.buttonClose]: !appBarOpen,
          })}
          onClick={() => handleAppBarOpen(!appBarOpen)}
        >{appBarOpen ?
          <ArrowUpIcon />
          : <ArrowDownIcon />
          }
        </IconButton>
      }
      <AppBar color="primary" position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton id='settingsAppbar' onClick={() => { options(true); handleAppBarOpen(false) }} className={classes.logoutButton} color="inherit">
            <SettingsIcon />
          </IconButton>
          <IconButton id='logoutAppbar' onClick={logout} color="inherit">
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Drawer>
  );
}
