import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { InputLabel } from '@mui/material';
import { makeStyles, Typography } from '@material-ui/core';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box } from '@material-ui/core';
import { addPredictionsAsync } from '../../../../../service/admin.service';
import { useConfirm } from 'material-ui-confirm';
import { usePending } from '../../../../../GlobalComponents/Contexes/pending';
import { useHistory } from 'react-router';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from '@mui/material/colors';
import PredictionPopUpBox from './PredictionPopUpBox';
import { addCloudWatchLog } from '../../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  addPredictionRoot: {
    position: 'relative',
    top: '10%',
    left: '15%',
  },
  predictionLabel: {
    position: 'relative',
    top: '1.8rem',
    right: '13rem',
  },
  inputAndLabel: {
    paddingTop: '10px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-around',
  },
  saveButton: {
    position: 'relative',
    top: '10rem',
    left: '10rem',
  },
  cancelButton: {
    position: 'relative',
    top: '10rem',
    left: '23rem',
  },
  fileEl: {
    position: 'relative',
    bottom: '2.3rem',
    left: '11rem',
    backgroundColor: '#cfd8dc',
    borderRadius: '5%',
    width: 'fit-content',
    height: 'fit-content',
    marginBottom: '-2.37rem',
  },
  filePresent: {
    position: 'relative',
    top: '0.4rem',
    marginRight: '0.2rem',
  },
  paragraphEl: {
    display: 'inline-block',
    marginRight: '0.5rem',
  },
  fileCancelling: {
    position: 'relative',
    bottom: '0.1rem',
    '&:hover': {
      backgroundColor: '#b0bec5',
    },
  },
}));

function useAddPredictionFunction() {
  const confirm = useConfirm();
  const addPredictionFn = addPredictionsAsync;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`AddPrediction.js:83 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`AddPrediction.js:97 ${e}`);
        console.error(e);
      });
  }

  async function submit(
    _data,
    jsonError,
    fileTypeErrorMessage,
    isFileChoosen,
    isDataValid
  ) {
    if (!isFileChoosen) {
      const message = 'File not selected!';
      showMessage({ message });
      return { data: '', success: false, error: null, message };
    } else if (jsonError) {
      const message = 'Please choose json files which are in JSON format!';
      showMessage({ message });
      return { data: '', success: false, error: jsonError, message };
    } else if (fileTypeErrorMessage) {
      const message = fileTypeErrorMessage;
      showMessage({ message });
      return { data: '', success: false, error: null, message };
    } else if (!isDataValid) {
      const message = `Example file content must be as follows:

      {
          "modelIdentifier": "string",
          "predictions": [
              {
                  "imageStoragePath": "string",
                  "boundingBoxes": [array]
              },
              ...
          ]
      
      }`;
      showMessage({ message });
      return { data: '', success: false, error: null, message };
    }

    try {
      const { data, success, message } = await addPredictionFn(_data.predictions);
      showMessage({ message: 'Prediction added successfully!', title: 'Success' });
      return { data, success, message };
    } catch (e) {
      showMessage({ message: 'Bilinmeyen bir hata oluştu. Lütfen konsolu kontrol ediniz.', error: e });
      return { data: '', success: false, error: e, message: 'Bilinmeyen bir hata oluştu.' };
    }
    
  }

  return submit;
}

export default function AddPrediction() {
  const classes = useStyles();
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jsonError, setJsonError] = useState(null);
  const [fileTypeErrorMessage, setFileTypeErrorMessage] = useState(null);
  const [isFileChoosen, setFileChoosen] = useState(false);
  const [isDataValid, setValidData] = useState(false);
  const [fileName, setFileName] = useState('');
  const [token,setToken] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const pending = usePending();

  const addPredictionFn = useAddPredictionFunction();
  const history = useHistory();

  let fileReader;

  const controlData = (jsonContent) => {
    if (!jsonContent['modelIdentifier'] || jsonContent['modelIdentifier'] === '') {
      return false;
    }
    
    if (Array.isArray(jsonContent.predictions)) {

      jsonContent.predictions.map((obj) => {
        const keyArray = Object.keys(obj);

        if (
          keyArray[0] !== 'boundingBoxes' ||
          keyArray[1] !== 'imageStoragePath'
        ) {
          return false;
        }
        return true;
      });

      return true;
    }

    return false;
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;
    let jsonContent;

    try {
      jsonContent = JSON.parse(content);
      const isValid = controlData(jsonContent);
      setValidData(isValid);
      setPredictions(jsonContent);
    } catch (e) {
      setJsonError(e);
      return;
    }
  };

  const handleFileChoosen = (file) => {
    setJsonError(null);
    setFileTypeErrorMessage(null);
    setFileChoosen(false);

    if (file) {
      setFileChoosen(true);
      setFileName(file.name);
    }

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;

    if (file?.type === 'application/json') {
      fileReader.readAsText(file);
    } else {
      setFileTypeErrorMessage('Not a .json file!');
    }
  };

  async function submit(e) {
    e.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const request = { predictions };

    const { data, success, error } = await addPredictionFn(
      request,
      jsonError,
      fileTypeErrorMessage,
      isFileChoosen,
      isDataValid
    );
    setToken(data)

    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/settings/prediction');
      setFileChoosen(false);
    }
  }

  return (
    <Box         
    component='form'
    autoComplete='off'
    onSubmit={submit}>
        <Typography
            className={classes.inputAndLabel}
            variant='h4'
            id='tableTitle'
            component='div'
        >
            Prediction
        </Typography>
        <Box
        className={classes.addPredictionRoot}
        >
        <label htmlFor='upload-photo'>
            <InputLabel className={classes.predictionLabel}>Prediction Payload</InputLabel>
            <input
            style={{ display: 'none' }}
            id='upload-photo'
            name='upload-photo'
            type='file'
            accept='.json'
            onChange={(e) => handleFileChoosen(e.target.files[0])}
            onClick={(e) => (e.target.value = '')}
            />
            <Button
            startIcon={<FileUploadIcon />}
            color='primary'
            variant='contained'
            component='span'
            type='button'
            >
            Choose File
            </Button>
        </label>
        {isFileChoosen && (
            <div className={classes.fileEl}>
            <FilePresentIcon className={classes.filePresent} />
            <Typography className={classes.paragraphEl}>{fileName}</Typography>
            <Button
                endIcon={<CancelIcon sx={{ color: red['A700'] }} />}
                variant= 'text'
                className={classes.fileCancelling}
                type='button'
                onClick={() => {
                setFileChoosen(false);
                }}
            />
            </div>
        )}
        </Box>
        <Box>    
            <Button
                variant='contained'
                color='primary'
                className={classes.saveButton}
                type='submit'
                onClick={()=>setOpen(true)}
            >
                Save
            </Button>
        </Box>
        <PredictionPopUpBox open={open} token={token} onClose={handleClose}></PredictionPopUpBox>
    </Box>
  );
}