import React, { useState } from 'react';
import { InputLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import { resetPassword } from '../../../../service/admin.service';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { actions } from '../../../../redux/slices/admin';
import { addCloudWatchLog } from '../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  inputAndLabel: {
    position: 'relative',
    left: '15rem',
    top: '5rem',
  },
  inputEl: {
    width: '70%',
  },
  labelEl: {
    position: 'relative',
    top: '2.4rem',
    right: '10rem',
  },
  OKButton: {
    position: 'relative',
    left: '83.5%',
    top: '7rem',
  },
}));

function useResetPasswordFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const resetPasswordFn = resetPassword;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog(`ChangePassword.js:47 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`ChangePassword.js:61 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data, isValidPassword) {
    if (!isValidPassword) {
      const data = '';
      const success = true;
      const error = null;
      const message = 'The password confirmation does not match!';
      showMessage({ message });
      return { data, success, error, message };
    }

    const { data, success, error, message } = await resetPasswordFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.updateEnum({ enumKey: 'users', data }));
    }
    return { data, success, error, message };
  }
  return submit;
}

function InputandLabel({ inputId, label, ...otherProps }) {
  const classes = useStyles();
  const { setEmail, setRecoveryCode, setPassword, setConfirmedPassword } =
    otherProps.setters;
  const emailRegex =
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
  let strategies = new Map([
    ['0', setEmail],
    ['1', setRecoveryCode],
    ['2', setPassword],
    ['3', setConfirmedPassword],
  ]);

  function startAction(inputId, input) {
    let strategy = strategies.get(inputId);
    strategy(input);
  }

  const handleTextFieldChange = (e) => {
    startAction(e.target.id, e.target.value);
  };

  return (
    <div className={classes.inputAndLabel}>
      <InputLabel className={classes.labelEl}>{label}</InputLabel>
      <TextField
        id={inputId}
        label={label}
        className={classes.inputEl}
        onChange={handleTextFieldChange}
        type={label.includes('Password') ? 'password' : 'text'}
        inputProps={{ pattern: label === 'E-mail' ? emailRegex : undefined }}
        required
      />
    </div>
  );
}

export default function ChangePassword() {
  const classes = useStyles();
  const labels = ['E-mail', 'Code', 'Password', 'Confirm Password'];
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const resetPasswordFn = useResetPasswordFunction();

  const [email, setEmail] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  const setters = {
    setEmail,
    setRecoveryCode,
    setPassword,
    setConfirmedPassword,
  };

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const request = {
      email,
      recoveryCode,
      password,
    };

    const { success, error } = await resetPasswordFn(
      request,
      password === confirmedPassword
    );
    setLoading(false);
    pending(false);

    if (success && !error) {
    }
  }

  return (
    <Box component='form' autoComplete='off' onSubmit={submit}>
      {labels.map((label, index) => (
        <InputandLabel
          key={index}
          inputId={index.toString()}
          label={label}
          setters={{ ...setters }}
        />
      ))}
      <Button
        className={classes.OKButton}
        type='submit'
        color='primary'
        variant='contained'
        disabled={loading}
      >
        OK
      </Button>
    </Box>
  );
}
