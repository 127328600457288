import React, { createContext, useContext, useState } from 'react';
import { addCloudWatchLog } from '../../service/cloud.service';
import Options from '../Options';


const OptionsContext = createContext();

export const OptionsProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const afterClose = React.useRef();

  function handleOpen(bool, _afterClose) {
    setOpen(bool);
    afterClose.current = _afterClose;
  }
  function handleClose() {
    setOpen(false)
    try {
      let _ = afterClose.current?.()
      console.log(_)
    } catch {
      addCloudWatchLog(`options.js:22 ${afterClose.current}`);
      console.error(afterClose.current)
    }
  }
  return <OptionsContext.Provider value={handleOpen}>
    <Options open={open} onClose={handleClose} />
    {children}
  </OptionsContext.Provider>
}
export const useOptions = () => useContext(OptionsContext);