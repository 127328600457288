import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';


export default ({loading, message}) => {
  return <div style={{width: '100%', display: 'flex', position: 'fixed', top: 0, left: 0}}>
    <div style={{display: 'grid', margin: 'auto', marginTop: '40vh'}}>
      {loading&&<CircularProgress style={{margin: 'auto'}} />}
      <p>{message}</p>
    </div>
  </div>
}
