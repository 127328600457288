import { IconButton, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Box, Grid, makeStyles} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
    snackbarRoot:{
        position:'fixed',
        bottom:'10%',
        right:'0.8%',
        width: '35%',
        minHeight:'12%',
        maxHeight:'70%',
        display:'flex',
        flexDirection:'column',
        backgroundColor:'#D3D3D3'
    },
    listGroup:{
        maxHeight: '50%',
        overflowY: 'scroll',
        backgroundColor:'#fff',
        overflowX: 'hidden'
    },
    text: {
        display: 'inline',
    },
    readhide: {
        color: '#808080',
        cursor: 'pointer',
    }
}));

const ReadMore = ({ children }) => {
    const classes = useStyles();
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className={classes.text}>
        {isReadMore ? ' ' : text}
        <span onClick={toggleReadMore} className={classes.readhide}>
          {isReadMore ? "...read more" : " show less"}
        </span>
      </p>
    );
  };

const ListItem = ({item, itemId,itemName,itemCreateDate,itemStatus,status,userProblemId}) =>{
    const [info,setInfo] = useState({
        progress:'pending',
        completed:false,
    })

    useEffect(()=>{
        setInfo({
            progress:status,
            completed:item.completed,
        })
    },[status]);

    return (
        <Grid container direction="row" id='gt-progress' justifyContent="space-between" alignItems="flex-start" spacing={3} >
            <Grid item xs={1}>
               <> {itemId} </> 
            </Grid>
            <Grid item xs={9}>
                <>  {itemStatus==='FAILED'?<>{itemName}; <ReadMore>{item.description} </ReadMore></>:<> {itemName} </>}
                    {info.progress === 'pending' && <>Initializing...</>} </>
             </Grid>
             <Grid item  xs={2}>
                { info.completed ? <>{itemStatus ==='SUCCESSFUL'?
                <CheckCircleIcon id='checked-gt-icon' sx={{ color: '#00D100' }}></CheckCircleIcon>: 
                <CancelPresentationIcon id='failed-gt-icon' sx={{ color: '#FF0000' }}></CancelPresentationIcon> } </>:
                <CircularProgress id='pending-gt-icon' aria-busy={true} size={'10px'} justifycontent={'space-between'}> Not Completed! </CircularProgress>}
             </Grid>
        </Grid>
    )
}

function PopUpAnnotate(props){
    const classes = useStyles(); 
    const [userProblem,setUserProblem] = useState(null)
    
    useEffect(()=>{
        setUserProblem(props.userProblemId);
    },[props.userProblemId])

    return (props.open)? (
    <Draggable>
        <Box p={1} pt={1} display='flex' alignItems="stretch" className={classes.snackbarRoot}>
            <Grid container justifyContent= 'space-between' >
                <div className="card-header">  Job Status </div>
                <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={props.onClose}
                >
                <CloseIcon fontSize='small' />
                </IconButton>
            </Grid>
            <Grid justifycontent="flex-start" className={classes.listGroup}>
                <ul className="list-group list-group-flush">
                {(props.items.length>0 && userProblem===null)?(props.items.map(item =>(
                                <ListItem 
                                key={item.id}
                                itemId={item.id}
                                item={item}
                                itemCreateDate={item.createDate}
                                itemStatus={item.status}
                                itemName={item.jobName}
                                status={props.status}
                                userProblemId={userProblem}
                                {...item}
                                ></ListItem>
                            ))):(
                                <ListItem
                                key={props.items.id}
                                item={props.items}
                                itemId={props.items.id}
                                itemCreateDate={props.items.createDate}
                                itemStatus={props.items.status}
                                itemName={props.items.jobName}
                                status={props.status}
                                userProblemId={userProblem}
                                {...props.items}
                                ></ListItem>
                            )}
                </ul>
            </Grid>
        </Box>
    </Draggable>
    ):"";
}

export default PopUpAnnotate;