import React, { useState, useMemo } from 'react';
import { Line, Rect, Label, Text, Tag } from 'react-konva';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../../redux/slices/annotation';
import { useChange, useLabelMap } from '../../store';
import { getRelativePointerPosition } from '../../util/konvaUtils';
import useFlagCoordinates from '../../../../util/useFlagCoordinates';

const editableRegionColors = {
  gt: {
    [true]: {
      text: '#737373',
      shape: '#5eff60',
    },
    [false]: {
      text: '#737373',
      shape: '#99cc9b',
    },
  },
  pred: {
    [true]: {
      text: '#e6e6e6',
      shape: 'red',
    },
    [false]: {
      text: '#e6e6e6',
      shape: 'grey',
    },
  },
  default: {
    [true]: {
      text: '#e6e6e6',
      shape: '#3f51b595',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#2135a5',
    },
  },
  new: {
    [undefined]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [true]: {
      text: '#e6e6e6',
      shape: '#3f51b5',
    },
    [false]: {
      text: '#e6e6e6',
      shape: '#2135a5',
    },
  },
};

const flagConfigs = {
  0: {
    title: 'Bilinmeyen Flag',
    description: '??????????',
    color: '#858585',
  },
  1: {
    title: 'Hatalı "Polygonal Box"',
    description: 'Bu "polygonal box" bozuk olarak işaretlenmiş.',
    color: '#9617ea',
  },
  2: {
    title: 'Şüpheli Anomali',
    description: 'Bu "polygonal box" Burak Hoca\'ya teyit ettirilecek.',
    color: '#ea17e2',
  },
  3: {
    title: 'Onaylı',
    description: 'Bu "polygonal box" Burak Hoca tarafından onaylandı!',
    color: '#13e300',
  },
};

function useAnnotStore() {
  const dispatch = useDispatch();

  const editableRegionRectWidth = useSelector(
    (state) => state.annotation.editableRegionRectWidth
  );
  const editableRegionRectHeight = useSelector(
    (state) => state.annotation.editableRegionRectHeight
  );
  const editableRegionRectOffsetX = useSelector(
    (state) => state.annotation.editableRegionRectOffsetX
  );
  const editableRegionRectOffsetY = useSelector(
    (state) => state.annotation.editableRegionRectOffsetY
  );
  const editableRegionLineStrokeWidth = useSelector(
    (state) => state.annotation.editableRegionLineStrokeWidth
  );

  const numberOfPoints = useSelector(
    (state) => state.annotation.numberOfPoints
  );
  //TODO: Use comparision function for selector

  return {
    updateTempBox: (tb) => dispatch(actions.updateTempBox(tb)),
    setTempBox: (tb) => dispatch(actions.setTempBox(tb)),
    saveTempBox: (tb) => dispatch(actions.saveTempBox(tb)),
    putNewBox: (newBox) => dispatch(actions.putNewBox(newBox)),
    setTool: (tool) => dispatch(actions.setTool(tool)),
    setEditablePolygonalBoxFiltersDisabled: (isDisabled) =>
      dispatch(actions.setEditablePolygonalBoxFiltersDisabled(isDisabled)),
    setEditableRegionRectWidth: (width) =>
      dispatch(actions.setEditableRegionRectWidth(width)),
    setEditableRegionRectHeight: (height) =>
      dispatch(actions.setEditableRegionRectHeight(height)),

    editableRegionRectWidth,
    editableRegionRectHeight,
    editableRegionRectOffsetX,
    editableRegionRectOffsetY,
    editableRegionLineStrokeWidth,
    numberOfPoints,
  };
}

function getFlagConfigs(flagId) {
  if (flagId in flagConfigs) return flagConfigs[flagId];
  return flagConfigs[0];
}

function EditablePolygonalBoxFlag({ flags, imageSize, coords }) {
  const [open, setOpen] = useState(false);
  const { width, height } = imageSize;

  const { maxX, minY } = coords;
  function renderDetails() {
    // Expand functionality of flags
    return flags.map((flagId, i) => {
      const config = getFlagConfigs(flagId);
      return (
        <Label x={maxX * width} y={minY * height}>
          <Tag fill={config.color} />
          <Text text={config.title} fontSize={14} fill={`#ffff`} padding={2} />
        </Label>
      );
    });
  }

  // Render flags
  return (
    <>
      <Label
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        offsetY={18}
        offsetX={-5}
        x={maxX * width}
        y={minY * height}
      >
        <Tag fill={getFlagConfigs(flags[0]).color} />
        <Text
          text={`${flags.length}`}
          fontSize={14}
          fill={'#ffff'}
          padding={2}
        />
      </Label>
      {open && renderDetails()}
    </>
  );
}

const EditablePolygonalBoxes = ({
  editableRegion,
  type,
  imageSize,
  currentLayerRef,
  hideBoxes,
  flaggedOnly,
  isPatched,
  setIsDrawing,
  boxColor = 'new',
  scale,
  showMask,
  ...props
}) => {
  const showLabelsSet = useMemo(
    () => new Set(props.showLabels),
    [props.showLabels]
  );

  const [color, setColor] = useState(
    editableRegionColors[boxColor][editableRegion.isValid]
  );
  const informChange = useChange((state) => state.changed);
  const patchColor = useSelector(
    (state) => state.annotation.options.patchColor
  );
  const labels = useLabelMap((state) => state.labels);
  let text = useMemo(
    () => labels[editableRegion?.labelId],
    [labels, editableRegion.labelId]
  );
  const {
    updateTempBox,
    editableRegionRectWidth,
    editableRegionRectHeight,
    editableRegionRectOffsetX,
    editableRegionRectOffsetY,
    editableRegionLineStrokeWidth,
    setEditablePolygonalBoxFiltersDisabled,
    setEditableRegionRectWidth,
    setEditableRegionRectHeight,
    numberOfPoints,
  } = useAnnotStore();
  let { points, flags, labelId, currentCenterPoints } = editableRegion;
  const { maxX, minX, minY } = useFlagCoordinates(points);

  const getNewCenterPoints = (_points) => {
    let centerX = 0;
    let centerY = 0;

    for (const point of _points) {
      centerX += point.x;
      centerY += point.y;
    }

    centerX /= numberOfPoints + 1;
    centerY /= numberOfPoints + 1;

    return { x: centerX, y: centerY };
  };

  const makeTheShapeClosed = (index, arrangedPoints) => {
    if (index === 0) {
      arrangedPoints[numberOfPoints] = arrangedPoints[0];
    } else if (index === numberOfPoints) {
      arrangedPoints[0] = arrangedPoints[numberOfPoints];
    }
    return arrangedPoints;
  };

  const handleScaling = (event) => {
    setIsDrawing(true);
    setEditablePolygonalBoxFiltersDisabled(true);

    const reducer = showMask ? 3 : 2;
    const index = event.target.index - reducer;

    const { x, y } = getRelativePointerPosition(currentLayerRef);
    const pos = { x, y };

    let arrangedPoints = [
      ...points.slice(0, index),
      pos,
      ...points.slice(index + 1),
    ];
    arrangedPoints = makeTheShapeClosed(index, arrangedPoints);

    updateTempBox({
      points: arrangedPoints,
      currentCenterPoints: getNewCenterPoints(points),
    });

    informChange();
  };

  const handleCenterPointMove = (e) => {
    setIsDrawing(true);
    const { x: currentCenterX, y: currentCenterY } =
      getRelativePointerPosition(currentLayerRef);
    const diffX = currentCenterX - currentCenterPoints.x;
    const diffY = currentCenterY - currentCenterPoints.y;

    const movedPoints = points.map((point) => {
      return { x: point.x + diffX, y: point.y + diffY };
    });

    updateTempBox({
      currentCenterPoints: { x: currentCenterX, y: currentCenterY },
      points: movedPoints,
    });

    informChange();
  };

  function handleClick() {
    if (props.clickable) {
      if ('handleClick' in props) {
        informChange();
        props.handleClick(editableRegion);
      } else {
        editableRegion.isValid = !editableRegion.isValid;
        setColor(editableRegionColors[boxColor][editableRegion.isValid]);
      }
    }
  }

  function handleMouseEnter() {
    if (props.clickable) {
      props.handleHover(true);
    }
  }

  function handleMouseExit() {
    if (props.clickable) {
      props.handleHover(false);
    }
  }

  function handlePointMouseEnter() {
    setEditableRegionRectWidth(editableRegionRectWidth * 1.5);
    setEditableRegionRectHeight(editableRegionRectHeight * 1.5);
  }

  function handlePointMouseLeave() {
    setEditableRegionRectWidth(editableRegionRectWidth / 1.5);
    setEditableRegionRectHeight(editableRegionRectHeight / 1.5);
  }

  const clickableProps = {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseExit,
  };

  if (!showLabelsSet.has(labelId) && type !== 'temp') {
    return <></>;
  }

  if (hideBoxes) {
    return <></>;
  }

  if (!flags?.[0] && flaggedOnly) {
    return <></>;
  }

  return (
    <>
      {/* Line around the shape */}
      <Line
        {...clickableProps}
        points={points.flatMap((flattenedPoint) => [
          flattenedPoint.x * imageSize.width,
          flattenedPoint.y * imageSize.height,
        ])}
        stroke={isPatched ? patchColor : color.shape}
        strokeWidth={editableRegionLineStrokeWidth}
        opacity={1}
        onClick={handleClick}
        strokeScaleEnabled={false}
      />
      {/* Points around the shape */}
      {type === 'temp' &&
        points.map((point, index) => {
          return (
            <Rect
              key={index}
              x={point.x * imageSize.width}
              y={point.y * imageSize.height}
              offsetX={editableRegionRectOffsetX}
              offsetY={editableRegionRectOffsetY}
              width={editableRegionRectWidth / scale}
              height={editableRegionRectHeight / scale}
              fill='white'
              stroke='black'
              strokeWidth={1}
              onDragMove={handleScaling}
              draggable
              ignoreStroke
              strokeScaleEnabled={false}
              onMouseEnter={handlePointMouseEnter}
              onMouseLeave={handlePointMouseLeave}
            />
          );
        })}
      {/* The center point of the shape */}
      {type === 'temp' && (
        <Rect
          x={currentCenterPoints.x * imageSize.width}
          y={currentCenterPoints.y * imageSize.height}
          width={editableRegionRectWidth / scale}
          height={editableRegionRectHeight / scale}
          fill='white'
          stroke='black'
          strokeWidth={1}
          onDragMove={handleCenterPointMove}
          draggable
          ignoreStroke
          strokeScaleEnabled={false}
          onMouseEnter={handlePointMouseEnter}
          onMouseLeave={handlePointMouseLeave}
        />
      )}
      {text && !isPatched && (
        <Label
          {...clickableProps}
          x={minX * imageSize.width}
          y={minY * imageSize.height}
          offsetX={10}
          offsetY={50}
          onClick={handleClick}
        >
          <Tag fill={color.shape} />
          <Text text={text} fontSize={14} fill={color.text} padding={2} />
        </Label>
      )}
      {Boolean(flags?.length) && !isPatched && (
        <EditablePolygonalBoxFlag
          flags={flags}
          imageSize={imageSize}
          coords={{ maxX, minY }}
        />
      )}
    </>
  );
};

export default EditablePolygonalBoxes;
