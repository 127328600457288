import React, { useState } from 'react';
import { Typography, InputLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box } from '@material-ui/core';
import { usePending } from '../../../../GlobalComponents/Contexes/pending';
import { addUser } from '../../../../service/admin.service';
import { useDispatch } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { actions } from '../../../../redux/slices/admin';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { addCloudWatchLog } from '../../../../service/cloud.service';

const useStyles = makeStyles((theme) => ({
  inputAndLabel: {
    position: 'relative',
    left: '13%',
    top: '8%',
  },
  inputEl: {
    width: '70%',
  },
  labelEl: {
    position: 'relative',
    top: '2.4rem',
    right: '6rem',
  },
  addUserButton: {
    position: 'relative',
    left: '75%',
    top: '2rem',
  },
  userSettingsButton: {
    position: 'relative',
    top: '2%',
  },
}));

function useAddUserFunction() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const addUserFn = addUser;

  function showMessage({
    message,
    error = null,
    title = 'Hata',
    showButton = true,
  }) {
    if (error) {
      addCloudWatchLog (`AddUser.js:59 ${error}`);
      console.error(error);
    }
    confirm({
      title,
      confirmationText: 'Tamam',
      confirmationButtonProps: showButton
        ? { color: 'primary' }
        : { style: { display: 'none' } },
      cancellationButtonProps: { style: { display: 'none' } },
      description: message ?? 'Bilinmeyen bir hata oluştu.',
    })
      .then(() => {})
      .catch((e) => {
        addCloudWatchLog(`AddImage.js:73 ${e}`);
        console.error(e);
      });
  }

  async function submit(_data) {
    const { data, success, error, message } = await addUserFn(_data);

    if (!success || error) {
      showMessage({ message, error });
    } else {
      dispatch(actions.updateEnum({ enumKey: 'users', data }));
    }
    return { data, success, error, message };
  }
  return submit;
}

function InputandLabel({ inputId, label, ...otherProps }) {
  const classes = useStyles();
  const { role } = otherProps.getters;
  const { setUserName, setName, setEmail, setPassword, setTitle, setRole } =
    otherProps.setters;
  const emailRegex =
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
  let strategies = new Map([
    ['0', setUserName],
    ['1', setName],
    ['2', setEmail],
    ['3', setPassword],
    ['4', setTitle]
  ]);

  function startAction(inputId, input) {
    let strategy = strategies.get(inputId);
    strategy(input);
  }

  const handleTextFieldChange = (e) => {
    startAction(e.target.id, e.target.value);
  };

  const handleSelectChange = (e) => {
    setRole(e.target.value);
  };

  return (
    <div className={classes.inputAndLabel}>
      <InputLabel className={classes.labelEl}>{label}</InputLabel>
      {label === 'Role' ? (
        <FormControl className={classes.inputEl} required>
          <InputLabel id='select-label'>Select</InputLabel>
          <Select
            labelId='select-label'
            value={role}
            label='Select'
            onChange={handleSelectChange}
          >
            <MenuItem value={'Superuser'}>Superuser</MenuItem>
            <MenuItem value={'User'}>User</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <TextField
          id={inputId}
          label={label}
          className={classes.inputEl}
          onChange={handleTextFieldChange}
          type={label === 'Password' ? 'password' : 'text'}
          inputProps={{ pattern: label === 'E-mail' ? emailRegex : undefined }}
          required
        />
      )}
    </div>
  );
}

export default function AddUser() {
  const classes = useStyles();
  const labels = ['User Name', 'Name', 'E-mail', 'Password', 'Title', 'Role'];
  const [loading, setLoading] = useState(false);
  const pending = usePending();
  const addUserFn = useAddUserFunction();
  const history = useHistory();

  const { byId: users } = useSelector((state) => state.admin.enums.users);

  function getRoleById(roleId) {
    if (roleId === 1) {
      return 'Superuser';
    } else if (roleId === 2) {
      return 'User';
    } else {
      return 'Invalid Value';
    }
  }

 
  let initialRole = getRoleById(users[2].roleId);

  const [userName, setUserName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [title, setTitle] = useState('');
  const [role, setRole] = useState(initialRole);

  const getters = { userName, name, email, password, title, role };
  const setters = {
    setUserName,
    setName,
    setEmail,
    setPassword,
    setTitle,
    setRole,
  };

  function getRoleId(role) {
    if (role === 'Superuser') {
      return 1;
    } else if (role === 'User') {
      return 2;
    }
  }

  async function submit(e) {
    e.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    pending(true, 'Kaydediliyor...');

    const roleId = getRoleId(role);

    const request = {
      userName,
      name,
      email,
      password,
      title,
      roleId,
    };

    const { success, error } = await addUserFn(request);
    setLoading(false);
    pending(false);

    if (success && !error) {
      history.push('/admin/users/all');
    }
  }

  return (
    <Box component='form' autoComplete='off' onSubmit={submit}>
      <IconButton
        color='primary'
        className={classes.userSettingsButton}
        to='/admin/users'
        component={Link}
      >
        <ChevronLeftIcon />
        <Typography>User Settings</Typography>
      </IconButton>
      {labels.map((label, index) => (
        <InputandLabel
          key={index}
          inputId={index.toString()}
          label={label}
          getters={{ ...getters }}
          setters={{ ...setters }}
        />
      ))}
      <Button
        className={classes.addUserButton}
        type='submit'
        color='primary'
        variant='contained'
        disabled={loading}
      >
        Add User
      </Button>
    </Box>
  );
}
