import React, { useState, useRef, useEffect, useMemo } from 'react';
import { actions } from '../../redux/slices/annotation';
import {
  useSelector,
  useDispatch,
  ReactReduxContext,
  Provider,
} from 'react-redux';
import { Stage, Layer } from 'react-konva';
import { getRelativePointerPosition } from './util/konvaUtils';
import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Image from './Components/Image';
import imageProvider from './Components/ImageProvider';
import Loading from '../../GlobalComponents/LoadingMessage';
import BBoxList, { TempBox, NewBoxList } from './Components/BoundingBox';
import RightSidebar from './Components/RightSidebar';
import LeftSidebar from './Components/LeftSidebar';
import Config from './Components/Config';
import downloadURI from '../../util/downloadURI';
import Toolbar from './Components/Toolbar';
import { useChange, useLabelMap } from './store';
import MaskImage from './Components/MaskImage';
import { useImageMask } from './Contexes/imageMask';
import { actions as layoutActions } from '../../redux/slices/layout';
import Regions from './Components/PolygonalBox/Regions';
import { auth } from '../../service/auth.service';
import EditablePolygonalBoxes from './Components/EditablePolygonalBox/EditablePolygonalBoxes';
import { BrushTool } from './Components/BrushTool/BrushTool';
import { s3ClientGetObject, s3ClientWriteObject } from 'service/s3.service';
import { useImageBrush } from './Contexes/imageBrush';
import { S3_PATH } from 'service/Api';

// ['Layer', 'FastLayer', 'Group', 'Label', 'Rect', 'Circle', 'Ellipse', 'Wedge', 'Line', 'Sprite', 'Image', 'Text', 'TextPath', 'Star', 'Ring', 'Arc', 'Tag', 'Path', 'RegularPolygon', 'Arrow', 'Shape', 'Transformer'];
const tabletMode = window.innerWidth < 1024;

const useStyles = makeStyles((theme) => ({
  stage: {
    backgroundColor: '#a6a6a0',
    backgroundImage: `
      linear-gradient(rgba(255,255,255,.5) 2px, transparent 2px),
      linear-gradient(90deg, rgba(255,255,255,.5) 2px, transparent 2px),
      linear-gradient(rgba(255,255,255,.28) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255,255,255,.28) 1px, transparent 1px)`,
    backgroundSize: '100px 100px, 100px 100px, 20px 20px, 20px 20px',
    backgroundPosition: '-2px -2px, -2px -2px, -1px -1px, -1px -1px',
    backgroundBlendMode: 'soft-light',
  },
}));

function useAnnotStore() {
  const dispatch = useDispatch();

  //TODO: Use comparision function for selector
  const region = useSelector((state) => state.annotation.region);
  const editableRegion = useSelector(
    (state) => state.annotation.editableRegion
  );
  const tempBox = useSelector((state) => state.annotation.tempBox);
  const isDrawing = useSelector((state) => state.annotation.isDrawing);
  const tempLabelId = useSelector((state) => state.annotation.tempLabelId);
  const tool = useSelector((state) => state.annotation.tool);
  const cursor = useSelector((state) => state.annotation.cursor);

  const loading = useSelector((state) => state.annotation.loading);
  const regionList = useSelector((state) => state.annotation.regionList);
  const editableRegionList = useSelector((state) => state.annotation.editableRegionList);
  const newBoxes = useSelector((state) => state.annotation.newBoxes);
  const keyEventsActive = useSelector(
    (state) => state.annotation.keyEventsActive
  );
  const leftMenuOpened = useSelector(
    (state) => state.annotation.leftMenuOpened
  );

  const showGT = useSelector((state) => state.annotation.filterOptions.showGT);
  const showPred = useSelector(
    (state) => state.annotation.filterOptions.showPred
  );
  const showPoly = useSelector(
    (state) => state.annotation.filterOptions.showPoly
  );
  const showSelectedOnly = useSelector(
    (state) => state.annotation.filterOptions.showSelectedOnly
  );
  const showFlaggedOnly = useSelector(
    (state) => state.annotation.filterOptions.showFlaggedOnly
  );
  const threshold = useSelector(
    (state) => state.annotation.filterOptions.threshold
  );
  const showLabels = useSelector(
    (state) => state.annotation.filterOptions.showLabels
  );

  const pointFrequency = useSelector(
    (state) => state.annotation.pointFrequency
  );

  const lineThickness = useSelector(
    (state) => state.annotation.lineThickness
  );

  const fixedBBoxMode = useSelector(
    (state) => state.annotation.fixedBoundingBoxMode
  );

  const fixedBBoxCoefficient = useSelector(
    (state) => state.annotation.fixedBoundingBoxCoefficient
  );

  const brushToolCoefficient = useSelector(
    (state) => state.annotation.brushToolCoefficient
  );

  const paintedImagesGT = useSelector(
    (state) => state.annotation.paintedImagesGT
  );
  const arteryImagePath = useSelector(
    (state) => state.annotation.arteryImagePath
  );
  const veinImagePath = useSelector(
    (state) => state.annotation.veinImagePath
  );
  const isSaveButtonClicked = useSelector(
    (state) => state.annotation.isSaveButtonClicked
  );

  return {
    loading,
    setLoading: (bool) => dispatch(actions.setLoading(bool)),
    regionList,
    editableRegionList,
    newBoxes,
    keyEventsActive,
    region,
    editableRegion,
    selectRegion: (selectRegionId) =>
      dispatch(actions.selectRegion(selectRegionId)),
    setTempBox: (tb) => dispatch(actions.setTempBox(tb)),
    updateTempBox: (tb) => dispatch(actions.updateTempBox(tb)),
    setTempLabelId: (payload) => dispatch(actions.setTempLabelId(payload)),
    setIsDrawing: (bool) => dispatch(actions.setIsDrawing(bool)),
    setTool: (tool) => dispatch(actions.setTool(tool)),
    setPrevTool: () => dispatch(actions.setPrevTool()),
    setCursor: (cur) => dispatch(actions.setCursor(cur)),
    clearTempBox: () => dispatch(actions.clearTempBox()),

    delTempBox: () => dispatch(actions.delTempBox()),
    saveTempBox: () => dispatch(actions.saveTempBox()),
    setKeyEventsActive: (bool) => dispatch(actions.setKeyEventsActive(bool)),
    setShowPoly: (val) =>
      dispatch(actions.updateFilterOptions({ showPoly: val })),
    setBrushToolCoefficient: (val) =>
      dispatch(actions.setBrushToolCoefficient(val)),
    brushToolCoefficient,
    paintedImagesGT,
    setPaintedImagesGT: (bool) =>
      dispatch(actions.setPaintedImagesGT(bool)),
    arteryImagePath,
    setArteryImagePath: (path) =>
      dispatch(actions.setArteryImagePath(path)),
    veinImagePath,
    setVeinImagePath: (path) =>
      dispatch(actions.setVeinImagePath(path)),
    isSaveButtonClicked,
    setSaveButtonClicked: (bool) =>
      dispatch(actions.setIsSaveButtonClicked(bool)),
    setPaintedImageChanged: (bool) =>
      dispatch(actions.setPaintedImageChanged(bool)),
    tempLabelId,
    isDrawing,
    tempBox,
    tool,
    cursor,
    leftMenuOpened,
    showGT,
    showPred,
    showPoly,
    showSelectedOnly,
    showFlaggedOnly,
    threshold,
    showLabels,
    pointFrequency,
    lineThickness,
    fixedBBoxMode,
    fixedBBoxCoefficient,

    //Layout store
    disableHeader: () => dispatch(layoutActions.disableHeader()),
    enableHeader: () => dispatch(layoutActions.enableHeader()),
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

function Scene({ width, height, ...props }) {
  const [scale, setScale] = useState(1);
  const [imagePos, setImagePos] = useState({ x: 0, y: 0 });
  const [configOpen, setConfigOpen] = useState(false);
  const [hideBoxes, setHideBoxes] = useState(false);
  const [bottomAlert, setBottomAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertText, setAlertText] = useState('');
  const [labels, labelsId] = useLabelMap((store) => [
    store.labels,
    store.labelsId,
  ]);
  const [patchBBoxes, setPatchBBoxes] = useState(false);
  let savePoint = 0;
  const {userProblemId} = useSelector((state) => state.user.problem)
  const [pinchLastPosition, setPinchLastPosition] = useState(null);
  const [pinchScaling, setPinchScaling] = useState(false);
  const toolBoxDisabled = useRef(false);
  const {
    submitChanges,
    image,
    hasNext,
    jumpTo,
    jumpToWithDrSays,
    handleComplete,
    description,
    setDescription,
    evaluateDRDME,
    currentIndex,
    totalCount,
    next,
    hasPrev,
    prev,
    imObj,
  } = imageProvider();
  const { showMask, setShowMask, alpha, maskImg } = useImageMask();
  const [ arteryImageBase64, setArteryImageBase64 ] = useState(null)
  const [ veinImageBase64, setVeinImageBase64 ] = useState(null)
  const [ paintedImagePath, setPaintedImagePath ] = useState(null)

  const stageRef = useRef(null);
  const layerRef = useRef(null);
  const classes = useStyles();
  const informChange = useChange((state) => state.changed);

  const {
    loading,
    regionList,
    editableRegionList,
    newBoxes,
    keyEventsActive,

    setTempBox,
    updateTempBox,
    setTempLabelId,

    delTempBox,
    saveTempBox,
    setKeyEventsActive,

    setIsDrawing,
    setTool,
    setPrevTool,
    setShowPoly,
    setCursor,
    clearTempBox,

    tempLabelId,
    isDrawing,
    tempBox,
    tool,
    cursor,

    showGT,
    showPred,
    showPoly,
    showSelectedOnly,
    showFlaggedOnly,
    threshold,
    showLabels,
    pointFrequency,
    lineThickness,
    fixedBBoxMode,
    fixedBBoxCoefficient,

    disableHeader,
    enableHeader,
    region,
    editableRegion,
    brushToolCoefficient,
    setBrushToolCoefficient,
    leftMenuOpened,
    setPaintedImagesGT,
    setArteryImagePath,
    setVeinImagePath,
    isSaveButtonClicked,
    setSaveButtonClicked,
    setPaintedImageChanged,
  } = useAnnotStore();

  //Could there be a better practice?
  toolBoxDisabled.current =
    tempBox !== null || region !== null || editableRegion !== null;

  useEffect(() => {
    if (imObj) {
      setImagePos({ x: width / 2, y: height / 2 });
      const sc =
        imObj.width < imObj.height
          ? width / imObj.width
          : height / imObj.height;
      setScale(sc);
    }
  }, [imObj]);
  
  function resetBrushTool() {
    setArteryImageBase64(null)
    setVeinImageBase64(null)
    setPaintedImagePath(null)
    setTool('select')
  }

  useEffect(() => {
    resetBrushTool()
    setPaintedImageChanged(false)
    setPaintedImagesGT(false)
  }, [currentIndex])

  /* 
    GET BRUSH IMAGE FROM S3
  */ 
  function getBrushImageFromS3(bucket, key, name) {
    const brushData = s3ClientGetObject(bucket, key).then((res) => {
      if (name === '_artery') setArteryImageBase64(res)
      if (name === '_vein') setVeinImageBase64(res)

      return res
    }).catch((err) => {
      setArteryImageBase64(null)
      setVeinImageBase64(null)
    })
    return brushData
  }

  async function prepareBrushImageGetFromS3(name) {
    let _paintedImagePath, _bucket, _key
    let _path = 'cropped/painted_images' + S3_PATH

    _paintedImagePath = (image?.imagePath).replace('cropped', _path)
    _bucket = _paintedImagePath?.split('/')[2]
    _key = _paintedImagePath?.split('/').slice(3).join('/')
    _key = _key.split('.').slice(0, -1).join('.') + name + '.' + _key.split('.').pop()

    if (name === '_artery') setArteryImagePath(_key)
    if (name === '_vein') setVeinImagePath(_key)

    setPaintedImagePath(_paintedImagePath)
    await getBrushImageFromS3(_bucket, _key, name)

    return _key
  }

  useEffect(() => {
    if (image?.paintedImageGT?.length > 0) {
      setPaintedImagesGT(true)
      if (image?.imageAnswer) {
        image?.paintedImageGT?.forEach(({id}) => {
          setPaintedImagesGT(!(image?.imageAnswer?.groundTruthDeleteList.includes(id)))
        })
      }
    }

    if ((arteryImageBase64 == null) && (image?.imagePath !== undefined)) {
      prepareBrushImageGetFromS3('_artery')
    }

    if ((veinImageBase64 == null) && (image?.imagePath !== undefined)) {
      prepareBrushImageGetFromS3('_vein')
    }
  }, [image?.imageAnswer, image?.imagePath, paintedImagePath])

  /* 
    GET BRUSH IMAGE FROM S3
  */

  function sendAlert(text, severity) {
    setAlertText(text);
    setBottomAlert(true);
    setAlertSeverity(severity);

    setTimeout(() => {
      setBottomAlert(false);
      setAlertText('');
    }, 3000);
  }

  
  /* 
    SET BRUSH IMAGE TO S3
  */ 
  function setBrushImageToBase64(bucket, key, base64, imageName, imageType) {
    s3ClientWriteObject(bucket, key, base64, imageName, imageType).then((res) => {
      sendAlert("Fırça ile çizilen resim başarıyla S3'e kaydedildi.", 'success')
    }).catch((err) => {
      sendAlert("Fırça ile çizilen resim S3'e kaydedilemedi. Detaylar için konsolu inceleyiniz.", 'error')
      console.error(err)
    })
  }

  function prepareBrushImageToWriteS3(image, name) {
    let _bucket = paintedImagePath?.split('/')[2]
    let _key = paintedImagePath?.split('/').slice(3).join('/')

    _key = _key.split('.').slice(0, -1).join('.') + name + '.' + _key.split('.').pop()
    
    let imageName = paintedImagePath.split('/').pop().split('.').shift();
    let imageType = paintedImagePath.split('/').pop().split('.')[1];

    setBrushImageToBase64(_bucket, _key, image, imageName, imageType)
  }

  useEffect(() => {
    if (arteryImageBase64 && paintedImagePath && isSaveButtonClicked) {
      prepareBrushImageToWriteS3(arteryImageBase64, '_artery')
    }

    if (veinImageBase64 && paintedImagePath && isSaveButtonClicked) {
      prepareBrushImageToWriteS3(veinImageBase64, '_vein')
    }

    setSaveButtonClicked(false)

  }, [isSaveButtonClicked])

  /* 
    SET BRUSH IMAGE TO S3
  */ 

  // TODO: It is not a best practice to use useEffect for this, but it works. Need to find a better way.
  useEffect(() => {
    try {
      const continueLastIndex = async () => {
        function sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }
    
        // waits for 1500ms
        await sleep(1500);
        if (window.localStorage.getItem('lastCurrentIndex') && JSON.parse(window.localStorage.getItem('lastCurrentIndex'))[userProblemId] !== undefined) {
          jumpTo(JSON.parse(window.localStorage.getItem('lastCurrentIndex'))[userProblemId])
        } else {
          return
        }
      };
      continueLastIndex();  
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    var userCheck = setInterval(() => {
      if (auth.lastFiveMinutes) {
        sendAlert('Oturum sürenizin dolmasına 5 dakika kaldı! Lütfen değişikliklerinizi kaydediniz.', 'warning')
        clearInterval(userCheck);
      }
    }, 1 * 60 * 1000);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setBottomAlert(false);
    setAlertText('');
  };

  useEffect(() => {
    // AppBar is not need in this screen so disable it and enable when exit.
    disableHeader();
    return enableHeader;
  }, []);

  //TODO: Check if labelId is valid
  function handleDigitKey(key) {
    if (key <= 9 && key >= 0) {
      const labelIds = Object.keys(labels).map((k) => parseInt(k));
      setTempLabelId({
        id: key - 1 in labelIds ? labelIds[key - 1] : labelIds[0],
        checkTool: true,
      });
    }
  }

  //TODO: Split code?
  function listenKeydownEvents(e) {
    if (e.altKey && e.key == 2 ) {
      if (brushToolCoefficient >= 1) return

      setBrushToolCoefficient(brushToolCoefficient + 0.05)
      return
    } else if (e.altKey && e.key == 1) {
      if (brushToolCoefficient <= 0) return

      setBrushToolCoefficient(brushToolCoefficient - 0.05)
      return
    }
    handleDigitKey(parseInt(e.key));

    //Visual Events
    if (e.code === 'KeyW') {
      setShowMask(true);
    } else if (e.code === 'KeyE') {
      setHideBoxes(true);
      setShowPoly(false);
    }

    if (e.code === 'KeyF') {
      setPatchBBoxes(true);
    }

    //TempoBox
    if (e.code === 'KeyR') {
      delTempBox();
    } else if (e.code === 'KeyT') {
      saveTempBox();
    }

    //Tool Events
    if (toolBoxDisabled.current) {
      return;
    }
    if (e.code === 'KeyS') {
      //Select Bbox
      setTool('select');
    } else if (e.code === 'KeyD' || e.code === 'Space') {
      //Drag Image
      setTool('drag');
    } else if (e.code === 'KeyA') {
      //Add Bbox
      setTool('add');
    } else if (e.code === 'KeyQ') {
      //Poly Bbox
      setTool('poly');
    } else if (e.code === 'KeyY') {
      setTool('editablePoly');
    } else if (e.code === 'KeyB') {
      setTool('brushTool')
    } else if (e.code === 'KeyG') {
      setTool('eraser')
    } else {
      return;
    }
    e.preventDefault();
  }

  function listenKeyupEvents(e) {
    if (e.code === 'KeyW') {
      setShowMask(false);
    } else if (e.code === 'KeyE') {
      setHideBoxes(false);
      setShowPoly(true);
    }

    if (e.code === 'KeyF') {
      setPatchBBoxes(false);
    }

    if (toolBoxDisabled.current) {
      return;
    }
    if (e.code === 'Space') {
      //Select Bbox
      setPrevTool();
    } else {
      return;
    }
    e.preventDefault();
  }

  useEffect(() => {
    if (keyEventsActive) {
      window.document.addEventListener('keydown', listenKeydownEvents);
      window.document.addEventListener('keyup', listenKeyupEvents);
      return () => {
        window.document.removeEventListener('keydown', listenKeydownEvents);
        window.document.removeEventListener('keyup', listenKeyupEvents);
      };
    }
  }, [keyEventsActive, labelsId, brushToolCoefficient]);

  function setBBoxesValid(threshold) {
    let somethingChanged = false;
    let dummy = image.bboxPred?.forEach?.((bboxPred) => {
      if (
        threshold[0] <= bboxPred.confidence &&
        bboxPred.confidence <= threshold[1] &&
        showLabels.includes(bboxPred.labelId)
      ) {
        bboxPred.isValid = true;
        somethingChanged = true;
      }
    });
    console.log(dummy);
    if (somethingChanged) {
      informChange();
    }
  }

  function downloadView() {
    let imageWidth = imObj.width
    let imageHeight = imObj.height
    let dataURL = stageRef.current.toDataURL({
      pixelRatio: 7,
      x: (imagePos.x) - (imageWidth * scale / 2),
      y: (imagePos.y) - (imageHeight * scale / 2),
      width: imageWidth * scale,
      height: imageHeight * scale
    });

    let fileName = `${image?.imagePath.split('/').pop().split('.').shift()}.png`;
    downloadURI(dataURL, fileName);
  }

  function pinchZoomStart(e) {
    e.evt.preventDefault();

    if (e.evt.touches.length === 2) {
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];
      
      if (e.type === 'touchstart') {
        setPinchScaling(true);
        layerRef.current.draggable(false);

        const dist1 = Math.hypot(touch1.pageX - touch2.pageX, touch1.pageY - touch2.pageY);
        setPinchLastPosition(dist1);
      }
    }
  }

  function pinchZoomMove(e) {
    if (e.evt.touches.length === 2) {
      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      const deltaXY = Math.hypot(touch1.pageX - touch2.pageX, touch1.pageY - touch2.pageY);
      if(pinchLastPosition > deltaXY) {
        pinchZoom(e, (100));
      } else if (pinchLastPosition < deltaXY) {
        pinchZoom(e, (-100));
      }
    }
  }

  function pinchZoomEnd(e) {
    if (pinchScaling) {
      setPinchScaling(false);
      layerRef.current.draggable(true);
      setPinchLastPosition(null);
    }
  }

  function pinchZoom(e, delta) {
    let newScale = Math.min(Math.max(0.1, scale + (delta) * -0.0002 * scale), 10);

    let pointer = stageRef.current.getPointerPosition();

    let touchPointTo = {
      x: (pointer.x - layerRef.current.x()) / scale,
      y: (pointer.y - layerRef.current.y()) / scale,
    };

    let newPos = {
      x: pointer.x - touchPointTo.x * newScale,
      y: pointer.y - touchPointTo.y * newScale,
    };

    setImagePos(newPos);
    setScale(newScale);
  }

  function zoom(e) {
    e.evt.preventDefault();

    const {
      evt: { deltaY },
    } = e;

    let newScale = Math.min(Math.max(0.25, scale + deltaY * -0.001 * scale), 10);

    let pointer = stageRef.current.getPointerPosition();

    let mousePointTo = {
      x: (pointer.x - layerRef.current.x()) / scale,
      y: (pointer.y - layerRef.current.y()) / scale,
    };

    let newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    setImagePos(newPos);
    setScale(newScale);
  }

  function handleBoxHover(isEnter) {
    if (stageRef.current === null) {
      return;
    }
    if (isEnter) {
      setCursor('pointer');
    } else {
      if (tool === 'add') {
        setCursor('crosshair');
      } else if (tool === 'poly') {
        setCursor('crosshair');
      } else if(tool === 'editablePoly') {
        setCursor('');
      } else if (tool === 'brushTool') {
        setCursor('crosshair');
      } else if (tool === 'eraser') {
        setCursor('cell');
      } else {
        setCursor('');
      }
    }
  }

  //useLayoutEffect?
  useEffect(() => {
    //Handle cursor change
    if (stageRef.current) {
      stageRef.current.container().style.cursor = cursor;
    } else {
      console.log(`Couldn't set cursor because there is no stage ref`);
    }
  }, [cursor]);

  function redraw({ x1, y1 }) {
    setIsDrawing(true);
    const { x, y } = getRelativePointerPosition(layerRef.current);
    updateTempBox({ x1, y1, x2: x, y2: y });
  }

  function startDraw(e) {
    if (e.evt.button === 1) {
      return;
    }
    informChange();

    if (isDrawing) {
      return;
    }
    setIsDrawing(true);

    if(fixedBBoxMode) {
      // draw fixed bbox
      const { x, y } = getRelativePointerPosition(layerRef.current);
      setTempBox({
        boxType: 1,
        tempId: Math.random(),
        labelId: parseInt(tempLabelId),
        x1: x + (fixedBBoxCoefficient / 50),
        y1: y + (fixedBBoxCoefficient / 50),
        x2: x - (fixedBBoxCoefficient / 50),
        y2: y - (fixedBBoxCoefficient / 50),
      });
      return;
    }

    const { x, y } = getRelativePointerPosition(layerRef.current);
    setTempBox({
      boxType: 1,
      tempId: Math.random(),
      labelId: parseInt(tempLabelId),
      x1: x,
      y1: y,
      x2: x,
      y2: y,
    });
  }

  function updateDraw(e) {
    if (e.evt.button === 1) {
      return;
    }
    if (!isDrawing) {
      return;
    }
    const { x, y } = getRelativePointerPosition(layerRef.current);
    updateTempBox({ x2: x, y2: y });
  }

  function endDraw(e) {
    if (e.evt.button === 1) {
      return;
    }
    if (!isDrawing) {
      return;
    }
    setIsDrawing(false);

    const { x, y } = getRelativePointerPosition(layerRef.current);
    // Fix order of coordinates in case of drawn reverse
    const { x1, y1, x2, y2 } = tempBox;
    updateTempBox({
      x1: Math.min(x1, x2, x),
      x2: Math.max(x1, x2, x),
      y1: Math.min(y1, y2, y),
      y2: Math.max(y1, y2, y),
    });
    setCursor('');
    updateImageCenter();
  }

  function startPolyDraw(e) {
    if (e.evt.button === 1) {
      return;
    }

    informChange();
    setIsDrawing(true);
    const point = getRelativePointerPosition(layerRef.current);
    const newRegion = {
      boxType: 2,
      tempId: Math.random(),
      labelId: parseInt(tempLabelId),
      points: [point],
    };
    setTempBox(newRegion);
  }

  function updatePolyDraw(e) {
    if (e.evt.button === 1) {
      return;
    }

    if (!isDrawing) {
      return;
    }

    const point = getRelativePointerPosition(layerRef.current);

    // pick up 1/40 of the points
    savePoint++
    if (savePoint % pointFrequency == 0) {
      setTempBox({ has: true, point: point });
    }
  }

  function endPolyDraw(e) {
    if (e.evt.button === 1) {
      return;
    }

    if (!isDrawing) {
      return;
    }

    setIsDrawing(false);
    setCursor('');
    updateImageCenter();
  }

  function handleNewBoxClick(newBox) {
    const tool =
      newBox.boxType === 1
        ? 'add'
        : newBox.boxType === 2
        ? 'poly'
        : newBox.boxType === 3
        ? 'editablePoly'
        : newBox.boxType === 4
        ? 'brushTool'
        : null;
    setTool(tool);
    setCursor('');
    setTempLabelId({ id: parseInt(newBox.labelId) });
    setTempBox(newBox);
  }

  function updateImageCenter() {
    if (stageRef.current && stageRef.current.children[0]) {
      const { x, y } = stageRef.current.children[0].attrs;
    }
  }
  function handleDragEnd(callback, hide = false) {
    return () => {
      // setIsDragging(false);
      updateImageCenter();
      callback();
    };
  }

  const { stageProps, layerProps, boxProps, newBoxProps } = useMemo(() => {
    const stageProps = {},
      layerProps = {},
      boxProps = {};
    const newBoxProps = {};

    boxProps['showTags'] = showLabels.length !== 1;
    boxProps['showLabels'] = showLabels;

    // layerProps['onDragStart'] = () => setIsDragging(true);
    layerProps['onDragMove'] = () =>
      cursor !== 'grabbing' && setCursor('grabbing');
    layerProps['onDragEnd'] = handleDragEnd(() => setCursor('grab'));

    if (tool === 'drag') {
      setHideBoxes(false);
      layerProps['draggable'] = true;
    } else if (tool === 'select') {
      setHideBoxes(false);
      boxProps['clickable'] = true;
      boxProps['handleHover'] = handleBoxHover;
      layerProps['draggable'] = true;
      newBoxProps['handleClick'] = handleNewBoxClick;
    } else if (tool === 'add') {
      setHideBoxes(false);
      boxProps['display'] = false;
      layerProps['draggable'] = !isDrawing;

      if (!(tempBox !== null && !isDrawing)) {
        layerProps['onDragEnd'] = handleDragEnd(() => setCursor('crosshair'));
        layerProps['onMouseDown'] = startDraw;
        layerProps['onMouseMove'] = updateDraw;
        layerProps['onMouseUp'] = endDraw;

        // touch events
        layerProps['onTouchStart'] = startDraw;
        layerProps['onTouchMove'] = updateDraw;
        layerProps['onTouchEnd'] = endDraw;
      }
    } else if (tool === 'poly') {
      boxProps['display'] = false;
      layerProps['draggable'] = !isDrawing;
      setHideBoxes(true);

      if (!(region !== null && !isDrawing)) {
        layerProps['onDragEnd'] = handleDragEnd(() => setCursor('crosshair'));
        layerProps['onMouseDown'] = startPolyDraw;
        layerProps['onMouseMove'] = updatePolyDraw;
        layerProps['onMouseUp'] = endPolyDraw;

        // touch events
        layerProps['onTouchStart'] = startPolyDraw;
        layerProps['onTouchMove'] = updatePolyDraw;
        layerProps['onTouchEnd'] = endPolyDraw;

      }
    } else if (tool === 'editablePoly') {
      boxProps['display'] = false;
      layerProps['draggable'] = !isDrawing;
      setHideBoxes(true);

      if (editableRegion !== null) {
        layerProps['onDragMove'] = () => cursor !== 'nwse-resize' && setCursor('nwse-resize');
        layerProps['onDragEnd'] = handleDragEnd(() => setCursor('nwse-resize'));
      }
    } else if (tool === 'brushTool') {
      boxProps['display'] = false;
      setHideBoxes(true);
    } else if (tool === 'eraser') {
      boxProps['display'] = false;
      layerProps['draggable'] = !isDrawing;
      setHideBoxes(true);
    } else {
      setHideBoxes(false);
      throw Error(`Unexpected toolName given: ${tool}`);
    }
    return { stageProps: stageProps, layerProps, boxProps, newBoxProps };
  }, [
    tool,
    tempBox === null,
    region,
    editableRegion === null,
    isDrawing,
    tempLabelId,
    cursor,
    showLabels.length,
    fixedBBoxMode,
    fixedBBoxCoefficient
  ]);

  function dragFunction(i, e, _width, _height) {
    let _regionPoints = Object.assign([], region.points)

    _regionPoints[i] = {
        x: parseFloat((e.target.x() / _width).toFixed(5)),
        y: parseFloat((e.target.y() / _height).toFixed(5))
      }
  
    updateTempBox({ points: _regionPoints });
  }
  const imageSize = imObj
    ? { width: imObj.width, height: imObj.height }
    : { width: 0, height: 0 };
  const allBoxProps = { imageSize, scale, patch: patchBBoxes, ...boxProps };
  const rightActionsDisabled =
    tempBox !== null || region !== null || editableRegion !== null;

  return (
    <>
      <Snackbar open={bottomAlert} autoHideDuration={3000}>
        <Alert onClose={handleClose} severity={alertSeverity}>
          {alertText}
        </Alert>
      </Snackbar>
      <Toolbar
        currentTool={tool}
        display={!(loading || toolBoxDisabled.current)}
        handleChange={setTool}
        imageSize={imageSize}
        imagePath= {loading ? '...' : image?.imagePath}
      />
      <Config open={configOpen} onClose={() => setConfigOpen(false)} />
      <RightSidebar
        contentProps={{
          setKeyEventsActive,
          setSaveButtonClicked,
          save: {
            description,
            setDescription,
            disabled: loading || rightActionsDisabled,
            submitChanges,
          },
          searchBox: { jumpTo, jumpToWithDrSays, disabled: loading || rightActionsDisabled },
          pagination: {
            jumpTo,
            handleComplete,
            currentIndex: currentIndex + 1,
            totalCount,
            jumpDisabled: loading || rightActionsDisabled,
            next,
            hasNext,
            nextDisabled: loading || !hasNext || rightActionsDisabled,
            prev,
            hasPrev,
            prevDisabled: loading || !hasPrev || rightActionsDisabled,
          },
          details: { imagePath: loading ? '...' : image?.imagePath },
          filter: { setBBoxesValid },
          snapshot: {
            download: downloadView,
            disabled: loading || rightActionsDisabled,
          },
          evaluateDRDME,
        }}
      />
        <LeftSidebar
          open={
            tabletMode ? 
            (leftMenuOpened && (tool === 'add' || tool === 'poly' || tool === 'editablePoly' || tool === 'brushTool')) : 
            (tool === 'add' || tool === 'poly' || tool === 'editablePoly' || tool === 'brushTool')}
          onClose={clearTempBox}
          whichTool={tool}
        />
      {loading ? (
        <Loading loading message='Resim Yükleniyor...' />
      ) : (
        //INFO: Reason to use Redux Context -> https://github.com/konvajs/react-konva/issues/311
        //TODO: Since this issue is being solved, we can use redux instead of zustand. Migrate './store' folder to redux.
        <ReactReduxContext.Consumer>
          {({ store }) => (
            <Stage
              className={classes.stage}
              width={width}
              height={height}
              ref={stageRef}
              fill='orange'
              onWheel={zoom}
              onTouchStart={pinchZoomStart}
              onTouchMove={pinchZoomMove}
              onTouchEnd={pinchZoomEnd}
              {...stageProps}
            >
              <Provider store={store}>
                <Layer
                  ref={layerRef}
                  scaleX={scale}
                  scaleY={scale}
                  offsetX={imageSize.width / 2}
                  offsetY={imageSize.height / 2}
                  {...imagePos}
                  {...layerProps}
                >
                  {imObj && (
                    <>
                      <Image imObj={imObj} />
                      {/* <ProcessedImage imObj={imObj} /> */}
                      {showMask && (
                        <MaskImage alpha={alpha} maskImg={maskImg} />
                      )}
                      {tempBox ? (
                        <TempBox
                          hideBoxes={hideBoxes}
                          imageSize={imageSize}
                          bbox={tempBox}
                          isDrawing={isDrawing}
                          scale={scale}
                          updateCoords={setTempBox}
                          setCursor={setCursor}
                          redraw={redraw}
                          patch={patchBBoxes}
                        />
                      ) : region ? (
                        <Regions
                          type='temp'
                          region={region || []}
                          imageSize={imageSize}
                          dragFunction={dragFunction}
                          hideBoxes={!showPoly}
                          lineThickness={lineThickness}
                        />
                      ) : editableRegion ? (
                        <EditablePolygonalBoxes
                          type='temp'
                          editableRegion={editableRegion}
                          imageSize={imageSize}
                          hideBoxes={!showPoly}
                          currentLayerRef={layerRef.current}
                          setIsDrawing={setIsDrawing}
                          scale={scale}
                          showMask={showMask}
                        />
                      ) : (
                        <>
                          <BBoxList
                            hideBoxes={hideBoxes || !showPred}
                            selectedOnly={showSelectedOnly}
                            flaggedOnly={showFlaggedOnly}
                            threshold={threshold}
                            items={image.bboxPred || []}
                            itemProps={allBoxProps}
                            showLabels={showLabels}
                            color='pred'
                          />
                          {/*This component shows IR Kanama: 100% box on the image. */}
                          <BBoxList
                            hideBoxes={hideBoxes || !showGT}
                            selectedOnly={showSelectedOnly}
                            flaggedOnly={showFlaggedOnly}
                            threshold={threshold}
                            items={image.bboxGT || []}
                            itemProps={allBoxProps}
                            showLabels={showLabels}
                            color='gt'
                          />
                          {/* This NewBoxList(inside the BoundingBox.js) handles drawed rectangle boxes on the image.
                            NewBoxList(inside the NewBoxList.js) called from the LeftSideBar handles labels on the left side.
                         */}
                          <NewBoxList
                            hideBoxes={hideBoxes}
                            tool={tool}
                            flaggedOnly={showFlaggedOnly}
                            threshold={threshold}
                            items={newBoxes || []}
                            itemProps={{ ...allBoxProps, ...newBoxProps }}
                            color='new'
                          />
                          {regionList.map((region, index) => (
                            <Regions
                              key={index}
                              region={region || []}
                              imageSize={imageSize}
                              hideBoxes={!showPoly}
                              isPatched={patchBBoxes}
                              flaggedOnly={showFlaggedOnly}
                              lineThickness={lineThickness}
                              {...allBoxProps}
                              {...newBoxProps}
                            />
                          ))}
                          {image.regionPred?.map((region, index) => (
                            <Regions
                              key={index}
                              region={region || []}
                              imageSize={imageSize}
                              hideBoxes={!showPoly}
                              isPatched={patchBBoxes}
                              flaggedOnly={showFlaggedOnly}
                              showPred={showPred}
                              threshold={threshold}
                              boxColor='pred'
                              lineThickness={lineThickness}
                              {...allBoxProps}
                            />
                          ))}
                          {image.regionGT?.map((region, index) => (
                            <Regions
                              key={index}
                              region={region || []}
                              imageSize={imageSize}
                              hideBoxes={!showPoly}
                              isPatched={patchBBoxes}
                              flaggedOnly={showFlaggedOnly}
                              showGt={showGT}
                              boxColor='gt'
                              lineThickness={lineThickness}
                              {...allBoxProps}
                            />
                          ))}
                          {editableRegionList.map((editableRegion, index) => (
                            <EditablePolygonalBoxes
                              key={index}
                              editableRegion={editableRegion}
                              imageSize={imageSize}
                              currentLayerRef={layerRef.current}
                              hideBoxes={!showPoly}
                              isPatched={patchBBoxes}
                              flaggedOnly={showFlaggedOnly}
                              {...allBoxProps}
                              {...newBoxProps}
                            />
                          ))}
                        </>
                      )}
                    </>

                  )}
                  {(tool === 'brushTool' || tool === 'eraser') && (
                    <BrushTool
                      arteryImage={arteryImageBase64}
                      veinImage={veinImageBase64}
                      setArteryImg={setArteryImageBase64}
                      setVeinImg={setVeinImageBase64}
                      imageWidth={imObj?.width}
                      imageHeight={imObj?.height}
                      tool={tool}
                      isDrawing={isDrawing}
                      setIsDrawing={setIsDrawing}
                      tempLabelId={tempLabelId}
                      layerRef={layerRef}
                      imageData={image}
                    />
                )}
                </Layer>
              </Provider>
            </Stage>
          )}
        </ReactReduxContext.Consumer>
      )}
      {/* <TempBoxButtons imageCenter={imageCenter} imageSize={imageSize} scale={scale} isDragging={isDragging} /> */}
    </>
  );
}

export default Scene;
