import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import GetUsers from '../Components/UserManagement/GetUsers';
import AddUser from '../Components/UserManagement/AddUser';
import EditUser from '../Components/UserManagement/EditUser';
import ChangePassword from '../Components/UserManagement/ChangePassword';

export default function () {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.url}/all`} component={GetUsers}></Route>
        <Route path={`${match.url}/create`} component={AddUser}></Route>
        <Route path={`${match.url}/edit/:id`} component={EditUser}></Route>
        <Route path={`${match.url}/changepassword`} component={ChangePassword}></Route>
      </Switch>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/all`} />
      </Switch>
    </>
  );
}
